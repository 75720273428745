import React from "react";
import { Serialized } from "@barona/lapa-common-types";
import { Cell, HeaderCell, HeaderRow, Row, Table, TableBody } from "../../../components/Table";
import CheckboxInput from "../../../components/form/CheckboxInput";
import Loading from "../../../components/Loading";
import { ProductItem } from "@barona/lapa-common-frontend";

const unitOptions = [
  { label: "h", value: "h" },
  { label: "kpl", value: "pcs" },
];

export const getUnit = (unitValue: string | null) => unitOptions.find((unit) => unit.value === unitValue)?.label || "";

interface AvailableProductsProps {
  products: Serialized<ReadonlyArray<ProductItem>>;
  selectedProductCodes: string[];
  handleOnProductsOnSelect: (productCodes: string[]) => void;
  isLoading?: boolean;
}

const AvailableProducts = ({
  products,
  selectedProductCodes,
  handleOnProductsOnSelect,
  isLoading,
}: AvailableProductsProps) => {
  const allProductCodes = products.map((product) => product.productCode);
  const isAllChecked =
    allProductCodes.every((productCode) => selectedProductCodes.includes(productCode)) && !!allProductCodes.length;

  return (
    <>
      <Table>
        <HeaderRow>
          <HeaderCell style={{ width: "5%" }}>
            <CheckboxInput
              data-testid="CheckAllProducts"
              checked={isAllChecked}
              onChange={() => {
                if (isAllChecked) {
                  handleOnProductsOnSelect([]);
                } else {
                  handleOnProductsOnSelect(allProductCodes);
                }
              }}
            />
          </HeaderCell>
          <HeaderCell style={{ width: "35%" }}>TUOTE / PALKKALAJI</HeaderCell>
          <HeaderCell style={{ width: "20%" }}>NAV-KOODI</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>YKSIKKÖ</HeaderCell>
        </HeaderRow>
        <TableBody>
          {!isLoading &&
            products?.map((product) => (
              <Row key={product.productCode} data-testid="AvailableProductRow">
                <Cell>
                  <CheckboxInput
                    data-testid="CheckAvailableProduct"
                    checked={isAllChecked || selectedProductCodes.includes(product.productCode)}
                    onChange={() => {
                      if (selectedProductCodes.includes(product.productCode)) {
                        handleOnProductsOnSelect(
                          selectedProductCodes.filter((productCode) => productCode !== product.productCode)
                        );
                      } else {
                        handleOnProductsOnSelect([...selectedProductCodes, product.productCode]);
                      }
                    }}
                  />
                </Cell>
                <Cell>{product.name}</Cell>
                <Cell>{product.productCode}</Cell>
                <Cell>{getUnit(product.unit)}</Cell>
              </Row>
            ))}
        </TableBody>
      </Table>
      {isLoading && <Loading height={36} count={7} />}
    </>
  );
};

export default AvailableProducts;
