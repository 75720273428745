import styled from "styled-components";
import Color from "../../colors";

export const ContentSeparator = styled.div`
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
  height: 10px;
  margin: 0 -27px 20px;
`;

export const ContentSeparatorLight = styled.div`
  border-top: 1px solid ${Color.GREY_LIGHT};
  height: 10px;
  margin: 20px -26px 20px;
`;
