import { PriceType } from "@barona/lapa-common-types";
import React, { useCallback } from "react";
import styled from "styled-components";
import TextInput from "../../components/form/TextInput";
import Dropdown, { DropdownValue } from "../../components/Dropdown";
import { Cell } from "../../components/Table";
import { TimesCircle } from "@styled-icons/fa-solid/TimesCircle";
import Color from "../../colors";
import CheckboxInput from "../../components/form/CheckboxInput";
import ExpandableRow from "../../components/Table.ExpandableRow";
import ProductGroupProductsTable from "./ProductGroupProductsTable";
import { frontendApi } from "../../services/api";
import { ProductGroupProductItem } from "@barona/lapa-common-frontend";
import { NotificationState, useNotifications } from "../../context";

export const RemoveIcon = styled(TimesCircle)`
  height: 16px;
  width: 16px;
  padding-right: 20px;
  color: ${Color.LAPA_BLUE};
  cursor: pointer;
`;

export const priceTypeOptions = [
  { label: "Kerroin", value: PriceType.Multiplier },
  { label: "Hinta", value: PriceType.Fixed },
];

export const FillAvailableTextInput = styled(TextInput)`
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

const ExpandableProductGroupRow = styled(ExpandableRow)`
  background-color: ${Color.LIGHT_BLUE};
  box-shadow: inset 0 -1px 0 0 ${Color.LIGHTER_BLUE};

  &:nth-child(even) {
    background-color: ${Color.LIGHT_BLUE};
  }
`;

const BoldCell = styled(Cell)`
  font-weight: bold;
`;

export const getPriceType = (value: string) => priceTypeOptions.find((priceType) => priceType.value === value)!;

export interface ProductGroupItemProps {
  productGroupProductPricings: ReadonlyArray<ProductGroupProductItem>;
  name: string;
  priceType?: string;
  priceValue?: string;
  billable: boolean;
  productGroupId: string;
  priceListId?: string;
  isOpen: boolean;
  disabled?: boolean;
  handlePriceValueOnChange: (productGroupId: string, value: string) => void;
  handlePriceTypeOnChange: (productGroupId: string, value: DropdownValue | null) => void;
  handleBillableOnChange: (productGroupId: string, value: boolean) => void;
  handleProductPriceValueOnChange: (productGroupId: string, productCode: string, value: string) => void;
  handleProductPriceTypeOnChange: (productGroupId: string, productCode: string, value: DropdownValue | null) => void;
  handleProductBillableOnChange: (productGroupId: string, productCode: string, value: boolean) => void;
  handleOverridePrice: (productGroupId: string, productCode: string) => void;
  refreshProductGroups: () => void;
  handleOnExpandClick: (productGroupId: string, isOpen: boolean) => void;
}

const ProductGroupItem = (props: ProductGroupItemProps) => {
  const {
    productGroupProductPricings,
    name,
    priceType,
    priceValue,
    billable = true,
    productGroupId,
    priceListId,
    isOpen,
    disabled = false,
    handlePriceValueOnChange,
    handlePriceTypeOnChange,
    handleBillableOnChange,
    handleProductPriceValueOnChange,
    handleProductPriceTypeOnChange,
    handleProductBillableOnChange,
    handleOverridePrice,
    refreshProductGroups,
    handleOnExpandClick,
  } = props;

  const { addNotification } = useNotifications();

  const handleCancelPriceOverride = useCallback(
    async (productCode: string) => {
      if (!priceListId) {
        throw new Error("priceListId not set");
      }

      await frontendApi.cancelGroupOverridePrice({ priceListId, productCode, productGroupId });
      addNotification("Tuotteen hinta palautettu ryhmän mukaiseksi", NotificationState.Success);
      refreshProductGroups();
    },
    [priceListId, productGroupId, refreshProductGroups, addNotification]
  );

  return (
    <ExpandableProductGroupRow
      onStateChange={(newState) => {
        handleOnExpandClick(productGroupId, newState);
      }}
      isOpenInitially={isOpen}
      key={name}
      renderExpandedContent={
        <ProductGroupProductsTable
          productItems={productGroupProductPricings}
          productGroupId={productGroupId}
          cancelPriceOverride={handleCancelPriceOverride}
          overridePrice={handleOverridePrice}
          handlePriceValueOnChange={handleProductPriceValueOnChange}
          handlePriceTypeOnChange={handleProductPriceTypeOnChange}
          handleBillableOnChange={handleProductBillableOnChange}
          isLoading={false}
          disabled={disabled}
        />
      }
    >
      <BoldCell>{`${name} (${productGroupProductPricings?.length})`}</BoldCell>
      <Cell>
        <FillAvailableTextInput
          data-testid="GroupPriceValue"
          value={priceValue ?? ""}
          disabled={!billable || disabled}
          onChange={(event) => {
            handlePriceValueOnChange(productGroupId, event.currentTarget.value);
          }}
        />
      </Cell>
      <Cell>
        <Dropdown
          testId="GroupPriceType"
          value={priceType ? getPriceType(priceType) : undefined}
          options={priceTypeOptions}
          placeholder=""
          onChange={(value) => handlePriceTypeOnChange(productGroupId, value)}
          isDisabled={disabled}
        />
      </Cell>
      <Cell>
        <CheckboxInput
          data-testid="GroupBillable"
          checked={billable}
          onChange={(event) => handleBillableOnChange(productGroupId, event.currentTarget.checked)}
          disabled={disabled}
        />
      </Cell>
      <Cell />
      <Cell />
    </ExpandableProductGroupRow>
  );
};

export default React.memo(ProductGroupItem);
