import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface Props {
  className?: string;
  count?: number;
  height?: number;
}

const Container = styled.div``;

const Loading = ({ className, count = 8, height }: Props) => (
  <Container className={className} data-testid="Loading">
    <Skeleton count={count} height={height} />
  </Container>
);

export default Loading;
