import { FunctionComponent, PropsWithChildren } from "react";
import styled from "styled-components";
import Color from "../colors";

const ModalContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const ModalSection = styled.section`
  border-left: ${Color.LAPA_BLUE} 8px solid;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.WHITE};
  min-width: 25%;
  min-height: 25%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

interface ModalProps {
  show: boolean;
  testId?: string;
}

const IndicatorModal: FunctionComponent<PropsWithChildren<ModalProps>> = ({ show, testId, children }) => (
  <ModalContainer data-testid={testId ?? ""} show={show}>
    <ModalSection>{children}</ModalSection>
  </ModalContainer>
);

const Modal = ({ show, testId, children }: PropsWithChildren<ModalProps>) =>
  show ? (
    <ModalContainer data-testid={testId ?? ""} show={show}>
      {children}
    </ModalContainer>
  ) : null;

export { Modal, IndicatorModal };
