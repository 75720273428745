import { useState, useEffect } from "react";
import InvoiceList from "./InvoiceList";
import { useInvoiceCustomers, useInvoices } from "../../services/invoices";
import { useBaronaCompany } from "../../context";
import { InvoiceStatus } from "@barona/lapa-common-types";
import styled from "styled-components";
import Select from "../../components/form/Select";
import DatePicker from "../../components/DatePicker";
import { DEFAULT_PAGE_SIZE } from "../../components/Table.Pagination";

const InvoiceFilterContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

const SelectContainer = styled.div`
  width: 300px;
`;

const SelectLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
`;

const DateRangeContainer = styled.div`
  display: grid;
  align-items: center;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const sentAndDiscardedStatuses = [InvoiceStatus.sent, InvoiceStatus.discarded];

const ArchivedInvoices = () => {
  const { baronaCompany } = useBaronaCompany();
  const [customer, setCustomer] = useState<{ label: string; value: string } | null>(null);
  const [invoiceDateStart, setInvoiceDateStart] = useState<Date | undefined>();
  const [invoiceDateEnd, setInvoiceDateEnd] = useState<Date | undefined>();

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    invoices,
    totalCount: totalInvoiceCount,
    isLoading: isLoadingInvoices,
  } = useInvoices(
    {
      baronaCompany,
      customerId: customer?.value,
      statuses: sentAndDiscardedStatuses,
      invoiceDateStart,
      invoiceDateEnd,
    },
    { limit: pageSize, skip: currentPage > 1 ? (currentPage - 1) * pageSize : 0 }
  );

  const { invoiceCustomers, isLoading: isLoadingInvoiceCustomers } = useInvoiceCustomers({
    baronaCompany,
    statuses: sentAndDiscardedStatuses,
    invoiceDateStart,
    invoiceDateEnd,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [baronaCompany, customer?.value, invoiceDateStart, invoiceDateEnd]);

  return (
    <>
      <InvoiceFilterContainer>
        <SelectContainer>
          <SelectLabel>Asiakas</SelectLabel>
          <Select
            noOptionsMessage={() => "Ei asiakkaita"}
            placeholder="Valitse asiakas"
            isLoading={isLoadingInvoiceCustomers}
            options={invoiceCustomers.map(({ id, name }) => ({ label: name, value: id }))}
            onChange={(value) => setCustomer(value)}
            value={customer}
            isClearable
          />
        </SelectContainer>
        <SelectContainer>
          <SelectLabel>Laskun pvm</SelectLabel>
          <DateRangeContainer>
            <DatePicker
              width={140}
              selected={invoiceDateStart}
              onChange={setInvoiceDateStart}
              placeholderText="Alkupäivä"
            />
            —
            <DatePicker
              width={140}
              selected={invoiceDateEnd}
              onChange={setInvoiceDateEnd}
              placeholderText="Loppupäivä"
            />
          </DateRangeContainer>
        </SelectContainer>
      </InvoiceFilterContainer>
      <InvoiceList
        invoices={invoices}
        isLoading={isLoadingInvoices}
        type="archive"
        currentPage={currentPage}
        onSetCurrentPage={(page) => setCurrentPage(page)}
        pageSize={pageSize}
        onSetPageSize={(pageSize) => setPageSize(pageSize)}
        pageCount={Math.ceil(totalInvoiceCount / pageSize)}
      />
    </>
  );
};

export default ArchivedInvoices;
