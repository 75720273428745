import Loading from "../../components/Loading";
import styled from "styled-components";
import {
  Table,
  HeaderRow,
  HeaderCell,
  Row,
  Cell,
  RigthAlignCell,
  RigthAlignHeaderCell,
  TableBody,
} from "../../components/Table";
import { useDraftInvoicesSummary } from "../../services/invoices";
import Big from "big.js";
import LoadReconciliationReportButton from "./components/LoadReconciliationReportButton";
import { SidePane } from "react-side-pane";
import Color from "../../colors";
import { Close } from "@styled-icons/evaicons-solid/Close";

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 95% 5%;
`;

const DownloadContainer = styled.div`
  text-align: right;
  margin: 16px;
`;

const InvoicesSummaryContainer = styled.div`
  ${Cell} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const CloseIcon = styled(Close)`
  height: 24px;
  color: ${Color.BLACK};
  cursor: pointer;
`;

const RightAlignBoldCell = styled(RigthAlignCell)`
  font-weight: bold;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const SidePaneContent = styled.div`
  margin: 30px;
`;

export interface InvoicesSummaryProps {
  invoiceIds: string[];
  hasSelectedIds: boolean;
  isLoadingInvoices: boolean;
  customerId?: string;
  customerReference?: string | null;
  period?: { periodStart: Date; periodEnd: Date };
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

export interface InvoicesSummaryContentProps extends Omit<InvoicesSummaryProps, "isOpen"> {}

export const InvoicesSummaryContent = (props: InvoicesSummaryContentProps) => {
  const { customerId, customerReference, period, invoiceIds, hasSelectedIds, isLoadingInvoices, setIsOpen } = props;
  const { draftInvoicesSummary, isLoading } = useDraftInvoicesSummary(invoiceIds);
  const totalSum = draftInvoicesSummary.reduce((sum, item) => sum.add(Big(item.amount)), Big(0));
  const totalCount = draftInvoicesSummary.reduce((count, item) => count + Number(item.count), 0);
  return (
    <SidePaneContent data-testid="SidePaneContent">
      <HeaderContainer>
        <Title>
          {hasSelectedIds ? `Täsmäytystiedot valituille laskuille (${invoiceIds.length})` : "Täsmäytystiedot"}
        </Title>
        <CloseIcon
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </HeaderContainer>
      <InvoicesSummaryContainer>
        <DownloadContainer>
          <LoadReconciliationReportButton
            invoiceIds={invoiceIds}
            customerId={customerId}
            customerReference={customerReference}
            period={period}
            isLoadingInvoices={isLoadingInvoices}
          />
        </DownloadContainer>
        <Table>
          <HeaderRow>
            <HeaderCell style={{ width: "25%" }}>PALKKALAJI</HeaderCell>
            <HeaderCell style={{ width: "25%" }}>RESURSSI</HeaderCell>
            <RigthAlignHeaderCell style={{ width: "25%" }}>MÄÄRÄ</RigthAlignHeaderCell>
            <RigthAlignHeaderCell style={{ width: "25%" }}>LASKUTUSSUMMA</RigthAlignHeaderCell>
          </HeaderRow>
          <TableBody>
            {!isLoading && !isLoadingInvoices && (
              <>
                {draftInvoicesSummary.map((summary, i) => (
                  <Row key={summary.productCode} data-testid="DraftSummaryItem">
                    <Cell>{summary.productName}</Cell>
                    <Cell>{summary.productCode}</Cell>
                    <RigthAlignCell>
                      {Number.isInteger(Number(summary.count))
                        ? Number(summary.count)
                        : Number(summary.count).toFixed(2)}
                    </RigthAlignCell>
                    <RigthAlignCell>
                      {Number(summary.amount).toFixed(2)}
                      {" €"}
                    </RigthAlignCell>
                  </Row>
                ))}
                <Row key="summary" data-testid="DraftSumItem">
                  <Cell></Cell>
                  <Cell></Cell>
                  <RightAlignBoldCell>
                    {Number.isInteger(totalCount) ? totalCount : totalCount.toFixed(2)}
                  </RightAlignBoldCell>
                  <RightAlignBoldCell>
                    {totalSum.toNumber().toFixed(2)} {" €"}
                  </RightAlignBoldCell>
                </Row>
              </>
            )}
          </TableBody>
        </Table>
        {(isLoading || isLoadingInvoices) && <Loading height={36} count={4} />}
      </InvoicesSummaryContainer>
    </SidePaneContent>
  );
};

const InvoicesSummary = (props: InvoicesSummaryProps) => {
  const { isOpen, setIsOpen } = props;
  return (
    <SidePane open={isOpen} width={40} onClose={() => setIsOpen(false)} style={{ overflow: "auto" }}>
      <InvoicesSummaryContent {...props} />
    </SidePane>
  );
};

export default InvoicesSummary;
