import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Outlet, useMatch, useParams, NavLink, NavLinkProps } from "react-router-dom";
import { LapaNavigationLink } from "../components/Link";
import PageContainer from "./common/PageContainer";
import { PageHeader } from "./common/PageHeader";
import { frontendApi } from "@barona/lapa-common-frontend";
import Color from "../colors";
import Notifications from "./common/Notifications";

const SubNavigationBar = styled.nav`
  display: flex;
  align-items: center;
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
  margin: 0 -27px;
  padding: 0 27px;
`;

const NavLinkWithActiveSupport = (props: NavLinkProps) => (
  <NavLink {...props} className={({ isActive }) => `${props.className} ${isActive ? "active" : ""}`.trim()} />
);

const SubNavigationBarLink = styled(NavLinkWithActiveSupport)`
  font-size: 14px;
  text-decoration: none;
  color: ${Color.GREY};
  margin-right: 40px;
  padding: 20px 0;
  &.active {
    border-bottom: solid 4px ${Color.LAPA_BLUE};
    margin-top: 4px;
    color: ${Color.BLACK};
  }
`;

const CustomerPage = () => {
  const { customerId } = useParams<"customerId">();
  const isEditingPricelist = useMatch("/customers/:id/pricelists/:id");
  const isEditingInvoicingModel = useMatch("/customers/:id/invoicing-models/:id");
  const isViewingPricelistHistory = useMatch("/customers/:id/pricelisthistory/:id/");
  const [customerName, setCustomerName] = useState(customerId);
  const [customerBusinessId, setCustomerBusinessId] = useState("");

  useEffect(() => {
    const fetchCustomerName = async () => {
      if (!customerId) return;
      const customerName = await frontendApi.fetchCustomerName({ customerId });
      setCustomerName(customerName || customerId);
      const businessId = await frontendApi.fetchCustomerBusinessId({ customerId });
      setCustomerBusinessId(businessId || "");
    };

    fetchCustomerName();
  }, [customerId]);

  if (isEditingPricelist || isEditingInvoicingModel || isViewingPricelistHistory) return <Outlet />;

  return (
    <PageContainer>
      <LapaNavigationLink to={".."}>{"<"} Takaisin asiakaslistaan</LapaNavigationLink>
      <PageHeader>Asiakas {`${customerName}${customerBusinessId ? ` (${customerBusinessId})` : ""}`}</PageHeader>
      <Notifications />
      <SubNavigationBar>
        <SubNavigationBarLink to="pricelists">Hinnastot</SubNavigationBarLink>
        <SubNavigationBarLink to="references">Viitteet</SubNavigationBarLink>
        <SubNavigationBarLink to="invoicing-models">Laskutusmallit</SubNavigationBarLink>
      </SubNavigationBar>
      <Outlet />
    </PageContainer>
  );
};

export default CustomerPage;
