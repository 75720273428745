import React from "react";
import ProductGroupPricelistItem from "./ProductGroupPricelistItem";
import { ProductGroupProductItem } from "@barona/lapa-common-frontend";
import { DropdownValue } from "../../components/Dropdown";

interface ProductGroupProductsTableProps {
  productItems: ReadonlyArray<ProductGroupProductItem>;
  productGroupId: string;
  isLoading: boolean;
  disabled?: boolean;
  cancelPriceOverride: (productCode: string) => void;
  overridePrice: (productGroupId: string, productCode: string) => void;
  handlePriceValueOnChange: (productGroupId: string, productCode: string, value: string) => void;
  handlePriceTypeOnChange: (productGroupId: string, productCode: string, value: DropdownValue | null) => void;
  handleBillableOnChange: (productGroupId: string, productCode: string, value: boolean) => void;
}

const ProductGroupProductsTable = ({
  productItems,
  productGroupId,
  isLoading,
  disabled = false,
  cancelPriceOverride,
  overridePrice,
  handlePriceValueOnChange,
  handlePriceTypeOnChange,
  handleBillableOnChange,
}: ProductGroupProductsTableProps) => (
  <>
    {!isLoading &&
      productItems?.map((productPrice, idx) => (
        <ProductGroupPricelistItem
          key={idx}
          {...productPrice}
          productGroupId={productGroupId}
          disabled={disabled}
          handlePriceValueOnChange={handlePriceValueOnChange}
          handlePriceTypeOnChange={handlePriceTypeOnChange}
          handleBillableOnChange={handleBillableOnChange}
          cancelPriceOverride={cancelPriceOverride}
          overridePrice={overridePrice}
        />
      ))}
  </>
);

export default ProductGroupProductsTable;
