import React, { useState } from "react";
import styled from "styled-components";
import Color from "../../colors";
import { Row, Cell, RigthAlignCell } from "../../components/Table";
import { TrashAlt } from "@styled-icons/fa-solid/TrashAlt";
import { TimesCircle } from "@styled-icons/fa-solid/TimesCircle";
import { CheckCircle } from "@styled-icons/fa-solid/CheckCircle";
import { Edit } from "@styled-icons/boxicons-regular/Edit";
import TextInput from "../../components/form/TextInput";
import { ReferencesResponse } from "../../services/references";
import CheckboxInput from "../../components/form/CheckboxInput";

const ReferenceActionButton = styled.button`
  all: unset;
  cursor: pointer;
  vertical-align: middle;
  color: ${Color.LAPA_BLUE};
  font-size: 14px;
  margin-left: 20px;
`;

const TrashIcon = styled(TrashAlt)`
  height: 12px;
  margin: 0 3px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

const CancelIcon = styled(TimesCircle)`
  height: 12px;
  margin: 0 3px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

const SaveIcon = styled(CheckCircle)`
  height: 12px;
  margin: 0 3px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

const EditIcon = styled(Edit)`
  height: 12px;
  margin: 0 3px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

const AlignedTextInput = styled(TextInput)`
  margin-left: -11px;
`;

interface ReferenceRowProps {
  className?: string;
  reference?: ReferencesResponse[number];
  isAddingReference?: boolean;
  onCancelAddingNewReference?: () => void;
  onHide?: (reference: ReferencesResponse[number]) => void;
  onReferenceSave?: (
    referenceValue: string,
    isSelectableInHourReporting: boolean,
    editing: boolean,
    referenceName: string | null,
    referenceId?: string
  ) => void;
  refreshReferences: () => void;
  "data-testid"?: string;
}

const ReferenceRow = ({
  className,
  reference,
  onCancelAddingNewReference,
  onHide,
  onReferenceSave,
  refreshReferences,
  "data-testid": testId,
  isAddingReference = false,
}: ReferenceRowProps) => {
  const [editing, setEditing] = useState(false);
  const [referenceValue, setReferenceValue] = useState<string>(reference?.value ?? "");
  const [referenceName, setReferenceName] = useState<string>(reference?.name ?? "");
  const [isSelectableInHourReporting, setIsSelectableInHourReporting] = useState(
    reference?.isSelectableInHourReporting || false
  );

  return (
    <Row className={className} data-testid={testId}>
      <Cell>
        {isAddingReference ? (
          <AlignedTextInput
            placeholder="Anna viite"
            value={referenceValue}
            maxLength={35}
            onChange={(e) => setReferenceValue(e.currentTarget.value)}
          />
        ) : (
          referenceValue
        )}
      </Cell>
      <Cell>
        {editing || isAddingReference ? (
          <AlignedTextInput
            placeholder="Nimi vain tarvittaessa"
            value={referenceName}
            onChange={(e) => setReferenceName(e.currentTarget.value)}
          />
        ) : (
          referenceName
        )}
      </Cell>
      <Cell>
        <CheckboxInput
          disabled={!editing && !isAddingReference}
          data-testid="SelectableInHoursReporting"
          checked={isSelectableInHourReporting}
          onChange={(e) => setIsSelectableInHourReporting(e.currentTarget.checked)}
        />
      </Cell>
      <RigthAlignCell>
        {(editing || isAddingReference) && (
          <>
            <ReferenceActionButton
              onClick={() => {
                if (isAddingReference) {
                  onCancelAddingNewReference?.();
                } else {
                  setEditing(!editing);
                }
              }}
            >
              <CancelIcon />
              Peruuta
            </ReferenceActionButton>
            <ReferenceActionButton
              onClick={() => {
                onReferenceSave?.(
                  referenceValue,
                  isSelectableInHourReporting,
                  isAddingReference ? false : editing,
                  referenceName || null,
                  reference?.referenceId
                );
                setEditing(!editing);
              }}
            >
              <SaveIcon />
              Tallenna
            </ReferenceActionButton>
          </>
        )}
        {!editing && !reference?.hidden && !isAddingReference && (
          <>
            <ReferenceActionButton onClick={() => setEditing(!editing)}>
              <EditIcon />
              Muokkaa
            </ReferenceActionButton>
            <ReferenceActionButton onClick={() => onHide?.(reference!)}>
              <TrashIcon />
              Poista käytöstä
            </ReferenceActionButton>
          </>
        )}
      </RigthAlignCell>
    </Row>
  );
};

export default ReferenceRow;
