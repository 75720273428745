import { BaronaCompany } from "./baronaCompanyEnums";

export const companyMap = new Map<BaronaCompany, string>([
  [BaronaCompany.Solutions, "Barona Solutions Oy"],
  [BaronaCompany.CustomerServices, "Barona Customer Services Oy"],
  [BaronaCompany.Finance, "Barona Finance Oy"],
  [BaronaCompany.Fodbar, "Fodbar Oy"],
  [BaronaCompany.FodbarHenkilosto, "Fodbar Henkilöstö Oy"],
  [BaronaCompany.Frontliners, "Frontliners Oy"],
  [BaronaCompany.HR, "Barona HR Oy"],
  [BaronaCompany.IT, "Barona IT Oy"],
  [BaronaCompany.Logistiikka, "Barona Logistiikka Oy"],
  [BaronaCompany.Logistiikkapalvelut, "Barona Logistiikkapalvelut Oy"],
  [BaronaCompany.LogistiikkaKoulutuspalvelut, "Barona Logistiikan Koulutuspalvelut Oy"],
  [BaronaCompany.Luova, "Barona IT Talent Oy"],
  [BaronaCompany.SCCEast, "Barona SCC East Oy"],
  [BaronaCompany.SCCNorth, "Barona SCC North Oy"],
  [BaronaCompany.SCCNorthWest, "Barona SCC North West Oy"],
  [BaronaCompany.SCCWest, "Barona SCC West Oy"],
  [BaronaCompany.Sales, "Barona Sales Oy"],
  [BaronaCompany.SalesRetail, "Barona Sales Retail Oy"],
  [BaronaCompany.SalesEast, "Barona Sales East Oy"],
  [BaronaCompany.SalesNorth, "Barona Sales North Oy"],
  [BaronaCompany.SalesSouth, "Barona Sales South Oy"],
  [BaronaCompany.SalesFashion, "Barona Sales Fashion Oy"],
  [BaronaCompany.SalesSouthWest, "Barona Sales South West Oy"],
  [BaronaCompany.SalesWest, "Barona Sales West Oy"],
  [BaronaCompany.SalesServices, "Barona Sales Services Oy"],
  [BaronaCompany.Varastopalvelut, "Barona Varastopalvelut Oy"],
  [BaronaCompany.Teollisuus, "Barona Teollisuus Oy"],
  [BaronaCompany.TekniikkaJaAsennus, "Barona Tekniikka & Asennus"],
  [BaronaCompany.Skillcode, "Skillcode Oy"],
  [BaronaCompany.Production, "Barona Production Oy"],
  [BaronaCompany.Global, "Barona Global HR"],
  [BaronaCompany.Flex, "Barona Flexible Industries"],
  [BaronaCompany.Pohjanmaa, "Barona Pohjanmaa Oy"],
  [BaronaCompany.ItaSuomi, "Barona Itä-Suomi Oy"],
  [BaronaCompany.SisaSuomi, "Barona Sisä-Suomi Oy"],
  [BaronaCompany.LounaisSuomi, "Barona Lounais-Suomi Oy"],
  [BaronaCompany.Lapland, "Barona Lapland Oy"],
  [BaronaCompany.CostaCommerce, "Costa Commerce Oy"],
  [BaronaCompany.Saranen, "Saranen Consulting Oy"],
  [BaronaCompany.TalentGate, "TG Talent Gate Oy"],
  [BaronaCompany.Horeca, "Barona HoReCa Oy"],
  [BaronaCompany.CustomerCare, "Barona Customer Care Oy"],
  [BaronaCompany.UgglaEngineering, "Uggla Engineering Oy"],
  [BaronaCompany.Sevendos, "Sevendos Oy"],
  [BaronaCompany.Momentous, "Momentous Oy"],
  [BaronaCompany.Bravedo, "Bravedo Oy"],
  [BaronaCompany.BaronaICTServicesOy, "Barona ICT Services Oy"],
  [BaronaCompany.Kauppa, "Barona Kauppa Oy"],
  [BaronaCompany.Barona, "Barona Oy"],
]);
