import styled from "styled-components";
import { BaronaCompany, InvoiceSource, PersistedInvoice, Serialized, companyMap } from "@barona/lapa-common-types";
import Color from "../../colors";
import { dateISOStringToLocaleDateString } from "@barona/lapa-common-date-utils";
import { useCustomerName } from "../../services/customer";

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100px;
  width: 50%;
`;

const FilterItem = styled.div`
  display: flex;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const FilterText = styled.div`
  padding-right: 10px;
  color: ${Color.GREY};
  min-width: 100px;
`;

const FilterValue = styled.div``;
const CapitalizedFilterValue = styled(FilterValue)`
  text-transform: capitalize;
`;

interface CreatedWithFiltersProps {
  draftInvoice?: Serialized<PersistedInvoice>;
}

const CreatedWithFilters = (props: CreatedWithFiltersProps) => {
  const { draftInvoice } = props;
  const customerReferences = draftInvoice?.filters?.customerReferences;
  const { customerName } = useCustomerName(draftInvoice?.customerId || "");
  const shouldRenderItemFilters = draftInvoice?.source === InvoiceSource.lapa;

  return (
    <FiltersContainer>
      <FilterItem>
        <FilterText>Barona-yhtiö</FilterText>
        <FilterValue>{companyMap.get(draftInvoice?.baronaCompany as BaronaCompany)}</FilterValue>
      </FilterItem>
      <FilterItem>
        <FilterText>Asiakas</FilterText>
        <FilterValue>{customerName}</FilterValue>
      </FilterItem>
      {shouldRenderItemFilters && (
        <>
          <FilterItem>
            <FilterText>Ajoryhmä</FilterText>
            <FilterValue>{draftInvoice?.filters?.paymentGroup || "Ei ajoryhmää"}</FilterValue>
          </FilterItem>
          <FilterItem>
            <FilterText>Palkkakausi</FilterText>
            <FilterValue>
              {draftInvoice?.filters?.paymentPeriod
                ? `${dateISOStringToLocaleDateString(
                    draftInvoice.filters.paymentPeriod.start
                  )} - ${dateISOStringToLocaleDateString(draftInvoice.filters.paymentPeriod.end)}`
                : "Ei palkkakautta"}
            </FilterValue>
          </FilterItem>
          <FilterItem>
            <FilterText>Viitteet</FilterText>
            <FilterValue>
              {customerReferences && customerReferences.length
                ? customerReferences.map((reference) => (reference === null ? "Tyhjä" : reference)).join(", ")
                : "Ei viitteitä"}
            </FilterValue>
          </FilterItem>
        </>
      )}

      <FilterItem>
        <FilterText>Lähde</FilterText>
        <CapitalizedFilterValue>{draftInvoice?.source}</CapitalizedFilterValue>
      </FilterItem>
    </FiltersContainer>
  );
};

export default CreatedWithFilters;
