import React from "react";
import styled from "styled-components";
import Color from "../../colors";
import { inputStyle } from "./shared-input-styles";

interface Props extends React.HTMLProps<HTMLInputElement> {
  type?: "text";
}

const StyledTextInput = styled.input`
  ${inputStyle}
  &:disabled {
    background-color: ${Color.GREY_LIGHTEST};
    border: 1px solid ${Color.GREY_LIGHT};
    color: ${Color.GREY};
    cursor: not-allowed;
  }
`;

const TextInput = (props: Props) => <StyledTextInput type="text" {...(props as any)} />;

export default TextInput;
