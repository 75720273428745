import React, { useState } from "react";
import { Product, Serialized } from "@barona/lapa-common-types";
import Color from "../../../colors";
import styled from "styled-components";
import Expander from "../../../components/Expander";
import { DragIndicator } from "@styled-icons/material-outlined/DragIndicator";
import ProductGroupProductTable from "./ProductGroupProductTable";
import random from "lodash/random";
import { Trash } from "@styled-icons/boxicons-regular/Trash";
import { frontendApi } from "@barona/lapa-common-frontend";
import ProductGroupRemoveConfirmationModal from "./ProductGroupRemoveConfirmationModal";

const PRODUCT_GROUP_COLORS = new Map<number, Color>([
  [0, Color.LAPA_BLUE],
  [1, Color.LIGHTER_BLUE],
  [2, Color.RED_DARK],
  [3, Color.RED],
  [4, Color.RED_LIGHTEST],
  [5, Color.GREEN_DARKER],
  [6, Color.GREEN],
  [7, Color.YELLOW_DARKER],
  [8, Color.YELLOW],
  [9, Color.GREY],
]);

const getProductGroupHighlightColor = (sortNumber: number): Color => {
  if (sortNumber >= 0 && sortNumber <= PRODUCT_GROUP_COLORS.size - 1) {
    return PRODUCT_GROUP_COLORS.get(sortNumber)!;
  } else {
    return PRODUCT_GROUP_COLORS.get(random(0, PRODUCT_GROUP_COLORS.size))!;
  }
};

const ProductGroupContainer = styled.div<{ sortNumber: number }>`
  border: 2px solid ${(props) => getProductGroupHighlightColor(props.sortNumber)};
  border-radius: 3px;
  margin: 8px 0 8px 0;
`;

const ProductGroupExpanderTitle = styled.div`
  display: grid;
  grid-template-columns: 7% 70%;
  margin: 8px 0 8px 5px;
`;

const DragIndicatorIcon = styled(DragIndicator)`
  height: 20px;
`;

const ProductGroupTitleText = styled.div`
  width: 270px;
  font-size: 16px;
`;

const ProductGroupActionsContainer = styled.div`
  display: grid;
  height: 30px;
  grid-template-columns: 50% 50%;
`;

const ShowAllGroupProductsButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: ${Color.LAPA_BLUE};

  &:disabled {
    background-color: ${Color.WHITE};
    color: ${Color.GREY_LIGHT};
    cursor: not-allowed;
  }
`;

const FloatLeft = styled.div`
  display: flex;
  justify-content: start;
  padding: 0 10px 0 10px;
`;

const FloatRight = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 0 10px;
`;

const RemoveGroupIcon = styled(Trash)`
  width: 18px;
  color: ${Color.LAPA_BLUE};
  margin: 0 4px 0 -8px;
`;

const RemoveGroupButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: ${Color.LAPA_BLUE};
`;

interface ProductGroupProps {
  productGroupId: string;
  name: string;
  sortNumber: number;
  products: Serialized<ReadonlyArray<Product>>;
  handleDrag: (groupName: string) => void;
  handleDrop: (groupName: string) => void;
  refreshProductGroups: () => void;
}

const ProductGroup = ({
  productGroupId,
  name,
  sortNumber,
  products,
  handleDrag,
  handleDrop,
  refreshProductGroups,
}: ProductGroupProps) => {
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [showProductGroupRemoveConfirmation, setShowProductGroupRemoveConfirmation] = useState(false);

  const removeGroupHandler = async () => {
    await frontendApi.removeProductGroup({ productGroupId });
    setShowProductGroupRemoveConfirmation(false);
    refreshProductGroups?.();
  };

  const removeProductFromAGroupHandler = async (product: Product) => {
    await frontendApi.removeProductFromAGroup({ productGroupId, productCode: product.productCode });
    refreshProductGroups?.();
  };

  return (
    <>
      <ProductGroupContainer
        id={name}
        draggable={true}
        onDragStart={(event) => {
          event.dataTransfer.dropEffect = "move";
          handleDrag(name);
        }}
        onDrop={(event) => handleDrop(event.currentTarget.id)}
        onDragOver={(event) => event.preventDefault()}
        sortNumber={sortNumber}
      >
        <Expander
          testId="ProductGroup"
          title={
            <ProductGroupExpanderTitle>
              <DragIndicatorIcon />
              <ProductGroupTitleText>{`${name} (${products.length})`}</ProductGroupTitleText>
            </ProductGroupExpanderTitle>
          }
        >
          <ProductGroupProductTable
            showAllProducts={showAllProducts}
            products={products}
            color={getProductGroupHighlightColor(sortNumber)}
            removeProductFromAGroup={removeProductFromAGroupHandler}
          />
          <ProductGroupActionsContainer>
            <FloatLeft>
              {products.length > 3 && (
                <ShowAllGroupProductsButton onClick={() => setShowAllProducts(!showAllProducts)}>
                  {showAllProducts ? "Näytä vähemmän" : "Näytä kaikki"}
                </ShowAllGroupProductsButton>
              )}
            </FloatLeft>
            <FloatRight>
              <RemoveGroupButton
                data-testid="RemoveGroupButton"
                onClick={() => setShowProductGroupRemoveConfirmation(true)}
              >
                <RemoveGroupIcon />
                Poista ryhmä
              </RemoveGroupButton>
            </FloatRight>
          </ProductGroupActionsContainer>
        </Expander>
      </ProductGroupContainer>
      <ProductGroupRemoveConfirmationModal
        show={showProductGroupRemoveConfirmation}
        groupName={name}
        onCancel={() => setShowProductGroupRemoveConfirmation(!showProductGroupRemoveConfirmation)}
        onConfirm={removeGroupHandler}
      />
    </>
  );
};

export default ProductGroup;
