import { frontendApi, UninvoicedItemsResponseItem } from "@barona/lapa-common-frontend";
import { BaronaCompany } from "@barona/lapa-common-types";
import { dateToISODate } from "@barona/lapa-common-date-utils";
import { useQuery } from "react-query";

export interface UninvoiceditemFilters {
  baronaCompany?: BaronaCompany;
  customerId?: string;
  customerReferences?: (string | null)[];
  paymentGroup?: string;
  paymentPeriod?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface UninvoicedItem extends Omit<UninvoicedItemsResponseItem, "itemDate"> {
  itemDate: Date;
}

export const useUninvoicedItems = ({
  baronaCompany,
  customerId,
  customerReferences,
  paymentGroup,
  paymentPeriod,
  startDate,
  endDate,
}: UninvoiceditemFilters) => {
  const { data, isLoading, refetch } = useQuery(
    ["uninvoicedItems", baronaCompany, customerId, customerReferences, paymentGroup, paymentPeriod, startDate, endDate],
    async () => {
      if (!baronaCompany || !customerId) {
        return undefined;
      }
      return await frontendApi.fetchUninvoicedItems({
        baronaCompany,
        customerId,
        customerReferences,
        paymentGroup,
        paymentPeriod,
        startDate: startDate ? dateToISODate(startDate) : undefined,
        endDate: endDate ? dateToISODate(endDate) : undefined,
      });
    }
  );
  const uninvoicedItems: UninvoicedItem[] = data
    ? data.items.map((item) => ({ ...item, itemDate: new Date(item.itemDate) }))
    : [];
  const hasAnyItemsWithoutPrice = data ? data.hasAnyItemsWithoutPrice : false;
  return { uninvoicedItems, hasAnyItemsWithoutPrice, isLoading, forceRefresh: refetch };
};
