enum Color {
  LAPA_BLUE = "#379cf0",
  LIGHT_BLUE = "#d7ebfc",
  LIGHTER_BLUE = "#87c4f6",
  DARK_BLUE = "#215E90",
  RED_DARK = "#a64848",
  RED = "#d05a5a",
  RED_LIGHTEST = "#f6dede",
  GREEN_DARKER = "#30744f",
  GREEN = "#50c283",
  GREEN_LIGHTEST = "#dcf3e6",
  WHITE = "#ffffff",
  YELLOW_DARKER = "#97893a",
  YELLOW = "#fbe460",
  YELLOW_LIGHTEST = "#fefadf",
  GREY_LIGHTEST = "#f9f9f9",
  GREY_LIGHTER = "#ececec",
  GREY_LIGHT = "#e0e0e0",
  GREY = "#9d9d9d",
  GREY_DARK = "#5a5a5a",
  BLACK = "#4a4a4a",
  BORDER_SHADOW = "#dfe3e8",
  DISABLED_INPUT = "#f9fafb",
}

export default Color;
