import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useInvoice } from "../../services/invoice";
import { LapaNavigationLink } from "../../components/Link";
import PageContainer from "../common/PageContainer";
import { PageHeader } from "../common/PageHeader";
import CreatedWithFilters from "./CreatedWithFilters";
import DraftInvoiceActions from "./DraftInvoiceActions";
import { ContentSeparator, ContentSeparatorLight } from "../common/ContentSeparator";
import { SecondaryButton } from "../../components/SecondaryButton";
import React, { useState, useEffect } from "react";
import Notifications from "../common/Notifications";
import InvoiceRows from "./InvoiceRows";
import DraftInvoiceEditableParameters, { DraftInvoiceEditableParams } from "./DraftInvoiceEditableParameters";
import isEqual from "lodash/isEqual";
import { BaronaCompany, InvoiceSource, InvoiceStatus, PersistedInvoice, Serialized } from "@barona/lapa-common-types";
import { useNotifications, useBaronaCompany, useOpenInvoicesCount, NotificationState } from "../../context";
import { useSelectedInvoiceTab } from "../InvoicesPage/hooks/useSelectedInvoiceTab";
import { frontendApi } from "../../services/api";
import { dateISOStringToLocaleDateTimeString, dateToISODate } from "@barona/lapa-common-date-utils";
import Color from "../../colors";
import InvoiceActionModal from "./InvoiceActionModal";
import CheckboxInput from "../../components/form/CheckboxInput";

const FiltersWithActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: end;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 78% 22%;
  align-items: end;
`;

const SendNotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledCheckbox = styled(CheckboxInput)`
  margin-left: 0px;
`;

const NoInvoice = styled.div``;
const AuditSection = styled.div`
  color: ${Color.GREY};
`;

export const mapInvoiceParams = (invoice?: Serialized<PersistedInvoice>): DraftInvoiceEditableParams => ({
  invoiceDate: invoice?.invoiceDate ? new Date(invoice?.invoiceDate) : undefined,
  customerReference: invoice?.customerReference?.toString(),
  ourReference: invoice?.ourReference?.toString(),
  groupBy: invoice?.groupBy!,
  paymentPeriod: {
    end: invoice?.periodEnd ? new Date(invoice?.periodEnd) : undefined,
    start: invoice?.periodStart ? new Date(invoice?.periodStart) : undefined,
  },
  invoiceInfoId: invoice?.invoiceInfoId,
  contactPerson: invoice?.contactPerson,
  sendNotification: invoice?.sendNotification,
});

const InvoicePage = () => {
  // TODO: fix typing
  const { invoiceId } = useParams<"invoiceId">() as { invoiceId: string };
  const selectedInvoiceType = useSelectedInvoiceTab();

  const { invoice, isLoading, invoiceNotFound, forceRefresh } = useInvoice(invoiceId);
  const showInvoice = invoice && !isLoading && !invoiceNotFound;
  const [draftInvoiceParams, setDraftInvoiceParams] = useState<DraftInvoiceEditableParams>({});
  const [draftInvoiceParamsHaveChanged, setDraftInvoiceParamsHaveChanged] = useState(false);
  const [originalParameters, setOriginalParameters] = useState<DraftInvoiceEditableParams>();
  const { baronaCompany, setBaronaCompany } = useBaronaCompany();
  const { addNotification } = useNotifications();
  const isInvoiceEditable = Boolean(showInvoice && invoice.status === InvoiceStatus.draft);
  const [showReopenInvoiceModal, setReopenInvoiceModal] = useState(false);
  const navigate = useNavigate();
  const { refresh: refreshOpenInvoicesCount } = useOpenInvoicesCount();

  const reopenInvoiceModalTitle = `Poista lasku myös Workdaysta!`;
  const reopenInvoiceModalBodyText = `Laskun palauttaminen avoimeksi LAPA:ssa ei vaikuta laskuun Workdayssa. Muista poistaa lasku myös Workdaysta.`;

  useEffect(() => {
    const params = mapInvoiceParams(invoice);

    setDraftInvoiceParams(params);
    setOriginalParameters(params);
  }, [invoice]);

  useEffect(() => {
    if (baronaCompany && invoice?.baronaCompany && baronaCompany !== invoice.baronaCompany) {
      addNotification("Valittu Barona-yhtiö asetettiin samaksi kuin laskulla oleva.");
      setBaronaCompany(invoice.baronaCompany as BaronaCompany);
    }
  }, [baronaCompany, invoice?.baronaCompany, addNotification, setBaronaCompany]);

  const draftInvoiceParametersOnChangeHandler = (params: DraftInvoiceEditableParams) => {
    setDraftInvoiceParams(() => {
      const isEqualWithOriginalParams = !isEqual(originalParameters, params);
      setDraftInvoiceParamsHaveChanged(isEqualWithOriginalParams);
      return params;
    });
  };

  const reopenInvoice = async () => {
    setReopenInvoiceModal(false);
    await frontendApi.updateInvoiceStatus({ invoiceId: invoiceId, newStatus: InvoiceStatus.draft });
    refreshOpenInvoicesCount();
    navigate("..");
  };

  const sendNotificationHandler = (checked: boolean) => {
    draftInvoiceParametersOnChangeHandler({ ...draftInvoiceParams, sendNotification: checked });
  };

  const refreshInvoiceHandler = () => {
    if (draftInvoiceParamsHaveChanged) {
      saveDraftInvoiceParametersHandler();
    } else {
      forceRefresh();
    }
  };

  const saveDraftInvoiceParametersHandler = async () => {
    if (!draftInvoiceParams?.invoiceDate) {
      addNotification("Kirjauspäivä on pakollinen tieto", NotificationState.Error);
      return;
    }
    if (showInvoice) {
      try {
        await frontendApi.updateInvoiceParameters({
          invoiceId: invoice.invoiceId,
          ourReference: draftInvoiceParams?.ourReference ?? null,
          customerReference: draftInvoiceParams?.customerReference ?? null,
          groupBy: draftInvoiceParams?.groupBy,
          invoiceInfoId: draftInvoiceParams?.invoiceInfoId ?? null,
          invoiceDate: draftInvoiceParams?.invoiceDate ? dateToISODate(draftInvoiceParams.invoiceDate) : undefined,
          paymentPeriod: {
            start: draftInvoiceParams?.paymentPeriod?.start
              ? dateToISODate(draftInvoiceParams.paymentPeriod.start)
              : "",
            end: draftInvoiceParams?.paymentPeriod?.end ? dateToISODate(draftInvoiceParams.paymentPeriod.end) : "",
          },
          contactPerson: draftInvoiceParams.contactPerson,
          sendNotification: draftInvoiceParams.sendNotification,
        });
        addNotification("Laskun tallennus onnistui", NotificationState.Success);
        setDraftInvoiceParamsHaveChanged(false);
        forceRefresh();
      } catch (error) {
        addNotification("Laskun tallennus epäonnistui", NotificationState.Error);
      }
    }
  };

  return (
    <PageContainer>
      <LapaNavigationLink to={`..${selectedInvoiceType ? `?type=${selectedInvoiceType}` : ""}`}>
        {"<"} Takaisin laskuihin
      </LapaNavigationLink>
      <Notifications />
      {showInvoice ? (
        <>
          <HeaderContainer>
            <PageHeader>Lasku {invoiceId}</PageHeader>
            {invoice.status === InvoiceStatus.sent && (
              <SecondaryButton
                data-testid="ReopenInvoice"
                onClick={() => setReopenInvoiceModal(!showReopenInvoiceModal)}
              >
                Palauta lasku avoimeksi laskuksi
              </SecondaryButton>
            )}
          </HeaderContainer>
          <FiltersWithActionsContainer>
            <CreatedWithFilters draftInvoice={invoice} />
            {isInvoiceEditable && (
              <DraftInvoiceActions
                draftInvoice={invoice}
                draftInvoiceParameters={draftInvoiceParams}
                isSaveInvoiceDisabled={!draftInvoiceParamsHaveChanged}
                isSendInvoiceToNAVDisabled={draftInvoiceParamsHaveChanged || invoice.invoiceRows.length === 0}
                refreshInvoice={refreshInvoiceHandler}
                ourReferenceFromContactPerson={invoice.ourReferenceFrom === "contactPerson"}
                saveDraftInvoice={saveDraftInvoiceParametersHandler}
              />
            )}
          </FiltersWithActionsContainer>
          {invoice.source !== InvoiceSource.lapa && (
            <SendNotificationContainer>
              <StyledCheckbox
                checked={draftInvoiceParams.sendNotification ?? false}
                onChange={(event) => sendNotificationHandler(event.currentTarget.checked)}
              />
              Lähetä laskusta ilmoitus laskutustiimille
            </SendNotificationContainer>
          )}
          <ContentSeparator />
          <DraftInvoiceEditableParameters
            invoice={invoice}
            parameters={draftInvoiceParams}
            parametersOnChange={draftInvoiceParametersOnChangeHandler}
            editable={isInvoiceEditable}
            ourReferenceFromContactPerson={invoice.ourReferenceFrom === "contactPerson"}
            availableContactPersons={invoice.availableContactPersons ?? []}
          />
          <InvoiceRows
            invoiceRows={invoice?.invoiceRows ?? []}
            isLoading={isLoading}
            allowExpandingRows={invoice.source === InvoiceSource.lapa}
            invoiceId={invoiceId}
            refreshInvoice={refreshInvoiceHandler}
            allowEditingRows={[InvoiceSource.lapa, InvoiceSource.manual].includes(invoice.source)}
            invoiceType={invoice.source}
            customerId={invoice.customerId}
          />
          <ContentSeparatorLight />
          <AuditSection>
            {"Lasku luotu "}
            {dateISOStringToLocaleDateTimeString(invoice.createdAt, {
              dateOptions: { zone: "system", locale: "fi-FI" },
            })}{" "}
            {invoice.createdBy}
          </AuditSection>
        </>
      ) : (
        !isLoading && invoiceNotFound && <NoInvoice>Laskua ei löytynyt</NoInvoice>
      )}
      <InvoiceActionModal
        testId="ReopenInvoiceModal"
        onConfirm={() => reopenInvoice()}
        onCancel={() => setReopenInvoiceModal(false)}
        show={showReopenInvoiceModal}
        titleText={reopenInvoiceModalTitle}
        bodyText={reopenInvoiceModalBodyText}
        actionText="Palauta lasku"
      />
    </PageContainer>
  );
};

export default InvoicePage;
