import { useCallback, useState, useRef } from "react";
import ItemFilters from "./components/ItemFilters";
import PageContainer from "../common/PageContainer";
import UninvoicedItems from "./components/UninvoicedItems";
import { UninvoiceditemFilters, useUninvoicedItems } from "../../services/uninvoicedItems";
import React from "react";
import { ContentSeparator } from "../common/ContentSeparator";
import { NotificationsProvider } from "../../context/Notifications";

export interface ItemFiltersRef {
  refreshAllFilters: () => void;
}

const ItemsPage = () => {
  const [selectedFilters, setSelectedFilters] = useState<UninvoiceditemFilters>({});
  const [isRemoveItemsMode, setIsRemoveItemsMode] = useState(false);
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
  const itemFiltersRef = useRef<ItemFiltersRef>(null);
  const { uninvoicedItems, hasAnyItemsWithoutPrice, forceRefresh, isLoading } = useUninvoicedItems(selectedFilters);

  const handleSelectedFiltersChange = useCallback((value: UninvoiceditemFilters) => {
    setSelectedFilters(value);
    setIsRemoveItemsMode(false);
    setSelectedItemsIds([]);
  }, []);

  const handleRefreshAllFilters = useCallback(() => itemFiltersRef.current?.refreshAllFilters(), [itemFiltersRef]);

  return (
    <PageContainer>
      <ItemFilters
        ref={itemFiltersRef}
        hasAnyItemsWithoutPrice={hasAnyItemsWithoutPrice}
        onChange={handleSelectedFiltersChange}
      />
      <ContentSeparator />
      <NotificationsProvider>
        <UninvoicedItems
          uninvoicedItems={uninvoicedItems}
          selectedItemsIds={selectedItemsIds}
          setSelectedItemsIds={setSelectedItemsIds}
          setIsRemoveItemsMode={setIsRemoveItemsMode}
          isRemoveItemsMode={isRemoveItemsMode}
          refreshAllFilters={handleRefreshAllFilters}
          refreshUninvoicedItems={forceRefresh}
          isLoading={isLoading}
        />
      </NotificationsProvider>
    </PageContainer>
  );
};

export default ItemsPage;
