import { PriceSetThrough, PriceType, ProductUnit } from "@barona/lapa-common-types";
import React, { useState } from "react";
import styled from "styled-components";
import TextInput from "../../components/form/TextInput";
import Dropdown, { DropdownValue } from "../../components/Dropdown";
import { Row, Cell, RigthAlignCell } from "../../components/Table";
import { Pencil } from "@styled-icons/evil/Pencil";
import { ArrowGoBack } from "@styled-icons/remix-fill/ArrowGoBack";
import Color from "../../colors";
import CheckboxInput from "../../components/form/CheckboxInput";

export const OverridePriceIcon = styled(Pencil)<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? Color.GREY_LIGHT : Color.LAPA_BLUE)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const ReverseOverriddenPriceIcon = styled(ArrowGoBack)<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? Color.GREY_LIGHT : Color.LAPA_BLUE)};
  height: 22px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const priceTypeOptions = [
  { label: "Kerroin", value: PriceType.Multiplier },
  { label: "Hinta", value: PriceType.Fixed },
];

const unitOptions = [
  { label: "h", value: "h" },
  { label: "kpl", value: "pcs" },
];

export const FillAvailableTextInput = styled(TextInput)`
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

const PaddedCell = styled(Cell)`
  padding-left: 17px;
`;

const OverriddenRow = styled(Row)`
  background-color: ${Color.YELLOW_LIGHTEST};

  &:nth-child(even) {
    background-color: ${Color.YELLOW_LIGHTEST};
  }
`;

export const getPriceType = (value: string) => priceTypeOptions.find((priceType) => priceType.value === value);
export const getUnit = (unitValue: string | null) => unitOptions.find((unit) => unit.value === unitValue)?.label || "";

export interface ProductGroupPricelistItemProps {
  name: string;
  productGroupId: string;
  priceType?: string;
  priceValue?: string;
  billable?: boolean;
  unit: ProductUnit | null;
  productCode: string;
  priceSetThrough?: PriceSetThrough;
  disabled?: boolean;
  handlePriceValueOnChange: (productGroupId: string, productCode: string, value: string) => void;
  handlePriceTypeOnChange: (productGroupId: string, productCode: string, value: DropdownValue | null) => void;
  handleBillableOnChange: (productGroupId: string, productCode: string, value: boolean) => void;
  cancelPriceOverride: (productCode: string) => void;
  overridePrice: (productGroupId: string, productCode: string) => void;
}

const ProductGroupPricelistItem = (props: ProductGroupPricelistItemProps) => {
  const {
    name,
    productGroupId,
    priceType,
    priceValue,
    billable,
    productCode,
    priceSetThrough,
    unit,
    disabled = false,
    handlePriceValueOnChange,
    handlePriceTypeOnChange,
    handleBillableOnChange,
    cancelPriceOverride,
    overridePrice,
  } = props;

  const [isOverriding, setIsOverriding] = useState(false);

  if (priceSetThrough === PriceSetThrough.Manually || isOverriding) {
    return (
      <OverriddenRow key={name}>
        <Cell>{name}</Cell>
        <Cell>
          <FillAvailableTextInput
            data-testid="OverridePriceValue"
            value={priceValue ?? ""}
            onChange={(event) => {
              handlePriceValueOnChange(productGroupId, productCode, event.currentTarget.value);
            }}
            disabled={disabled}
          />
        </Cell>
        <Cell>
          <Dropdown
            testId="OverridePriceType"
            value={priceType ? getPriceType(priceType) : { label: "", value: "" }}
            options={priceTypeOptions}
            placeholder=""
            onChange={(value) => handlePriceTypeOnChange(productGroupId, productCode, value)}
            isDisabled={disabled}
          />
        </Cell>
        <Cell>
          <CheckboxInput
            data-testid="OverrideBillable"
            checked={billable}
            onChange={(event) => handleBillableOnChange(productGroupId, productCode, event.currentTarget.checked)}
            disabled={disabled}
          />
        </Cell>
        <Cell>{productCode}</Cell>
        <Cell>{getUnit(unit)}</Cell>
        <RigthAlignCell>
          <ReverseOverriddenPriceIcon
            disabled={disabled}
            data-testid="ReverseOverriddenPrice"
            onClick={() => {
              if (!disabled) {
                if (isOverriding) {
                  setIsOverriding(!isOverriding);
                } else {
                  cancelPriceOverride(productCode);
                }
              }
            }}
          />
        </RigthAlignCell>
      </OverriddenRow>
    );
  } else {
    return (
      <Row key={name}>
        <Cell>{name}</Cell>
        <PaddedCell>{String(priceValue ?? "")}</PaddedCell>
        <PaddedCell>{priceType ? getPriceType(priceType)?.label : null}</PaddedCell>
        <Cell>
          <CheckboxInput disabled data-testid="Billable" checked={billable} />
        </Cell>
        <Cell>{productCode}</Cell>
        <Cell>{getUnit(unit)}</Cell>
        <RigthAlignCell>
          <OverridePriceIcon
            disabled={disabled}
            data-testid="OverridePrice"
            onClick={() => {
              if (!disabled) {
                overridePrice(productGroupId, productCode);
                setIsOverriding(!isOverriding);
              }
            }}
          />
        </RigthAlignCell>
      </Row>
    );
  }
};

export default React.memo(ProductGroupPricelistItem);
