import React from "react";
import { Product, Serialized } from "@barona/lapa-common-types";
import { Cell, Row, Table, RigthAlignCell, TableBody } from "../../../components/Table";
import styled from "styled-components";
import Color from "../../../colors";
import { rgba } from "polished";
import { TimesCircle } from "@styled-icons/fa-solid/TimesCircle";
import { getUnit } from "./AvailableProducts";

const ProductRow = styled(Row)<{ color: Color }>`
  box-shadow: none;
  box-sizing: content-box;

  &:nth-child(even) {
    background-color: ${(props) => rgba(props.color, 0.1)};
  }
`;

const ProductRowCell = styled(Cell)`
  padding: 0px;
`;

const ProductRigthAlignCell = styled(RigthAlignCell)`
  padding: 0px;
`;

const RemoveIcon = styled(TimesCircle)`
  height: 16px;
  width: 16px;
  padding-right: 20px;
  color: ${Color.LAPA_BLUE};
  cursor: pointer;
`;

interface ProductGroupProductTableProps {
  products: Serialized<ReadonlyArray<Product>>;
  showAllProducts: boolean;
  color: Color;
  removeProductFromAGroup: (product: Product) => void;
}

const ProductGroupProductTable = ({
  products,
  showAllProducts,
  color,
  removeProductFromAGroup,
}: ProductGroupProductTableProps) => {
  const showableProducts = showAllProducts ? products : products.slice(0, 3);

  return (
    <Table>
      <TableBody>
        {showableProducts.map((product, idx) => (
          <ProductRow color={color} data-testid="ProductGroupProductRow" key={idx}>
            <ProductRowCell style={{ width: "30%" }}>{product.name}</ProductRowCell>
            <ProductRowCell style={{ width: "12%" }}>{product.productCode}</ProductRowCell>
            <ProductRowCell style={{ width: "5%" }}>{getUnit(product.unit)}</ProductRowCell>
            <ProductRigthAlignCell style={{ width: "2%" }}>
              <RemoveIcon data-testid="RemoveProductFromGroup" onClick={() => removeProductFromAGroup(product)} />
            </ProductRigthAlignCell>
          </ProductRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ProductGroupProductTable;
