import React, { FC, useEffect, useState, forwardRef, useImperativeHandle, useCallback, useMemo } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { InfoCircle } from "@styled-icons/fa-solid";
import { useBaronaCompany, useNotifications, NotificationState, useOpenInvoicesCount } from "../../../context";
import ListSelect, { Props as ListSelectProps } from "../../../components/form/ListSelect";
import DatePicker from "../../../components/DatePicker";
import styled from "styled-components";
import { customerFiltersQueryKeys, useCustomerFilters } from "../../../services/customerFilters";
import { referenceFiltersQueryKeys, useReferenceFilters } from "../../../services/referenceFilters";
import { paymentGroupFiltersQueryKeys, usePaymentGroupFilters } from "../../../services/paymentGroupFilters";
import { paymentPeriodFiltersQueryKeys, usePaymentPeriodFilters } from "../../../services/paymentPeriodFilters";
import compact from "lodash/compact";
import Color from "../../../colors";
import InvoiceParams, { InvoiceParamsValue } from "./InvoiceParams";
import { CustomerReference, CustomerIdWithName, PaymentGroup, PaymentPeriod } from "@barona/lapa-common-frontend";
import { createInvoice, createMultipleInvoices } from "../../../services/invoice";
import Notifications, { NotificationItem, NotificationsContainer } from "../../common/Notifications";
import { Title } from "../../common/Title";
import { UninvoiceditemFilters } from "../../../services/uninvoicedItems";
import { IndicatorModal } from "../../../components/Modal";
import { Copy } from "@styled-icons/boxicons-regular/Copy";
import { companyMap } from "@barona/lapa-common-types";
import { InvoiceGroupBy, IProblemJSON } from "@barona/lapa-common-types";
import { dateToLocaleDateString, determineInvoiceDate, finnishDateStringToDate } from "@barona/lapa-common-date-utils";
import axios, { AxiosError } from "axios";
import { TimesCircle } from "@styled-icons/fa-solid/TimesCircle";
import { ItemFiltersRef } from "../ItemsPage";
import FilterCount from "./FilterCount";
import { useQueryClient } from "react-query";

const ReferenceTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 16px;
`;

const ReferenceInfoCircle = styled(InfoCircle)`
  width: 16px;
  height: 16px;
  color: ${Color.LAPA_BLUE};
`;

const ReferenceInfoText = styled.div`
  width: 200px;
  margin: 7px;
`;

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 240px);
  grid-row-gap: 32px;
  grid-column-gap: 30px;
`;

const FiltersWithParametersContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

const FilterSelectContainer = styled.div`
  width: 240px;
`;

const FiltersContainerAside = styled.div`
  padding-right: 24px;
`;

const ParametersContainer = styled.div`
  padding-left: 24px;
  border-left: 1px solid ${Color.GREY_LIGHTER};
`;

const DateRangeContainer = styled(FilterSelectContainer)`
  display: grid;
  align-items: center;
  column-gap: 11px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const DashContainer = styled.div`
  text-align: center;
`;

const StyledListSelect = styled(ListSelect)`
  width: 240px;
  height: 200px;
`;

const SelectedFiltersContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  *:not(:first-child) {
    margin-left: 8px;
  }
`;

const SelectedItem = styled.span`
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.BLACK};
  padding: 3px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
`;

const CreateInvoiceButton = styled.button`
  background-color: ${Color.LAPA_BLUE};
  font-family: "Lato", sans-serif;
  font-size: 16px;
  padding: 8px 18px;
  border-radius: 3px;
  color: ${Color.WHITE};
  border: none;
  display: inline-block;
  cursor: pointer;
  &:disabled {
    background-color: ${Color.LIGHTER_BLUE};
    cursor: not-allowed;
  }
`;

const CopyToClipboardButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: ${Color.LAPA_BLUE};
`;

const ClearSelectionIcon = styled(TimesCircle)<{ disabled: boolean }>`
  height: 16px;
  width: 16px;
  padding-right: 5px;
  color: ${(props) => (props.disabled ? Color.GREY : Color.LAPA_BLUE)};
  cursor: pointer;
`;

const ClearSelectionButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: ${Color.LAPA_BLUE};

  &:disabled {
    color: ${Color.GREY};
  }
`;

const ClearSelectionContainer = styled.div`
  padding-top: 4px;
  float: right;
`;

const ListSelectLabel = styled.span`
  display: block;
  margin-bottom: 8px;
  color: ${Color.GREY_DARK};
`;

const CopyIcon = styled(Copy)`
  width: 18px;
  color: ${Color.LAPA_BLUE};
  margin: 0 4px 0 0;
`;

const FailedReferencesList = styled.ul`
  list-style-type: none;
`;

const FailedReference = styled.li``;
interface ItemType {
  label: string;
}

interface CustomerItemType extends ItemType {
  value: CustomerIdWithName;
}

interface ReferenceItemType extends ItemType {
  value: CustomerReference;
}

interface PaymentGroupItemType extends ItemType {
  value: PaymentGroup;
}

interface PaymentPeriodItemType extends ItemType {
  value: PaymentPeriod;
}

interface Props {
  onChange?: (value: UninvoiceditemFilters) => any;
  hasAnyItemsWithoutPrice: boolean;
}

const ItemFilters = (props: Props, ref: React.ForwardedRef<ItemFiltersRef>) => {
  const { onChange, hasAnyItemsWithoutPrice } = props;
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerItemType | undefined>();
  const [selectedReferences, setSelectedReferences] = useState<ReferenceItemType[] | undefined>([]);
  const [selectedPaymentGroup, setSelectedPaymentGroup] = useState<PaymentGroupItemType | undefined>();
  const [selectedPaymentPeriod, setSelectedPaymentPeriod] = useState<PaymentPeriodItemType | undefined>();
  const [invoiceParams, setInvoiceParams] = useState<InvoiceParamsValue>({
    groupBy: InvoiceGroupBy.person,
    automaticallyCreateInvoicesUsingReferences: false,
    useStaffingContractContactPersonAsOurReference: false,
  });
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const { baronaCompany } = useBaronaCompany();

  const setReferenceFilterAndParam = useCallback((references: ReferenceItemType[] | undefined) => {
    setSelectedReferences(references);
    setInvoiceParams((prev) => ({
      ...prev,
      referenceOnInvoice: references?.length === 1 ? references[0].value?.content ?? undefined : undefined,
    }));
  }, []);

  const setPaymentPeriodFilterAndInvoiceParam = useCallback((paymentPeriod: PaymentPeriodItemType | undefined) => {
    setSelectedPaymentPeriod(paymentPeriod);
    const paymentPeriodEndDate = (paymentPeriod?.value?.content ?? "").match(
      /^\d{2}\.\d{2}\.\d{4} - (\d{2}\.\d{2}\.\d{4})$/
    )?.[1];
    const invoiceDate = paymentPeriodEndDate
      ? determineInvoiceDate(finnishDateStringToDate(paymentPeriodEndDate))
      : undefined;
    if (invoiceDate) {
      setInvoiceParams((prev) => ({ ...prev, invoiceDate }));
    }
  }, []);

  const setEndDateAndInvoiceParam = useCallback((endDate: Date | undefined) => {
    setEndDate(endDate);
    const invoiceDate = endDate ? determineInvoiceDate(endDate) : undefined;
    if (invoiceDate) {
      setInvoiceParams((prev) => ({ ...prev, invoiceDate }));
    }
  }, []);

  const selectedReferenceValues = useMemo(() => {
    if (!selectedReferences || !selectedReferences.length) {
      return [];
    }

    return selectedReferences.map((value) => value.value.content);
  }, [selectedReferences]);

  const { customers, isLoading: isLoadingCustomers } = useCustomerFilters(
    baronaCompany,
    selectedReferenceValues,
    selectedPaymentGroup?.value.content,
    selectedPaymentPeriod?.value.content,
    startDate,
    endDate
  );

  const { references, isLoading: isLoadingReferences } = useReferenceFilters(
    baronaCompany,
    selectedCustomer?.value.customerId,
    selectedPaymentGroup?.value.content,
    selectedPaymentPeriod?.value.content,
    startDate,
    endDate
  );

  const { paymentGroups, isLoading: isLoadingPaymentGroups } = usePaymentGroupFilters(
    baronaCompany,
    selectedCustomer?.value.customerId,
    selectedReferenceValues,
    selectedPaymentGroup?.value.content,
    selectedPaymentPeriod?.value.content,
    startDate,
    endDate
  );

  const { paymentPeriods, isLoading: isLoadingPaymentPeriods } = usePaymentPeriodFilters(
    baronaCompany,
    selectedCustomer?.value.customerId,
    selectedReferenceValues,
    selectedPaymentGroup?.value.content,
    selectedPaymentPeriod?.value.content,
    startDate,
    endDate
  );

  const queryClient = useQueryClient();

  const refreshAllFilters = useCallback(() => {
    queryClient.invalidateQueries(customerFiltersQueryKeys.customers);
    queryClient.invalidateQueries(referenceFiltersQueryKeys.references);
    queryClient.invalidateQueries(paymentGroupFiltersQueryKeys.paymentGroup);
    queryClient.invalidateQueries(paymentPeriodFiltersQueryKeys.paymentPeriod);
  }, [queryClient]);

  useImperativeHandle(ref, () => ({ refreshAllFilters }));

  const { addNotification } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { refresh: refreshOpenInvoicesCount } = useOpenInvoicesCount();

  useEffect(() => {
    onChange?.({
      baronaCompany,
      customerId: selectedCustomer?.value.customerId,
      customerReferences: selectedReferenceValues,
      paymentGroup: selectedPaymentGroup?.value.content,
      paymentPeriod: selectedPaymentPeriod?.value.content,
      startDate,
      endDate,
    });
  }, [
    onChange,
    baronaCompany,
    selectedCustomer,
    selectedReferenceValues,
    selectedPaymentGroup,
    selectedPaymentPeriod,
    startDate,
    endDate,
  ]);

  const selectedFilters = compact([
    companyMap.get(baronaCompany),
    selectedCustomer?.value.customerName,
    selectedReferences?.length &&
      selectedReferences?.map((reference) => (reference?.value.content === null ? "Tyhjä" : reference?.value.content)),
    selectedPaymentGroup?.value.content,
    selectedPaymentPeriod?.value.content,
  ]);

  if (startDate && !endDate) {
    selectedFilters.push(`alkaen ${dateToLocaleDateString(startDate)}`);
  } else if (endDate && !startDate) {
    selectedFilters.push(`${dateToLocaleDateString(endDate)} asti`);
  } else if (startDate && endDate) {
    selectedFilters.push(`${dateToLocaleDateString(startDate)} - ${dateToLocaleDateString(endDate)}`);
  }

  const [successfullReferences, setSuccessfullReferences] = useState<(string | null)[]>([]);
  const [failedReferences, setFailedReferences] = useState<
    {
      reference: string | null;
      details?: string;
    }[]
  >([]);

  const handleOnSuccessfullInvoice = useCallback((reference: string | null) => {
    setSuccessfullReferences((prevState) => [...prevState, reference]);
  }, []);

  const handleOnFailedInvoice = useCallback((reference: string | null, details?: string) => {
    setFailedReferences((prevState) => [...prevState, { reference, details }]);
  }, []);

  const handleClickCreateInvoice = useCallback(async () => {
    const validateParameters = (): string[] => {
      const validationErrors: string[] = [];
      if (!selectedCustomer) {
        validationErrors.push("Asiakas puuttuu");
      }

      if (!invoiceParams.invoiceDate) {
        validationErrors.push("Laskun kirjauspäivä puuttuu");
      }

      if ((invoiceParams.referenceOnInvoice?.length ?? 0) > 35) {
        validationErrors.push("Viite on liian pitkä (max 35 merkkiä)");
      }

      return validationErrors;
    };

    try {
      const validationResult = validateParameters();

      if (validationResult.length) {
        addNotification(
          `Seuraavat tiedot on korjattava jotta laskun muodostus onnistuu: ${validationResult.join(", ")}`,
          NotificationState.Error
        );

        return;
      }

      setIsSubmitting(true);

      if (invoiceParams.automaticallyCreateInvoicesUsingReferences) {
        setFailedReferences([]);

        const referencesToUse =
          selectedReferences && selectedReferences.length
            ? selectedReferences.map((ref) => ref.value.content)
            : references.map((reference) => reference.content);

        const sentInvoices = await createMultipleInvoices({
          baronaCompanyCode: baronaCompany,
          customerId: selectedCustomer!.value.customerId,
          rangeStart: startDate,
          rangeEnd: endDate,
          customerReferences: referencesToUse,
          onSuccessfullInvoice: handleOnSuccessfullInvoice,
          onFailedInvoice: handleOnFailedInvoice,
          invoiceDate: invoiceParams.invoiceDate!,
          paymentGroup: selectedPaymentGroup?.value.content,
          paymentPeriod: selectedPaymentPeriod?.value.content,
          ...invoiceParams,
        });

        if (sentInvoices.length) {
          addNotification(
            `${sentInvoices.length} / ${referencesToUse.length} laskua luotiin onnistuneesti.`,
            NotificationState.Success
          );
        }
      } else {
        const persistendInvoice = await createInvoice({
          baronaCompanyCode: baronaCompany,
          customerId: selectedCustomer!.value.customerId,
          rangeStart: startDate,
          rangeEnd: endDate,
          customerReferences: selectedReferenceValues,
          invoiceDate: invoiceParams.invoiceDate!,
          paymentGroup: selectedPaymentGroup?.value.content,
          paymentPeriod: selectedPaymentPeriod?.value.content,
          ...invoiceParams,
        });
        addNotification(
          `Lasku luotiin onnistuneesti! Laskun id on ${persistendInvoice.invoiceId}`,
          NotificationState.Success
        );
      }

      setSuccessfullReferences([]);
      setSelectedReferences(undefined);
      setSelectedCustomer(undefined);
      setInvoiceParams({
        automaticallyCreateInvoicesUsingReferences: false,
        groupBy: invoiceParams.groupBy,
        referenceOnInvoice: undefined,
        useStaffingContractContactPersonAsOurReference: false,
        invoiceDate: invoiceParams.invoiceDate,
      });
      setSelectedPaymentGroup(undefined);
      setSelectedPaymentPeriod(undefined);
      refreshAllFilters();
    } catch (error) {
      const title = (axios.isAxiosError(error) && (error as AxiosError<IProblemJSON>)?.response?.data?.title) ?? "";
      addNotification(
        `Laskun luonti epäonnistui! ${title ? `Yksityiskohdat virheestä: ${title}` : ``} `,
        NotificationState.Error
      );
    } finally {
      setIsSubmitting(false);
      refreshOpenInvoicesCount();
    }
  }, [
    addNotification,
    baronaCompany,
    selectedCustomer,
    endDate,
    handleOnFailedInvoice,
    handleOnSuccessfullInvoice,
    selectedReferenceValues,
    invoiceParams,
    selectedPaymentGroup?.value.content,
    selectedPaymentPeriod?.value.content,
    references,
    refreshAllFilters,
    refreshOpenInvoicesCount,
    startDate,
    selectedReferences,
  ]);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  return (
    <FiltersWithParametersContainer>
      <FiltersContainerAside>
        <Title>Rajaa tapahtumia</Title>
        <NotificationsContainer>
          <NotificationItem
            canRemove={false}
            notification={{
              state: NotificationState.Info,
              text: "Asiakas sekä kirjauspäivä ovat pakollisia tietoja",
              id: "guideText",
            }}
          />
          {!isSubmitting && failedReferences.length ? (
            <NotificationItem
              notification={{
                state: NotificationState.Error,
                text: `${failedReferences.length} laskun luonti epäonnistui, alla viitteet jotka epäonnistuivat`,
                id: "failedReferences",
              }}
              onClose={() => {
                setFailedReferences([]);
              }}
            >
              <FailedReferencesList>
                {failedReferences.map((referenceWithDetails) => (
                  <FailedReference key={referenceWithDetails.reference}>
                    {referenceWithDetails.reference ? referenceWithDetails.reference : "Tyhjä"}
                    {`${referenceWithDetails.details ? ` (${referenceWithDetails.details})` : ""}`}
                  </FailedReference>
                ))}
              </FailedReferencesList>
              <CopyToClipboardButton
                onClick={async () => {
                  await navigator.clipboard.writeText(failedReferences.join(", "));
                }}
              >
                <CopyIcon />
                Kopioi viitteet leikepöydälle
              </CopyToClipboardButton>
            </NotificationItem>
          ) : null}
        </NotificationsContainer>
        <Notifications />
        <FiltersContainer>
          <FilterSelectContainer>
            <ListSelectLabel>
              Asiakas <FilterCount count={customers.length} /> *
            </ListSelectLabel>
            <StyledListSelect<FC<ListSelectProps<CustomerItemType>>>
              items={customers.map((customer) => ({ value: customer, label: customer.customerName }))}
              onChange={(item) => {
                setSelectedCustomer(item[0]);
                setReferenceFilterAndParam(undefined);
              }}
              value={compact([selectedCustomer])}
              emptyMessage="Ei asiakkaita"
              renderLoading={isLoadingCustomers}
            />
            <ClearSelectionContainer>
              <ClearSelectionButton
                data-testid="ClearCustomerSelection"
                disabled={!selectedCustomer}
                onClick={() => setSelectedCustomer(undefined)}
              >
                <ClearSelectionIcon disabled={!selectedCustomer} />
                Tyhjennä
              </ClearSelectionButton>
            </ClearSelectionContainer>
          </FilterSelectContainer>
          <FilterSelectContainer>
            <ReferenceTitleContainer>
              <ListSelectLabel>
                Viite <FilterCount count={references.length} />
              </ListSelectLabel>
              <div ref={setTriggerRef}>
                <ReferenceInfoCircle />
              </div>
              {visible && (
                <div
                  ref={setTooltipRef}
                  {...getTooltipProps({
                    className: "tooltip-container",
                    style: { margin: 0 },
                  })}
                >
                  <ReferenceInfoText>
                    Jos valittuna on useampi viite, voit luoda joka viitteestä oman laskun valitsemalla oikealta 'Joka
                    viitteestä oma lasku'.
                  </ReferenceInfoText>
                  <div {...getArrowProps({ className: "tooltip-arrow" })} />
                </div>
              )}
            </ReferenceTitleContainer>
            <StyledListSelect<FC<ListSelectProps<ReferenceItemType>>>
              items={references.map((reference) => ({
                value: reference,
                label: reference.content === null ? "Tyhjä" : reference.content,
              }))}
              onChange={(items) => setReferenceFilterAndParam(items)}
              value={compact(selectedReferences)}
              emptyMessage="Ei viitteitä"
              multiple={true}
              renderLoading={isLoadingReferences}
            />
            <ClearSelectionContainer>
              <ClearSelectionButton
                data-testid="ClearReferenceSelection"
                disabled={!selectedReferences?.length}
                onClick={() => setReferenceFilterAndParam(undefined)}
              >
                <ClearSelectionIcon disabled={!selectedReferences?.length} />
                Tyhjennä
              </ClearSelectionButton>
            </ClearSelectionContainer>
          </FilterSelectContainer>
          <FilterSelectContainer>
            <ListSelectLabel>Tapahtuman päivä</ListSelectLabel>
            <DateRangeContainer>
              <DatePicker width={100} selected={startDate} onChange={setStartDate} placeholderText="Alkaen pv" />
              <DashContainer>-</DashContainer>
              <DatePicker
                width={100}
                selected={endDate}
                onChange={setEndDateAndInvoiceParam}
                placeholderText="Asti pv"
              />
            </DateRangeContainer>
            <ClearSelectionContainer>
              <ClearSelectionButton
                data-testid="ClearDateRangeSelection"
                disabled={!startDate && !endDate}
                onClick={() => {
                  setStartDate(undefined);
                  setEndDateAndInvoiceParam(undefined);
                }}
              >
                <ClearSelectionIcon disabled={!startDate && !endDate} />
                Tyhjennä
              </ClearSelectionButton>
            </ClearSelectionContainer>
          </FilterSelectContainer>
          <FilterSelectContainer>
            <ListSelectLabel>
              Ajoryhmä <FilterCount count={paymentGroups.length} />
            </ListSelectLabel>
            <StyledListSelect<FC<ListSelectProps<PaymentGroupItemType>>>
              items={paymentGroups.map((paymentGroup) => ({ value: paymentGroup, label: paymentGroup.content }))}
              onChange={(item) => setSelectedPaymentGroup(item[0])}
              value={compact([selectedPaymentGroup])}
              emptyMessage="Ei ajoryhmiä"
              renderLoading={isLoadingPaymentGroups}
            />
            <ClearSelectionContainer>
              <ClearSelectionButton
                data-testid="ClearPaymentGroupSelection"
                disabled={!selectedPaymentGroup}
                onClick={() => setSelectedPaymentGroup(undefined)}
              >
                <ClearSelectionIcon disabled={!selectedPaymentGroup} />
                Tyhjennä
              </ClearSelectionButton>
            </ClearSelectionContainer>
          </FilterSelectContainer>
          <FilterSelectContainer>
            <ListSelectLabel>
              Palkkakausi <FilterCount count={paymentPeriods.length} />
            </ListSelectLabel>
            <StyledListSelect<FC<ListSelectProps<PaymentPeriodItemType>>>
              items={paymentPeriods.map((paymentPeriod) => ({ value: paymentPeriod, label: paymentPeriod.content }))}
              onChange={(item) => setPaymentPeriodFilterAndInvoiceParam(item[0])}
              value={compact([selectedPaymentPeriod])}
              emptyMessage="Ei palkkakausia"
              renderLoading={isLoadingPaymentPeriods}
            />
            <ClearSelectionContainer>
              <ClearSelectionButton
                data-testid="ClearPaymentPeriodSelection"
                disabled={!selectedPaymentPeriod}
                onClick={() => setPaymentPeriodFilterAndInvoiceParam(undefined)}
              >
                <ClearSelectionIcon disabled={!selectedPaymentPeriod} />
                Tyhjennä
              </ClearSelectionButton>
            </ClearSelectionContainer>
          </FilterSelectContainer>
        </FiltersContainer>
        <SelectedFiltersContainer>
          {selectedFilters.map((filter) => (
            <SelectedItem key={Array.isArray(filter) ? filter.join() : filter} data-testid="SelectedItem">
              {Array.isArray(filter) ? filter.join(", ") : filter}
            </SelectedItem>
          ))}
        </SelectedFiltersContainer>
      </FiltersContainerAside>
      <ParametersContainer>
        <Title>Laskun määrittelyt</Title>
        <InvoiceParams
          value={invoiceParams}
          onChange={setInvoiceParams}
          paymentPeriod={
            startDate && endDate
              ? `${dateToLocaleDateString(startDate)} – ${dateToLocaleDateString(endDate)}`
              : selectedPaymentPeriod?.label
          }
        />
        <CreateInvoiceButton disabled={isSubmitting || hasAnyItemsWithoutPrice} onClick={handleClickCreateInvoice}>
          Luo laskut
        </CreateInvoiceButton>
      </ParametersContainer>
      <IndicatorModal testId="SendingInvoicesModal" show={isSubmitting}>
        {invoiceParams.automaticallyCreateInvoicesUsingReferences
          ? `Muodostetaan useaa laskua... (${successfullReferences.length + failedReferences.length} / ${
              selectedReferences && selectedReferences.length ? selectedReferences.length : references.length
            })`
          : "Muodostetaan yhtä laskua..."}
      </IndicatorModal>
    </FiltersWithParametersContainer>
  );
};

export default React.memo(forwardRef(ItemFilters));
