import { css } from "styled-components";
import Color from "../../colors";

export const inputStyle = css`
  min-width: auto;
  padding: 8px 10px;
  border: solid 1px ${Color.GREY_LIGHT};
  border-radius: 3px;
  font-family: "Lato", sans-serif;
  background-color: ${Color.WHITE};
  font-size: 14px;
  color: ${Color.BLACK};
  &:focus {
    box-shadow: 0px 0px 0px 2px ${Color.LAPA_BLUE};
    outline: none;
  }
  &::placeholder {
    color: ${Color.GREY};
  }
  transition: box-shadow 0.1s linear;
`;
