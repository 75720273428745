import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextInput from "../../components/form/TextInput";
import Color from "../../colors";
import DatePicker, { RangePicker } from "../../components/DatePicker";
import { BaronaCompany, InvoiceGroupBy, InvoiceSource, PersistedInvoice, Serialized } from "@barona/lapa-common-types";
import Select from "../../components/form/Select";
import { useFrontendApi } from "../../hooks/useFrontendApi";
import { LapaLink } from "../../components/Link";
import { ExternalLink } from "@styled-icons/evaicons-solid/ExternalLink";
import { dateToLocaleDateString } from "@barona/lapa-common-date-utils";

const ParametersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  row-gap: 20px;
`;

const Title = styled.label`
  margin-bottom: 8px;
`;

const InputGroup = styled.div`
  width: 22%;
  display: grid;
  color: ${Color.GREY};
  ${LapaLink} {
    margin-top: 5px;
  }
`;

const ExternalLinkIcon = styled(ExternalLink)`
  width: 14px;
  height: 14px;
  margin-right: 2px;
`;

const NonEditableValue = styled.span`
  color: ${Color.BLACK};
`;

export interface DraftInvoiceEditableParams {
  ourReference?: string;
  customerReference?: string;
  groupBy?: InvoiceGroupBy;
  invoiceDate?: Date;
  paymentPeriod?: { end?: Date; start?: Date } | null;
  invoiceInfoId?: string | null;
  contactPerson?: string | null;
  sendNotification?: boolean;
}

interface DraftInvoiceEditableParametersProps {
  invoice: Serialized<PersistedInvoice>;
  parameters: DraftInvoiceEditableParams;
  parametersOnChange: (parameters: DraftInvoiceEditableParams) => void;
  editable: boolean;
  ourReferenceFromContactPerson: boolean;
  availableContactPersons: string[];
}

const DraftInvoiceEditableParameters = (props: DraftInvoiceEditableParametersProps) => {
  const { ourReference, customerReference, groupBy, invoiceDate, paymentPeriod, invoiceInfoId, contactPerson } =
    props.parameters;
  const { parametersOnChange, invoice, editable } = props;
  const isOnlyGroupByProductEnabled = (invoice.baronaCompany as BaronaCompany) === BaronaCompany.Varastopalvelut;

  const groupByOptions = [
    { value: InvoiceGroupBy.product, label: "Tuotteittain", disabled: false },
    { value: InvoiceGroupBy.person, label: "Työntekijöittäin", disabled: isOnlyGroupByProductEnabled },
    {
      value: InvoiceGroupBy.referenceAndPerson,
      label: "Viitteittäin ja työntekijöittäin",
      disabled: isOnlyGroupByProductEnabled,
    },
  ];

  const { result: invoiceInfos, isLoading: isInvoiceInfosLoading } = useFrontendApi("fetchCustomerInvoiceInfos", {
    baronaCompanyCode: invoice.baronaCompany as BaronaCompany,
    customerId: invoice.customerId,
  });

  const { result: contactPersonDetails, isLoading: isLoadingContactPersonDetails } = useFrontendApi(
    "fetchContactPersonDetails",
    props.availableContactPersons.length > 0 ? { contactPersonId: props.availableContactPersons } : null
  );

  const [contactPersonOptions, setContactPersonOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (contactPersonDetails?.length) {
      let newOptions = props.availableContactPersons.map((availableContactPersonId) => ({
        value: availableContactPersonId,
        label:
          contactPersonDetails.find(({ contactPersonId }) => contactPersonId === availableContactPersonId)?.name ??
          availableContactPersonId,
      }));
      setContactPersonOptions(newOptions);
    }
  }, [contactPersonDetails, props.availableContactPersons]);

  const handleCreateOption = (option: string) => {
    const newOption = { label: option.substring(0, 35), value: option.substring(0, 35) };
    handleContactPersonOnChange(newOption);
  };

  const invoiceInfoOptions = (invoiceInfos ?? []).map((invoiceInfo) => ({
    label: invoiceInfo.salesforceId,
    value: invoiceInfo.invoiceInfoA2Id,
  }));

  const selectedInvoiceInfo =
    invoiceInfoOptions.find(({ value }) => value === invoiceInfoId) ??
    (invoiceInfoId
      ? {
          label: invoiceInfoId,
          value: invoiceInfoId,
        }
      : null);

  const handleCustomerReferenceOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    parametersOnChange({
      ...props.parameters,
      customerReference: event.target.value || undefined,
    });
  };

  const handleOurReferenceOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    parametersOnChange({
      ...props.parameters,
      ourReference: event.target.value || undefined,
    });
  };

  const handleGroupByOnChange = (groupBy: InvoiceGroupBy) => {
    parametersOnChange({
      ...props.parameters,
      groupBy,
    });
  };

  const handleInvoiceDateOnChange = (date: Date) => {
    const invoiceDate = date ? date : undefined;

    parametersOnChange({
      ...props.parameters,
      invoiceDate,
    });
  };

  const handleInvoicePeriodOnChange = (dates: [Date, Date]) => {
    const [start, end] = dates;

    parametersOnChange({
      ...props.parameters,
      paymentPeriod: {
        end: end ? end : undefined,
        start: start ? start : undefined,
      },
    });
  };

  const handleInvoiceInfoIdOnChange = (option: { value: string; label: string } | null) => {
    parametersOnChange({
      ...props.parameters,
      invoiceInfoId: option?.value ?? null,
    });
  };

  const handleContactPersonOnChange = (option: { value: string; label: string } | null) => {
    parametersOnChange({
      ...props.parameters,
      contactPerson: option?.value ?? null,
    });
  };

  return (
    <ParametersContainer>
      <InputGroup>
        <Title htmlFor="CustomerReference">Asiakkaan viite</Title>
        {editable ? (
          <TextInput
            id="CustomerReference"
            data-testid="CustomerReference"
            value={customerReference ?? ""}
            onChange={handleCustomerReferenceOnChange}
            placeholder="Asiakkaan viite"
          />
        ) : (
          <NonEditableValue>{customerReference ?? "—"}</NonEditableValue>
        )}
      </InputGroup>
      {props.ourReferenceFromContactPerson ? (
        <InputGroup>
          <Title htmlFor="OurReference">Yhteyshenkilö (Baronan viite)</Title>
          {editable ? (
            <Select
              inputId="OurReference"
              allowCreateOptions={true}
              onCreateOption={handleCreateOption}
              placeholder="Yhteyshenkilö"
              noOptionsMessage={() => "Ei yhteyshenkilöitä"}
              isLoading={isLoadingContactPersonDetails}
              options={contactPersonOptions}
              value={
                contactPerson
                  ? {
                      value: contactPerson,
                      label:
                        contactPersonOptions.find((option) => option.value === contactPerson)?.label ?? contactPerson,
                    }
                  : null
              }
              onChange={handleContactPersonOnChange}
            />
          ) : (
            <NonEditableValue>
              {contactPersonDetails?.find(({ contactPersonId }) => contactPersonId === contactPerson)?.name ??
                contactPerson ??
                "—"}
            </NonEditableValue>
          )}
        </InputGroup>
      ) : (
        <InputGroup>
          <Title htmlFor="OurReference">Baronan viite</Title>
          {editable ? (
            <TextInput
              id="OurReference"
              data-testid="OurReference"
              value={ourReference ?? ""}
              onChange={handleOurReferenceOnChange}
              placeholder="Baronan viite"
            />
          ) : (
            <NonEditableValue>{ourReference ?? "—"}</NonEditableValue>
          )}
        </InputGroup>
      )}

      {invoice.source === InvoiceSource.lapa && (
        <InputGroup>
          <Title htmlFor="groupByOptions">Ryhmittely</Title>
          <Select
            inputId="groupByOptions"
            options={groupByOptions}
            isOptionDisabled={(option) => option.disabled}
            onChange={(value) => handleGroupByOnChange(value!.value)}
            value={groupByOptions.find((option) => option.value === groupBy)}
          />
        </InputGroup>
      )}

      <InputGroup>
        <Title htmlFor="InvoiceDate">Kirjauspäivä</Title>
        {editable ? (
          <DatePicker
            id="InvoiceDate"
            placeholderText="Laskun päivämäärä"
            testId="InvoiceDate"
            onChange={handleInvoiceDateOnChange}
            selected={invoiceDate}
          />
        ) : (
          <NonEditableValue>{invoiceDate ? dateToLocaleDateString(invoiceDate) : "—"}</NonEditableValue>
        )}
      </InputGroup>
      <InputGroup>
        <Title htmlFor="PaymentPeriod">Laskutuskausi</Title>
        {editable ? (
          <RangePicker
            id="PaymentPeriod"
            placeholderText="Laskutuskausi"
            testId="PaymentPeriod"
            onChange={handleInvoicePeriodOnChange}
            startDate={paymentPeriod?.start}
            endDate={paymentPeriod?.end}
          />
        ) : (
          <NonEditableValue>
            {paymentPeriod?.start ? dateToLocaleDateString(paymentPeriod?.start) : ""} —
            {paymentPeriod?.end ? dateToLocaleDateString(paymentPeriod?.end) : ""}
          </NonEditableValue>
        )}
      </InputGroup>
      <InputGroup>
        <Title htmlFor="invoiceInfo">Invoice info *</Title>
        {editable ? (
          <>
            <Select
              inputId="invoiceInfo"
              placeholder="Invoice info"
              noOptionsMessage={() => "Ei invoice infoja"}
              isLoading={isInvoiceInfosLoading}
              options={invoiceInfoOptions}
              value={selectedInvoiceInfo}
              onChange={handleInvoiceInfoIdOnChange}
            />
            {selectedInvoiceInfo?.label && (
              <LapaLink
                target="_blank"
                href={`https://bravedo.lightning.force.com/lightning/r/Invoice_Information__c/${selectedInvoiceInfo.label}/view`}
              >
                <ExternalLinkIcon />
                Näytä Salesforcessa
              </LapaLink>
            )}
          </>
        ) : (
          <>
            <NonEditableValue>{selectedInvoiceInfo?.label ?? "—"}</NonEditableValue>
            {selectedInvoiceInfo?.label && (
              <LapaLink
                target="_blank"
                href={`https://bravedo.lightning.force.com/lightning/r/Invoice_Information__c/${selectedInvoiceInfo.label}/view`}
              >
                <ExternalLinkIcon />
                Näytä Salesforcessa
              </LapaLink>
            )}
          </>
        )}
      </InputGroup>
    </ParametersContainer>
  );
};

export default DraftInvoiceEditableParameters;
