import Select from "./form/Select";
import styled from "styled-components";

export type DropdownValue = { label: string; value: string };

interface DropdownProps {
  placeholder: string;
  value: DropdownValue | undefined;
  options: DropdownValue[];
  onChange: (value: DropdownValue | null) => void;
  className?: string;
  isDisabled?: boolean;
  testId?: string;
  width?: number;
}

const DropdownContainer = styled.div``;

const Dropdown = (props: DropdownProps) => (
  <DropdownContainer data-testid={props.testId ? props.testId : "Dropdown"}>
    <Select {...props} />
  </DropdownContainer>
);

export default Dropdown;
