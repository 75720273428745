import styled from "styled-components";
import Color from "../colors";

export const Button = styled.button`
  height: 36px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  background-color: ${Color.LAPA_BLUE};
  padding: 8px 18px;
  border-radius: 3px;
  color: ${Color.WHITE};
  border: none;
  display: inline-block;
  cursor: pointer;
  &:disabled {
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_LIGHT};
    color: ${Color.GREY_LIGHT};
    cursor: not-allowed;
  }
`;
