import styled from "styled-components";
import Color from "../../colors";
import { DotsVerticalRounded } from "@styled-icons/boxicons-regular";
import { usePopperTooltip } from "react-popper-tooltip";

const RowTooltipIcon = styled(DotsVerticalRounded)`
  height: 26px;
  color: ${Color.GREY_DARK};
  &:hover {
    color: ${Color.LAPA_BLUE};
    cursor: pointer;
  }
`;

export interface Props {
  tooltip: React.ReactNode;
}

const InvoiceRowTooltip = ({ tooltip }: Props) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: "click",
    interactive: true,
  });
  return (
    <div style={{ float: "right" }} ref={setTriggerRef}>
      <RowTooltipIcon data-testid="InvoiceRow.Tooltip" />
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          {tooltip}
        </div>
      )}
    </div>
  );
};

export default InvoiceRowTooltip;
