import { useState } from "react";

export const useLocalStorage = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
  const [persistedValue, setPersistedValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (e) {
      console.error("Error getting value from local storage", e);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      setPersistedValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error("Error storing value in local storage", e);
    }
  };

  return [persistedValue, setValue];
};
