import styled from "styled-components";
import Color from "../../colors";

export const PageHeader = styled.h1`
  font-size: 16px;
  text-decoration: none;
  color: ${Color.BLACK};
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 4px;
  display: block;
`;
