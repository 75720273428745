import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany } from "@barona/lapa-common-types";
import { useQuery } from "react-query";

export const usePriceLists = (customerId: string, baronaCompany?: BaronaCompany, searchFilter?: string) => {
  const { data, isLoading } = useQuery(
    ["pricelists", baronaCompany, customerId, searchFilter],
    async () =>
      await frontendApi.searchPriceLists({
        searchFilter,
        customerId,
        baronaCompany,
      })
  );
  return { priceLists: data, isLoading };
};

export const usePriceList = (priceListId: string) => {
  const { data, isLoading } = useQuery(
    ["pricelist", priceListId],
    async () => await frontendApi.fetchPriceList({ priceListId })
  );
  return { pricelist: data, isLoading };
};

export const usePriceListHistory = (priceListId: string) => {
  const { data, isLoading } = useQuery(
    ["pricelistHistory", priceListId],
    async () => await frontendApi.fetchPriceListHistory({ priceListId })
  );
  return { pricelistHistory: data, isLoading };
};
