import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Color from "../../colors";
import ReferenceTable from "./ReferenceTable";
import { Button } from "../../components/Button";
import {
  addReference,
  hideReference,
  ReferencesResponse,
  updateReference,
  useReferences,
} from "../../services/references";
import { useBaronaCompany, useNotifications, NotificationState } from "../../context";
import axios from "axios";

const TableTitle = styled.h3`
  color: ${Color.GREY};
  margin: 20px 0 0 0;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
`;

const ReferencesPage = () => {
  // TODO: fix typing
  const { customerId } = useParams<"customerId">() as { customerId: string };
  const { baronaCompany: baronaCompanyCode } = useBaronaCompany();
  const { addNotification } = useNotifications();

  const [isAddingNewReference, setIsAddingNewReference] = useState(false);

  const {
    references: visibleReferences,
    forceRefresh: forceRefreshVisibleReferences,
    isLoading: isLoadingVisibleReferences,
  } = useReferences({
    baronaCompanyCode,
    customerId,
    hidden: false,
  });
  const {
    references: hiddenReferences,
    forceRefresh: forceRefreshHiddenReferences,
    isLoading: isLoadingHiddenReferences,
  } = useReferences({
    baronaCompanyCode,
    customerId,
    hidden: true,
  });

  const handleOnCancelNewReference = useCallback(() => {
    setIsAddingNewReference(false);
  }, [setIsAddingNewReference]);

  const handleReferenceSave = async (
    referenceValue: string,
    isSelectableInHourReporting: boolean,
    editing: boolean,
    referenceName: string | null,
    referenceId?: string
  ) => {
    try {
      if (editing) {
        if (!referenceId) {
          throw new Error("Reference is not set");
        }

        await updateReference(referenceId, { name: referenceName, isSelectableInHourReporting });

        addNotification("Viite päivitetty onnistuneesti", NotificationState.Success);
      } else {
        if (!referenceValue) {
          addNotification("Viitteen arvo on pakollinen kenttä", NotificationState.Error);
          return;
        }

        await addReference({
          baronaCompanyCode,
          customerId,
          name: referenceName || null,
          value: referenceValue,
          isSelectableInHourReporting,
          hidden: false,
        });

        handleOnCancelNewReference();
        addNotification("Viite tallennettu onnistuneesti", NotificationState.Success);
        refreshReferences();
      }
    } catch (error) {
      const userMessage =
        axios.isAxiosError(error) && error?.response?.status === 409
          ? `Viite arvolla "${referenceValue}" on jo olemassa tällä asiakkaalla`
          : (error as Error)?.message;
      addNotification(`Viitteen lisäys epäonnistui: ${userMessage}`, NotificationState.Error);
    }
  };

  const handleOnHideReference = useCallback(
    async (reference: ReferencesResponse[number]) => {
      try {
        await hideReference(reference.referenceId);

        addNotification("Viite piilotettu onnistuneesti", NotificationState.Success);
        forceRefreshVisibleReferences();
        forceRefreshHiddenReferences();
      } catch (error) {
        const userMessage = (error as Error)?.message ?? "";
        addNotification(`Viitteen piilotus epäonnistui: ${userMessage}`, NotificationState.Error);
      }
    },
    [forceRefreshVisibleReferences, forceRefreshHiddenReferences, addNotification]
  );

  const refreshReferences = useCallback(() => {
    forceRefreshVisibleReferences();
    forceRefreshHiddenReferences();
  }, [forceRefreshVisibleReferences, forceRefreshHiddenReferences]);

  return (
    <>
      <Controls>
        <Button onClick={() => setIsAddingNewReference(true)} disabled={isAddingNewReference}>
          Uusi viite
        </Button>
      </Controls>
      <TableTitle>Käytössä olevat viitteet</TableTitle>
      <ReferenceTable
        references={visibleReferences}
        isAddingNewReference={isAddingNewReference}
        onCancelNewReference={handleOnCancelNewReference}
        onHideReference={handleOnHideReference}
        onReferenceSave={handleReferenceSave}
        isLoading={isLoadingVisibleReferences}
        refreshReferences={refreshReferences}
      />
      <TableTitle>Käytöstä poistetut viitteet</TableTitle>
      <ReferenceTable
        references={hiddenReferences}
        isLoading={isLoadingHiddenReferences}
        onCancelNewReference={handleOnCancelNewReference}
        refreshReferences={refreshReferences}
      />
    </>
  );
};

export default ReferencesPage;
