import React from "react";
import {
  Table,
  HeaderRow,
  HeaderCell,
  Row,
  Cell,
  RigthAlignCell,
  RigthAlignHeaderCell,
  TableBody,
} from "../../../components/Table";
import { MepcoItemAdditionalInfo } from "@barona/lapa-common-types";
import styled, { css } from "styled-components";
import { Checkmark } from "@styled-icons/evaicons-solid";
import { dateToLocaleDateString } from "@barona/lapa-common-date-utils";
import { TrashAlt } from "@styled-icons/fa-solid/TrashAlt";
import { TimesCircle } from "@styled-icons/fa-solid/TimesCircle";
import Color from "../../../colors";
import CheckboxInput from "../../../components/form/CheckboxInput";
import { frontendApi } from "@barona/lapa-common-frontend";
import Notifications from "../../common/Notifications";
import { NotificationState, useNotifications } from "../../../context";
import { AlertTriangle } from "@styled-icons/evaicons-solid/AlertTriangle";
import { LapaNavigationLink } from "../../../components/Link";
import { UninvoicedItem } from "../../../services/uninvoicedItems";
import Loading from "../../../components/Loading";

const CheckmarkIcon = styled(Checkmark)`
  width: 20px;
`;

const NoPriceIcon = styled(AlertTriangle)`
  width: 20px;
`;

const TrashIcon = styled(TrashAlt)<{ disabled?: boolean }>`
  height: 12px;
  margin: 0 3px 2px 0;
  color: ${Color.LAPA_BLUE};

  ${(props) =>
    props.disabled &&
    css`
      color: ${Color.GREY_LIGHT};
    `}
`;

const RemoveItemsButtonBase = styled.button`
  all: unset;
  cursor: pointer;
  vertical-align: middle;
  color: ${Color.LAPA_BLUE};
  font-size: 14px;
  margin-left: 20px;

  &:disabled {
    color: ${Color.GREY_LIGHT};
    cursor: not-allowed;
  }
`;

const RemoveUninvoicedItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 12px;
`;

const RightAligned = styled.div`
  text-align: right;
`;

const LeftAligned = styled.div`
  text-align: left;
`;

const RemoveIcon = styled(TimesCircle)`
  height: 12px;
  margin: 0 3px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

const StyledCheckboxInput = styled(CheckboxInput)`
  &:disabled {
    visibility: hidden;
  }
`;

const NoPriceContainer = styled.span`
  color: ${Color.RED};
`;

const removeUninvoicedItems = async (itemIds: string[]) => {
  await frontendApi.removeItems({ itemIds });
};

interface UninvoicedItemsProps {
  uninvoicedItems: UninvoicedItem[];
  isRemoveItemsMode: boolean;
  selectedItemsIds: string[];
  setSelectedItemsIds: (selectedItemsIds: string[]) => void;
  setIsRemoveItemsMode: (setIsRemoveItemsMode: boolean) => void;
  refreshAllFilters?: () => void;
  refreshUninvoicedItems?: () => void;
  isLoading: boolean;
}

const UninvoicedItems = (props: UninvoicedItemsProps) => {
  const { addNotification } = useNotifications();
  const { uninvoicedItems, selectedItemsIds, isRemoveItemsMode, setSelectedItemsIds, setIsRemoveItemsMode, isLoading } =
    props;

  const allItemIds = uninvoicedItems.map(({ itemId }) => itemId);
  const isAllChecked = allItemIds.every((itemId) => selectedItemsIds.includes(itemId)) && !!selectedItemsIds.length;

  const removeSelectedItemsHandler = async () => {
    try {
      await removeUninvoicedItems(selectedItemsIds);
      addNotification(`Valitut tapahtumat poistettu`, NotificationState.Success);
      setSelectedItemsIds([]);
      setIsRemoveItemsMode(false);
    } catch (error) {
      console.error(error);
      addNotification(`Tapahtumien poisto epäonnistui`, NotificationState.Error);
    }

    props.refreshUninvoicedItems?.();
    props.refreshAllFilters?.();
  };

  const renderPrice = (price: number | null, pricedAt: Date | null, billable: boolean) => {
    if (!pricedAt) {
      return (
        <NoPriceContainer>
          <NoPriceIcon /> Ei hintaa
        </NoPriceContainer>
      );
    }

    if (!billable) {
      return "-";
    }

    return `${price} €`;
  };

  return (
    <>
      <Notifications />
      <RemoveUninvoicedItemsContainer>
        {isRemoveItemsMode ? (
          <LeftAligned>
            <RemoveItemsButtonBase
              data-testid="RemoveSelectedItems"
              disabled={selectedItemsIds.length < 1}
              onClick={() => removeSelectedItemsHandler()}
            >
              <TrashIcon disabled={selectedItemsIds.length < 1} />
              Poista valitut tapahtumat
            </RemoveItemsButtonBase>
            <RemoveItemsButtonBase
              data-testid="CancelRemoveSelectedItems"
              onClick={() => {
                setSelectedItemsIds([]);
                setIsRemoveItemsMode(!isRemoveItemsMode);
              }}
            >
              <RemoveIcon />
              Peruuta
            </RemoveItemsButtonBase>
          </LeftAligned>
        ) : (
          <>
            <LeftAligned data-testid="OpenItemsCount">{`${
              uninvoicedItems.length >= 1000
                ? "1000+ avointa tapahtumaa"
                : `${uninvoicedItems.length} avointa tapahtumaa`
            }`}</LeftAligned>
            <RightAligned>
              <RemoveItemsButtonBase
                data-testid="ActivateRemoveSelectedItems"
                onClick={() => setIsRemoveItemsMode(!isRemoveItemsMode)}
              >
                <TrashIcon />
                Poista tapahtumia
              </RemoveItemsButtonBase>
            </RightAligned>
          </>
        )}
      </RemoveUninvoicedItemsContainer>
      {!isLoading && (
        <Table>
          <HeaderRow>
            <HeaderCell>
              <StyledCheckboxInput
                disabled={!isRemoveItemsMode}
                data-testid="CheckAllItems"
                checked={isAllChecked}
                onChange={() => {
                  if (isAllChecked) {
                    setSelectedItemsIds([]);
                  } else {
                    setSelectedItemsIds(allItemIds);
                  }
                }}
              />
            </HeaderCell>
            <HeaderCell>PVM</HeaderCell>
            <HeaderCell style={{ width: "25%" }}>ASIAKKAAN VIITE</HeaderCell>
            <HeaderCell style={{ width: "10%" }}>HINNASTO</HeaderCell>
            <HeaderCell style={{ width: "15%" }}>TYÖNTEKIJÄ</HeaderCell>
            <HeaderCell style={{ width: "20%" }}>TUOTE</HeaderCell>
            <HeaderCell>KPL</HeaderCell>
            <RigthAlignHeaderCell style={{ width: "10%" }}>YKSIKKÖ-HINTA</RigthAlignHeaderCell>
            <RigthAlignHeaderCell style={{ width: "10%" }}>HINTA</RigthAlignHeaderCell>
            <HeaderCell>LASKUTETAAN</HeaderCell>
          </HeaderRow>
          <TableBody>
            {uninvoicedItems.map((item) => (
              <Row key={item.itemId} data-testid="UninvoicedItemRow">
                <Cell>
                  <StyledCheckboxInput
                    disabled={!isRemoveItemsMode}
                    data-testid="CheckItem"
                    checked={isAllChecked || selectedItemsIds.includes(item.itemId)}
                    onChange={() => {
                      if (selectedItemsIds.includes(item.itemId)) {
                        setSelectedItemsIds(selectedItemsIds.filter((itemId) => itemId !== item.itemId));
                      } else {
                        setSelectedItemsIds([...selectedItemsIds, item.itemId]);
                      }
                    }}
                  />
                </Cell>
                <Cell>{dateToLocaleDateString(item.itemDate)}</Cell>
                <Cell>{item.customerReference}</Cell>
                <Cell>
                  <LapaNavigationLink to={`/customers/${item.customerId}/pricelists/${item.priceListId}`}>
                    {item.priceListName}
                  </LapaNavigationLink>
                </Cell>
                <Cell>
                  {(item.additionalInfo as MepcoItemAdditionalInfo)?.employeeName || item.employeeNumberDimension || ""}
                </Cell>
                <Cell>
                  {item.productName}
                  <br />({item.productCode})
                </Cell>
                <Cell>{item.amount}</Cell>
                <RigthAlignCell>{renderPrice(item.price, item.pricedAt, item.billable)}</RigthAlignCell>
                <RigthAlignCell>{item.price ? (item.price * item.amount).toFixed(2) + " €" : " - "}</RigthAlignCell>
                <Cell>{item.billable && <CheckmarkIcon />}</Cell>
              </Row>
            ))}
          </TableBody>
        </Table>
      )}
      {isLoading && <Loading height={36} count={4} />}
    </>
  );
};

export default React.memo(UninvoicedItems);
