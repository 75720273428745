import { PriceType, ProductUnit } from "@barona/lapa-common-types";
import React from "react";
import styled from "styled-components";
import TextInput from "../../components/form/TextInput";
import Dropdown, { DropdownValue } from "../../components/Dropdown";
import { Row, Cell, RigthAlignCell } from "../../components/Table";
import { TimesCircle } from "@styled-icons/fa-solid/TimesCircle";
import Color from "../../colors";
import CheckboxInput from "../../components/form/CheckboxInput";

export const RemoveIcon = styled(TimesCircle)<{ disabled?: boolean }>`
  height: 16px;
  width: 16px;
  padding-right: 20px;
  color: ${(props) => (props.disabled ? Color.GREY_LIGHT : Color.LAPA_BLUE)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const priceTypeOptions = [
  { label: "Kerroin", value: PriceType.Multiplier },
  { label: "Hinta", value: PriceType.Fixed },
];

const unitOptions = [
  { label: "h", value: "h" },
  { label: "kpl", value: "pcs" },
];

export const RemoveProductContainer = styled(RigthAlignCell)`
  box-sizing: unset;
`;

export const FillAvailableTextInput = styled(TextInput)`
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

export const getPriceType = (value: string) => priceTypeOptions.find((priceType) => priceType.value === value)!;
export const getUnit = (unitValue: string | null) => unitOptions.find((unit) => unit.value === unitValue)?.label || "";

export interface PricelistItemProps {
  name: string;
  priceType?: string;
  priceValue?: string;
  billable?: boolean;
  unit: ProductUnit | null;
  productCode: string;
  disabled?: boolean;
  handlePriceValueOnChange: (productCode: string, value: string) => void;
  handlePriceTypeOnChange: (productCode: string, value: DropdownValue | null) => void;
  handleBillableOnChange: (productCode: string, value: boolean) => void;
  handleRemoveOnClick?: (productCode: string) => void;
}

const PricelistItem = (props: PricelistItemProps) => {
  const {
    name,
    priceType,
    priceValue,
    billable,
    productCode,
    unit,
    disabled = false,
    handlePriceValueOnChange,
    handlePriceTypeOnChange,
    handleBillableOnChange,
    handleRemoveOnClick,
  } = props;

  return (
    <Row key={name}>
      <Cell>{name}</Cell>
      <Cell>
        <FillAvailableTextInput
          data-testid="PriceValue"
          value={billable ? priceValue : ""}
          disabled={!billable || disabled}
          onChange={(event) => {
            handlePriceValueOnChange(productCode, event.currentTarget.value);
          }}
        />
      </Cell>
      <Cell>
        <Dropdown
          testId="PriceType"
          value={getPriceType(priceType!)}
          options={priceTypeOptions}
          placeholder=""
          onChange={(value) => handlePriceTypeOnChange(productCode, value)}
          isDisabled={disabled}
        />
      </Cell>
      <Cell>
        <CheckboxInput
          data-testid="Billable"
          checked={billable}
          onChange={(event) => handleBillableOnChange(productCode, event.currentTarget.checked)}
          disabled={disabled}
        />
      </Cell>
      <Cell>{productCode}</Cell>
      <Cell>{getUnit(unit)}</Cell>
      {handleRemoveOnClick && (
        <RemoveProductContainer>
          <RemoveIcon
            data-testid="RemoveProduct"
            onClick={() => !disabled && handleRemoveOnClick(productCode)}
            disabled={disabled}
          />
        </RemoveProductContainer>
      )}
    </Row>
  );
};

export default React.memo(PricelistItem);
