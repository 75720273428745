import {
  PublicClientApplication,
  Configuration,
  LogLevel,
  AccountInfo,
  AuthenticationResult,
} from "@azure/msal-browser";
import config from "../config";

const msalConfig: Configuration = {
  auth: {
    authority: "https://login.microsoftonline.com/baronafi.onmicrosoft.com/",
    clientId: config.adClientId,
    redirectUri: "/login",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            return console.error(message);
          case LogLevel.Warning:
            return console.warn(message);
          case LogLevel.Info:
          case LogLevel.Verbose:
            return;
        }
      },
    },
  },
};

const scopes = ["User.Read"];
const USERNAME_KEY = "currentUsername";

class AdAuthenticator {
  private msal: PublicClientApplication = new PublicClientApplication(msalConfig);
  private currentAccount: AccountInfo | null = null;
  private storedUsername: string | null = null;

  constructor() {
    this.storedUsername = localStorage.getItem(USERNAME_KEY);
  }

  public getToken = async (): Promise<string> => {
    try {
      if (!this.currentAccount) throw new Error("Can't get token silently without logging in first");
      const response = await this.msal.acquireTokenSilent({ scopes, account: this.currentAccount });
      return response.idToken;
    } catch (e) {
      console.error("Could not fetch token silently", e);
      throw e;
    }
  };

  public login = async (): Promise<{ accessToken: string; account: AccountInfo } | { redirect: true }> => {
    try {
      return await this.silentLogin();
    } catch (e) {
      // If silent login fails, try redirect login
      console.log("Silent login failed", e);
      this.redirectLogin();
      return { redirect: true };
    }
  };

  public handleRedirectLoginCallback = async () => {
    const response = await this.msal.handleRedirectPromise();
    return response ? this.handleAuthResponse(response) : null;
  };

  private redirectLogin = () => this.msal.loginRedirect({ scopes });

  private silentLogin = async () => {
    if (!this.storedUsername) throw new Error("Can't do silent login without username");
    return this.handleAuthResponse(await this.msal.ssoSilent({ scopes, loginHint: this.storedUsername }));
  };

  private handleAuthResponse = (response: AuthenticationResult) => {
    if (!response.account) throw new Error("Could not extract account from AD response");
    localStorage.setItem(USERNAME_KEY, response.account.username);
    this.currentAccount = response.account;
    return { accessToken: response.accessToken, account: response.account };
  };
}

const mockAuthenticationData = {
  idToken: "mock-id-token",
  accessToken: "mock-access-token",
  username: "mock-user@baronatest.fi",
};

class MockAdAuthenticator extends AdAuthenticator {
  public getToken = async (): Promise<string> => mockAuthenticationData.idToken;

  public login = async (): Promise<{ accessToken: string; account: AccountInfo }> => ({
    accessToken: mockAuthenticationData.accessToken,
    account: { username: mockAuthenticationData.username } as AccountInfo,
  });

  public handleRedirectLoginCallback = async () => null;
}

export default config.useMockAuthentication ? new MockAdAuthenticator() : new AdAuthenticator();
