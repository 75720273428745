import { InvoiceStatus } from "@barona/lapa-common-types";
import styled from "styled-components";
import { Invoice } from "../../services/invoices";
import { CloseIcon, HeaderContainer, SidePaneContent, StyledSecondaryButton } from "../InvoicePage/CommonPanelContent";
import { Cell, Row, Table, TableBody } from "../../components/Table";

export interface InvoiceListErrorContentProps {
  closePanel(): void;
  invoice: Invoice;
}

const ErrorTable = styled(Table)`
  border: 1px solid black;
`;

const ErrorRow = styled(Row)`
  height: 32px;
`;

const ErrorContent = styled(Cell)`
  margin: 20px;
`;

const ButtonsContainer = styled.div`
  margin-top: 3em;
  float: right;
`;

const InvoiceListErrorContent = ({ closePanel, invoice }: InvoiceListErrorContentProps) => {
  let errors: string[] = [];
  if (!invoice.invoiceInfoId) {
    errors.push("Laskulle pitää valita invoice info");
  }

  if (invoice.ourReferenceFrom === "contactPerson" && !invoice.contactPerson) {
    errors.push("Yhteyshenkilö puuttuu");
  }

  if (invoice.navError && invoice.status !== InvoiceStatus.inTransit && invoice.status !== InvoiceStatus.sent) {
    const errorSeparator = ";-;";
    errors = errors.concat(
      (invoice.navError + errorSeparator).split(errorSeparator).filter((errorMessage) => errorMessage)
    );
  }

  return (
    <SidePaneContent>
      <HeaderContainer>
        <h2>Virhe laskun lähetyksessä</h2>
        <div>
          <CloseIcon data-testid="closeIcon" onClick={closePanel} />
        </div>
      </HeaderContainer>
      <ErrorTable>
        <TableBody>
          {errors.map((error) => (
            <ErrorRow>
              <ErrorContent>{error}</ErrorContent>
            </ErrorRow>
          ))}
          <Row></Row>
        </TableBody>
      </ErrorTable>
      <ButtonsContainer>
        <StyledSecondaryButton onClick={closePanel}>Takaisin</StyledSecondaryButton>
      </ButtonsContainer>
    </SidePaneContent>
  );
};

export default InvoiceListErrorContent;
