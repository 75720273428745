import { BaronaCompany } from ".";
import { InvoiceGroupBy } from "./invoicingEnums";
import { ItemSource } from "./itemTypes";

export enum InvoicePeriod {
  MONTHLY = "monthly",
  TWICE_A_MONTH = "twiceAMonth",
}

export interface InvoicingModel {
  id: number;
  invoicingModelId: string;
  runOrder: number;
  name: string;
  enabled: boolean;

  baronaCompanyCode: BaronaCompany;
  customerId: string;
  itemSource: ItemSource;

  invoiceRowGrouping: InvoiceGroupBy;
  invoiceInfoId: string | null;
  invoiceByPaymentPeriod: boolean;
  invoiceByReference: boolean;
  /**
   * - Null means empty reference was selected as filter.
   * - String with a value means that value was used as a filter.
   */
  customerReferenceFilter: (string | null)[];
  invoicePeriod: InvoicePeriod | null;
  customerReferenceFrom: "customerReferenceFilter" | "manual";
  customerReference: string | null;
  ourReferenceFrom: "contactPerson" | "manual";
  ourReference: string | null;

  createdAt: Date;
  updatedAt: Date | null;
}

export interface CustomerInvoicingModelSum {
  customerId: string;
  customerName?: string;
  baronaCompanyCode?: BaronaCompany;
  invoicingModelCount: number;
}
