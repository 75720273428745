import axios, { AxiosResponse } from "axios";
import config from "../config";
import authService from "./authentication";
import { frontendApiBase } from "@barona/lapa-common-frontend";
export { frontendApi } from "@barona/lapa-common-frontend";

const frontendApiAxios = axios.create();

export const configureFrontendApi = (onResponse?: (response: AxiosResponse<any>) => void) =>
  frontendApiBase.configure({
    baseURL: config.apiBaseUrl,
    requestAdapter: async (method: string, url: string, query: any, body: any) => {
      try {
        const token = await authService.getToken();
        const response = await frontendApiAxios({
          method: method as any,
          url,
          params: query,
          data: "get" === method ? undefined : body,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 40000,
        });

        onResponse?.(response);
        return response.data;
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          onResponse?.(err.response);
        }
        throw err;
      }
    },
  });

export const __testable__ = {
  frontendApiAxios,
};
