import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Dropdown from "../../components/Dropdown";
import { Row, Cell } from "../../components/Table";
import {
  FillAvailableTextInput,
  getPriceType,
  getUnit,
  priceTypeOptions,
  RemoveIcon,
  RemoveProductContainer,
} from "./PricelistItem";
import { ProductItem } from "@barona/lapa-common-frontend";
import { PriceSetThrough, PriceType, ProductUnit } from "@barona/lapa-common-types";
import Color from "../../colors";
import CheckboxInput from "../../components/form/CheckboxInput";

const NewPricelistItemRow = styled(Row)`
  background-color: ${Color.LIGHT_BLUE} !important;
`;

export interface NewPricelistItemProps {
  products: ProductItem[];
  priceListId: string;
  handleNewProductOnChange: (productItem: ProductItem) => void;
  handleRemoveOnClick: () => void;
}

const NewPricelistItem = (props: NewPricelistItemProps) => {
  const { products, priceListId, handleNewProductOnChange, handleRemoveOnClick } = props;

  const [newProductItem, setNewProductItem] = useState<ProductItem>({
    name: "",
    billable: true,
    priceValue: "",
    priceType: PriceType.Multiplier,
    priceListId,
    unit: null,
    productCode: "",
    priceSetThrough: PriceSetThrough.Manually,
  });

  useEffect(() => {
    handleNewProductOnChange(newProductItem);
  }, [newProductItem, handleNewProductOnChange]);

  const setProductCodeWithUnit = (productCode: string, unit: ProductUnit | null) => {
    setNewProductItem((prevState) => {
      const newState = {
        ...prevState,
        productCode,
        unit,
      };

      return newState;
    });
  };

  const setPriceValue = (priceValue: string) => {
    setNewProductItem((prevState) => {
      const newState = {
        ...prevState,
        priceValue,
      };

      return newState;
    });
  };

  const setPriceType = (priceType: string) => {
    setNewProductItem((prevState) => {
      const newState = {
        ...prevState,
        priceType,
      };

      return newState;
    });
  };

  const setBillable = (billable: boolean) => {
    setNewProductItem((prevState) => {
      const newState = {
        ...prevState,
        billable,
        priceValue: billable ? prevState.priceValue : "",
      };
      return newState;
    });
  };

  const productNameOptions = products.map((product) => ({
    label: `${product.name} (${product.productCode})`,
    value: product.productCode,
  }));

  return (
    <NewPricelistItemRow data-testid="NewPricelistItem">
      <Cell>
        <Dropdown
          width={250}
          testId="NewProductName"
          value={undefined}
          options={productNameOptions}
          placeholder="Tuote"
          onChange={(value) => {
            const product = products.find((product) => product.productCode === value!.value)!;
            setProductCodeWithUnit(product.productCode, product.unit);
          }}
        />
      </Cell>
      <Cell>
        <FillAvailableTextInput
          data-testid="NewPriceValue"
          value={newProductItem.priceValue}
          disabled={!newProductItem.billable}
          onChange={(event) => {
            setPriceValue(event.currentTarget.value);
          }}
        />
      </Cell>
      <Cell>
        <Dropdown
          testId="NewPriceType"
          value={newProductItem.priceType ? getPriceType(newProductItem.priceType) : undefined}
          options={priceTypeOptions}
          placeholder=""
          onChange={(value) => {
            setPriceType(value!.value);
          }}
        />
      </Cell>
      <Cell>
        <CheckboxInput
          data-testid="NewBillable"
          checked={newProductItem.billable}
          onChange={(event) => {
            setBillable(event.currentTarget.checked);
          }}
        />
      </Cell>
      <Cell>{newProductItem.productCode}</Cell>
      <Cell>{getUnit(newProductItem.unit)}</Cell>
      <RemoveProductContainer>
        <RemoveIcon onClick={handleRemoveOnClick} />
      </RemoveProductContainer>
    </NewPricelistItemRow>
  );
};

export default React.memo(NewPricelistItem);
