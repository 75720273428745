import React, { useState } from "react";
import styled from "styled-components";
import Color from "../../../colors";
import { Button } from "../../../components/Button";
import TextInput from "../../../components/form/TextInput";
import { Modal } from "../../../components/Modal";
import { RemoveButton } from "../../../components/RemoveButton";
import { ContentSeparator } from "../../common/ContentSeparator";

const AddNewGroupTitle = styled.div`
  font-size: 20px;
`;

const ModalContent = styled.section`
  position: fixed;
  background: ${Color.WHITE};
  width: 500px;
  height: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const Container = styled.div`
  padding: 30px;
`;

const InputGroup = styled.div`
  margin-bottom: 10px;
  display: grid;
`;

const SubTitle = styled.div`
  font-size: 16px;
  margin-bottom: 7px;
`;

const FillAvailableTextInput = styled(TextInput)`
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

interface AddNewProductGroupModalProps {
  show: boolean;
  onCancel: () => void;
  onSave: (groupName: string) => void;
}

const AddNewProductGroupModal = ({ show, onCancel, onSave }: AddNewProductGroupModalProps) => {
  const [groupName, setGroupName] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSave(groupName);
      setGroupName("");
    }
  };

  return (
    <Modal show={show}>
      <ModalContent>
        <Container>
          <AddNewGroupTitle>Lisää tuoteryhmä</AddNewGroupTitle>
          <ContentSeparator />
          <InputGroup>
            <SubTitle>Ryhmän nimi</SubTitle>
            <FillAvailableTextInput
              onKeyDown={handleKeyDown}
              data-testid="NewGroupName"
              value={groupName}
              onChange={(event) => setGroupName(event?.currentTarget.value)}
              placeholder="Anna ryhmän nimi"
              autoFocus
            />
          </InputGroup>
          <ButtonsContainer>
            <RemoveButton
              onClick={() => {
                onCancel();
                setGroupName("");
              }}
            >
              Peruuta
            </RemoveButton>
            <Button
              data-testid="SaveNewGroup"
              onClick={(_event) => {
                onSave(groupName);
                setGroupName("");
              }}
            >
              Tallenna
            </Button>
          </ButtonsContainer>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default AddNewProductGroupModal;
