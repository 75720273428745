import React, { useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../context";
import authService from "../services/authentication";
import { inIFrame } from "../util";
import styled, { keyframes } from "styled-components";
import Color from "../colors";

const ZoomInOut = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
`;

const LoadingSplash = styled.div`
  color: ${Color.LAPA_BLUE};
  font-family: "Helvetica Neue", "Lato";
  font-size: 80px;
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: ${ZoomInOut} 3s infinite ease-in-out;
`;

const AuthProvider = (props: React.PropsWithChildren<{}>) => {
  const [auth, setAuth] = useState<AuthContextType>({ isAuthenticated: false, authFailed: false, user: null });

  useEffect(() => {
    (async () => {
      if (!auth.isAuthenticated && !auth.authFailed && !inIFrame()) {
        try {
          const redirectCallbackResponse = await authService.handleRedirectLoginCallback();

          if (redirectCallbackResponse) {
            setAuth({ ...auth, isAuthenticated: true, user: { email: redirectCallbackResponse.account.username } });
            return;
          }

          if (!redirectCallbackResponse) {
            const result = await authService.login();
            if ("redirect" in result) {
              console.log("Redirecting to auth provider");
              return;
            }
            setAuth({ ...auth, isAuthenticated: true, user: { email: result.account.username } });
          }
        } catch (e) {
          console.error("login failed", e);
          setAuth({ ...auth, authFailed: true });
        }
      }
    })();
  }, [auth]);

  return auth.isAuthenticated ? (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  ) : auth.authFailed ? (
    <h1>Authentication failed, try reloading the page.</h1>
  ) : (
    <LoadingSplash>LAPA</LoadingSplash>
  );
};

export default AuthProvider;
