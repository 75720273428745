import fileDownload from "js-file-download";
import { frontendApi } from "@barona/lapa-common-frontend";
import styled from "styled-components";
import { Download } from "@styled-icons/boxicons-regular/Download";
import { dateToLocaleDateString, DateTime } from "@barona/lapa-common-date-utils";
import Color from "../../../colors";

const DownloadIcon = styled(Download)`
  height: 12px;
  margin: 0 3px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

const DownloadButton = styled.button`
  all: unset;
  cursor: pointer;
  vertical-align: middle;
  color: ${Color.LAPA_BLUE};
  font-size: 14px;
  margin-left: 20px;
`;

interface Props {
  invoiceIds: string[];
  isLoadingInvoices: boolean;
  customerId?: string;
  customerReference?: string | null;
  period?: { periodStart: Date; periodEnd: Date };
}

const LoadReconciliationReportButton = (props: Props) => {
  const { invoiceIds, customerId, customerReference, period, isLoadingInvoices } = props;
  const now = DateTime.utc().toLocaleString();
  const periodStart = period && dateToLocaleDateString(period.periodStart);
  const periodEnd = period && dateToLocaleDateString(period.periodEnd);

  const handleDownload = async () => {
    try {
      const csv = await frontendApi.fetchReportItems({
        invoiceIds,
      });

      fileDownload(
        new Blob([csv]),
        `Täsmäytysraportti_${now}${customerId ? `_${customerId}` : ""}${
          customerReference ? `_${customerReference}` : ""
        }${period ? `_${periodStart}-${periodEnd}` : ""}.csv`,
        "text/csv"
      );
    } catch (err) {
      console.error("Fetching a csv report failed", err);
    }
  };

  return (
    <DownloadButton
      data-testid="LoadReconciliationReportButton"
      onClick={() => {
        handleDownload();
      }}
      disabled={isLoadingInvoices || invoiceIds.length === 0}
    >
      <DownloadIcon />
      Lataa täsmäytystiedot
    </DownloadButton>
  );
};

export default LoadReconciliationReportButton;
