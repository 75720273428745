import styled from "styled-components";
import { InvoiceStatus, PersistedInvoice, Serialized, IProblemJSON, InvoiceSource } from "@barona/lapa-common-types";
import { frontendApi } from "@barona/lapa-common-frontend";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useNotifications, NotificationState, useOpenInvoicesCount } from "../../context";
import React, { useState } from "react";
import { IndicatorModal } from "../../components/Modal";
import { DraftInvoiceEditableParams } from "./DraftInvoiceEditableParameters";
import ButtonDropdown from "../../components/ButtonDropdown";
import { Ban } from "@styled-icons/fa-solid/Ban";
import { Redo } from "@styled-icons/fa-solid/Redo";
import axios, { AxiosError } from "axios";
import Color from "../../colors";
import InvoiceActionModal from "./InvoiceActionModal";

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin: 8px; ;
`;

const MoreActionsButton = styled(ButtonDropdown)`
  margin: 8px;
`;

const MoreActionsDropdownButton = styled.button`
  display: block;
  all: unset;
  cursor: pointer;
  color: ${Color.LAPA_BLUE};
  width: 100px;
  font-size: 14px;
  margin: 5px 0;
  &:disabled {
    color: ${Color.GREY_DARK};
    opacity: 50%;
    cursor: not-allowed;
  }
`;

const DiscardIcon = styled(Ban)`
  height: 12px;
  margin: 0 5px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

const RedoIcon = styled(Redo)`
  height: 12px;
  margin: 0 5px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

export interface DraftInvoiceActionsMessage {
  invoiceId?: string;
  state: "DeletedInvoice" | "DiscardInvoice" | "SentToFrends";
}

interface DraftInvoiceActionsProps {
  draftInvoice: Serialized<PersistedInvoice>;
  isSaveInvoiceDisabled: boolean;
  isSendInvoiceToNAVDisabled: boolean;
  refreshInvoice: Function;
  draftInvoiceParameters?: DraftInvoiceEditableParams;
  ourReferenceFromContactPerson: boolean;
  saveDraftInvoice: () => void;
}

const DraftInvoiceActions = (props: DraftInvoiceActionsProps) => {
  const {
    draftInvoice,
    isSaveInvoiceDisabled,
    isSendInvoiceToNAVDisabled,
    draftInvoiceParameters,
    refreshInvoice,
    saveDraftInvoice,
  } = props;
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { refresh: refreshOpenInvoicesCount } = useOpenInvoicesCount();
  const [isSendingInvoiceToNAV, setIsSendingInvoiceToNAV] = useState(false);
  const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false);
  const [showReviseInvoiceModal, setShowReviseInvoiceModal] = useState(false);
  const [showDiscardInvoiceModal, setShowDiscardInvoiceModal] = useState(false);

  const deleteInvoiceHandler = async () => {
    await frontendApi.deleteInvoice({ invoiceId: draftInvoice?.invoiceId! });
    refreshOpenInvoicesCount();
    navigate("..", {
      state: { invoiceId: draftInvoice?.invoiceId, state: "DeletedInvoice" } as DraftInvoiceActionsMessage,
    });
  };

  const discardInvoiceHandler = async () => {
    await frontendApi.updateInvoiceStatus({ invoiceId: draftInvoice?.invoiceId!, newStatus: InvoiceStatus.discarded });
    navigate("..", {
      state: {
        invoiceId: draftInvoice?.invoiceId,
        state: "DiscardInvoice",
      } as DraftInvoiceActionsMessage,
    });
  };

  const reviseInvoiceHandler = async () => {
    try {
      await frontendApi.reviseInvoice({ invoiceId: draftInvoice?.invoiceId! });
      refreshInvoice();
      addNotification("Laskun päivittäminen onnistui", NotificationState.Success);
    } catch {
      addNotification("Laskun päivittäminen epäonnistui", NotificationState.Error);
    }
  };

  const sendInvoiceToNAVHandler = async () => {
    try {
      if (!draftInvoiceParameters?.invoiceInfoId) {
        addNotification("Laskulle pitää ensin valita invoice info", NotificationState.Error);
        return;
      }

      if (props.ourReferenceFromContactPerson && !draftInvoiceParameters.contactPerson) {
        addNotification("Laskulle pitää ensin valita yhteyshenkilö", NotificationState.Error);
        return;
      }

      setIsSendingInvoiceToNAV(true);
      await frontendApi.sendInvoicesToNavQueue({ invoiceIds: [draftInvoice?.invoiceId!] });
      setIsSendingInvoiceToNAV(false);

      refreshOpenInvoicesCount();
      navigate("..", {
        state: {
          invoiceId: draftInvoice?.invoiceId,
          state: "SentToFrends",
        } as DraftInvoiceActionsMessage,
      });
    } catch (error) {
      setIsSendingInvoiceToNAV(false);

      const title = (axios.isAxiosError(error) && (error as AxiosError<IProblemJSON>)?.response?.data?.title) ?? "";
      addNotification(
        `Laskun lähetys Workdayhin epäonnistui. ${title ? `Yksityiskohdat virheestä: ${title}` : ""}`,
        NotificationState.Error
      );
    }
  };

  return (
    <>
      <ActionsContainer>
        <StyledButton data-testid="SaveInvoiceChanges" disabled={isSaveInvoiceDisabled} onClick={saveDraftInvoice}>
          Tallenna muutokset
        </StyledButton>
        <StyledButton
          data-testid="SendInvoiceToNAV"
          disabled={isSendInvoiceToNAVDisabled}
          onClick={sendInvoiceToNAVHandler}
        >
          Vie Workdayhin
        </StyledButton>

        <MoreActionsButton data-testid="MoreActions">
          <MoreActionsDropdownButton
            data-testid="ReviseInvoice"
            onClick={() => setShowReviseInvoiceModal(true)}
            disabled={draftInvoice.source !== InvoiceSource.lapa}
          >
            <RedoIcon />
            Päivitä lasku
          </MoreActionsDropdownButton>
          <MoreActionsDropdownButton
            data-testid="DiscardInvoice"
            onClick={() => setShowDiscardInvoiceModal(true)}
            disabled={draftInvoice.source === InvoiceSource.salesforce}
          >
            <DiscardIcon />
            Ei laskuteta
          </MoreActionsDropdownButton>
          <MoreActionsDropdownButton
            data-testid="DeleteInvoice"
            onClick={() => setShowDeleteInvoiceModal(true)}
            disabled={draftInvoice.source !== InvoiceSource.lapa}
          >
            <RedoIcon />
            Poista lasku
          </MoreActionsDropdownButton>
        </MoreActionsButton>
      </ActionsContainer>
      <IndicatorModal testId="SendingSingleInvoiceToNavModal" show={isSendingInvoiceToNAV}>
        Lähetetään laskua Workdayhin...
      </IndicatorModal>
      <InvoiceActionModal
        show={showDiscardInvoiceModal}
        onCancel={() => setShowDiscardInvoiceModal(false)}
        onConfirm={discardInvoiceHandler}
        titleText="Lasku hylätään"
        bodyText="Lasku siirretään laskuarkistoon hylättynä ja siihen liittyvät tapahtumat eivät palaudu avoimiin tapahtumiin."
        actionText="Hylkää lasku"
      />
      <InvoiceActionModal
        testId="ReviseInvoiceModal"
        show={showReviseInvoiceModal}
        onCancel={() => setShowReviseInvoiceModal(false)}
        onConfirm={reviseInvoiceHandler}
        titleText="Laskun päivitys"
        bodyText="Avoimet tapahtumat lisätään laskulle käyttäen laskulle määriteltyjä muodostuksen perusteita. Tätä voi käyttää esimerkiksi silloin, jos laskun muodostuksen jälkeen on ladattu uutta materiaalia joka halutaan vielä samalle laskulle."
        actionText="Päivitä lasku"
      />
      <InvoiceActionModal
        testId="DeleteInvoiceModal"
        show={showDeleteInvoiceModal}
        onCancel={() => setShowDeleteInvoiceModal(false)}
        onConfirm={deleteInvoiceHandler}
        titleText="Lasku poistetaan"
        bodyText="Lasku poistetaan ja siihen liittyvät tapahtumat palautuvat avoimiin tapahtumiin."
        actionText="Poista lasku"
      />
    </>
  );
};

export default DraftInvoiceActions;
