import { useEffect, useRef, useState, PropsWithChildren } from "react";

import styled from "styled-components";
import Color from "../colors";
import { Button } from "./Button";

const Container = styled.div`
  display: inline-block;
  position: relative;
`;

const StyledButton = styled(Button)`
  background-color: white;
  border: 1px solid ${Color.LAPA_BLUE};
  color: ${Color.LAPA_BLUE};
  padding: 0 10px;
  letter-spacing: -1px;
`;

const DropdownPopup = styled.div`
  position: absolute;
  background-color: white;
  margin-top: 4px;
  padding: 8px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid ${Color.GREY_LIGHT};
  border-radius: 3px;
  left: auto;
  right: 0;
  z-index: 5;
`;

interface Props {
  className?: string;
  "data-testid"?: string;
}

const ButtonDropdown = (props: PropsWithChildren<Props>) => {
  const { children, className, "data-testid": testId } = props;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      // Close dropdown when user clicks outside the dropdown
      if (ref.current && event.target && !ref.current.contains(event.target as any)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [ref]);

  return (
    <Container ref={ref} className={className}>
      <StyledButton onClick={() => setShowDropdown(!showDropdown)} data-testid={testId}>
        •••
      </StyledButton>
      {showDropdown && <DropdownPopup>{children}</DropdownPopup>}
    </Container>
  );
};

export default ButtonDropdown;
