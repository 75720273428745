export enum DimensionType {
  businessUnitD = "TOIMIALA",
  service = "PALVELU",
  costPlace = "KUSTANNUSPAIKKA",
  area = "ALUE",
  project = "PROJEKTI",
  employee = "TYONTEKIJA",
}

export interface DimensionData {
  code: string;
  name: string;
}
