import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { BaronaCompany, ProductType, IProblemJSON } from "@barona/lapa-common-types";
import Select from "../../../components/form/Select";
import Notifications from "../../common/Notifications";
import PageContainer from "../../common/PageContainer";
import { Title } from "../../common/Title";
import AvailableProducts from "./AvailableProducts";
import ProductGroup from "./ProductGroup";
import Color from "../../../colors";
import { Button } from "../../../components/Button";
import AddNewProductGroupModal from "./AddNewProductGroupModal";
import { useProductGroups, useProducts } from "../../../services/products";
import { NotificationState, useBaronaCompany, useNotifications } from "../../../context";
import differenceBy from "lodash/differenceBy";
import { frontendApi } from "../../../services/api";
import { moveElementInArray } from "../../../util";
import axios, { AxiosError } from "axios";

const ProductGroupsContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

const SubTitle = styled.div`
  font-size: 16px;
`;

const SelectContainer = styled.div`
  width: 300px;
  float: right;
`;

const SelectLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
`;

const ProductsContainer = styled.div`
  padding-right: 24px;
`;

const GroupsContainer = styled.div`
  padding-left: 24px;
  border-left: 1px solid ${Color.GREY_LIGHTER};
`;

const GroupTitleWithCreateNewGroupContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding-bottom: 10px;
`;

const FloatRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ProductGroupsPage = () => {
  const { baronaCompany } = useBaronaCompany();
  const {
    products,
    isLoading: isLoadingProducts,
    forceRefresh: forceRefreshProducts,
  } = useProducts(baronaCompany === BaronaCompany.Varastopalvelut ? [ProductType.Fidaware] : [ProductType.Mepco]);
  const {
    productGroups,
    isLoading: isLoadingProductGroups,
    forceRefresh: forceRefreshProductGroups,
  } = useProductGroups(baronaCompany);
  const { addNotification } = useNotifications();

  const [selectedProductCodes, setSelectedProductCodes] = useState<string[]>([]);
  const [showAddNewGroupModal, setShowAddNewGroupModal] = useState(false);
  const [productGroupOnDrag, setProductGroupOnDrag] = useState("");

  const productsWithoutGroup = useMemo(
    () =>
      differenceBy(
        products,
        productGroups.flatMap((group) => group.products),
        (product) => product.productCode
      ),
    [products, productGroups]
  );

  const handleOnProductsOnSelect = (selectedProductCodes: string[]) => {
    setSelectedProductCodes(selectedProductCodes);
  };

  const handleOnDrag = (groupName: string) => {
    setProductGroupOnDrag(groupName);
  };

  const handleOnDrop = async (groupName: string) => {
    const currentOrderByIdx = productGroups.map((group, idx) => ({
      productGroupId: group.productGroupId,
      sortNumber: idx,
    }));

    const dragGroup = productGroups.find(({ name }) => name === productGroupOnDrag);
    const droppedOnGroup = productGroups.find(({ name }) => name === groupName);

    const currentLocation = currentOrderByIdx.findIndex((g) => g.productGroupId === dragGroup?.productGroupId);
    const wantedLocation = currentOrderByIdx.findIndex((g) => g.productGroupId === droppedOnGroup?.productGroupId);
    const newOrderByIdx = moveElementInArray(currentOrderByIdx, currentLocation, wantedLocation);

    await frontendApi.updateProductGroupPositions({
      baronaCompanyCode: baronaCompany,
      sortPositions: newOrderByIdx.map((group, idx) => ({ sortNumber: idx, productGroupId: group.productGroupId })),
    });
    forceRefreshProductGroups();
  };

  const handleOnSaveNewProductGroup = async (groupName: string) => {
    try {
      await frontendApi.createProductGroup({
        groupName,
        baronaCompanyCode: baronaCompany,
      });
      forceRefreshProductGroups();
      setShowAddNewGroupModal(false);
    } catch (error) {
      const title = (axios.isAxiosError(error) && (error as AxiosError<IProblemJSON>)?.response?.data?.title) ?? "";
      addNotification(
        `Tuoteryhmän lisääminen epäonnistui. ${title ? `Lisätietoja virheestä: ${title}` : ""}`,
        NotificationState.Error
      );
    }
  };

  const handleProductMoveToGroup = async (productGroupId: string, productCodes: string[]) => {
    await frontendApi.moveProductsToProductGroup({ productCodes, productGroupId });
  };

  return (
    <PageContainer>
      <Title>Tuotteiden ryhmittely</Title>
      <Notifications />
      <ProductGroupsContainer>
        <ProductsContainer>
          <GroupTitleWithCreateNewGroupContainer>
            <SubTitle>Tuotteet</SubTitle>
            <FloatRight>
              <SelectContainer>
                <SelectLabel>Siirrä valitut ryhmään</SelectLabel>
                <Select
                  isDisabled={!selectedProductCodes.length}
                  noOptionsMessage={() => "Ei tuoteryhmiä"}
                  placeholder="Valitse"
                  isLoading={isLoadingProducts}
                  options={productGroups?.map((group) => ({
                    label: group.name,
                    value: group.productGroupId,
                  }))}
                  value={null}
                  onChange={async (value) => {
                    await handleProductMoveToGroup(value!.value, selectedProductCodes);
                    setSelectedProductCodes([]);
                    forceRefreshProducts();
                    forceRefreshProductGroups();
                  }}
                />
              </SelectContainer>
            </FloatRight>
          </GroupTitleWithCreateNewGroupContainer>
          <AvailableProducts
            products={productsWithoutGroup}
            selectedProductCodes={selectedProductCodes}
            handleOnProductsOnSelect={handleOnProductsOnSelect}
            isLoading={isLoadingProductGroups}
          />
        </ProductsContainer>
        <GroupsContainer>
          <GroupTitleWithCreateNewGroupContainer>
            <SubTitle>Ryhmät</SubTitle>
            <Button data-testid="AddGroupButton" onClick={() => setShowAddNewGroupModal(!showAddNewGroupModal)}>
              Lisää ryhmä
            </Button>
          </GroupTitleWithCreateNewGroupContainer>
          {productGroups.length
            ? productGroups.map((productGroup, idx) => (
                <ProductGroup
                  {...productGroup}
                  key={productGroup.name}
                  handleDrag={handleOnDrag}
                  handleDrop={handleOnDrop}
                  refreshProductGroups={forceRefreshProductGroups}
                  sortNumber={idx}
                />
              ))
            : "Ei ryhmiä"}
        </GroupsContainer>
      </ProductGroupsContainer>
      <AddNewProductGroupModal
        onSave={handleOnSaveNewProductGroup}
        onCancel={() => setShowAddNewGroupModal(false)}
        show={showAddNewGroupModal}
      />
    </PageContainer>
  );
};

export default ProductGroupsPage;
