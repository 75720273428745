import Big from "big.js";
import { InvoiceVat, PersistedInvoiceRowProduct, Serialized } from "@barona/lapa-common-types";
import { Field, FieldProps, Form, Formik, FormikHandlers, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { DimensionType, ProductType, ProductUnit } from "@barona/lapa-common-types";
import {
  ButtonsContainer,
  ButtonsItem,
  CloseIcon,
  ContentContainer,
  Data,
  DescriptionItem,
  FormItem,
  getProductUnit,
  HeaderContainer,
  Label,
  SidePaneContent,
  StyledButton,
  StyledSecondaryButton,
  StyledTextInput,
  SubmitIcon,
  ProductOption,
  SelectOption,
  StyledSelect,
  StyledErrorMessage,
  TotalAndVatContainer,
  EmployeeOption,
} from "./CommonPanelContent";
import { frontendApi } from "@barona/lapa-common-frontend";
import { NotificationState, useBaronaCompany, useNotifications } from "../../context";
import { useProducts } from "../../services/products";
import { useMemo } from "react";
import { formatToEuroCurrencyString } from "../../util/money";
import { useFrendsDimension } from "../../services/useFrendsDimension";

interface FormValues {
  productName: string;
  productCode: SelectOption;
  description: string;
  amount: string | null;
  unitPrice: string | null;
  businessUnitDimension: SelectOption;
  costPlaceDimension: SelectOption;
  areaDimension: SelectOption;
  serviceDimension: SelectOption;
  projectDimension: SelectOption;
  contractNumberDimension: string;
  employeeNumberDimension: SelectOption;
  productUnit: ProductUnit | null;
  vat: SelectOption;
  employeeName: string;
}

const rowSchema = Yup.object().shape({
  productName: Yup.string().required("Tuotenimi on pakollinen tieto"),
  productCode: Yup.object().shape({
    value: Yup.string().required("Tuotekoodi on pakollinen tieto"),
  }),
  unitPrice: Yup.number().required("Yksikköhinta on pakollinen tieto").typeError("Syötä hinta käyttäen numeroita"),
  amount: Yup.number().required("Määrä on pakollinen tieto").typeError("Syötä määrä käyttäen numeroita"),
  businessUnitDimension: Yup.object().shape({
    value: Yup.string().required("Toimiala on pakollinen tieto"),
  }),
  costPlaceDimension: Yup.object().shape({
    value: Yup.string().required("Kustannuspaikka on pakollinen tieto"),
  }),
  areaDimension: Yup.object().shape({
    value: Yup.string().required("Alue on pakollinen tieto"),
  }),
  serviceDimension: Yup.object().shape({
    value: Yup.string().required("Palvelu on pakollinen tieto"),
  }),
  contractNumberDimension: Yup.string().matches(/^[0-9]+$/, {
    message: "Syötä sopimusnumero käyttäen numeroita",
    excludeEmptyString: true,
  }),
});

const calculateTotal = (unitPrice: FormValues["unitPrice"], amount: FormValues["amount"]) => {
  if (unitPrice && amount) {
    try {
      return formatToEuroCurrencyString(new Big(unitPrice).round(2).mul(amount));
    } catch (error) {
      return " - ";
    }
  }
  return " - ";
};

const handleProductCodeChange = (option: ProductOption, setFieldValue: FormikHelpers<FormValues>["setFieldValue"]) => {
  setFieldValue("productCode", option);
  setFieldValue("productName", option.productName);
  setFieldValue("productUnit", option.productUnit);
};

const handleEmployeeChange = (option: EmployeeOption, setFieldValue: FormikHelpers<FormValues>["setFieldValue"]) => {
  setFieldValue("employeeNumberDimension", option);
  setFieldValue("employeeName", option.employeeName);
};

const handleSelectOptionInitialValue = (option: string | undefined | null) => ({
  value: option || "",
  label: option || "",
});

export enum InvoiceRowPanelAction {
  view = "view",
  create = "create",
  edit = "edit",
  copy = "copy",
}

export interface InvoiceRowPanelContentProps {
  invoiceRow?: Serialized<PersistedInvoiceRowProduct>;
  closeSidePanel(): void;
  setIsUpdateRequired: Function;
  action: InvoiceRowPanelAction;
  invoiceId: string;
}

const handleNumberValueChange = (
  fieldName: string,
  event: React.FormEvent<HTMLInputElement>,
  setFieldValue: FormikHelpers<FormValues>["setFieldValue"],
  handleBlur: FormikHandlers["handleBlur"]
) => {
  try {
    const newValue = Big(event.currentTarget.value).toFixed(2);
    setFieldValue(fieldName, newValue);
  } catch (error) {
  } finally {
    handleBlur(event);
  }
};

const EditableInvoiceRowPanelContent = ({
  invoiceRow,
  closeSidePanel,
  setIsUpdateRequired,
  action,
  invoiceId,
}: InvoiceRowPanelContentProps) => {
  const { products, isLoading: isProductsLoading } = useProducts([ProductType.Mepco, ProductType.Manual]);
  const { baronaCompany } = useBaronaCompany();

  const { dimensionValues: areas, isLoading: isAreasLoading } = useFrendsDimension(DimensionType.area, baronaCompany);
  const { dimensionValues: businessUnits, isLoading: isBusinessUnitsLoading } = useFrendsDimension(
    DimensionType.businessUnitD,
    baronaCompany
  );
  const { dimensionValues: costPlaces, isLoading: isCostPlacesLoading } = useFrendsDimension(
    DimensionType.costPlace,
    baronaCompany
  );
  const { dimensionValues: services, isLoading: isServicesLoading } = useFrendsDimension(
    DimensionType.service,
    baronaCompany
  );
  const { dimensionValues: projects, isLoading: isProjectsLoading } = useFrendsDimension(
    DimensionType.project,
    baronaCompany
  );
  const { dimensionValues: employees, isLoading: isEmployeeLoading } = useFrendsDimension(
    DimensionType.employee,
    baronaCompany
  );

  if (action !== InvoiceRowPanelAction.create && !invoiceRow) {
    throw new Error("InvoiceRow must be defined");
  }
  if (action === InvoiceRowPanelAction.create && !invoiceId) {
    throw new Error("InvoiceId must be defined");
  }

  const { addNotification } = useNotifications();

  const handleSubmit = async (values: FormValues) => {
    if ([InvoiceRowPanelAction.create, InvoiceRowPanelAction.copy].includes(action)) {
      const createdInvoiceRow = {
        invoiceId,
        productCode: values.productCode.value,
        description: values.description,
        amount: values.amount,
        unitPrice: values.unitPrice,
        businessUnitDimension: values.businessUnitDimension.value,
        costPlaceDimension: values.costPlaceDimension.value,
        areaDimension: values.areaDimension.value,
        serviceDimension: values.serviceDimension.value,
        projectDimension: values.projectDimension.value,
        contractNumberDimension: values.contractNumberDimension,
        employeeNumberDimension: values.employeeNumberDimension.value,
        productName: values.productName,
        productUnit: values.productUnit,
        vat: values.vat.value,
        employeeName: values.employeeName,
      };
      try {
        await frontendApi.createInvoiceRow(createdInvoiceRow);
        setIsUpdateRequired(true);
        addNotification("Laskurivin lisäys onnistui", NotificationState.Success);
        closeSidePanel();
      } catch (error) {
        addNotification("Laskurivin lisäys epäonnistui", NotificationState.Error);
      }
    } else {
      const updatedInvoiceRow = {
        invoiceRowId: invoiceRow!.invoiceRowId,
        amount: values.amount,
        costPlaceDimension: values.costPlaceDimension.value,
        areaDimension: values.areaDimension.value,
        businessUnitDimension: values.businessUnitDimension.value,
        serviceDimension: values.serviceDimension.value,
        contractNumberDimension: values.contractNumberDimension,
        employeeNumberDimension: values.employeeNumberDimension.value,
        productCode: values.productCode.value,
        productName: values.productName,
        unitPrice: values.unitPrice,
        description: values.description,
        projectDimension: values.projectDimension.value,
        productUnit: values.productUnit,
        vat: values.vat.value,
        employeeName: values.employeeName,
      };
      try {
        await frontendApi.updateInvoiceRow(updatedInvoiceRow);
        setIsUpdateRequired(true);
        closeSidePanel();
        addNotification("Tapahtumarivin muokkaaminen onnistui", NotificationState.Success);
      } catch (error) {
        addNotification("Tapahtumarivin muokkaaminen epäonnistui", NotificationState.Error);
      }
    }
  };

  const vatOptions: SelectOption[] = [
    { value: InvoiceVat.vat0, label: "0 %" },
    { value: InvoiceVat.vat10, label: "10 %" },
    { value: InvoiceVat.vat14, label: "14 %" },
    { value: InvoiceVat.vat24, label: "24 %" },
    { value: InvoiceVat.vat25_5, label: "25.5 %" },
    { value: InvoiceVat.vat24rak, label: "RAK 24 %" },
  ];

  const handleVatOptionInitialValue = (vat: string | undefined) => {
    return vatOptions.find((option) => option.value === vat) ?? vatOptions[4];
  };

  const handleEmployeeName = (invoiceRow: Serialized<PersistedInvoiceRowProduct> | undefined) => {
    if (!invoiceRow) {
      return null;
    }
    const additionalInfo = invoiceRow.additionalGroupingProperties;
    if (additionalInfo?.employeeName) {
      return additionalInfo?.employeeName;
    }
    return null;
  };

  const initialValues: FormValues = {
    productName: invoiceRow?.productName || "",
    productCode: handleSelectOptionInitialValue(invoiceRow?.productCode),
    description: invoiceRow?.description || "",
    amount: invoiceRow?.amount ? Big(invoiceRow.amount as string).toFixed(2) : null,
    vat: handleVatOptionInitialValue(invoiceRow?.vat),
    businessUnitDimension: handleSelectOptionInitialValue(invoiceRow?.businessUnitDimension),
    costPlaceDimension: handleSelectOptionInitialValue(invoiceRow?.costPlaceDimension),
    areaDimension: handleSelectOptionInitialValue(invoiceRow?.areaDimension),
    serviceDimension: handleSelectOptionInitialValue(invoiceRow?.serviceDimension),
    projectDimension: handleSelectOptionInitialValue(invoiceRow?.projectDimension),
    unitPrice: invoiceRow?.unitPrice ? Big(invoiceRow.unitPrice).toFixed(2) : null,
    contractNumberDimension: invoiceRow?.contractNumberDimension || "",
    employeeNumberDimension: handleSelectOptionInitialValue(invoiceRow?.employeeNumberDimension),
    productUnit: invoiceRow?.productUnit || null,
    employeeName: handleEmployeeName(invoiceRow) || "",
  };

  const productOptions: ProductOption[] = useMemo(
    () =>
      products
        .map((product) => ({
          value: product.productCode,
          label: product.productCode,
          productName: product.name,
          productUnit: product.unit,
          productType: product.productType,
        }))
        .sort((a, b) => {
          if (a.productType && b.productType) {
            if (a.productType === b.productType) {
              return a.value.localeCompare(b.value);
            }
            if (a.productType === ProductType.Manual) {
              return -1;
            } else {
              return 1;
            }
          }
          return a.value.localeCompare(b.value);
        }),
    [products]
  );

  const areaOptions: SelectOption[] = useMemo(
    () => areas.map((area) => ({ value: area.code, label: `${area.code} - ${area.name}` })),
    [areas]
  );

  const businessUnitOptions: SelectOption[] = useMemo(
    () =>
      businessUnits.map((businessUnit) => ({
        value: businessUnit.code,
        label: `${businessUnit.code} - ${businessUnit.name}`,
      })),
    [businessUnits]
  );

  const costPlaceOptions: SelectOption[] = useMemo(
    () => costPlaces.map((costPlace) => ({ value: costPlace.code, label: `${costPlace.code} - ${costPlace.name}` })),
    [costPlaces]
  );

  const servicesOptions: SelectOption[] = useMemo(
    () => services.map((service) => ({ value: service.code, label: `${service.code} - ${service.name}` })),
    [services]
  );

  const projectOptions: SelectOption[] = useMemo(
    () => projects.map((project) => ({ value: project.code, label: `${project.code} - ${project.name}` })),
    [projects]
  );

  const employeeOptions: EmployeeOption[] = useMemo(
    () => employees.map((employee) => ({ value: employee.code, label: employee.code, employeeName: employee.name })),
    [employees]
  );

  const getPanelHeader = () => {
    switch (action) {
      case InvoiceRowPanelAction.create:
      case InvoiceRowPanelAction.copy:
        return <h2>Uusi rivi</h2>;
      case InvoiceRowPanelAction.view:
      case InvoiceRowPanelAction.edit:
        return <h2>{`Rivin tiedot: ${invoiceRow?.productName} ${invoiceRow?.productCode}`}</h2>;
    }
  };

  return (
    <SidePaneContent>
      <HeaderContainer>
        {getPanelHeader()}
        <div>
          <CloseIcon data-testid="closeIcon" onClick={closeSidePanel} />
        </div>
      </HeaderContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={rowSchema}
        onSubmit={(values: FormValues) => handleSubmit(values)}
      >
        {({ setFieldValue, values, handleBlur, isSubmitting }: FormikProps<FormValues>) => (
          <Form>
            <ContentContainer>
              <FormItem>
                <Label htmlFor="productName">SELITE</Label>
                <Data>
                  <Field name="productName">
                    {({ field }: FieldProps) => (
                      <StyledTextInput
                        {...field}
                        id="productName"
                        maxLength={50}
                        disabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="productName" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label htmlFor="productCode">TUOTE</Label>
                <Data>
                  <Field name="productCode">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        inputId="productCode"
                        options={productOptions}
                        onChange={(option) => handleProductCodeChange(option as ProductOption, setFieldValue)}
                        isLoading={isProductsLoading}
                        isDisabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="productCode.value" component="div" />
                </Data>
              </FormItem>
              <DescriptionItem>
                <Label htmlFor="description">KUVAUS</Label>
                <Data>
                  <Field name="description">
                    {({ field }: FieldProps) => <StyledTextInput {...field} id="description" maxLength={50} />}
                  </Field>
                </Data>
              </DescriptionItem>
              <FormItem>
                <Label htmlFor="amount">MÄÄRÄ</Label>
                <Data>
                  <Field name="amount">
                    {({ field }: FieldProps) => (
                      <StyledTextInput
                        {...field}
                        id="amount"
                        onBlur={(value) => handleNumberValueChange("amount", value, setFieldValue, handleBlur)}
                        disabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="amount" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label>YKSIKKÖ</Label>
                <Data>{getProductUnit(values.productUnit)}</Data>
              </FormItem>
              <FormItem>
                <Label htmlFor="unitPrice">A-HINTA</Label>
                <Data>
                  <Field name="unitPrice">
                    {({ field }: FieldProps) => (
                      <StyledTextInput
                        {...field}
                        id="unitPrice"
                        onBlur={(value) => handleNumberValueChange("unitPrice", value, setFieldValue, handleBlur)}
                        disabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="unitPrice" component="div" />
                </Data>
              </FormItem>
              <TotalAndVatContainer>
                <FormItem>
                  <Label>YHTEENSÄ</Label>
                  <Data>{calculateTotal(values.unitPrice, values.amount)}</Data>
                </FormItem>
                <FormItem>
                  <Label htmlFor="vat">ALV</Label>
                  <Data>
                    <Field name="vat">
                      {({ field }: FieldProps) => (
                        <StyledSelect
                          {...field}
                          inputId="vat"
                          options={vatOptions}
                          onChange={(option) => setFieldValue("vat", option)}
                        />
                      )}
                    </Field>
                  </Data>
                </FormItem>
              </TotalAndVatContainer>
              <FormItem>
                <Label htmlFor="businessUnitDimension">TOIMIALA</Label>
                <Data>
                  <Field name="businessUnitDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        inputId="businessUnitDimension"
                        options={businessUnitOptions}
                        onChange={(option) => setFieldValue("businessUnitDimension", option)}
                        isLoading={isBusinessUnitsLoading}
                        isDisabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="businessUnitDimension.value" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label htmlFor="areaDimension">ALUE</Label>
                <Data>
                  <Field name="areaDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        inputId="areaDimension"
                        options={areaOptions}
                        onChange={(option) => setFieldValue("areaDimension", option)}
                        isLoading={isAreasLoading}
                        isDisabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="areaDimension.value" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label htmlFor="serviceDimension">PALVELU</Label>
                <Data>
                  <Field name="serviceDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        inputId="serviceDimension"
                        options={servicesOptions}
                        onChange={(option) => setFieldValue("serviceDimension", option)}
                        isLoading={isServicesLoading}
                        isDisabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="serviceDimension.value" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label htmlFor="costPlaceDimension">KUSTANNUSPAIKKA</Label>
                <Data>
                  <Field name="costPlaceDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        inputId="costPlaceDimension"
                        options={costPlaceOptions}
                        onChange={(option) => setFieldValue("costPlaceDimension", option)}
                        isLoading={isCostPlacesLoading}
                        isDisabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="costPlaceDimension.value" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label htmlFor="contractNumberDimension">SOPIMUSNUMERO</Label>
                <Data>
                  <Field name="contractNumberDimension">
                    {({ field }: FieldProps) => (
                      <StyledTextInput
                        {...field}
                        id="contractNumberDimension"
                        maxLength={12}
                        disabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="contractNumberDimension" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label htmlFor="projectDimension">PROJEKTI</Label>
                <Data>
                  <Field name="projectDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        inputId="projectDimension"
                        options={projectOptions}
                        onChange={(option) => setFieldValue("projectDimension", option)}
                        isLoading={isProjectsLoading}
                        isDisabled={action === InvoiceRowPanelAction.view}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>TYÖNTEKIJÄ</Label>
                <Data>
                  <Field name="employeeName">
                    {({ field }: FieldProps) => (
                      <StyledTextInput
                        {...field}
                        maxLength={50}
                        disabled={values.employeeNumberDimension.value.length > 0}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>TYÖNTEKIJÄNUMERO</Label>
                <Data>
                  <Field name="employeeNumberDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        options={employeeOptions}
                        onChange={(option) => handleEmployeeChange(option as EmployeeOption, setFieldValue)}
                        isLoading={isEmployeeLoading}
                        openMenuOnClick={false}
                        isSearchable={true}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <ButtonsItem>
                <ButtonsContainer>
                  <StyledSecondaryButton type="button" onClick={closeSidePanel}>
                    Peruuta
                  </StyledSecondaryButton>
                  <StyledButton type="submit" disabled={isSubmitting}>
                    Tallenna rivi {isSubmitting && <SubmitIcon />}
                  </StyledButton>
                </ButtonsContainer>
              </ButtonsItem>
            </ContentContainer>
          </Form>
        )}
      </Formik>
    </SidePaneContent>
  );
};

export default EditableInvoiceRowPanelContent;
