import React, { useState, PropsWithChildren } from "react";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";
import styled from "styled-components";
import Color from "../colors";
import { Cell, Row } from "./Table";
import InvoiceRowTooltip from "../pages/InvoicePage/InvoiceRowTooltip";

const ExpandableRowContainer = styled(Row)``;

const Icon = styled(ArrowIosForwardOutline)<{ isOpen?: boolean }>`
  height: 24px;
  width: 24px;
  margin-top: -1px;
  margin-left: 4px;
  color: ${Color.BLACK};
  transform: rotate(${(props) => (props.isOpen ? "-90deg" : "90deg")});
  transition: transform 0.2s ease-out;
  user-select: none;
`;

const TooltipCell = styled(Cell)`
  display: flex;
  align-items: center;
`;

interface ExpandableRowProps {
  renderExpandedContent: React.ReactNode;
  renderTooltip?: React.ReactNode;
  testId?: string;
  className?: string;
  onStateChange?: (isOpen: boolean) => void;
  isOpenInitially?: boolean;
  hideExpandButton?: boolean;
}

const ExpandableRow: React.FunctionComponent<PropsWithChildren<ExpandableRowProps>> = ({
  renderExpandedContent,
  renderTooltip = undefined,
  children,
  testId,
  className,
  onStateChange,
  isOpenInitially = false,
  hideExpandButton = false,
}: PropsWithChildren<ExpandableRowProps>) => {
  const [isOpen, setIsOpen] = useState(isOpenInitially);

  return (
    <>
      <ExpandableRowContainer className={className} data-testid={testId}>
        {children}
        <TooltipCell>
          {!hideExpandButton && (
            <div>
              <Icon
                data-testid="ExpandableRow.Expand"
                isOpen={isOpen}
                onClick={() => {
                  setIsOpen(!isOpen);
                  onStateChange?.(!isOpen);
                }}
              />
            </div>
          )}
          {renderTooltip && (
            <div>
              <InvoiceRowTooltip tooltip={renderTooltip} />
            </div>
          )}
        </TooltipCell>
      </ExpandableRowContainer>
      {isOpen && renderExpandedContent}
    </>
  );
};

export default ExpandableRow;
