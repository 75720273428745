import styled from "styled-components";
import Color from "../../../colors";
import { ChevronCircleLeft } from "@styled-icons/fa-solid/ChevronCircleLeft";

export interface InvoiceParametersProps {
  filters: {
    type: "info" | "notice";
    text: string;
  }[];
  invoiceId: string;
  openSidePanel(invoiceId: string): void;
  hasErrors: boolean;
}

const InvoiceParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvoiceParameter = styled.span<{ paramType: InvoiceParametersProps["filters"][number]["type"] }>`
  background-color: ${({ paramType }) => (paramType === "info" ? Color.LIGHT_BLUE : Color.YELLOW)};
  color: ${Color.BLACK};
  padding: 3px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  width: fit-content;
`;

const ErrorChevron = styled(ChevronCircleLeft)`
  height: 16px;
  width: 16px;
  transform: rotate(180deg);
  margin-left: 8px;
`;

const ClickableInvoiceParameter = styled.div`
  color: ${Color.BLACK};
  background-color: ${Color.RED_LIGHTEST};
  padding: 3px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const InvoiceParameters: React.FC<InvoiceParametersProps> = ({
  filters,
  invoiceId,
  openSidePanel,
  hasErrors,
}) => (
  <InvoiceParametersContainer>
    {hasErrors && (
      <ClickableInvoiceParameter onClick={() => openSidePanel(invoiceId)}>
        <div>Näytä virheet</div> {<ErrorChevron />}
      </ClickableInvoiceParameter>
    )}
    {filters.map((filter, idx) => (
      <InvoiceParameter key={filter.text + idx} paramType={filter.type}>
        {filter.text}
      </InvoiceParameter>
    ))}
  </InvoiceParametersContainer>
);
