import styled from "styled-components";
import clamp from "lodash/clamp";
import NumberInput from "./form/NumberInput";
import Select from "./form/Select";
import Color from "../colors";

export const DEFAULT_PAGE_SIZE_OPTIONS = [50, 100, 200];
export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[0];

const StylableSelect = styled(Select)`
  width: 144px;
` as typeof Select;

const PageLabel = styled.span`
  margin-right: 8px;
`;

const PaginationButton = styled.button`
  background: none;
  border: none;
  color: ${Color.LAPA_BLUE};
  cursor: pointer;

  &:disabled {
    color: ${Color.GREY};
    cursor: not-allowed;
  }
`;

export const TablePaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0 8px;
  }
`;

export interface Props {
  currentPage: number;
  onSetCurrentPage: (page: number) => void;
  pageCount: number;
  pageSize: number;
  onSetPageSize: (pageSize: number) => void;
  pageSizeOptions?: number[];
}

const TablePagination = (props: Props) => {
  const {
    onSetCurrentPage,
    onSetPageSize,
    currentPage = 1,
    pageCount = 1,
    pageSize = DEFAULT_PAGE_SIZE,
    pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  } = props;

  const canPreviousPage = currentPage > 1;
  const canNextPage = currentPage < pageCount;
  return (
    <TablePaginationContainer>
      <PaginationButton onClick={() => onSetCurrentPage(currentPage - 1)} disabled={!canPreviousPage}>
        {"< Edellinen"}
      </PaginationButton>
      <span>
        <PageLabel>Sivu</PageLabel>
        <NumberInput
          data-testid="TablePaginationPageInput"
          value={currentPage}
          onChange={(e) => {
            const page = clamp(e.currentTarget.value ? Number(e.currentTarget.value) : 1, 1, pageCount);
            onSetCurrentPage(page);
          }}
          style={{ width: "48px" }}
          min={1}
          max={pageCount}
        />{" "}
        / {pageCount}
      </span>
      <StylableSelect
        value={{ label: `${pageSize} riviä / sivu`, value: pageSize }}
        options={pageSizeOptions.map((size) => ({ label: `${size} riviä / sivu`, value: size }))}
        onChange={(option) => {
          onSetPageSize(option?.value ?? pageSizeOptions[0]);
          onSetCurrentPage(1);
        }}
      />
      <PaginationButton onClick={() => onSetCurrentPage(currentPage + 1)} disabled={!canNextPage}>
        {"Seuraava >"}
      </PaginationButton>
    </TablePaginationContainer>
  );
};

export default TablePagination;
