import { PersistedInvoiceRowProduct, Serialized } from "@barona/lapa-common-types";
import Big from "big.js";
import {
  CloseIcon,
  ContentContainer,
  Data,
  DescriptionItem,
  FormItem,
  getPrice,
  getProductUnit,
  getTotal,
  HeaderContainer,
  Label,
  SidePaneContent,
  TotalAndVatContainer,
} from "./CommonPanelContent";

export interface InvoiceRowPanelContentProps {
  invoiceRow: Serialized<PersistedInvoiceRowProduct>;
  closeSidePanel(): void;
}

const InvoiceRowPanelContent = ({ invoiceRow, closeSidePanel }: InvoiceRowPanelContentProps) => (
  <SidePaneContent>
    <HeaderContainer>
      <h2>{`Rivin tiedot: ${invoiceRow?.productName} ${invoiceRow?.productCode}`}</h2>
      <div>
        <CloseIcon data-testid="closeIcon" onClick={closeSidePanel} />
      </div>
    </HeaderContainer>
    <ContentContainer>
      <FormItem>
        <Label>SELITE</Label>
        <Data>{invoiceRow.productName}</Data>
      </FormItem>
      <FormItem>
        <Label>TUOTE</Label>
        <Data>{invoiceRow.productCode}</Data>
      </FormItem>
      <DescriptionItem>
        <Label>KUVAUS</Label>
        <Data>{invoiceRow.description}</Data>
      </DescriptionItem>
      <FormItem>
        <Label>MÄÄRÄ</Label>
        <Data>{Big(invoiceRow.amount as string).toFixed(2) /* amount cant be null for product row */}</Data>
      </FormItem>
      <FormItem>
        <Label>YKSIKKÖ</Label>
        <Data>{getProductUnit(invoiceRow.productUnit)}</Data>
      </FormItem>
      <FormItem>
        <Label>A-HINTA</Label>
        <Data>{getPrice(invoiceRow.unitPrice, invoiceRow.billable)}</Data>
      </FormItem>
      <TotalAndVatContainer>
        <FormItem>
          <Label>YHTEENSÄ</Label>
          <Data>{getTotal(invoiceRow.unitPrice, invoiceRow.amount ?? undefined, invoiceRow.billable)}</Data>
        </FormItem>
        <FormItem>
          <Label>ALV</Label>
          <Data>{`${invoiceRow.vat} %`}</Data>
        </FormItem>
      </TotalAndVatContainer>
      <FormItem>
        <Label>TOIMIALA</Label>
        <Data>{invoiceRow.businessUnitDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>ALUE</Label>
        <Data>{invoiceRow.areaDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>PALVELU</Label>
        <Data>{invoiceRow.serviceDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>KUSTANNUSPAIKKA</Label>
        <Data>{invoiceRow.costPlaceDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>SOPIMUSNUMERO</Label>
        <Data>{invoiceRow.contractNumberDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>PROJEKTI</Label>
        <Data>{invoiceRow.projectDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>TYÖNTEKIJÄ</Label>
        <Data>{invoiceRow.additionalGroupingProperties?.["employeeName"]}</Data>
      </FormItem>
    </ContentContainer>
  </SidePaneContent>
);

export default InvoiceRowPanelContent;
