import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany } from "@barona/lapa-common-types";
import { dateToISODate } from "@barona/lapa-common-date-utils";
import { useQuery } from "react-query";

export const paymentPeriodFiltersQueryKeys = Object.freeze({
  paymentPeriod: "paymentPeriod",
});

export const usePaymentPeriodFilters = (
  baronaCompany?: BaronaCompany,
  customerId?: string,
  customerReferences?: (string | null)[],
  paymentGroup?: string,
  paymentPeriod?: string,
  startDate?: Date,
  endDate?: Date
) => {
  const { data, isLoading } = useQuery(
    [
      paymentPeriodFiltersQueryKeys.paymentPeriod,
      baronaCompany,
      customerId,
      customerReferences,
      paymentGroup,
      paymentPeriod,
      startDate,
      endDate,
    ],
    async () =>
      await frontendApi.fetchPaymentPeriods({
        baronaCompany,
        customerId,
        customerReferences,
        paymentGroup,
        paymentPeriod,
        startDate: startDate ? dateToISODate(startDate) : undefined,
        endDate: endDate ? dateToISODate(endDate) : undefined,
      }),
    { keepPreviousData: true }
  );

  return { paymentPeriods: data || [], isLoading };
};
