import { PropsWithChildren } from "react";

interface Props {
  hidden?: boolean;
  reason?: string;
}

const Hidden = ({ hidden = true, children }: PropsWithChildren<Props>) => <>{!hidden && children}</>;

export default Hidden;
