import { useCallback, useEffect, useState } from "react";
import { InvoicePeriod, InvoicingModel, Serialized } from "@barona/lapa-common-types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { HeaderRow, HeaderCell, Row, Cell, Table, TableBody } from "../../components/Table";
import { NotificationState, useBaronaCompany, useNotifications } from "../../context";
import { useFrontendApi } from "../../hooks/useFrontendApi";
import { Checkmark } from "@styled-icons/evaicons-solid/Checkmark";
import { DragIndicator } from "@styled-icons/material-outlined/DragIndicator";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { LapaNavigationLink } from "../../components/Link";
import { moveElementInArray } from "../../util";
import { frontendApi } from "@barona/lapa-common-frontend";
import Color from "../../colors";
import { CrossPageNotificationState } from "../InvoicingModelPage/InvoicingModelPage";

const DragIndicatorIcon = styled(DragIndicator)`
  height: 20px;
  color: ${Color.LAPA_BLUE};
`;

const CheckmarkIcon = styled(Checkmark)`
  width: 20px;
`;

const getInvoicingPeriodString = (invoicingModel: Serialized<InvoicingModel>): string => {
  if (invoicingModel.invoiceByPaymentPeriod) {
    return "Palkkakausittain";
  }

  if (invoicingModel.invoicePeriod) {
    switch (invoicingModel.invoicePeriod) {
      case InvoicePeriod.MONTHLY:
        return "Tapahtumaväli kerran kuussa";
      case InvoicePeriod.TWICE_A_MONTH:
        return "Tapahtumaväli kaksi kertaa kuussa";
    }
  }

  return "Ei määritelty";
};

const getReferenceString = (invoicingModel: Serialized<InvoicingModel>): string => {
  if (invoicingModel.invoiceByReference) {
    return "Joka viitteestä oma lasku";
  }

  if (invoicingModel.customerReferenceFilter.length !== 0) {
    return "Valitusta viitteestä lasku";
  }

  return "Kaikki viitteet samalle laskulle";
};

const Controls = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
`;

const InvoicingModelsPage = () => {
  const { customerId } = useParams<"customerId">() as { customerId: string };
  const { baronaCompany: baronaCompanyCode } = useBaronaCompany();
  const navigate = useNavigate();
  const [draggedInvoicingModelId, setDraggedInvoicingModelId] = useState<string | undefined>();
  const [beingDraggedOverInvoicingModelId, setBeingDraggedOverInvoicingModelId] = useState<string | undefined>();
  const location = useLocation();
  const { addNotification } = useNotifications();

  const {
    result: invoicingModels,
    isLoading: isLoadingInvoicingModels,
    forceRefresh: forceRefreshInvoicingModels,
  } = useFrontendApi("getInvoicingModels", {
    customerId,
    baronaCompanyCode,
  });

  useEffect(() => {
    const locationState = location.state as CrossPageNotificationState;
    if (locationState?.notificationMessage) {
      addNotification(locationState?.notificationMessage, NotificationState.Success);
      window.history.replaceState({}, document.title);
    }

    // We want this to only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDrop = useCallback(
    async (droppedOnInvoicingModelId: string) => {
      if (!invoicingModels) return;

      const currentLocation = invoicingModels.findIndex(
        ({ invoicingModelId }) => invoicingModelId === draggedInvoicingModelId
      );
      const wantedLocation = invoicingModels.findIndex(
        ({ invoicingModelId }) => invoicingModelId === droppedOnInvoicingModelId
      );

      if (currentLocation === wantedLocation) return;

      const newRunOrders = moveElementInArray(invoicingModels, currentLocation, wantedLocation);

      await frontendApi.updateInvoicingModelRunOrders({
        runOrders: newRunOrders.map(({ invoicingModelId }, idx) => ({
          runOrder: idx,
          invoicingModelId: invoicingModelId,
        })),
      });

      forceRefreshInvoicingModels();
    },
    [draggedInvoicingModelId, forceRefreshInvoicingModels, invoicingModels]
  );

  return (
    <>
      <Controls>
        <Button onClick={() => navigate("new")}>Uusi laskutusmalli</Button>
      </Controls>
      <Table>
        <HeaderRow>
          <HeaderCell style={{ width: "1%" }}></HeaderCell>
          <HeaderCell style={{ width: "20%" }}>Nimi</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>Laskutusväli</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>Viitteiden käsittely</HeaderCell>
          <HeaderCell style={{ width: "5%" }}>Käytössä</HeaderCell>
        </HeaderRow>
        <TableBody>
          {!isLoadingInvoicingModels &&
            invoicingModels?.map((invoicingModel) => (
              <Row
                id={invoicingModel.invoicingModelId}
                key={invoicingModel.invoicingModelId}
                data-testid="InvoicingModelRow"
                draggable
                onDragStart={(event) => {
                  setDraggedInvoicingModelId(event.currentTarget.id);
                }}
                onDrop={(event) => handleOnDrop(event.currentTarget.id)}
                onDragOver={(event) => {
                  event.preventDefault();
                }}
                onDragEnter={(event) => {
                  setBeingDraggedOverInvoicingModelId(event.currentTarget.id);
                }}
                onDragEnd={() => {
                  setBeingDraggedOverInvoicingModelId(undefined);
                  setDraggedInvoicingModelId(undefined);
                }}
                style={
                  invoicingModel.invoicingModelId === beingDraggedOverInvoicingModelId &&
                  invoicingModel.invoicingModelId !== draggedInvoicingModelId
                    ? { backgroundColor: Color.GREY_LIGHT }
                    : {}
                }
              >
                <Cell>
                  <DragIndicatorIcon />
                </Cell>
                <Cell>
                  <LapaNavigationLink to={invoicingModel.invoicingModelId}>{invoicingModel.name}</LapaNavigationLink>
                </Cell>
                <Cell>{getInvoicingPeriodString(invoicingModel)}</Cell>
                <Cell>{getReferenceString(invoicingModel)}</Cell>
                <Cell>{invoicingModel.enabled && <CheckmarkIcon />}</Cell>
              </Row>
            ))}
        </TableBody>
      </Table>
      {isLoadingInvoicingModels && <Loading height={36} count={4} />}
    </>
  );
};

export default InvoicingModelsPage;
