import React, { ReactNode, useEffect, PropsWithChildren } from "react";
import styled, { css, keyframes } from "styled-components";
import { Notification, useNotifications, NotificationState } from "../../context";
import Color from "../../colors";
import { Cross } from "@styled-icons/icomoon/Cross";

const StyledCross = styled(Cross)<{ state: NotificationState }>`
  float: right;
  ${(props) => {
    switch (props.state) {
      case NotificationState.Error:
        return css`
          fill: ${Color.RED_DARK};
        `;
      case NotificationState.Info:
        return css`
          fill: ${Color.YELLOW_DARKER};
        `;
      case NotificationState.Success:
        return css`
          fill: ${Color.GREEN_DARKER};
        `;
    }
  }};
`;

export const NotificationsContainer = styled.div`
  padding-bottom: 8px;
`;

const NotificationItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  padding-left: 21px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 5px;
  align-items: center;
  min-height: 36px;
  font-size: 14px;
`;

const NotificationItemContainerError = styled(NotificationItemContainer)`
  background-color: ${Color.RED_LIGHTEST};
  border-left: 8px ${Color.RED} solid;
`;

const NotificationItemContainerInfo = styled(NotificationItemContainer)`
  background-color: ${Color.YELLOW_LIGHTEST};
  border-left: 8px ${Color.YELLOW} solid;
`;

const NotificationItemContainerSuccess = styled(NotificationItemContainer)`
  background-color: ${Color.GREEN_LIGHTEST};
  border-left: 8px ${Color.GREEN} solid;
`;

const NotificationContent = styled.div``;
interface NotificationItemProps {
  notification: Notification;
  canRemove?: boolean;
  className?: string;
  onClose?: () => void;
}

const fadeIn = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

interface FadeOutProps {
  duration?: number;
  delay?: number;
  children: ReactNode;
  onClose?: () => void;
}

const FadeOutWrapper = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${fadeIn};
    animation-fill-mode: backwards;
  }
`;

const FadeOut = ({ duration = 500, delay = 0, children }: FadeOutProps) => (
  <FadeOutWrapper
    style={{
      animationDuration: duration + "ms",
      animationDelay: delay + "ms",
    }}
  >
    {children}
  </FadeOutWrapper>
);

const getContainer = (notificationState: NotificationState) => {
  switch (notificationState) {
    case NotificationState.Info:
      return NotificationItemContainerInfo;

    case NotificationState.Error:
      return NotificationItemContainerError;

    case NotificationState.Success:
      return NotificationItemContainerSuccess;
    default:
      throw Error(`Not valid notification state: ${notificationState}`);
  }
};

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: any;
  children: ReactNode;
}) => (condition ? wrapper(children) : children);

export const NotificationItem: React.FC<PropsWithChildren<NotificationItemProps>> = ({
  notification,
  canRemove = true,
  className,
  onClose,
  children,
}) => {
  const { removeNotification } = useNotifications();

  const Container = getContainer(notification.state);
  if (notification.state === NotificationState.Success) {
    setTimeout(() => {
      removeNotification(notification.id);
    }, 4 * 1000);
  }

  return (
    <ConditionalWrapper
      condition={notification.state === NotificationState.Success}
      wrapper={(children: ReactNode) => <FadeOut delay={3500}>{children}</FadeOut>}
    >
      <Container className={className}>
        <NotificationContent>
          {notification.text.includes("\n")
            ? notification.text.split("\n").map((text, index) => <p key={index}>{text}</p>)
            : notification.text}
          {children}
        </NotificationContent>
        {canRemove && (
          <StyledCross
            onClick={() => {
              onClose?.();
              removeNotification(notification.id);
            }}
            state={notification.state}
            height={14}
            width={14}
          />
        )}
      </Container>
    </ConditionalWrapper>
  );
};

const Notifications = () => {
  const { notifications, removeNotification } = useNotifications();

  useEffect(() => {
    notifications.forEach((notification) => removeNotification(notification.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationsContainer>
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
    </NotificationsContainer>
  );
};

export default Notifications;
