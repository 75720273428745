import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany } from "@barona/lapa-common-types";
import { dateToISODate } from "@barona/lapa-common-date-utils";
import { useQuery } from "react-query";

export const referenceFiltersQueryKeys = Object.freeze({
  references: "references",
});

export const useReferenceFilters = (
  baronaCompany?: BaronaCompany,
  customerId?: string,
  paymentGroup?: string,
  paymentPeriod?: string,
  startDate?: Date,
  endDate?: Date
) => {
  const { data, isLoading } = useQuery(
    [referenceFiltersQueryKeys.references, baronaCompany, customerId, paymentGroup, paymentPeriod, startDate, endDate],
    async () =>
      await frontendApi.fetchCustomerReferences({
        baronaCompany,
        customerId,
        paymentGroup,
        paymentPeriod,
        startDate: startDate ? dateToISODate(startDate) : undefined,
        endDate: endDate ? dateToISODate(endDate) : undefined,
      }),
    { keepPreviousData: true }
  );

  return { references: data || [], isLoading };
};
