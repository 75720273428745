import { PersistedItem, Serialized } from "@barona/lapa-common-types";
import Big from "big.js";
import { formatToEuroCurrencyString } from "../../util/money";
import {
  CloseIcon,
  ContentContainer,
  Data,
  FormItem,
  getProductUnit,
  getTotal,
  handleEmployeeName,
  HeaderContainer,
  Label,
  SidePaneContent,
} from "./CommonPanelContent";

export interface ItemPanelContentProps {
  item: Serialized<PersistedItem>;
  closeSidePanel(): void;
}

const ItemPanelContent = ({ item, closeSidePanel }: ItemPanelContentProps) => (
  <SidePaneContent>
    <HeaderContainer>
      <h2>{`Rivin tiedot: ${item?.productName} ${item?.productCode}`}</h2>
      <div>
        <CloseIcon data-testid="closeIcon" onClick={closeSidePanel} />
      </div>
    </HeaderContainer>
    <ContentContainer>
      <FormItem>
        <Label>SELITE</Label>
        <Data>{item?.productName}</Data>
      </FormItem>
      <FormItem>
        <Label>TUOTE</Label>
        <Data>{item?.productCode}</Data>
      </FormItem>
      <FormItem>
        <Label>MÄÄRÄ</Label>
        <Data>{item?.amount.toFixed(2)}</Data>
      </FormItem>
      <FormItem>
        <Label>YKSIKKÖ</Label>
        <Data>{getProductUnit(item?.productUnit ?? undefined)}</Data>
      </FormItem>
      <FormItem>
        <Label>A-HINTA</Label>
        <Data>{item?.billable && item?.price ? formatToEuroCurrencyString(new Big(item?.price)) : " - "}</Data>
      </FormItem>
      <FormItem>
        <Label>YHTEENSÄ</Label>
        <Data>{getTotal(item.price, item.amount, item.billable)}</Data>
      </FormItem>
      <FormItem>
        <Label>TOIMIALA</Label>
        <Data>{item?.businessUnitDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>ALUE</Label>
        <Data>{item?.areaDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>PALVELU</Label>
        <Data>{item?.serviceDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>KUSTANNUSPAIKKA</Label>
        <Data>{item?.costPlaceDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>SOPIMUSNUMERO</Label>
        <Data>{item?.contractNumberDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>PROJEKTI</Label>
        <Data>{item?.projectDimension}</Data>
      </FormItem>
      <FormItem>
        <Label>TYÖNTEKIJÄ</Label>
        <Data>{handleEmployeeName(item)}</Data>
      </FormItem>
    </ContentContainer>
  </SidePaneContent>
);

export default ItemPanelContent;
