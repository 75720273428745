import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { CircleWithCross } from "@styled-icons/entypo/CircleWithCross";

import { ProductType } from "@barona/lapa-common-types";
import { frontendApi, ProductItem } from "@barona/lapa-common-frontend";

import PageContainer from "../../common/PageContainer";
import { Title } from "../../common/Title";
import { Cell, HeaderCell, HeaderRow, Row, Table, TableBody } from "../../../components/Table";
import { useProducts } from "../../../services/products";
import { useDebounce } from "../../../hooks/useDebounce";
import { Modal } from "../../../components/Modal";
import Color from "../../../colors";
import { Button } from "../../../components/Button";
import SearchInput from "../../../components/form/SearchInput";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { Data, FormItem, Label, StyledSelect, StyledTextInput } from "../../InvoicePage/CommonPanelContent";
import Notifications from "../../common/Notifications";
import { NotificationState, useNotifications } from "../../../context";

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalContent = styled.section`
  position: fixed;
  background: ${Color.WHITE};
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 15px;
`;

const ErrorText = styled.div`
  padding: 5px;
  background-color: ${Color.RED_LIGHTEST};
  border-left: 8px ${Color.RED} solid;
`;

const ExitCross = styled(CircleWithCross)`
  color: ${Color.LAPA_BLUE};
`;

interface NewProduct {
  name: string;
  productCode: string;
  type: {
    value: string;
    label: string;
  };
}

const ProductManagementPage = () => {
  const { addNotification } = useNotifications();
  const { products, forceRefresh } = useProducts([ProductType.Mepco]);
  const [searchFilter, setSearchFilter] = useState("");
  const debouncedSearchFilter = useDebounce(searchFilter);
  const [filteredProducts, setFilteredProducts] = useState<ProductItem[]>(products);
  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);

  const createProductHandler = async ({ name, productCode, type }: NewProduct): Promise<boolean> => {
    setShowErrorText(false);

    if (products.some((item) => item.productCode === productCode)) {
      setShowErrorText(true);
      return false;
    }

    try {
      await frontendApi.createProduct({ name, productCode, type: type.value });
      setShowNewProductModal(false);
      addNotification("Tuote lisätty onnistuneesti", NotificationState.Success);
      forceRefresh();
      return true;
    } catch (error) {
      addNotification("Tuotteen lisääminen epäonnistui", NotificationState.Error);
      return false;
    }
  };

  useEffect(() => {
    if (products && products.length > 0) {
      if (debouncedSearchFilter.length < 1) {
        setFilteredProducts(products);
      }
      if (debouncedSearchFilter) {
        const filtered = products.filter((product) => {
          return product.name.toLowerCase().includes(debouncedSearchFilter.toLowerCase());
        });
        setFilteredProducts(filtered);
      }
    }
  }, [products, filteredProducts, debouncedSearchFilter]);

  return (
    <PageContainer>
      <Title>Tuotteiden hallinta</Title>
      <Container>
        <div>
          <h4>Tuotteet ovat käytössä kaikilla yhtiöillä. Tuotetta ei voi lisätä, jollei se ole jo Workdayssa.</h4>
        </div>
        <SearchContainer>
          <SearchInput
            value={searchFilter ?? ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchFilter(event.target.value)}
            placeholder="Hae tuotetta"
          />
          <Button onClick={() => setShowNewProductModal(true)}>Uusi tuote</Button>
        </SearchContainer>
        <Notifications />
        <Table>
          <HeaderRow>
            <HeaderCell>NIMI</HeaderCell>
            <HeaderCell>TUOTEKOODI</HeaderCell>
            <HeaderCell>YKSIKKÖ</HeaderCell>
          </HeaderRow>
          <TableBody>
            {filteredProducts?.map((product: ProductItem) => {
              return (
                <Row key={product.productCode}>
                  <Cell>{product.name}</Cell>
                  <Cell>{product.productCode}</Cell>
                  <Cell>{product.unit}</Cell>
                </Row>
              );
            })}
          </TableBody>
        </Table>
      </Container>
      <Modal show={showNewProductModal}>
        <ModalContent style={{ width: "20%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Lisää tuote</h2>
            <ExitCross style={{ alignSelf: "center" }} size="30" onClick={() => setShowNewProductModal(false)} />
          </div>
          <div>
            <Formik
              initialValues={{ name: "", productCode: "", type: { value: "pcs", label: "kpl" } }}
              onSubmit={(values) => {
                createProductHandler(values);
              }}
            >
              {({ setFieldValue }: FormikProps<NewProduct>) => (
                <Form style={{ display: "flex", flexDirection: "column" }}>
                  <FormItem style={{ marginBottom: "16px" }}>
                    <Label>Nimi</Label>
                    <Data>
                      <Field name="name">
                        {({ field }: FieldProps) => <StyledTextInput {...field} maxLength={50} />}
                      </Field>
                    </Data>
                  </FormItem>
                  <FormItem style={{ marginBottom: "16px" }}>
                    <Label>Tuotekoodi</Label>
                    <Data>
                      <Field name="productCode">
                        {({ field }: FieldProps) => <StyledTextInput {...field} maxLength={50} />}
                      </Field>
                    </Data>
                  </FormItem>
                  <FormItem style={{ marginBottom: "40px" }}>
                    <Label>Yksikkö</Label>
                    <Data>
                      <Field name="type">
                        {({ field }: FieldProps) => (
                          <StyledSelect
                            {...field}
                            options={[
                              { value: "pcs", label: "kpl" },
                              { value: "h", label: "tunti" },
                            ]}
                            onChange={(option) => setFieldValue("type", option)}
                          />
                        )}
                      </Field>
                    </Data>
                  </FormItem>
                  <Button style={{ margin: "8px", display: "flex", alignSelf: "end" }} type="submit">
                    Tallenna tuote Lapaan
                  </Button>
                  {showErrorText && (
                    <ErrorText>No eihän se nyt onnistunut, kun tuote löytyy jo Lapasta. Voi meitä.</ErrorText>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </ModalContent>
      </Modal>
    </PageContainer>
  );
};

export default ProductManagementPage;
