import { Link } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components";
import Color from "../colors";

export const LapaLink = styled.a<{ disabled?: boolean }>`
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => (props.disabled ? Color.GREY_LIGHT : Color.LAPA_BLUE)};
  margin: 0;
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      user-select: none;
    `}
`;

export const LapaNavigationLink = styled(Link)<{ disabled?: boolean }>`
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => (props.disabled ? Color.GREY_LIGHT : Color.LAPA_BLUE)};
  margin: 0;
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      user-select: none;
    `}
`;
