import { useCallback, useEffect, useState } from "react";
import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany, InvoiceStatus, InvoiceSummaryItem } from "@barona/lapa-common-types";
import { dateToISODate } from "@barona/lapa-common-date-utils";
import { useQuery } from "react-query";

type DraftInvoiceResponseElement = typeof frontendApi.getInvoices.ResponseType["invoices"][number];

export interface Invoice extends Omit<DraftInvoiceResponseElement, "invoiceDate" | "periodStart" | "periodEnd"> {
  invoiceDate: Date;
  periodStart?: Date;
  periodEnd?: Date;
}

interface InvoiceFilters {
  baronaCompany: BaronaCompany;
  customerId?: string;
  customerReference?: string | null;
  periodStart?: Date;
  periodEnd?: Date;
}

interface InvoiceFiltersWithStatusAndDate extends InvoiceFilters {
  statuses?: InvoiceStatus[];
  invoiceDateStart?: Date;
  invoiceDateEnd?: Date;
}

export const useInvoices = (
  {
    baronaCompany,
    customerId,
    customerReference,
    periodStart,
    periodEnd,
    statuses = [],
    invoiceDateStart,
    invoiceDateEnd,
  }: InvoiceFiltersWithStatusAndDate,
  { limit, skip }: { limit: number; skip: number }
) => {
  const [totalCount, setTotalCount] = useState<number>(0);

  const { data, isLoading, refetch } = useQuery(
    [
      "invoices",
      baronaCompany,
      customerId,
      customerReference,
      periodStart,
      periodEnd,
      statuses,
      invoiceDateStart,
      invoiceDateEnd,
      limit,
      skip,
    ],
    async () => {
      if (!baronaCompany) {
        return [];
      }
      const { invoices, paging } = await frontendApi.getInvoices({
        baronaCompany,
        statuses,
        customer: customerId,
        customerReference: customerReference === null ? "null" : customerReference,
        periodStart: periodStart ? dateToISODate(periodStart) : undefined,
        periodEnd: periodEnd ? dateToISODate(periodEnd) : undefined,
        invoiceDateStart: invoiceDateStart ? dateToISODate(invoiceDateStart) : undefined,
        invoiceDateEnd: invoiceDateEnd ? dateToISODate(invoiceDateEnd) : undefined,
        limit,
        skip,
      });
      setTotalCount(paging.totalCount);
      return invoices.map((invoice) => ({
        ...invoice,
        invoiceDate: new Date(invoice.invoiceDate),
        periodStart: invoice.periodStart ? new Date(invoice.periodStart) : undefined,
        periodEnd: invoice.periodEnd ? new Date(invoice.periodEnd) : undefined,
      }));
    },
    { keepPreviousData: false }
  );
  return { invoices: data || [], totalCount, isLoading, forceRefresh: refetch };
};

export const useDraftInvoicesSummary = (draftInvoiceIds: string[]) => {
  const [draftInvoicesSummary, setDraftInvoicesSummary] = useState<InvoiceSummaryItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefreshValue, setForceRefresh] = useState<{}>({});
  const forceRefresh = useCallback(() => setForceRefresh({}), []);

  useEffect(() => {
    if (!draftInvoiceIds.length) {
      setIsLoading(false);
      setDraftInvoicesSummary([]);
      return;
    }

    let shouldCancel = false;
    (async () => {
      setIsLoading(true);
      const invoiceSummaries = await frontendApi.getInvoicesSummary({
        invoiceIds: draftInvoiceIds,
      });

      if (!shouldCancel) {
        setIsLoading(false);
        setDraftInvoicesSummary(invoiceSummaries);
      }
    })();

    return () => {
      shouldCancel = true;
    };
  }, [draftInvoiceIds, forceRefreshValue]);

  return { draftInvoicesSummary, isLoading, forceRefresh };
};

export const useInvoiceCustomers = ({
  baronaCompany,
  customerReference,
  periodStart,
  periodEnd,
  statuses = [],
  invoiceDateStart,
  invoiceDateEnd,
}: InvoiceFiltersWithStatusAndDate) => {
  const { data, isLoading, refetch } = useQuery(
    [
      "invoiceCustomers",
      baronaCompany,
      customerReference,
      periodStart,
      periodEnd,
      statuses,
      invoiceDateStart,
      invoiceDateEnd,
    ],
    async () =>
      await frontendApi.getInvoiceCustomers({
        baronaCompany,
        customerReference: customerReference === null ? "null" : customerReference,
        periodStart: periodStart ? dateToISODate(periodStart) : undefined,
        periodEnd: periodEnd ? dateToISODate(periodEnd) : undefined,
        statuses,
        invoiceDateStart: invoiceDateStart ? dateToISODate(invoiceDateStart) : undefined,
        invoiceDateEnd: invoiceDateEnd ? dateToISODate(invoiceDateEnd) : undefined,
      })
  );

  return { invoiceCustomers: data || [], isLoading, forceRefresh: refetch };
};

type InvoiceReferencesApiResponse = typeof frontendApi.getInvoiceReferences.ResponseType;

export const useDraftInvoiceReferences = ({ baronaCompany, customerId, periodStart, periodEnd }: InvoiceFilters) => {
  const [invoiceReferences, setInvoiceReferences] = useState<InvoiceReferencesApiResponse>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefreshValue, setForceRefresh] = useState<{}>({});
  const forceRefresh = useCallback(() => setForceRefresh({}), []);

  useEffect(() => {
    let shouldCancel = false;
    (async () => {
      setIsLoading(true);
      const invoiceReferences = await frontendApi.getInvoiceReferences({
        draft: "true",
        baronaCompany,
        customer: customerId,
        periodStart: periodStart ? dateToISODate(periodStart) : undefined,
        periodEnd: periodEnd ? dateToISODate(periodEnd) : undefined,
      });
      if (!shouldCancel) {
        setIsLoading(false);
        setInvoiceReferences(invoiceReferences);
      }
    })();

    return () => {
      shouldCancel = true;
    };
  }, [baronaCompany, customerId, periodStart, periodEnd, forceRefreshValue]);

  return { invoiceReferences, isLoading, forceRefresh };
};

export const useDraftInvoicePeriods = ({ baronaCompany, customerId, customerReference }: InvoiceFilters) => {
  const [invoicePeriods, setInvoicePeriods] = useState<{ periodStart: Date; periodEnd: Date }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefreshValue, setForceRefresh] = useState<{}>({});
  const forceRefresh = useCallback(() => setForceRefresh({}), []);

  useEffect(() => {
    let shouldCancel = false;
    (async () => {
      setIsLoading(true);
      const invoicePeriods = await frontendApi.getInvoicePeriods({
        draft: "true",
        baronaCompany,
        customer: customerId,
        customerReference: customerReference === null ? "null" : customerReference,
      });
      if (!shouldCancel) {
        setIsLoading(false);
        setInvoicePeriods(
          invoicePeriods.map(({ periodStart, periodEnd }) => ({
            periodStart: new Date(periodStart),
            periodEnd: new Date(periodEnd),
          }))
        );
      }
    })();

    return () => {
      shouldCancel = true;
    };
  }, [baronaCompany, customerId, customerReference, forceRefreshValue]);

  return { invoicePeriods, isLoading, forceRefresh };
};
