import { BaronaCompany } from "@barona/lapa-common-types";
import { useContext, ContextType, PropsWithChildren, createContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

const BaronaCompanyContext = createContext<{
  baronaCompany: BaronaCompany;
  setBaronaCompany: (baronaCompany: BaronaCompany) => void;
}>({
  baronaCompany: BaronaCompany.HR,
  setBaronaCompany: () => {},
});

export const useBaronaCompany = () => useContext(BaronaCompanyContext);

type Props = PropsWithChildren<{
  defaultBaronaCompany?: BaronaCompany;
}>;

export const BaronaCompanyContextProvider = ({ children, defaultBaronaCompany = BaronaCompany.HR }: Props) => {
  const [baronaCompany, setBaronaCompany] = useLocalStorage<ContextType<typeof BaronaCompanyContext>["baronaCompany"]>(
    "baronaCompany",
    defaultBaronaCompany
  );

  return (
    <BaronaCompanyContext.Provider value={{ baronaCompany, setBaronaCompany }}>
      {children}
    </BaronaCompanyContext.Provider>
  );
};
