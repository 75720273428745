import { frontendApi } from "@barona/lapa-common-frontend";
import { useQuery } from "react-query";

export const useCustomerName = (customerId: string) => {
  const { data, isLoading } = useQuery(
    ["customerName", customerId],
    async () => await frontendApi.fetchCustomerName({ customerId })
  );
  return { customerName: data || customerId, isLoading };
};
