import { frontendApi } from "@barona/lapa-common-frontend";
import { useQuery } from "react-query";

export const useImportLogs = ({
  importedByFilter,
  limit,
  skip,
}: { importedByFilter?: string; limit?: number; skip?: number } = {}) => {
  const { data, isLoading, refetch } = useQuery(
    ["importLogs", importedByFilter, limit, skip],
    async () => await frontendApi.getImportLogs({ importedBy: importedByFilter, limit, skip }),
    { keepPreviousData: false }
  );

  return {
    importLogs: data?.importLogs || [],
    totalCount: data?.paging.totalCount || 0,
    isLoading,
    forceRefresh: refetch,
  };
};
