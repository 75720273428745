import React from "react";
import { InvoiceResponse } from "../../services/invoice";
import InvoiceRowsTable, { InvoiceRowsType } from "./InvoiceRowsTable";
import { InvoiceSource } from "@barona/lapa-common-types";

export interface Props {
  invoiceRows: InvoiceResponse["invoiceRows"];
  isLoading?: boolean;
  allowExpandingRows?: boolean;
  invoiceId: string;
  refreshInvoice: Function;
  allowEditingRows: boolean;
  invoiceType: InvoiceSource;
  customerId?: string;
}

const InvoiceRows = (props: Props) => {
  const {
    invoiceRows,
    isLoading = false,
    allowExpandingRows = true,
    invoiceId,
    refreshInvoice,
    allowEditingRows,
    invoiceType,
    customerId,
  } = props;

  const billableRows = invoiceRows.filter((row) => row.billable);
  const nonBillableRows = invoiceRows.filter((row) => !row.billable);
  return (
    <div>
      <h4>Laskutettavat rivit</h4>
      <InvoiceRowsTable
        invoiceRows={billableRows}
        isLoading={isLoading}
        allowExpandingRows={allowExpandingRows}
        invoiceId={invoiceId}
        refreshInvoice={refreshInvoice}
        allowEditingRows={allowEditingRows}
        invoiceType={invoiceType}
        type={InvoiceRowsType.billable}
        customerId={customerId}
      />
      {!isLoading && nonBillableRows.length > 0 && (
        <>
          <h4>Ei laskutettavat rivit</h4>
          <InvoiceRowsTable
            invoiceRows={nonBillableRows}
            isLoading={isLoading}
            allowExpandingRows={allowExpandingRows}
            invoiceId={invoiceId}
            refreshInvoice={refreshInvoice}
            allowEditingRows={allowEditingRows}
            invoiceType={invoiceType}
            type={InvoiceRowsType.nonBillable}
          />
        </>
      )}
    </div>
  );
};

export default InvoiceRows;
