import { useState, useEffect, useCallback } from "react";

export const useIsTabFocused = (): boolean => {
  const [isTabFocused, setIsTabFocused] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "hidden") {
      return setIsTabFocused(false);
    } else {
      return setIsTabFocused(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return isTabFocused;
};
