import styled from "styled-components";
import Color from "../colors";

export const SecondaryButton = styled.button`
  height: 36px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  background-color: ${Color.WHITE};
  padding: 8px 18px;
  border-radius: 3px;
  color: ${Color.LAPA_BLUE};
  border: 1px solid ${Color.LAPA_BLUE};
  display: inline-block;
  cursor: pointer;
  &:disabled {
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.LIGHTER_BLUE};
    color: ${Color.LIGHTER_BLUE};
    cursor: not-allowed;
  }
`;
