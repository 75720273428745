import React from "react";
import styled from "styled-components";
import Color from "../../../colors";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { RemoveButton } from "../../../components/RemoveButton";
import { ContentSeparator } from "../../common/ContentSeparator";

const AddNewGroupTitle = styled.div`
  font-size: 20px;
`;

const ModalContent = styled.section`
  position: fixed;
  background: ${Color.WHITE};
  width: 500px;
  height: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const Container = styled.div`
  padding: 30px;
`;

const InputGroup = styled.div`
  margin-bottom: 10px;
  display: grid;
`;

const SubTitle = styled.div`
  font-size: 16px;
  margin-bottom: 7px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

interface ProductGroupRemoveConfirmationModalProps {
  show: boolean;
  groupName: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ProductGroupRemoveConfirmationModal = ({
  show,
  groupName,
  onCancel,
  onConfirm,
}: ProductGroupRemoveConfirmationModalProps) => (
  <Modal show={show}>
    <ModalContent>
      <Container>
        <AddNewGroupTitle>Vahvista ryhmän poisto</AddNewGroupTitle>
        <ContentSeparator />
        <InputGroup>
          <SubTitle>
            Oletko varma että haluat poistaa ryhmän <Bold>{groupName}</Bold>
          </SubTitle>
        </InputGroup>
        <ButtonsContainer>
          <RemoveButton
            onClick={() => {
              onCancel();
            }}
          >
            Peruuta
          </RemoveButton>
          <Button
            data-testid="RemoveProductGroupConfirm"
            onClick={(_event) => {
              onConfirm();
            }}
          >
            Poista
          </Button>
        </ButtonsContainer>
      </Container>
    </ModalContent>
  </Modal>
);
export default ProductGroupRemoveConfirmationModal;
