import React from "react";
import { ImportLogError, Serialized } from "@barona/lapa-common-types";
import Loading from "../../components/Loading";
import { Table, Cell, Row, TableBody } from "../../components/Table";
import styled from "styled-components";
import Color from "../../colors";
import config from "../../config";
import { sortErrors } from "./ImportLogErrorUtils";

export interface ImportLogErrorTableProps {
  errors: Serialized<ImportLogError>[];
  isLoading: boolean;
}

interface ImportLogErrorTableRowProps {
  error: Serialized<ImportLogError>;
}

const Link = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${Color.LAPA_BLUE};
  margin: 0;
`;

const BoldValue = styled.span`
  font-weight: bold;
`;

const ErrorContainer = styled.div``;

const ImportLogErrorTableRow = ({ error }: ImportLogErrorTableRowProps) => {
  const getErrorWithGuidance = (error: Serialized<ImportLogError>): React.ReactElement | undefined => {
    switch (error.name) {
      case "MissingStaffingContractError":
        return (
          <ErrorContainer>
            Rivi ID <BoldValue>{error.metadata.itemId}</BoldValue> - Tilaussopimusta ei löytynyt: Sopimusnumero{" "}
            <BoldValue>{error.metadata.contractNumber}</BoldValue>, päiväys <BoldValue>{error.metadata.date}</BoldValue>
            , henkilönumero <BoldValue>{error.metadata.employeeNumberDimension}</BoldValue>
          </ErrorContainer>
        );
      case "MissingProductError":
        return (
          <ErrorContainer>
            Tuote puuttuu LAPA:sta: <BoldValue>{error.metadata.productCode}</BoldValue>.
          </ErrorContainer>
        );
      case "MissingInformationError":
        return (
          <ErrorContainer>
            Rivi ID <BoldValue>{error.metadata.itemId}</BoldValue> - Aineiston riviltä puuttuu arvo{" "}
            <BoldValue>{error.metadata.missingValue}</BoldValue>
          </ErrorContainer>
        );
      case "MissingPriceListFromStaffingContractError":
        return (
          <ErrorContainer>
            Tilaussopimukselta{" "}
            <Link target="_blank" href={`${config.S2BaseUrl}/staffing-contracts/${error.metadata.staffingContractId}`}>
              {error.metadata.staffingContractId}
            </Link>{" "}
            puuttuu hinnasto
          </ErrorContainer>
        );
      case "DuplicateItemError":
        return (
          <ErrorContainer>
            Rivi ID <BoldValue>{error.metadata.itemId}</BoldValue> - Rivi on jo tuotu lapaan.
          </ErrorContainer>
        );
      case "ParsingError":
        return (
          <ErrorContainer>
            Rivi ID <BoldValue>{error.metadata.itemId}</BoldValue> - Sarakkeesta{" "}
            <BoldValue>{error.metadata.header}</BoldValue> ei saatu luettua arvoa{" "}
            <BoldValue>{error.metadata.value}</BoldValue>
          </ErrorContainer>
        );
      case "UnableToUpdateError":
        return (
          <ErrorContainer>
            Rivi ID <BoldValue>{error.metadata.itemId}</BoldValue> - Rivi on jo laskutettu ja viety NAViin
          </ErrorContainer>
        );
      case "ForwardInvoicingError":
        return (
          <ErrorContainer>
            Rivi ID <BoldValue>{error.metadata.itemId}</BoldValue> - Laskuttava yhtiö ja tilaussopimukselle merkitty
            yhtiö eivät täsmää
          </ErrorContainer>
        );
      default:
        return undefined;
    }
  };

  const errorElement = getErrorWithGuidance(error);
  if (!errorElement) {
    return null;
  }
  return (
    <Row data-testid="ImportLogErrorRow">
      <Cell>{errorElement}</Cell>
    </Row>
  );
};

const ImportLogErrorTable = ({ errors, isLoading }: ImportLogErrorTableProps) => {
  const sortedErrors = sortErrors(errors);
  return (
    <>
      {sortedErrors.length > 0 && (
        <Table>
          <TableBody>
            {!isLoading && sortedErrors.map((error, idx) => <ImportLogErrorTableRow key={idx} error={error} />)}
          </TableBody>
        </Table>
      )}
      {isLoading && <Loading height={36} count={4} />}
    </>
  );
};

export default ImportLogErrorTable;
