import { BaronaCompany, IInvoice, InvoiceGroupBy, InvoiceSource, InvoiceStatus } from "@barona/lapa-common-types";
import { frontendApi } from "@barona/lapa-common-frontend";
import { useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button";
import Color from "../../colors";
import { Modal } from "../../components/Modal";
import { RemoveButton } from "../../components/RemoveButton";
import Select from "../../components/form/Select";
import { Title } from "../../pages/common/Title";
import { useSearchCustomers } from "../../services/searchCustomers";
import { useDebounce } from "../../hooks/useDebounce";

const ModalContent = styled.section`
  position: fixed;
  background: ${Color.WHITE};
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const SelectContainer = styled.div`
  width: 250px;
`;

const SelectLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
`;

interface Props {
  show: boolean;
  onCancel: () => void;
  onSubmit: (invoiceId: string) => void;
  baronaCompany: BaronaCompany;
}

const saveInvoice = (baronaCompany: BaronaCompany, customer: string) => {
  const invoice: IInvoice = {
    baronaCompany,
    invoiceInfoId: null,
    customerId: customer,
    invoiceDate: new Date(),
    ourReference: null,
    customerReference: null,
    periodStart: null,
    periodEnd: null,
    status: InvoiceStatus.draft,
    invoiceRows: [],
    groupBy: InvoiceGroupBy.person,
    navError: null,
    createdBy: null,
    invoicingModelId: null,
    filters: {
      customerReferences: [],
      rangeStart: null,
      rangeEnd: null,
      paymentGroup: null,
      paymentPeriod: null,
    },
    ourReferenceFrom: null,
    availableContactPersons: null,
    contactPerson: null,
    source: InvoiceSource.manual,
    externalId: null,
    sendNotification: true,
  };

  const invoiceId = frontendApi.createManualInvoice(invoice);
  return invoiceId;
};

const NewInvoiceModal = ({ show, onCancel, onSubmit, baronaCompany }: Props) => {
  const [customer, setCustomer] = useState<{ label: string; value: string } | null>(null);
  const [searchFilter, setSearchFilter] = useState("");
  const debouncedSearchFilter = useDebounce(searchFilter);
  const { customers, isLoading: isLoadingCustomers } = useSearchCustomers(debouncedSearchFilter, baronaCompany);

  return (
    <Modal show={show}>
      <ModalContent style={{ padding: "1rem" }}>
        <Title>Uusi lasku</Title>
        <SelectContainer>
          <SelectLabel>Valitse asiakas</SelectLabel>
          <Select
            noOptionsMessage={() => "Ei löydy asiakasta"}
            placeholder="Hae asiakasta"
            isLoading={isLoadingCustomers}
            options={customers.map(({ customerId, customerName }) => ({ label: customerName, value: customerId }))}
            onChange={(value) => setCustomer(value)}
            value={customer}
            isClearable
            isSearchable
            onInputChange={(value) => setSearchFilter(value)}
          />
        </SelectContainer>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <RemoveButton
            onClick={() => {
              onCancel();
            }}
            style={{ marginRight: "0.5rem" }}
          >
            Peruuta
          </RemoveButton>
          <Button
            disabled={!customer}
            onClick={async () => {
              const { invoiceId } = await saveInvoice(baronaCompany, customer!.value);
              onSubmit(invoiceId);
            }}
          >
            Vahvista
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default NewInvoiceModal;
