import React, { useState } from "react";
import styled, { css } from "styled-components";
import { SearchAlt2 } from "@styled-icons/boxicons-regular/SearchAlt2";
import Color from "../../colors";

interface Props extends React.HTMLProps<HTMLInputElement> {
  type?: "text";
}

const Icon = styled(SearchAlt2)`
  width: 25px;
  color: ${Color.GREY};
  margin-left: 8px;
`;

const Container = styled.div<{ hasFocus: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 3px;
  transition: box-shadow 0.1s linear;
  width: 200px;
  height: 36px;

  ${(props) =>
    props.hasFocus
      ? css`
          border: solid 1px ${Color.GREY_LIGHT};
          box-shadow: 0px 0px 0px 2px ${Color.LAPA_BLUE};
        `
      : css`
          border: solid 1px ${Color.GREY_LIGHT};
        `}
`;

const StyledTextInput = styled.input`
  border-style: none;
  outline: none;
  width: 100%;
  font-size: 14px;
`;

const SearchInput = (props: Props) => {
  const [hasFocus, setFocus] = useState(false);

  const onFocusHandler = () => {
    setFocus(!hasFocus);
  };

  return (
    <Container onFocus={onFocusHandler} onBlur={onFocusHandler} hasFocus={hasFocus}>
      <Icon />
      <StyledTextInput type="text" {...(props as any)} />
    </Container>
  );
};

export default SearchInput;
