import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LapaNavigationLink } from "../../components/Link";
import PageContainer from "../common/PageContainer";
import { Title } from "../common/Title";
import styled from "styled-components";
import { Button } from "../../components/Button";
import Dropdown, { DropdownValue } from "../../components/Dropdown";
import {
  updateProductPricings,
  useProductPricingsWithoutGroup,
  createPriceList,
  useProductGroupsPricings,
  updateProductGroupPricings,
  useProductGroupProductPricings,
} from "../../services/productPricings";
import { useNotifications, useBaronaCompany, NotificationState } from "../../context";
import { Table, HeaderRow, HeaderCell, TableBody } from "../../components/Table";
import Loading from "../../components/Loading";
import Color from "../../colors";
import { PriceListType, PriceSetThrough, BaronaCompany, IProblemJSON, ProductType } from "@barona/lapa-common-types";
import Notifications, { NotificationItem } from "../common/Notifications";
import { Trash } from "@styled-icons/boxicons-regular/Trash";
import { CalendarExclamation } from "@styled-icons/boxicons-regular/CalendarExclamation";
import { frontendApi, ProductGroupProductItem, ProductItem } from "@barona/lapa-common-frontend";
import TextInput from "../../components/form/TextInput";
import PricelistItem from "./PricelistItem";
import { useProductGroups, useProducts } from "../../services/products";
import { useCompanySettings } from "../../services/companySettings";
import { companyMap } from "@barona/lapa-common-types";
import { AddCircle } from "@styled-icons/ionicons-outline/AddCircle";
import NewPricelistItem from "./NewPricelistItem";
import sortBy from "lodash/sortBy";
import Hidden from "../../components/Hidden";
import ProductGroupItem from "./ProductGroupItem";
import differenceBy from "lodash/differenceBy";
import flatten from "lodash/flatten";
import { Redo } from "@styled-icons/boxicons-regular/Redo";
import axios, { AxiosError } from "axios";
import { useCustomerName } from "../../services/customer";

const PriceListNameContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 60% 30% 10%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ProductPricingsContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

const ProductListAdditionalInfoContainer = styled.div`
  display: table;
  margin-left: 30px;
`;

const ProductPricingTableContainer = styled.div`
  padding-bottom: 30px;
`;

const Label = styled.span`
  display: block;
  margin-bottom: 8px;
  margin-top: 12px;
  color: ${Color.GREY};
`;

const NotificationInfo = styled(NotificationItem)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const HidePriceListContainer = styled.div`
  display: flex;
  padding: 5px;
  margin-top: 10px;
`;

const HideIcon = styled(Trash)<{ disabled?: boolean }>`
  width: 18px;
  color: ${(props) => (props.disabled ? Color.GREY_LIGHT : Color.LAPA_BLUE)};
  margin: 0 4px 0 -8px;
`;

const ChangeHistoryIcon = styled(CalendarExclamation)`
  width: 18px;
  color: ${Color.LAPA_BLUE};
  margin: 0 4px 0 -8px;
`;

const DisabledLink = styled.div`
  color: ${Color.GREY_LIGHT};
  cursor: not-allowed;
`;

const RePriceItemsIcon = styled(Redo)`
  height: 18px;
  margin: 0 3px 2px 0;
`;

const RePriceItemsButton = styled.button`
  all: unset;
  cursor: pointer;
  vertical-align: middle;
  color: ${Color.LAPA_BLUE};
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;

  &:disabled {
    color: ${Color.GREY_LIGHT};
    cursor: not-allowed;
  }
`;

const NotificationContainer = styled.div`
  margin: 10px 0;
`;

const TextInputFillAllSpace = styled(TextInput)`
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

const AddProductIcon = styled(AddCircle)`
  width: 14px;
  height: 14px;
  padding-right: 4px;
`;

const AddProductContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddProductButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: ${Color.LAPA_BLUE};

  &:disabled {
    color: ${Color.GREY_LIGHT};
  }
`;

const SubTitle = styled.div`
  font-size: 16px;
`;

const priceListTypeOptions = [
  { label: "Mepco", value: PriceListType.Mepco },
  { label: "Fidaware", value: PriceListType.Fidaware },
];

const getPriceListTypeOption = (type: PriceListType) => priceListTypeOptions.find((option) => option.value === type);
export interface PriceListPageProps {
  new?: boolean;
}

const PricelistPage = (props: PriceListPageProps) => {
  // TODO: fix typing
  const { pricelistId, customerId } = useParams<"pricelistId" | "customerId">() as {
    pricelistId: string;
    customerId: string;
  };
  const navigate = useNavigate();
  const { baronaCompany, setBaronaCompany } = useBaronaCompany();
  const [priceListName, setPriceListName] = useState(pricelistId);
  const [baronaCompanyCode, setBaronaCompanyCode] = useState("");
  const [employeeProfileId, setEmployeeProfileId] = useState("");
  const [priceListType, setPriceListType] = useState(PriceListType.Mepco);
  const [priceListBaronaCompany, setPriceListBaronaCompany] = useState<BaronaCompany | undefined>();
  const [addedProduct, setAddedProduct] = useState<ProductItem>();
  const [openProductGroupIds, setOpenProductGroupIds] = useState<string[]>([]);
  const [savePriceListButtonDisabled, setSavePriceListButtonDisabled] = useState(false);
  const { addNotification } = useNotifications();
  const { settings, isLoading: isCompanySettingsLoading } = useCompanySettings(baronaCompany);
  const [priceListDisabled, setPriceListDisabled] = useState(false);

  useEffect(() => {
    if (!props.new && pricelistId) {
      const fetchPricelist = async () => {
        const priceList = await frontendApi.fetchPriceList({ priceListId: pricelistId });
        setPriceListName(priceList?.description ?? "");
        setBaronaCompanyCode(priceList?.baronaCompanyCode ?? "");
        setEmployeeProfileId(priceList?.employeeProfileId ?? "");
        setPriceListType(priceList.type);
        setPriceListBaronaCompany(priceList.baronaCompanyCode);
      };

      fetchPricelist();
    }
  }, [props.new, pricelistId]);

  useEffect(() => {
    if (!isCompanySettingsLoading && baronaCompany && baronaCompanyCode && baronaCompany !== baronaCompanyCode) {
      if (!settings?.usePricelistsFromOtherBaronaCompanies.includes(baronaCompanyCode as BaronaCompany)) {
        addNotification("Valittu Barona-yhtiö asetettiin samaksi kuin hinnastolla oleva.");
        setBaronaCompany(baronaCompanyCode as BaronaCompany);
        setPriceListDisabled(false);
      } else {
        addNotification(
          `Tämä hinnasto on ${companyMap.get(
            baronaCompanyCode as BaronaCompany
          )}:n luoma hinnasto. Sitä voi siis muokata vain sen yhtiön tiedoista, jossa hinnasto on alunperin tehty. Tarvittaessa voit muokata hinnastoa vaihtamalla yhtiön, mutta mikäli muokkaustarpeet koskevat lähinnä omaa yhtiötäsi, tee tästä hinnastosta mieluummin kopio`
        );
        setPriceListDisabled(true);
      }
    }
  }, [baronaCompany, baronaCompanyCode, addNotification, setBaronaCompany, isCompanySettingsLoading, settings]);

  const { customerName } = useCustomerName(customerId);

  const [mutatedProductPricings, setMutatedProductPricings] = useState<ProductItem[]>([]);

  const { productPricings, isLoading: isProductPricingsLoading } = useProductPricingsWithoutGroup(
    props.new ? "" : pricelistId
  );
  const { products, isLoading: isProductsLoading } = useProducts([
    priceListType === PriceListType.Mepco ? ProductType.Mepco : ProductType.Fidaware,
  ]);
  const {
    productGroupPricings,
    isLoading: isLoadingProductGroupPricings,
    forceRefresh: forceRefreshProductGroupPricing,
  } = useProductGroupsPricings(pricelistId, props.new ? baronaCompany : priceListBaronaCompany);

  const [mutatedGroupPricings, setMutatedGroupPricings] = useState(productGroupPricings);
  const { productGroups, isLoading: isLoadingProductGroups } = useProductGroups(
    props.new ? baronaCompany : priceListBaronaCompany
  );

  const productsWithoutGroup = useMemo(
    () =>
      differenceBy(
        products,
        productGroups.flatMap((group) => group.products),
        (product) => product.productCode
      ),
    [products, productGroups]
  );

  const isLoading = isProductPricingsLoading || isProductsLoading || isLoadingProductGroups;

  const newProducts = useMemo(() => {
    const existingProductCodes = new Set(mutatedProductPricings.map((pricing) => pricing.productCode));
    return sortBy(
      productsWithoutGroup.filter((product) => !existingProductCodes.has(product.productCode)),
      [(product) => product.name]
    );
  }, [productsWithoutGroup, mutatedProductPricings]);

  useEffect(() => {
    if (props.new) {
      setMutatedProductPricings(productsWithoutGroup);
    } else {
      setMutatedProductPricings(productPricings);
    }
  }, [productPricings, productsWithoutGroup, props.new]);

  useEffect(() => {
    setMutatedGroupPricings(productGroupPricings);
  }, [productGroupPricings]);

  const handlePriceTypeDropdownOnChange = (value: DropdownValue | null) =>
    setPriceListType(value!.value as PriceListType);

  const handleEmployeeProfileIdOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmployeeProfileId(event.target.value);

  const handlePriceListNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPriceListName(event.target.value);

  const handleRemoveClick = useCallback(
    async (productCode: string) => {
      await frontendApi.removeProductFromPricelist({ priceListId: pricelistId, productCode });
      addNotification("Tuote poistettu hinnastosta", NotificationState.Success);
      setMutatedProductPricings((prevState) => prevState.filter((price) => price.productCode !== productCode));
    },
    [setMutatedProductPricings, pricelistId, addNotification]
  );

  const handlePriceValueOnChange = useCallback(
    (productCode: string, value: string) => {
      setMutatedProductPricings((prevState) => {
        const index = prevState.findIndex((price) => price.productCode === productCode);
        const editedState = [...prevState];
        editedState[index].priceValue = value;
        return editedState;
      });
    },
    [setMutatedProductPricings]
  );
  const handlePriceTypeOnChange = useCallback(
    (productCode: string, value: DropdownValue | null) => {
      setMutatedProductPricings((prevState) => {
        const index = prevState.findIndex((price) => price.productCode === productCode);
        const editedState = [...prevState];
        editedState[index].priceType = value!.value;
        return editedState;
      });
    },
    [setMutatedProductPricings]
  );

  const handleBillableOnChange = useCallback(
    (productCode: string, value: boolean) => {
      setMutatedProductPricings((prevState) => {
        const index = prevState.findIndex((price) => price.productCode === productCode);
        const editedState = [...prevState];
        editedState[index].billable = value;
        if (!editedState[index].billable) editedState[index].priceValue = "";
        return editedState;
      });
    },
    [setMutatedProductPricings]
  );

  const handleGroupPriceValueOnChange = useCallback(
    (productGroupId: string, value: string) => {
      setMutatedGroupPricings((prevState) => {
        const index = prevState.findIndex((price) => price.productGroupId === productGroupId);
        const editedState = [...prevState];
        editedState[index].priceValue = value;
        return editedState;
      });
    },
    [setMutatedGroupPricings]
  );

  const handleGroupPriceTypeOnChange = useCallback(
    (productGroupId: string, value: DropdownValue | null) => {
      setMutatedGroupPricings((prevState) => {
        const index = prevState.findIndex((price) => price.productGroupId === productGroupId);
        const editedState = [...prevState];
        editedState[index].priceType = value!.value;
        return editedState;
      });
    },
    [setMutatedGroupPricings]
  );
  const handleGroupBillableOnChange = useCallback(
    (productGroupId: string, value: boolean) => {
      setMutatedGroupPricings((prevState) => {
        const index = prevState.findIndex((price) => price.productGroupId === productGroupId);
        const editedState = [...prevState];
        editedState[index].billable = value;
        if (!editedState[index].billable) editedState[index].priceValue = "";
        return editedState;
      });
    },
    [setMutatedGroupPricings]
  );

  const {
    productGroupProductPricings,
    isLoading: isLoadingProductGroupProductPricings,
    forceRefresh: forceRefereshProductGroupProductPricings,
  } = useProductGroupProductPricings(
    mutatedGroupPricings.map(({ productGroupId }) => productGroupId),
    pricelistId
  );

  const [mutatedProductGroupProductPricings, setMutatedProductGroupProductPricings] =
    useState(productGroupProductPricings);

  useEffect(() => {
    setMutatedProductGroupProductPricings(productGroupProductPricings);
  }, [productGroupProductPricings]);

  const handleProductPriceValueOnChange = useCallback(
    (productGroupId: string, productCode: string, value: string) => {
      setMutatedProductGroupProductPricings((prevState) => {
        const pricingsWithinGroup = prevState.get(productGroupId)!;
        const index = pricingsWithinGroup.findIndex((price) => price.productCode === productCode);
        const editedPricings = [...pricingsWithinGroup];
        editedPricings[index].priceValue = value ? value : undefined;

        const newState = new Map<string, ProductGroupProductItem[]>(prevState);
        newState.set(productGroupId, editedPricings);
        return newState;
      });
    },
    [setMutatedProductGroupProductPricings]
  );

  const handleProductPriceTypeOnChange = useCallback(
    (productGroupId: string, productCode: string, value: DropdownValue | null) => {
      setMutatedProductGroupProductPricings((prevState) => {
        const pricingsWithinGroup = prevState.get(productGroupId)!;
        const index = pricingsWithinGroup.findIndex((price) => price.productCode === productCode);
        const editedPricings = [...pricingsWithinGroup];
        editedPricings[index].priceType = value ? value.value : undefined;

        const newState = new Map<string, ProductGroupProductItem[]>(prevState);
        newState.set(productGroupId, editedPricings);
        return newState;
      });
    },
    [setMutatedProductGroupProductPricings]
  );

  const handleProductBillableOnChange = useCallback(
    (productGroupId: string, productCode: string, value: boolean) => {
      setMutatedProductGroupProductPricings((prevState) => {
        const pricingsWithinGroup = prevState.get(productGroupId)!;
        const index = pricingsWithinGroup.findIndex((price) => price.productCode === productCode);
        const editedPricings = [...pricingsWithinGroup];
        editedPricings[index].billable = value;
        editedPricings[index].priceValue = "";
        const newState = new Map<string, ProductGroupProductItem[]>(prevState);
        newState.set(productGroupId, editedPricings);
        return newState;
      });
    },
    [setMutatedProductGroupProductPricings]
  );

  const handleOverridePrice = useCallback(
    (productGroupId: string, productCode: string) => {
      setMutatedProductGroupProductPricings((prevState) => {
        const pricingsWithinGroup = prevState.get(productGroupId)!;
        const index = pricingsWithinGroup.findIndex((price) => price.productCode === productCode);
        const editedPricings = [...pricingsWithinGroup];
        editedPricings[index].priceSetThrough = PriceSetThrough.Manually;

        const newState = new Map<string, ProductGroupProductItem[]>(prevState);
        newState.set(productGroupId, editedPricings);
        return newState;
      });
    },
    [setMutatedProductGroupProductPricings]
  );

  const handleNewProductOnChange = (newProductItem: ProductItem) => {
    setAddedProduct(newProductItem);
  };

  const [addNewProductClicked, setAddNewProductClicked] = useState(false);

  const handlePriceListHideOnClick = () => frontendApi.updatePriceList({ priceListId: pricelistId, hidden: true });

  const handleAddProduct = () => {
    setAddNewProductClicked(true);
  };

  const handleProductGroupOnExpandClick = (productGroupId: string, isOpen: boolean) => {
    if (isOpen) {
      setOpenProductGroupIds((oldState) => [...oldState, productGroupId]);
    } else {
      setOpenProductGroupIds((oldState) => oldState.filter((id) => id !== productGroupId));
    }
  };

  const onSaveClick = async () => {
    if (!priceListName) {
      addNotification("Hinnaston nimi on pakollinen tieto.", NotificationState.Error);
      return;
    }

    try {
      setSavePriceListButtonDisabled(true);
      try {
        if (mutatedProductPricings.length || addedProduct) {
          const updatedProductPricings = await updateProductPricings(
            pricelistId,
            addedProduct ? [...mutatedProductPricings, addedProduct] : mutatedProductPricings
          );
          setMutatedProductPricings(updatedProductPricings);
        }

        setAddNewProductClicked(false);
      } catch (error) {
        const title =
          (axios.isAxiosError(error) && (error as AxiosError<IProblemJSON>)?.response?.data?.title) ??
          (error as Error)?.message;

        addNotification(`Hinnaston tallennus epäonnistui! Yksityiskohtia virheestä: ${title}`, NotificationState.Error);
        throw error;
      }

      try {
        const productGroupProductPricings = flatten(Array.from(mutatedProductGroupProductPricings.values()))
          .filter(
            (productGroupProductPrice) =>
              !!productGroupProductPrice.priceType && !!productGroupProductPrice.priceSetThrough
          )
          .map(
            (productGroupProductPrice) =>
              ({
                ...productGroupProductPrice,
              } as ProductItem)
          );

        if (productGroupProductPricings.length) {
          await updateProductPricings(pricelistId, productGroupProductPricings);
        }
      } catch (error) {
        const title =
          (axios.isAxiosError(error) && (error as AxiosError<IProblemJSON>)?.response?.data?.title) ??
          (error as Error)?.message;

        addNotification(
          `Ryhmien tuotteiden tallennus epäonnistui. Yksityiskohtia virheestä: ${title}`,
          NotificationState.Error
        );
        throw error;
      }

      try {
        if (productGroupPricings.length >= 1) {
          const updatedProductGroupPricings = await updateProductGroupPricings(
            pricelistId,
            productGroupPricings.filter((groupPrice) => !!groupPrice.priceType)
          );
          setMutatedGroupPricings(updatedProductGroupPricings);
        }
      } catch (error) {
        const title =
          (axios.isAxiosError(error) && (error as AxiosError<IProblemJSON>)?.response?.data?.title) ??
          (error as Error)?.message;

        addNotification(
          `Ryhmiteltyjen tuotteiden hintojen tallennus epäonnistui, yksityiskohtia virheestä: ${title}`,
          NotificationState.Error
        );
        throw error;
      }

      try {
        await frontendApi.updatePriceList({
          priceListId: pricelistId,
          description: priceListName,
          employeeProfileId: employeeProfileId ? employeeProfileId : undefined,
        });
      } catch (error) {
        addNotification(
          "Hinnaston nimen ja mahdollisen työntekijäprofiili ID:n tallennus epäonnistui!",
          NotificationState.Error
        );
        throw error;
      }

      addNotification("Hinnaston tallennus onnistui!", NotificationState.Success);
    } catch (error) {
    } finally {
      setSavePriceListButtonDisabled(false);
    }

    forceRefreshProductGroupPricing();
    forceRefereshProductGroupProductPricings();
  };

  const onCreateNewSaveClick = async () => {
    try {
      if (!priceListName) {
        addNotification("Hinnaston nimi on pakollinen tieto.", NotificationState.Error);
        return;
      }
      setSavePriceListButtonDisabled(true);
      const priceList = await createPriceList(
        {
          customerId: customerId,
          baronaCompanyCode: baronaCompany,
          description: priceListName,
          type: priceListType,
        },
        mutatedProductPricings.filter((price) => !!price.priceType)
      );
      setMutatedProductPricings(priceList.productPricings);

      const updatedProductGroupPricings = await updateProductGroupPricings(
        priceList.priceListId,
        productGroupPricings.filter((groupPrice) => !!groupPrice.priceType)
      );
      setMutatedGroupPricings(updatedProductGroupPricings);

      const productGroupProductPricings = flatten(Array.from(mutatedProductGroupProductPricings.values()))
        .filter((productPrice) => !!productPrice.priceType && !!productPrice.priceSetThrough)
        .map((productPrice) => {
          productPrice.priceListId = priceList.priceListId;
          return { ...productPrice } as ProductItem;
        });

      if (productGroupProductPricings.length) {
        await updateProductPricings(priceList.priceListId, productGroupProductPricings);
      }

      navigate(`../${priceList.priceListId}`);
      addNotification("Uuden hinnaston tallennus onnistui!", NotificationState.Success);
    } catch (error) {
      const title =
        (axios.isAxiosError(error) && (error as AxiosError<IProblemJSON>)?.response?.data?.title) ??
        (error as Error)?.message;

      addNotification(
        `Uuden hinnaston tallennus epäonnistui! Yksityiskohdat virheestä: ${title}`,
        NotificationState.Error
      );
    } finally {
      setSavePriceListButtonDisabled(false);
    }
  };

  const rePriceItemsHandler = async () => {
    try {
      await frontendApi.priceUnpricedItems({ priceListId: pricelistId });
      addNotification("Tapahtumien uudelleen hinnoittelu onnistui!", NotificationState.Success);
    } catch (error) {
      const title = (axios.isAxiosError(error) && error?.response?.data?.title) ?? (error as Error)?.message;

      addNotification(`Tapahtumien uudelleen hinnoittelu epäonnistui: ${title}`, NotificationState.Error);
    }
  };

  return (
    <PageContainer>
      <LapaNavigationLink to={".."}>{"<"} Takaisin asiakkaan tietoihin</LapaNavigationLink>
      <PriceListNameContainer>
        <Title data-testid={"PriceListPageTitle"}>{`${priceListName} / ${customerName}`}</Title>
        {!props.new ? (
          <RePriceItemsButton data-testid="RePriceItems" onClick={rePriceItemsHandler} disabled={priceListDisabled}>
            <RePriceItemsIcon />
            Päivitä muutokset laskuttamattomiin tapahtumiin
          </RePriceItemsButton>
        ) : (
          <div />
        )}
        <Button
          data-testid="SavePriceList"
          onClick={props.new ? onCreateNewSaveClick : onSaveClick}
          disabled={savePriceListButtonDisabled || priceListDisabled}
        >
          Tallenna
        </Button>
      </PriceListNameContainer>
      <NotificationContainer>
        <Notifications />
      </NotificationContainer>
      <ProductPricingsContainer>
        <div>
          <ProductPricingTableContainer>
            <SubTitle>Ryhmitellyt tuotteet</SubTitle>
            {isLoadingProductGroupPricings || isLoadingProductGroupProductPricings ? (
              <Loading />
            ) : (
              <Table>
                <HeaderRow>
                  <HeaderCell style={{ width: "30%" }}>NIMI</HeaderCell>
                  <HeaderCell style={{ width: "10%" }}>ARVO</HeaderCell>
                  <HeaderCell style={{ width: "20%" }}>TYYPPI</HeaderCell>
                  <HeaderCell style={{ width: "10%" }}>LASKUTETAAN</HeaderCell>
                  <HeaderCell>NAV-RESURSSI</HeaderCell>
                  <HeaderCell style={{ width: "8%" }}>YKSIKKÖ</HeaderCell>
                </HeaderRow>
                <TableBody>
                  {mutatedGroupPricings.map((productGroupPricing) => (
                    <ProductGroupItem
                      isOpen={openProductGroupIds.includes(productGroupPricing.productGroupId)}
                      key={productGroupPricing.productGroupId}
                      {...productGroupPricing}
                      productGroupProductPricings={
                        mutatedProductGroupProductPricings.get(productGroupPricing.productGroupId)!
                      }
                      disabled={priceListDisabled}
                      handlePriceValueOnChange={handleGroupPriceValueOnChange}
                      handlePriceTypeOnChange={handleGroupPriceTypeOnChange}
                      handleBillableOnChange={handleGroupBillableOnChange}
                      handleProductPriceValueOnChange={handleProductPriceValueOnChange}
                      handleProductPriceTypeOnChange={handleProductPriceTypeOnChange}
                      handleProductBillableOnChange={handleProductBillableOnChange}
                      handleOverridePrice={handleOverridePrice}
                      refreshProductGroups={forceRefereshProductGroupProductPricings}
                      handleOnExpandClick={handleProductGroupOnExpandClick}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </ProductPricingTableContainer>
          <ProductPricingTableContainer>
            <SubTitle>Muut tuotteet</SubTitle>
            {!props.new ? (
              <AddProductContainer>
                <AddProductButton
                  data-testid="AddProductButton"
                  disabled={addNewProductClicked || priceListDisabled}
                  onClick={handleAddProduct}
                >
                  <AddProductIcon />
                  Lisää tuote
                </AddProductButton>
              </AddProductContainer>
            ) : null}
            <Table>
              <HeaderRow>
                <HeaderCell style={{ width: "30%" }}>NIMI</HeaderCell>
                <HeaderCell style={{ width: "10%" }}>ARVO</HeaderCell>
                <HeaderCell style={{ width: "30%" }}>TYYPPI</HeaderCell>
                <HeaderCell style={{ width: "10%" }}>LASKU&shy;TETAAN</HeaderCell>
                <HeaderCell style={{ width: "10%" }}>NAV-RESURSSI</HeaderCell>
                <HeaderCell style={{ width: "8%" }}>YKSIKKÖ</HeaderCell>
              </HeaderRow>
              <TableBody>
                {addNewProductClicked ? (
                  <NewPricelistItem
                    priceListId={pricelistId}
                    products={newProducts}
                    handleNewProductOnChange={handleNewProductOnChange}
                    handleRemoveOnClick={() => setAddNewProductClicked(false)}
                  />
                ) : null}
                {mutatedProductPricings.map((productPrice) => (
                  <PricelistItem
                    key={productPrice.productCode}
                    handlePriceValueOnChange={handlePriceValueOnChange}
                    handlePriceTypeOnChange={handlePriceTypeOnChange}
                    handleBillableOnChange={handleBillableOnChange}
                    {...(!props.new && { handleRemoveOnClick: handleRemoveClick })}
                    {...productPrice}
                    disabled={priceListDisabled}
                  />
                ))}
              </TableBody>
            </Table>
            {isLoading && <Loading />}
          </ProductPricingTableContainer>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <ProductListAdditionalInfoContainer>
            <Label>Hinnaston tyyppi *</Label>
            <Dropdown
              isDisabled={!props.new || priceListDisabled}
              placeholder={""}
              value={getPriceListTypeOption(priceListType)}
              options={priceListTypeOptions}
              onChange={handlePriceTypeDropdownOnChange}
              testId={"PriceListType"}
            />
            <Label>Hinnaston nimi *</Label>
            <TextInputFillAllSpace
              value={priceListName}
              onChange={handlePriceListNameOnChange}
              data-testid={"PriceListName"}
              disabled={priceListDisabled}
            />
            <Hidden reason="Currently not in use" hidden={true}>
              <Label>Työntekijäprofiili ID</Label>
              <TextInputFillAllSpace
                placeholder={"Profiili ID jos käytössä"}
                value={employeeProfileId}
                onChange={handleEmployeeProfileIdOnChange}
                data-testid={"EmployeeId"}
              />
              <NotificationInfo
                canRemove={false}
                notification={{
                  state: NotificationState.Info,
                  text: "Työntekijäprofiilin ID tarvitaan vain henkilökohtaista hinnastoa tehdessä.",
                  id: "guideText",
                }}
              />
            </Hidden>
            {!props.new && (
              <HidePriceListContainer>
                {priceListDisabled ? (
                  <DisabledLink>
                    <HideIcon disabled />
                    Piilota hinnasto
                  </DisabledLink>
                ) : (
                  <LapaNavigationLink
                    data-testid="HidePriceList"
                    onClick={() => !priceListDisabled && handlePriceListHideOnClick()}
                    to={".."}
                  >
                    {<HideIcon />}
                    Piilota hinnasto
                  </LapaNavigationLink>
                )}
              </HidePriceListContainer>
            )}
            {!props.new && (
              <HidePriceListContainer>
                <LapaNavigationLink data-testid="ChangeHistory" to={`../../pricelisthistory/${pricelistId}`}>
                  <ChangeHistoryIcon />
                  Muutoshistoria
                </LapaNavigationLink>
              </HidePriceListContainer>
            )}
          </ProductListAdditionalInfoContainer>
        )}
      </ProductPricingsContainer>
    </PageContainer>
  );
};

export default PricelistPage;
