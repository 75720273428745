import React, { useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import SearchInput from "../components/form/SearchInput";
import { usePriceLists } from "../services/priceLists";
import { useBaronaCompany } from "../context";
import { LapaNavigationLink } from "../components/Link";
import { useDebounce } from "../hooks/useDebounce";
import Loading from "../components/Loading";
import { Cell, Row, Table, TableBody, HeaderRow, HeaderCell } from "../components/Table";
import { Button } from "../components/Button";
import { companyMap } from "@barona/lapa-common-types";
import { BaronaCompany } from "@barona/lapa-common-types";
import Color from "../colors";
import { dateToFinnishDateString } from "@barona/lapa-common-date-utils";

const PriceListsContainer = styled.div``;

const PriceListSearchContainer = styled.div`
  display: grid;
  grid-template-columns: 85% 15%;
  margin: 20px 0;
`;

const PriceListContainer = styled.div`
  margin-top: 24px;
`;

const PriceListTitle = styled.h2`
  font-size: 16px;
  color: ${Color.BLACK};
`;

type PriceListRowProps = {
  description: string | null;
  priceListId: string;
  baronaCompanyCode: BaronaCompany;
  currentBaronaCompanyCode: BaronaCompany;
  updatedAt: string | null;
};

const PriceListRow = ({
  description,
  priceListId,
  baronaCompanyCode,
  currentBaronaCompanyCode,
  updatedAt,
}: PriceListRowProps) => {
  const sourceName =
    currentBaronaCompanyCode === baronaCompanyCode ? "Oma hinnasto" : `${companyMap.get(baronaCompanyCode)}:n hinnasto`;
  return (
    <Row data-testid="PriceList">
      <Cell>
        <LapaNavigationLink to={priceListId}>{description}</LapaNavigationLink>
      </Cell>
      <Cell>{updatedAt ? dateToFinnishDateString(new Date(updatedAt)) : ""}</Cell>
      <Cell>{sourceName}</Cell>
    </Row>
  );
};

const PricelistsPage = () => {
  // TODO: fix typing
  const { customerId } = useParams<"customerId">() as { customerId: string };
  const navigate = useNavigate();
  const [searchFilter, setSearchFilter] = useState("");
  const debouncedSearchFilter = useDebounce(searchFilter);
  const { baronaCompany } = useBaronaCompany();

  const handleSearchFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchFilter(event.target.value);
  const { priceLists, isLoading } = usePriceLists(customerId, baronaCompany, debouncedSearchFilter);
  const hasOtherPricelists = !!(
    priceLists && priceLists.find(({ baronaCompanyCode }) => baronaCompanyCode !== baronaCompany)
  );
  return (
    <PriceListsContainer>
      <PriceListSearchContainer>
        <SearchInput
          data-testid="SearchPriceLists"
          value={searchFilter}
          onChange={handleSearchFilterChange}
          placeholder="Hakusana"
        />
        <Button onClick={() => navigate("new")}>Uusi hinnasto</Button>
      </PriceListSearchContainer>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <PriceListContainer>
            <PriceListTitle>Omat hinnastot</PriceListTitle>
            <Table>
              <HeaderRow>
                <HeaderCell style={{ width: "60%" }}>HINNASTO</HeaderCell>
                <HeaderCell style={{ width: "10%" }}>PÄIVITETTY VIIMEKSI</HeaderCell>
                <HeaderCell style={{ width: "10%" }}>LÄHDE</HeaderCell>
              </HeaderRow>
              <TableBody>
                {priceLists &&
                  priceLists
                    .filter(({ baronaCompanyCode }) => baronaCompanyCode === baronaCompany)
                    .map(({ description, priceListId, baronaCompanyCode, updatedAt, createdAt }) => (
                      <PriceListRow
                        key={priceListId}
                        description={description}
                        priceListId={priceListId}
                        baronaCompanyCode={baronaCompanyCode}
                        currentBaronaCompanyCode={baronaCompany}
                        updatedAt={updatedAt ?? createdAt}
                      />
                    ))}
              </TableBody>
            </Table>
          </PriceListContainer>
          {hasOtherPricelists && (
            <PriceListContainer>
              <PriceListTitle>Muiden yhtiöiden käyttöönotetut hinnastot</PriceListTitle>
              <Table>
                <HeaderRow>
                  <HeaderCell style={{ width: "60%" }}>HINNASTO</HeaderCell>
                  <HeaderCell style={{ width: "10%" }}>PÄIVITETTY VIIMEKSI</HeaderCell>
                  <HeaderCell style={{ width: "10%" }}>LÄHDE</HeaderCell>
                </HeaderRow>
                <TableBody>
                  {priceLists
                    .filter(({ baronaCompanyCode }) => baronaCompanyCode !== baronaCompany)
                    .map(({ description, priceListId, baronaCompanyCode, updatedAt }) => (
                      <PriceListRow
                        key={priceListId}
                        description={description}
                        priceListId={priceListId}
                        baronaCompanyCode={baronaCompanyCode}
                        currentBaronaCompanyCode={baronaCompany}
                        updatedAt={updatedAt}
                      />
                    ))}
                </TableBody>
              </Table>
            </PriceListContainer>
          )}
        </>
      )}
    </PriceListsContainer>
  );
};

export default PricelistsPage;
