import React from "react";
import Select from "./form/Select";
import { useBaronaCompany } from "../context";
import { BaronaCompany } from "@barona/lapa-common-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryParams } from "../hooks/useQueryParams";
import { companyMap } from "@barona/lapa-common-types";
type BaronaCompanyOptionType = { label: string; value: string };

const companyOptions: BaronaCompanyOptionType[] = Array.from(companyMap)
  .map(([value, label]) => ({ label: `${value} ${label}`, value }))
  .sort((a, b) => a.value.localeCompare(b.value, "fi", { numeric: true }));

const preserveQueryParamWhitelist = ["type"];

interface Props {
  className?: string;
  autoFocus?: boolean;
  width?: number;
}

const BaronaCompanySelect = (props: Props) => {
  const { baronaCompany, setBaronaCompany } = useBaronaCompany();
  const location = useLocation();
  const query = useQueryParams();
  const navigate = useNavigate();

  const locationPathParts = location.pathname.split("/");
  const queryParamsToPreserve = React.useMemo(
    () =>
      preserveQueryParamWhitelist.reduce((acc, param) => {
        const value = query.get(param);
        return value ? { ...acc, [param]: value } : acc;
      }, {} as Record<string, string>),
    [query]
  );

  return (
    <Select
      {...props}
      width={props.width ?? 150}
      options={companyOptions}
      value={companyOptions.find((companyOption) => companyOption.value === baronaCompany)}
      autoFocus={props.autoFocus}
      onChange={(value) => {
        setBaronaCompany(value?.value as BaronaCompany);

        // Navigate user to the root of current route branch when barona company is changed
        if (locationPathParts.length > 2) {
          const haveQueryParamsToPreseve = Object.keys(queryParamsToPreserve).length > 0;
          const queryParams = new URLSearchParams(queryParamsToPreserve);
          navigate(`/${locationPathParts[1]}${haveQueryParamsToPreseve ? `?${queryParams.toString()}` : ``}`);
        }
      }}
    />
  );
};

export default BaronaCompanySelect;
