import axios from "axios";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { CircleWithCross } from "@styled-icons/entypo/CircleWithCross";

import { companyMap } from "@barona/lapa-common-types";
import { Title } from "../../common/Title";
import PageContainer from "../../common/PageContainer";
import Dropdown from "../../../components/Dropdown";
import BaronaToggle from "../../../components/Toggle";
import { NotificationItem } from "../../common/Notifications";
import { NotificationState, useBaronaCompany } from "../../../context";
import Color from "../../../colors";
import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany } from "@barona/lapa-common-types";
import { CompanySettingResponse } from "@barona/lapa-common-api-definitions";

type BaronaCompanyOptionType = { label: string; value: string };

const companyOptions: BaronaCompanyOptionType[] = Array.from(companyMap)
  .map(([value, label]) => ({ label: `${value} ${label}`, value }))
  .sort((a, b) => a.value.localeCompare(b.value, "fi", { numeric: true }));

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-flow: column;
`;

const ToggleContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

const DropdownContainer = styled.div`
  width: 350px;
`;

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Pill = styled.div`
  padding: 5px;
  margin: 5px;
  min-width: 150px;
  background-color: ${Color.LIGHT_BLUE};
`;

const Icon = styled(CircleWithCross)`
  float: right;
  margin-left: 10px;
  height: 24px;
  width: 24px;
`;

const saveCompanySetting = async (companySetting: CompanySettingResponse) => {
  try {
    const { baronaCompanyCode } = companySetting;
    await frontendApi.getCompanySetting({ baronaCompanyCode });
  } catch (err: unknown) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      await frontendApi.createCompanySetting(companySetting);
    }
  }
};

const removeCompanySetting = async (baronaCompanyCode: BaronaCompany) => {
  await frontendApi.removeCompanySetting({ baronaCompanyCode });
};

const transformToCompanyName = (baronaCompanyCode: string) => companyMap.get(baronaCompanyCode as BaronaCompany);

const SettingsPage = () => {
  const { baronaCompany: baronaCompanyCode } = useBaronaCompany();
  const [companyList, setCompanyList] = useState([] as BaronaCompany[]);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const fetchCompanySetting = async () => {
      try {
        const companySetting = await frontendApi.getCompanySetting({ baronaCompanyCode });
        setIsChecked(true);
        setCompanyList(companySetting.usePricelistsFromOtherBaronaCompanies);
      } catch {
        setIsChecked(false);
        setCompanyList([]);
      }
    };
    fetchCompanySetting();
  }, [baronaCompanyCode]);

  const handleIsUsed = async (isUsed: boolean) => {
    if (isUsed) {
      await saveCompanySetting({ baronaCompanyCode, usePricelistsFromOtherBaronaCompanies: [] });
      setIsChecked(true);
      setCompanyList([]);
    } else {
      await removeCompanySetting(baronaCompanyCode);
      setIsChecked(false);
      setCompanyList([]);
    }
  };

  const handleAddCompany = async (item: BaronaCompanyOptionType | null) => {
    if (item) {
      await frontendApi.updateCompanySetting({
        baronaCompanyCode,
        usePricelistsFromOtherBaronaCompanies: [...companyList, item.value as BaronaCompany],
      });
      setCompanyList([...companyList, item.value as BaronaCompany]);
    }
  };

  const handleRemoveCompany = async (company: BaronaCompany) => {
    await frontendApi.updateCompanySetting({
      baronaCompanyCode,
      usePricelistsFromOtherBaronaCompanies: companyList.filter((item) => item !== company),
    });
    setCompanyList((companyList) => companyList.filter((item) => item !== company));
  };

  return (
    <PageContainer>
      <Title>Yleiset asetukset</Title>
      <Container>
        <div>
          <ToggleContainer>
            <span>Toisen yhtiön hinnastot käytössä </span>
            <BaronaToggle checked={isChecked} onChange={() => handleIsUsed(!isChecked)} />
          </ToggleContainer>
          {isChecked && (
            <>
              <DropdownContainer>
                <p>Valitse yhtiö *</p>
                <Dropdown
                  testId="Industry"
                  value={undefined}
                  options={companyOptions.filter(
                    (item) => item.value !== baronaCompanyCode && !companyList.includes(item.value as BaronaCompany)
                  )}
                  placeholder="Valitse"
                  onChange={(item) => handleAddCompany(item)}
                />
              </DropdownContainer>
              <PillContainer>
                {companyList.map((company) => (
                  <Pill key={company}>
                    {transformToCompanyName(company)} <Icon onClick={() => handleRemoveCompany(company)} />
                  </Pill>
                ))}
              </PillContainer>
            </>
          )}
        </div>
        <div>
          <NotificationItem
            canRemove={false}
            notification={{
              state: NotificationState.Info,
              text: `Toisen yhtiön hinnastot voi ottaa käyttöön, mikäli yhtiöiden välillä on edelleenlaskutusta tai hinnastot ovat muusta syystä samat. \n
              Toisen yhtiön hinnastot näkyvät asiakkaan alla omassa listassaan, mikäli niitä on asiakkaalle tehty.`,
              id: "guideText",
            }}
          />
        </div>
      </Container>
    </PageContainer>
  );
};

export default SettingsPage;
