import { ImportLogError, ImportLogErrorMetaItem, Serialized } from "@barona/lapa-common-types";

export const sortErrors = (errors: Serialized<ImportLogError>[]) => {
  const sortedErrors = [...errors];
  const rowErrors = [
    "ParsingError",
    "DuplicateItemError",
    "MissingInformationError",
    "MissingStaffingContractError",
    "UnableToUpdateError",
    "ForwardInvoicingError",
  ];
  const fileErrors = [
    "MepcoLineMissingColumnError",
    "MissingPriceListFromStaffingContractError",
    "MissingProductError",
    "MalformedMepcoFileError",
    "NoItemsFoundInMepcoFileError",
  ];
  sortedErrors.sort((a, b) => {
    if (fileErrors.includes(a.name) && !fileErrors.includes(b.name)) {
      return -1;
    }
    if (fileErrors.includes(b.name) && !fileErrors.includes(a.name)) {
      return 1;
    }
    if (rowErrors.includes(a.name) && rowErrors.includes(b.name)) {
      const aId = (a.metadata as ImportLogErrorMetaItem).itemId || "0";
      const bId = (b.metadata as ImportLogErrorMetaItem).itemId || "0";
      return aId.localeCompare(bId);
    }
    return 0;
  });
  return sortedErrors;
};
