import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import PageContainer from "../common/PageContainer";
import { Title } from "../common/Title";
import { Upload } from "@styled-icons/entypo/Upload";
import Color from "../../colors";
import head from "lodash/head";
import { frontendApi } from "../../services/api";
import Notifications, { NotificationItem } from "../common/Notifications";
import { useNotifications, NotificationState, useAuth, useOpenInvoicesCount } from "../../context";
import { ContentSeparator } from "../common/ContentSeparator";
import { useImportLogs } from "../../services/importLogs";
import ImportLogsTable from "./ImportLogsTable";
import CheckboxInput from "../../components/form/CheckboxInput";
import { useInterval } from "../../hooks/useInterval";
import { ImportLogStatus } from "@barona/lapa-common-types";
import { DEFAULT_PAGE_SIZE } from "../../components/Table.Pagination";
import SidePanel from "../../components/SidePanel";
import ImportLogErrorSidePanelContent from "./ImportLogErrorSidePanelContent";

const ChooseFile = styled.div`
  background-color: ${Color.LAPA_BLUE};
  padding: 8px 18px;
  margin-bottom: 10px;
  border-radius: 3px;
  color: ${Color.WHITE};
  display: inline-block;
  cursor: pointer;
`;

const UploadIcon = styled(Upload)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

const UploadInput = styled.input`
  display: none;
`;

const UploadLabel = styled.label``;

const CheckboxLabel = styled.label`
  display: inline-flex;
`;

const FILEINPUT_ID = "uploadItemCsv";

const ImportItemsPage = () => {
  const { addNotification } = useNotifications();
  const { user } = useAuth();

  const [isImportLogFiltered, setIsImportLogFiltered] = useState(true);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const { setRefreshIntervalTemporarily: setOpenInvoiceCountRefreshIntervalTemporarily } = useOpenInvoicesCount();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [sidePanelImportLogId, setSidePanelImportLogId] = useState("");
  const {
    importLogs,
    totalCount: totalImportLogsCount,
    isLoading,
    forceRefresh,
  } = useImportLogs({
    importedByFilter: isImportLogFiltered ? user?.email : undefined,
    limit: pageSize,
    skip: currentPage > 1 ? (currentPage - 1) * pageSize : 0,
  });

  useInterval(
    () => forceRefresh(),
    !isLoading &&
      importLogs.some(({ status }) => status === ImportLogStatus.Processing || status === ImportLogStatus.Uploaded)
      ? 10 * 1000
      : null
  );

  const uploadFile = async (file: File | undefined) => {
    if (file) {
      try {
        await frontendApi.importMepcoCsv({ fileName: file.name, content: await file.text() });
        addNotification(`Tiedoston lähetys onnistui (${file.name})`, NotificationState.Success);
        setOpenInvoiceCountRefreshIntervalTemporarily(10 * 1000, 2 * 60 * 1000);
      } catch (error) {
        const message =
          axios.isAxiosError(error) && error?.response?.status === 400
            ? `Tiedoston nimessä on kiellettyjä merkkejä. Sallitut merkit ovat a-z, A-Z, 0-9 sekä _ ja -.`
            : "";
        addNotification(`Tiedoston lähetys epäonnistui (${file.name}). ${message}`, NotificationState.Error);
      }

      forceRefresh();
    }
  };

  const openSidePanel = (importLogId: string) => {
    setSidePanelImportLogId(importLogId);
    setIsSidePanelOpen(true);
  };

  return (
    <PageContainer>
      <Title>Lataa tiedosto</Title>
      <Notifications />
      <UploadLabel htmlFor={FILEINPUT_ID}>
        <ChooseFile>
          <UploadIcon />
          Valitse tiedosto
        </ChooseFile>
      </UploadLabel>
      <UploadInput
        accept="text/csv"
        multiple={false}
        id={FILEINPUT_ID}
        type="file"
        onChange={(event) => {
          uploadFile(head(event.target.files));
          event.target.value = "";
        }}
      />
      <NotificationItem
        canRemove={false}
        notification={{
          state: NotificationState.Info,
          text: `Voit korvata aiemmin tuodun aineiston päivitetyllä aineistolla. Tällöin sekä avoimet tapahtumat että avoimet laskut päivittyvät. Jo Workdayhin lähetettyä materiaalia ei kuitenkaan voi tällä tavoin päivittää.`,
          id: "guideTextForCsvUpload",
        }}
      />
      <ContentSeparator />
      <Title>Lapaan ladattu aineisto</Title>
      <div>
        <CheckboxLabel>
          <CheckboxInput
            checked={isImportLogFiltered}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsImportLogFiltered(event.target.checked);
              setCurrentPage(1);
            }}
          />
          Näytä vain itse tuodut materiaalit
        </CheckboxLabel>
      </div>

      <ImportLogsTable
        importLogs={importLogs}
        isLoading={isLoading}
        refreshImportLogs={() => forceRefresh()}
        currentPage={currentPage}
        onSetCurrentPage={(page) => setCurrentPage(page)}
        pageSize={pageSize}
        onSetPageSize={(pageSize) => setPageSize(pageSize)}
        pageCount={Math.ceil(totalImportLogsCount / pageSize)}
        openSidePanel={openSidePanel}
      />
      <SidePanel isOpen={isSidePanelOpen} closeSidePanel={() => setIsSidePanelOpen(false)}>
        <ImportLogErrorSidePanelContent importLogId={sidePanelImportLogId} setIsOpen={setIsSidePanelOpen} />
      </SidePanel>
    </PageContainer>
  );
};

export default ImportItemsPage;
