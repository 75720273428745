import React, { useState, useEffect } from "react";
import SearchInput from "../components/form/SearchInput";
import Loading from "../components/Loading";
import { useBaronaCompany } from "../context";
import { useSearchCustomers } from "../services/searchCustomers";
import { LapaNavigationLink } from "../components/Link";
import PageContainer from "./common/PageContainer";
import { Cell, HeaderCell, HeaderRow, Row, Table, TableBody } from "../components/Table";
import { Title } from "./common/Title";
import { useDebounce } from "../hooks/useDebounce";

const CUSTOMER_SEARCH_FILTER_KEY = "customerSearchFilter";

const CustomersPage = () => {
  const [searchFilter, setSearchFilter] = useState("");
  const debouncedSearchFilter = useDebounce(searchFilter);
  const { baronaCompany } = useBaronaCompany();

  useEffect(() => setSearchFilter(sessionStorage.getItem(CUSTOMER_SEARCH_FILTER_KEY) || ""), []);
  useEffect(() => {
    sessionStorage.setItem(CUSTOMER_SEARCH_FILTER_KEY, debouncedSearchFilter ?? "");
  }, [debouncedSearchFilter]);

  const handleSearchFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchFilter(event.target.value);
  const { customers, isLoading } = useSearchCustomers(debouncedSearchFilter, baronaCompany);

  return (
    <PageContainer>
      <Title>Hae asiakkaita</Title>
      <SearchInput
        data-testid="SearchCustomers"
        value={searchFilter ?? ""}
        onChange={handleSearchFilterChange}
        placeholder="Nimi"
      />
      <Table>
        <HeaderRow>
          <HeaderCell style={{ width: "30%" }}>ASIAKAS</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>HINNASTOT</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>VIITTEET</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>LASKUTUSMALLIT</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>INVOICE INFOT</HeaderCell>
        </HeaderRow>
        <TableBody>
          {!isLoading &&
            customers.map((customer) => (
              <Row key={customer.customerId}>
                <Cell data-testid="CustomerName">
                  <LapaNavigationLink data-testid="CustomerNameLink" to={`${customer.customerId}/pricelists`}>
                    {`${customer.customerName}${customer.businessId ? ` (${customer.businessId})` : ""}`}
                  </LapaNavigationLink>
                </Cell>
                <Cell data-testid="PriceListCount">{customer.priceListsCount}</Cell>
                <Cell data-testid="ReferenceCount">{customer.referenceCount}</Cell>
                <Cell data-testid="InvoicingModelCount">{customer.invoicingModelCount}</Cell>
                <Cell data-testid="InvoiceInfoCount">{customer.invoiceInfoCount}</Cell>
              </Row>
            ))}
        </TableBody>
      </Table>
      {isLoading ? <Loading /> : null}
    </PageContainer>
  );
};

export default CustomersPage;
