import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany } from "@barona/lapa-common-types";
import { useQuery } from "react-query";

export type ReferencesResponse = typeof frontendApi.getReferences.ResponseType;
export const useReferences = ({
  baronaCompanyCode,
  customerId,
  hidden = false,
}: {
  baronaCompanyCode: BaronaCompany;
  customerId: string;
  hidden?: boolean;
}) => {
  const { data, isLoading, refetch } = useQuery(
    ["references", baronaCompanyCode, customerId, hidden],
    async () =>
      await frontendApi.getReferences({
        baronaCompanyCode,
        customerId,
        hidden,
      })
  );

  return { references: data || [], isLoading, forceRefresh: refetch };
};

type ReferenceBody = typeof frontendApi.addReference.BodyType;
export const addReference = async (reference: ReferenceBody) => {
  const newReference = await frontendApi.addReference(reference);
  return newReference;
};

export const hideReference = async (referenceId: string) => {
  const updatedReference = await frontendApi.updateReference({ referenceId, hidden: true });
  return updatedReference;
};

export const updateReference = async (
  referenceId: string,
  updatedValues: { name: string | null; isSelectableInHourReporting: boolean }
) => {
  const updatedReference = await frontendApi.updateReference({
    referenceId,
    ...updatedValues,
  });
  return updatedReference;
};
