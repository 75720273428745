import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany } from "@barona/lapa-common-types";
import { useQuery } from "react-query";

export const useCompanySettings = (baronaCompany: BaronaCompany) => {
  const { data, isLoading } = useQuery(
    ["companySettings", baronaCompany],
    async () => await frontendApi.getCompanySetting({ baronaCompanyCode: baronaCompany })
  );

  return { settings: data, isLoading };
};
