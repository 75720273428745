import styled from "styled-components";
import Color from "../../colors";
import { Button } from "../../components/Button";
import { ErrorWarning } from "@styled-icons/remix-fill";
import { Modal } from "../../components/Modal";
import { RemoveButton } from "../../components/RemoveButton";
import { ContentSeparator } from "../common/ContentSeparator";

const ModalTitle = styled.div`
  font-size: 20px;
`;

const ExclamationCircle = styled(ErrorWarning)`
  width: 24px;
  height: 24px;
  background-color: ${Color.WHITE};
  color: ${Color.LAPA_BLUE};
  margin-right: 10px;
`;

const ModalContent = styled.section`
  position: fixed;
  background: ${Color.WHITE};
  width: 500px;
  min-height: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const Container = styled.div`
  padding: 30px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const InfoText = styled.div`
  padding: 30px;
  color: ${Color.GREY_DARK};
`;

interface InvoiceActionModalProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  testId?: string;
  titleText: string;
  bodyText: string;
  cancelText?: string;
  actionText: string;
}

const InvoiceActionModal = ({
  show,
  onCancel,
  onConfirm,
  testId,
  titleText,
  bodyText,
  cancelText = "Peruuta",
  actionText,
}: InvoiceActionModalProps) => (
  <Modal show={show} testId={testId}>
    <ModalContent>
      <Container>
        <ModalTitle>
          <ExclamationCircle />
          {titleText}
        </ModalTitle>
        <InfoText>{bodyText}</InfoText>
        <ContentSeparator />
        <ButtonsContainer>
          <RemoveButton onClick={onCancel}>{cancelText}</RemoveButton>
          <Button onClick={onConfirm}>{actionText}</Button>
        </ButtonsContainer>
      </Container>
    </ModalContent>
  </Modal>
);

export default InvoiceActionModal;
