import { createContext, useContext } from "react";

export { useBaronaCompany } from "./BaronaCompany";
export { useOpenInvoicesCount } from "./OpenInvoicesCount";
export type { Notification } from "./Notifications";
export { useNotifications, NotificationState } from "./Notifications";

export interface AuthContextType {
  isAuthenticated: boolean;
  authFailed: boolean;
  user: { email: string } | null;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  authFailed: false,
  user: null,
});

export const useAuth = () => useContext(AuthContext);
