import { DimensionType } from "./dimensionTypes";

interface FrendsListApiSuccessfulResponse {
  success: true;
  message: string;
  category: string;
  list: FrendsCategoryValue[];
}

interface FrendsListApiFailedResponse {
  success: false;
  message: string;
  errors: string[];
}

export type FrendsListApiResponse = FrendsListApiFailedResponse | FrendsListApiSuccessfulResponse;

export interface FrendsFunctionCategoryValue {
  Function_ID: string;
  Descriptor: string;
  Active: boolean;
}
export interface FrendsRegionCategoryValue {
  Region_ID: string;
  Descriptor: string;
}
export interface FrendsServiceCategoryValue {
  Service_ID: string;
  Descriptor: string;
  Active: boolean;
}
export interface FrendsCostCenterCategoryValue {
  CostCenter_ID: string;
  Descriptor: string;
  Company_Restrictions: string[];
  Active: boolean;
}
export interface FrendsProjectCategoryValue {
  Workday_Project_ID: string;
  Project_Name: string;
  Description: string | null;
  Company_Reference_ID: string;
  Customer_Reference_ID: string;
  Inactive: string;
  Contract_ID: string;
}
export interface FrendsEmployeeCategoryValue {
  Employee_ID: string;
  Descriptor: string;
}

export type FrendsCategoryValue =
  | FrendsFunctionCategoryValue
  | FrendsRegionCategoryValue
  | FrendsServiceCategoryValue
  | FrendsCostCenterCategoryValue
  | FrendsProjectCategoryValue
  | FrendsEmployeeCategoryValue;

export const LAPA_DIMENSION_TO_FRENDS_CATEGORY = {
  [DimensionType.businessUnitD]: "function",
  [DimensionType.area]: "region",
  [DimensionType.service]: "service",
  [DimensionType.costPlace]: "costcenter",
  [DimensionType.project]: "project",
  [DimensionType.employee]: "employee",
} as const;
