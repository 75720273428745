import { getByText, findByText } from "@testing-library/react";
import { fireEvent } from "@testing-library/react";

export const delay = (delayMs: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, delayMs);
  });

export const inIFrame = () => window.self !== window.top;

const keyDownEvent = {
  key: "ArrowDown",
};

export const selectOption = async (container: HTMLElement, optionText: string, defaultText: string) => {
  const placeholder = getByText(container, defaultText);
  fireEvent.keyDown(placeholder, keyDownEvent);
  await findByText(container, optionText);
  fireEvent.click(getByText(container, optionText));
};

export const moveElementInArray = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  const newArray = [...array];
  const element = newArray[fromIndex];
  newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, element);
  return newArray;
};
