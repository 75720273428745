import React, { useEffect } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";
import Color from "../colors";
import { useAuth, useBaronaCompany, useOpenInvoicesCount } from "../context";
import { frontendApi } from "../services/api";
import { device } from "../util/breakpoints";

const Container = styled.nav`
  display: flex;
  align-items: center;
  background-color: ${Color.WHITE};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
`;

const Logo = styled.div`
  color: ${Color.LAPA_BLUE};
  font-family: "Helvetica Neue", "Lato";
  font-size: 48px;
  font-weight: 300;
  margin-right: 20px;
  user-select: none;
`;

const LinkContainer = styled.div`
  display: block;
`;

const NavLinkWithActiveSupport = (props: NavLinkProps) => (
  <NavLink {...props} className={({ isActive }) => `${props.className} ${isActive ? "active" : ""}`.trim()} />
);

const NavigationBarLink = styled(NavLinkWithActiveSupport)`
  font-size: 20px;
  text-decoration: none;
  color: ${Color.GREY};
  margin: 0 20px;
  padding-bottom: 4px;
  display: inline-flex;
  &.active {
    border-bottom: solid 4px ${Color.LAPA_BLUE};
    margin-top: 4px;
    color: ${Color.BLACK};
  }

  @media ${device.laptopL} {
    font-size: 16px;
  }
`;

const OpenInvoicesCountContainer = styled.div`
  border-radius: 180px;
  min-width: 24px;
  height: 24px;
  margin-left: 7px;
  padding: 0 6px;
  background: ${Color.LAPA_BLUE};
  color: white;
  text-align: center;
  font-size: 18px;
`;

const EmailContainer = styled.div`
  display: block;
  margin-left: auto;
`;

interface Props {
  mode?: "default" | "management";
}

const NavigationBar = (props: Props) => {
  const { mode = "default" } = props;
  const { user } = useAuth();
  const { openInvoicesCount, refresh: refreshOpenInvoices } = useOpenInvoicesCount();
  const { baronaCompany } = useBaronaCompany();

  useEffect(() => {
    refreshOpenInvoices();
  }, [refreshOpenInvoices, baronaCompany]);

  const handleEmailClick = async () => {
    try {
      console.log("Pinging frontend-api");
      const response = await frontendApi.ping();
      console.log(response);
    } catch (err) {
      console.error("Pinging frontend-api failed", err);
    }
  };

  return (
    <Container data-testid="NavigationBar">
      <Logo>LAPA</Logo>
      {mode === "management" ? (
        <>
          <LinkContainer>
            <NavigationBarLink to="/management/settings">Yleiset asetukset</NavigationBarLink>
          </LinkContainer>
          <LinkContainer>
            <NavigationBarLink to="/management/product-groups">Tuotteiden ryhmittely</NavigationBarLink>
          </LinkContainer>
        </>
      ) : (
        <>
          <LinkContainer>
            <NavigationBarLink to="/items">Laskuttamattomat tapahtumat</NavigationBarLink>
          </LinkContainer>
          <LinkContainer>
            <NavigationBarLink to="/invoices">
              Laskut<OpenInvoicesCountContainer>{openInvoicesCount}</OpenInvoicesCountContainer>
            </NavigationBarLink>
          </LinkContainer>
          <LinkContainer>
            <NavigationBarLink to="/customers">Asiakkaat</NavigationBarLink>
          </LinkContainer>
          <LinkContainer>
            <NavigationBarLink to="/import">Aineiston lataus</NavigationBarLink>
          </LinkContainer>
        </>
      )}
      <EmailContainer onClick={handleEmailClick}>{user?.email}</EmailContainer>
    </Container>
  );
};

export default NavigationBar;
