import styled from "styled-components";
import Color from "../../colors";
import { Button } from "../../components/Button";
import { ErrorWarning } from "@styled-icons/remix-fill";
import { Modal } from "../../components/Modal";
import { RemoveButton } from "../../components/RemoveButton";
import { useEffect, useState } from "react";

const ModalTitle = styled.div`
  font-size: 20px;
`;

const ExclamationCircle = styled(ErrorWarning)`
  width: 24px;
  height: 24px;
  background-color: ${Color.WHITE};
  color: ${Color.RED};
  margin-right: 10px;
`;

const ModalContent = styled.section`
  background: ${Color.WHITE};
  width: 500px;
  min-height: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const Container = styled.div`
  padding: 30px;
`;

const InfoText = styled.div`
  padding: 30px;
  color: ${Color.GREY_DARK};
`;

interface DeleteRowModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  show: boolean;
  title: string;
  body: React.ReactNode;
  confirmButtonText: string;
}

const DeleteRowModal = ({ show, onCancel, onConfirm, title, body, confirmButtonText }: DeleteRowModalProps) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    if (show) {
      setButtonDisabled(false);
    }
  }, [show]);
  return (
    <Modal show={show}>
      <ModalContent>
        <Container>
          <ModalTitle>
            <ExclamationCircle />
            {title}
          </ModalTitle>
          <InfoText>{body}</InfoText>
          <ButtonsContainer>
            <RemoveButton onClick={onCancel}>Peruuta</RemoveButton>
            <Button
              disabled={buttonDisabled}
              onClick={() => {
                setButtonDisabled(true);
                onConfirm();
              }}
            >
              {confirmButtonText}
            </Button>
          </ButtonsContainer>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default DeleteRowModal;
