import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { frontendApi } from "@barona/lapa-common-frontend";

const PricelistRedirector = () => {
  const { pricelistId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (pricelistId) {
      (async () => {
        try {
          const { customerId, priceListId } = await frontendApi.fetchPriceList({ priceListId: pricelistId });
          navigate(`/customers/${customerId}/pricelists/${priceListId}`);
        } catch (err) {
          navigate("/pricelist-not-found");
        }
      })();
    }
  }, [pricelistId, navigate]);

  return <div>Redirecting to correct pricelist...</div>;
};

export default PricelistRedirector;
