import React from "react";
import styled from "styled-components";
import { inputStyle } from "./shared-input-styles";

interface Props extends React.HTMLProps<HTMLInputElement> {
  type?: "number";
}

const StyledTextInput = styled.input`
  ${inputStyle}
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;

const NumberInput = (props: Props) => <StyledTextInput min={0} type="number" {...(props as any)} />;

export default NumberInput;
