import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import fi from "date-fns/locale/fi";
import Color from "../colors";
import { inputStyle } from "./form/shared-input-styles";
registerLocale("fi", fi);

interface DatePickerProps {
  selected?: Date;
  onChange: (date: Date) => void;
  placeholderText?: string;
  testId?: string;
  width?: number;
  id?: string;
}
interface RangePickerProps {
  startDate?: Date;
  endDate?: Date;
  onChange: (dates: [Date, Date]) => void;
  placeholderText?: string;
  testId?: string;
  id?: string;
}

const DatePickerContainer = styled.div<{ width?: number }>`
  display: inline-block;
  word-wrap: break-word;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: grid;
  }

  .react-datepicker__header {
    background-color: ${Color.WHITE};
    border-bottom: none;
  }

  .react-datepicker__input-container input {
    ${inputStyle}
    ${(props) =>
      props.width &&
      css`
        width: ${props.width}px;
      `}
  }

  .react-datepicker {
    color: ${Color.GREY_DARK};
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
  }

  .react-datepicker__day {
    border: 1px solid lightgrey;
    width: 40px;
    height: 33px;
    text-align: center;
    vertical-align: middle;
    color: ${Color.GREY_DARK};
    padding-top: 4px;
    padding-bottom: 0px;
  }

  .react-datepicker__day--selected {
    background-color: ${Color.LIGHTER_BLUE};
    border-radius: 0;
  }

  .react-datepicker__day-name {
    width: 37px;
  }

  .react-datepicker__day--keyboard-selected {
    color: ${Color.GREY};
    background-color: ${Color.LIGHTER_BLUE};
    border-radius: 0px;
  }

  .react-datepicker__day:hover {
    background-color: ${Color.GREY_LIGHTER};
    border-radius: 0px;
  }

  .react-datepicker__day {
    margin: 0px;
  }
`;

const convertUTCToLocalDate = (date?: Date) => {
  if (!date) {
    return date;
  }

  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

const convertLocalToUTCDate = (date?: Date | null | [Date | null, null | Date]) => {
  if (!date) {
    return date;
  }

  const convertDate = (date: Date | null) => {
    if (!date) return date;
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  };

  if (Array.isArray(date)) {
    return date.map(convertDate);
  } else {
    return convertDate(date);
  }
};

const Picker = (props: DatePickerProps) => (
  <DatePickerContainer width={props.width} data-testid={props.testId}>
    <DatePicker
      id={props.id}
      selected={convertUTCToLocalDate(props.selected)}
      onChange={(value) => props.onChange(convertLocalToUTCDate(value) as Date)}
      locale="fi"
      dateFormat="P"
      placeholderText={props.placeholderText}
    />
  </DatePickerContainer>
);

export const RangePicker = (props: RangePickerProps) => {
  const value = props.startDate
    ? `${convertUTCToLocalDate(props.startDate)!.toLocaleDateString("fi")} - ${
        props.endDate ? `${convertUTCToLocalDate(props.endDate)!.toLocaleDateString("fi")}` : ""
      }`
    : ``;

  return (
    <DatePickerContainer data-testid={props.testId}>
      <DatePicker
        id={props.id}
        startDate={convertUTCToLocalDate(props.startDate)}
        endDate={convertUTCToLocalDate(props.endDate)}
        onChange={(value) => props.onChange(convertLocalToUTCDate(value) as [Date, Date])}
        dateFormat="P"
        placeholderText={props.placeholderText}
        selectsRange
        isClearable
        shouldCloseOnSelect={false}
        value={value}
      />
    </DatePickerContainer>
  );
};

export default Picker;
