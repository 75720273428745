import { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useState } from "react";
import { v4 as uuid } from "uuid";

export enum NotificationState {
  Success,
  Info,
  Error,
}

export interface Notification {
  id: string;
  text: string;
  state: NotificationState;
}

export interface NotificationsContextType {
  notifications: Notification[];
  addNotification: (text: string, state?: NotificationState, children?: ReactNode) => void;
  removeNotification: (id: string) => void;
}

const NotificationsContext = createContext<NotificationsContextType>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
});

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = useCallback(
    (text: string, state: NotificationState = NotificationState.Info) => {
      setNotifications((notifications) => [
        ...notifications,
        {
          id: uuid(),
          text,
          state,
        },
      ]);
    },
    [setNotifications]
  );

  const removeNotification = useCallback(
    (id: string) => {
      setNotifications((notifications) => notifications.filter((notification) => notification.id !== id));
    },
    [setNotifications]
  );

  return (
    <NotificationsContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};
