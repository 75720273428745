import { FC, PropsWithChildren } from "react";
import styled, { ThemedCssFunction } from "styled-components";
import { useState } from "react";
import Color from "../colors";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";

interface ExpanderProps {
  title: React.ReactNode;
  testId?: string;
  className?: string;
  arrowIconCss?: ReturnType<ThemedCssFunction<any>>;
  contentCss?: ReturnType<ThemedCssFunction<any>>;
}

const ArrowIcon = styled(ArrowIosForwardOutline)<{ isOpen?: boolean; arrowIconCss?: ExpanderProps["arrowIconCss"] }>`
  height: 24px;
  margin-left: 4px;
  color: ${Color.BLACK};
  transform: rotate(${(props) => (props.isOpen ? "90deg" : "0")});
  transition: transform 0.2s ease-out;
  ${(props) => props.arrowIconCss}
`;

const TitleContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div``;

const ExpanderContent = styled.div<{ contentCss?: ExpanderProps["contentCss"] }>`
  ${(props) => props.contentCss}
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: ${Color.BLACK};
  margin: 0;
`;

const Expander: FC<PropsWithChildren<ExpanderProps>> = ({
  title,
  testId = "",
  children,
  className,
  arrowIconCss,
  contentCss,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className={className}>
      <TitleContainer
        data-testid={testId}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Title>{title}</Title>
        <ArrowIcon isOpen={isOpen} arrowIconCss={arrowIconCss} />
      </TitleContainer>
      <ExpanderContent>{isOpen ? children : null}</ExpanderContent>
    </Container>
  );
};

export default Expander;
