import { useState } from "react";
import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany, InvoiceGroupBy, IProblemJSON } from "@barona/lapa-common-types";
import { dateToISODate } from "@barona/lapa-common-date-utils";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";

export const createInvoice = ({
  baronaCompanyCode,
  customerId,
  rangeStart,
  rangeEnd,
  customerReferences,
  ourReference,
  useStaffingContractContactPersonAsOurReference,
  paymentGroup,
  paymentPeriod,
  invoiceDate,
  referenceOnInvoice,
  groupBy,
}: {
  baronaCompanyCode: BaronaCompany;
  customerId: string;
  rangeStart?: Date;
  rangeEnd?: Date;
  customerReferences?: (string | null)[];
  ourReference?: string;
  useStaffingContractContactPersonAsOurReference: boolean;
  paymentGroup?: string;
  paymentPeriod?: string;
  invoiceDate: Date;
  referenceOnInvoice?: string;
  groupBy: InvoiceGroupBy;
}) =>
  frontendApi.createInvoiceByFilters({
    filters: {
      baronaCompanyCode: baronaCompanyCode.toString(),
      customerId,
      rangeStart: rangeStart ? dateToISODate(rangeStart) : undefined,
      rangeEnd: rangeEnd ? dateToISODate(rangeEnd) : undefined,
      customerReferences,
      paymentGroup,
      paymentPeriod,
    },
    invoiceDate: dateToISODate(invoiceDate),
    referenceOnInvoice,
    ourReference,
    useStaffingContractContactPersonAsOurReference,
    groupBy,
  });

export const createMultipleInvoices = async ({
  baronaCompanyCode,
  customerId,
  rangeStart,
  rangeEnd,
  customerReferences,
  ourReference,
  useStaffingContractContactPersonAsOurReference,
  paymentGroup,
  paymentPeriod,
  invoiceDate,
  groupBy,
  onFailedInvoice,
  onSuccessfullInvoice,
}: {
  baronaCompanyCode: BaronaCompany;
  customerId: string;
  rangeStart?: Date;
  rangeEnd?: Date;
  customerReferences: (string | null)[];
  ourReference?: string;
  useStaffingContractContactPersonAsOurReference: boolean;
  paymentGroup?: string;
  paymentPeriod?: string;
  invoiceDate: Date;
  groupBy: InvoiceGroupBy;
  onFailedInvoice: (reference: string | null, errorMsg?: string) => void;
  onSuccessfullInvoice: (reference: string | null) => void;
}) => {
  const createdInvoices = [];
  for (const reference of customerReferences) {
    try {
      const invoice = await createInvoice({
        baronaCompanyCode,
        customerId,
        rangeStart,
        rangeEnd,
        customerReferences: [reference],
        ourReference,
        useStaffingContractContactPersonAsOurReference,
        paymentGroup,
        paymentPeriod,
        invoiceDate,
        referenceOnInvoice: reference ?? undefined,
        groupBy,
      });
      createdInvoices.push(invoice);
      onSuccessfullInvoice(reference);
    } catch (error) {
      const title = axios.isAxiosError(error) ? (error as AxiosError<IProblemJSON>)?.response?.data?.title : "";
      onFailedInvoice(reference, title);
    }
  }
  return createdInvoices;
};

export type InvoiceResponse = typeof frontendApi.getInvoice.ResponseType;
export const useInvoice = (invoiceId: string) => {
  const [invoiceNotFound, setInvoiceNotFound] = useState(false);

  const { data, isFetching, refetch } = useQuery(["invoice", invoiceId], async () => {
    try {
      setInvoiceNotFound(false);
      const invoice = await frontendApi.getInvoice({ invoiceId });
      return invoice;
    } catch (error) {
      setInvoiceNotFound(false);
      if (axios.isAxiosError(error) && error?.response?.status === 404) {
        setInvoiceNotFound(true);
      }
      return undefined;
    }
  });

  return { invoice: data, invoiceNotFound, isLoading: isFetching, forceRefresh: refetch };
};

export type InvoiceRowItemsResponse = typeof frontendApi.getInvoiceRowItems.ResponseType;
export const useInvoiceRowItems = (invoiceRowId: string) => {
  const { data, isLoading, refetch } = useQuery(
    ["invoiceRowItems", invoiceRowId],
    async () => await frontendApi.getInvoiceRowItems({ invoiceRowId })
  );

  return { items: data || [], isLoading, forceRefresh: refetch };
};
