import { frontendApi, ProductGroupItem, ProductGroupProductItem, ProductItem } from "@barona/lapa-common-frontend";
import Big from "big.js";
import {
  BaronaCompany,
  PriceList,
  PriceType,
  ProductGroupPricing,
  ProductGroupProductPricing,
  ProductPricing,
  Serialized,
} from "@barona/lapa-common-types";
import { useQuery } from "react-query";

export const useProductPricingsWithoutGroup = (priceListId: string, searchFilter?: string) => {
  const { data, isLoading } = useQuery(
    ["productPricings", priceListId, searchFilter],
    async () => {
      const productPricings = priceListId
        ? await frontendApi.fetchProductPricingsWithoutGroup({
            priceListId,
            searchFilter,
          })
        : [];
      return productPricings.map(mapProductPricingToProductItem);
    },
    { initialData: [] }
  );

  return { productPricings: data || [], isLoading };
};

export const updateProductPricings = async (
  priceListId: string,
  productItems: ProductItem[]
): Promise<ProductItem[]> => {
  const productPricings: ProductPricing[] = productItems.map(mapProductItemToProductPricing);

  const updatedProductPricings =
    (await frontendApi.updateProductPricings(
      productPricings.map((pp) => ({ ...pp, priceValue: pp.priceValue ? pp.priceValue.toJSON() : "" })),
      { priceListId }
    )) || [];
  return updatedProductPricings.map(mapProductPricingToProductItem);
};

export const createPriceList = async (
  priceList: Pick<PriceList, "customerId" | "baronaCompanyCode" | "description" | "type">,
  productItems: ProductItem[]
) => {
  const productPricings: ProductPricing[] = productItems.map(mapProductItemToProductPricing);
  const { productPricings: newProductPricings, ...rest } = await frontendApi.createPriceList({
    priceList,
    productPricings: productPricings.map((pp) => ({
      ...pp,
      priceValue: pp.priceValue ? pp.priceValue.toJSON() : "",
    })),
  });

  return {
    ...rest,
    productPricings: newProductPricings.map(mapProductPricingToProductItem),
  };
};

const mapProductPricingToProductItem = (productPricing: Serialized<ProductPricing>): ProductItem => ({
  ...productPricing,
  priceType: productPricing.priceType,
  priceValue: productPricing.priceValue ? String(productPricing.priceValue) : "",
});

const mapProductGroupPricingToProductItem = (
  productPricing: Serialized<ProductGroupProductPricing>
): ProductGroupProductItem => ({
  ...productPricing,
  priceSetThrough: productPricing.priceSetThrough,
  priceType: productPricing.priceType,
  priceValue: productPricing.priceValue ? String(productPricing.priceValue) : "",
});

const mapProductItemToProductPricing = (productItem: ProductItem): ProductPricing => {
  try {
    return {
      ...productItem,
      priceValue: productItem.priceValue ? Big(productItem.priceValue.replace(",", ".")) : null,
      priceType: productItem.priceType as PriceType,
    };
  } catch (error) {
    throw new Error(`Invalid price (${productItem.priceValue}) for product (${productItem.name})`);
  }
};

export const useProductGroupsPricings = (priceListId?: string, baronaCompany?: BaronaCompany) => {
  const { data, isLoading, refetch } = useQuery(
    ["productGroupPricings", priceListId, baronaCompany],
    async () => {
      const productGroupPricings = await frontendApi.fetchProductPricingsGroups({
        priceListId,
        baronaCompanyCode: baronaCompany,
      });
      return productGroupPricings.map(mapProductGroupPricingToProductGroupItem);
    },
    { initialData: [] }
  );

  return { productGroupPricings: data || [], isLoading, forceRefresh: refetch };
};

export const updateProductGroupPricings = async (
  priceListId: string,
  productItems: ProductGroupItem[]
): Promise<ProductGroupItem[]> => {
  const productGroupPricings: ProductGroupPricing[] = productItems.map(mapProductGroupItemToProductGroupPricing);

  const updatedProductGroupPricings = await frontendApi.updateProductGroupPrices({
    priceListId,
    productGroupPricings: productGroupPricings.map((pgp) => ({
      ...pgp,
      priceValue: pgp.priceValue ? pgp.priceValue.toJSON() : "",
    })),
  });

  return updatedProductGroupPricings.map(mapProductGroupPricingToProductGroupItem);
};

const mapProductGroupPricingToProductGroupItem = (
  productGroupPricing: Serialized<ProductGroupPricing>
): ProductGroupItem => ({
  ...productGroupPricing,
  priceType: productGroupPricing.priceType,
  priceValue: productGroupPricing.priceValue ? String(productGroupPricing.priceValue) : "",
});

const mapProductGroupItemToProductGroupPricing = (productGroupItem: ProductGroupItem): ProductGroupPricing => {
  /*if (!productGroupItem.priceValue) {
    throw new Error(`Price must be given to a group (${productGroupItem.name})`);
  }*/

  try {
    return {
      ...productGroupItem,
      priceValue: productGroupItem.priceValue ? Big(productGroupItem.priceValue.replace(",", ".")) : null,
      priceType: productGroupItem.priceType as PriceType,
    };
  } catch (error) {
    throw new Error(`Invalid price (${productGroupItem.priceValue}) for product group (${productGroupItem.name})`);
  }
};

export const useProductGroupProductPricings = (productGroupIds: string[], priceListId?: string) => {
  const { data, isLoading, refetch } = useQuery(
    ["productGroupProductPricings", productGroupIds, priceListId],
    async () => {
      const productGroupPricings = new Map<string, ProductGroupProductItem[]>();
      for (const productGroupId of productGroupIds) {
        const productGroupProductPricings = await frontendApi.fetchProductPricingForProductGroup({
          priceListId,
          productGroupId,
        });

        const prices = productGroupProductPricings.map(mapProductGroupPricingToProductItem);
        productGroupPricings.set(productGroupId, prices);
      }
      return productGroupPricings;
    },
    { initialData: new Map<string, ProductGroupProductItem[]>() }
  );

  return {
    productGroupProductPricings: data || new Map<string, ProductGroupProductItem[]>(),
    isLoading,
    forceRefresh: refetch,
  };
};
