import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany } from "@barona/lapa-common-types";
import { useQuery } from "react-query";

export const useSearchCustomers = (searchFilter: string, baronaCompany?: BaronaCompany) => {
  const withPricelists = searchFilter?.length <= 3;
  const { isLoading, data } = useQuery(["customers", searchFilter, baronaCompany], () =>
    frontendApi.searchCustomers({
      searchFilter: withPricelists ? "" : searchFilter,
      baronaCompany,
      withPricelists: withPricelists.toString(),
    })
  );
  return { customers: data || [], isLoading };
};
