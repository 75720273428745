import { useState } from "react";
import { ImportLogStatus, PersistedImportLog, Serialized } from "@barona/lapa-common-types";
import Loading from "../../components/Loading";
import { Table, HeaderRow, HeaderCell, Cell, TableBody, Row } from "../../components/Table";
import styled from "styled-components";
import Color from "../../colors";
import TablePagination from "../../components/Table.Pagination";
import { dateISOStringToLocaleDateString } from "@barona/lapa-common-date-utils";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";
import { Trash } from "@styled-icons/boxicons-regular/Trash";
import { frontendApi } from "../../services/api";
import DeleteRowModal from "../InvoicePage/DeleteRowModal";
import { NotificationState, useNotifications } from "../../context";

const ErrorCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 2px;
`;

const NoErrorsContainer = styled(ErrorCountContainer)``;

const RemovedErrorCountContainer = styled(ErrorCountContainer)`
  background-color: ${Color.GREY};
`;

const HasErrorsContainer = styled(ErrorCountContainer)`
  background-color: ${Color.RED};
`;

const GreyContainer = styled(ErrorCountContainer)`
  background-color: ${Color.GREY_LIGHT};
`;

const WhiteErrorCount = styled.div`
  flex: 0 auto;
  color: ${Color.WHITE};
`;

const GreyErrorCount = styled.div`
  flex: 0 auto;
  color: ${Color.GREY};
`;

const getErrorComponent = (errorCount: number, status: ImportLogStatus) => {
  const getContainer = (hasErrors: boolean, status: ImportLogStatus) => {
    if (hasErrors) {
      switch (status) {
        case ImportLogStatus.Deleted:
          return GreyContainer;
        case ImportLogStatus.Failed:
          return HasErrorsContainer;
        case ImportLogStatus.Processing:
        case ImportLogStatus.Succeeded:
        case ImportLogStatus.Uploaded:
          return RemovedErrorCountContainer;

        default: {
          const exhaustiveCheck: never = status;
          throw Error(`Not valid import log status: ${exhaustiveCheck}`);
        }
      }
    } else {
      return NoErrorsContainer;
    }
  };

  const getErrorCountElement = (hasErrors: boolean, status: ImportLogStatus) => {
    if (hasErrors) {
      switch (status) {
        case ImportLogStatus.Deleted:
        case ImportLogStatus.Failed:
          return WhiteErrorCount;

        case ImportLogStatus.Processing:
        case ImportLogStatus.Succeeded:
        case ImportLogStatus.Uploaded:
          return GreyErrorCount;

        default: {
          const exhaustiveCheck: never = status;
          throw Error(`Not valid import log status: ${exhaustiveCheck}`);
        }
      }
    } else {
      switch (status) {
        case ImportLogStatus.Deleted:
          return WhiteErrorCount;

        case ImportLogStatus.Failed:
        case ImportLogStatus.Processing:
        case ImportLogStatus.Succeeded:
        case ImportLogStatus.Uploaded:
          return GreyErrorCount;

        default: {
          const exhaustiveCheck: never = status;
          throw Error(`Not valid import log status: ${exhaustiveCheck}`);
        }
      }
    }
  };

  const hasErrors = errorCount > 0;
  const Container = getContainer(hasErrors, status);
  const ErrorCount = getErrorCountElement(hasErrors, status);

  return (
    <Container>
      <ErrorCount>{errorCount}</ErrorCount>
    </Container>
  );
};

const ArrowIcon = styled(ArrowIosForwardOutline)`
  height: 24px;
  color: ${Color.BLACK};
  cursor: pointer;
`;

const RemoveIcon = styled(Trash)`
  height: 18px;
  padding-right: 4px;
`;

const RemoveButton = styled.button<{ disabled: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-end;
  color: ${(props) => (props.disabled ? Color.GREY_LIGHT : Color.LAPA_BLUE)};
`;

const importStatuses = new Map<ImportLogStatus, string>([
  [ImportLogStatus.Deleted, "Poistettu"],
  [ImportLogStatus.Failed, "Epäonnistunut"],
  [ImportLogStatus.Processing, "Prosessoidaan"],
  [ImportLogStatus.Succeeded, "Onnistunut"],
  [ImportLogStatus.Uploaded, "Lähetetty"],
]);

interface ImportLogsTableProps {
  importLogs: Serialized<ReadonlyArray<PersistedImportLog>>;
  isLoading?: boolean;
  refreshImportLogs: () => void;
  currentPage: number;
  onSetCurrentPage: (page: number) => void;
  pageCount: number;
  pageSize: number;
  onSetPageSize: (pageSize: number) => void;
  openSidePanel: (importLogId: string) => void;
}

const ImportLogsTable = ({
  importLogs,
  isLoading,
  refreshImportLogs,
  currentPage,
  onSetCurrentPage,
  pageCount,
  pageSize,
  onSetPageSize,
  openSidePanel,
}: ImportLogsTableProps) => {
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [removeItemFilename, setRemoveItemFilename] = useState("");
  const { addNotification } = useNotifications();
  const showRemoveItemModal = (filename: string) => {
    setRemoveItemFilename(filename);
    setShowDeleteItemModal(true);
  };

  const removeItem = async () => {
    try {
      await frontendApi.removeImportedItems({ batchKey: removeItemFilename });
      refreshImportLogs();
      addNotification(`Aineisto poistettu`, NotificationState.Success);
    } catch (error) {
      addNotification(`Aineiston poistaminen epäonnistui`, NotificationState.Error);
    } finally {
      setRemoveItemFilename("");
      setShowDeleteItemModal(false);
    }
  };

  return (
    <>
      <Table>
        <HeaderRow>
          <HeaderCell style={{ width: "45%" }}>MATERIAALI</HeaderCell>
          <HeaderCell style={{ width: "13%" }}>AINEISTON TOI</HeaderCell>
          <HeaderCell>PVM</HeaderCell>
          <HeaderCell style={{ width: "5%" }}>VIRHEET</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>TILA</HeaderCell>
        </HeaderRow>
        <TableBody>
          {!isLoading &&
            importLogs.map((importLog) => (
              <Row key={importLog.importLogId} data-testid="ImportLogRow">
                <Cell>{importLog.fileName}</Cell>
                <Cell>{importLog.importedBy}</Cell>
                <Cell>{dateISOStringToLocaleDateString(importLog.createdAt)}</Cell>
                <Cell>{getErrorComponent(importLog.errorCount, importLog.status)}</Cell>
                <Cell>{importStatuses.get(importLog.status)}</Cell>
                <Cell>
                  <RemoveButton
                    disabled={!importLog.canRemoveImportedItems}
                    onClick={() => showRemoveItemModal(importLog.fileName)}
                  >
                    <RemoveIcon /> Poista aineisto
                  </RemoveButton>
                </Cell>
                <Cell>
                  {importLog.errorCount > 0 && (
                    <div onClick={() => openSidePanel(importLog.importLogId)}>{<ArrowIcon />}</div>
                  )}
                </Cell>
              </Row>
            ))}
        </TableBody>
      </Table>
      {isLoading && <Loading height={36} count={4} />}
      <TablePagination
        currentPage={currentPage}
        onSetCurrentPage={onSetCurrentPage}
        pageSize={pageSize}
        onSetPageSize={onSetPageSize}
        pageCount={pageCount}
      />
      <DeleteRowModal
        show={showDeleteItemModal}
        body={
          <div>
            <div>Aineisto ja kaikki siihen liittyvät tapahtumat poistetaan.</div>
            <br />
            <div>Lataa ne uudestaan, jos olikin virheliike</div>
          </div>
        }
        confirmButtonText="Poista aineisto"
        title="Aineisto poistetaan"
        onCancel={() => setShowDeleteItemModal(false)}
        onConfirm={removeItem}
      />
    </>
  );
};

export default ImportLogsTable;
