import Big from "big.js";
import { BaronaCompany } from "./baronaCompanyEnums";

import { PersistedEntry } from "./persistedTypes";
import { CustomerInfo, Product, ProductUnit } from "./pricingTypes";

export enum ItemSource {
  MEPCO = "mepco",
  FIDAWARE = "fidaware",
  MANUAL = "manual",
}

export interface MepcoItemAdditionalInfo {
  contactPersonA2Id: string;
  staffingContractId: string;
  employeeName: string;
  mepcoSalaryCode: string;
  paymentGroup: string;
  paymentPeriodStart: string;
  paymentPeriodEnd: string;
}

export interface FidawareItemAdditionalInfo {}

export interface SourceSpecific {
  source: ItemSource;
  additionalInfo: MepcoItemAdditionalInfo | FidawareItemAdditionalInfo;
}

export interface Item
  extends Pick<CustomerInfo, "baronaCompanyCode" | "customerId">,
    Pick<Product, "productCode">,
    SourceSpecific {
  itemId: string;
  itemDate: Date;
  customerReference: string | null;
  priceListId: string | null;
  amount: number;
  // TODO: Use Big for all monetary
  unitCost: number | null;
  customerSiteId: string | null;
  costPlaceDimension: string;
  businessUnitDimension: string;
  areaDimension: string;
  serviceDimension: string;
  contractNumberDimension: string | null;
  employeeNumberDimension: string | null;
  projectDimension: string | null;
  updatedByUser: boolean;
  hasManualItems: boolean;
}

export interface MepcoItem extends Item {
  source: ItemSource.MEPCO;
  contractNumberDimension: string;
  employeeNumberDimension: string;
  additionalInfo: MepcoItemAdditionalInfo;
}

export const isMepcoItem = (item: Item | PricedItem): item is MepcoItem => item.source === ItemSource.MEPCO;

export interface MepcoSource extends Pick<MepcoItem, "source" | "additionalInfo"> {}

export const isMepcoSource = (item: SourceSpecific): item is MepcoSource => item.source === ItemSource.MEPCO;

export const isManualSource = (item: SourceSpecific): item is MepcoSource => item.source === ItemSource.MANUAL;

export const isEveryItemMepcoItem = (items: PersistedItem[]): items is (PersistedItem & MepcoSource)[] =>
  items.every(isMepcoSource);

export interface FidawareItem extends Item {
  source: ItemSource.FIDAWARE;
  additionalInfo: FidawareItemAdditionalInfo;
  projectDimension: null;
}

export const isFidawareItem = (item: Item): item is FidawareItem => item.source === ItemSource.FIDAWARE;

export interface PricedItem extends Omit<Item, "priceListId" | "unitCost"> {
  priceListId: string;
  productName: string;
  productUnit: Product["unit"] | null;
  price: Big | null;
  originalUnitCost: number | null;
  billable: boolean;
  pricedAt: Date | null;
}

export interface UpdatedPricedItem
  extends Pick<
    PricedItem,
    | "itemId"
    | "price"
    | "costPlaceDimension"
    | "businessUnitDimension"
    | "areaDimension"
    | "serviceDimension"
    | "contractNumberDimension"
    | "projectDimension"
    | "productCode"
    | "productName"
    | "productUnit"
    | "source"
  > {
  amount: string | null;
  employeeNumberDimension: string;
  employeeName: string;
}

export interface PersistedItem extends PersistedEntry, PricedItem {
  batchKey: string;
}

export interface ItemRow extends PersistedEntry {
  item_id: string;
  item_date: Date;
  barona_company_code: string;
  customer_id: string;
  customer_reference: string;
  price_list_id: string;
  batch_key: string;
  product_code: string;
  product_name: string;
  product_unit: string | null;
  original_unit_cost: string | null;
  amount: string;
  price: string | null;
  billable: boolean;
  customer_site_id: string | null;
  cost_place_dimension: string;
  business_unit_dimension: string;
  area_dimension: string;
  service_dimension: string;
  contract_number_dimension: string | null;
  employee_number_dimension: string | null;
  project_dimension: string | null;
  source: ItemSource;
  additional_info: any;
  priced_at: Date | null;
  updated_by_user: boolean;
}

export const mapItemRow = (row: ItemRow): PersistedItem => ({
  id: row.id,
  itemId: row.item_id,
  itemDate: row.item_date,
  batchKey: row.batch_key,
  baronaCompanyCode: row.barona_company_code as BaronaCompany,
  customerId: row.customer_id,
  customerReference: row.customer_reference,
  priceListId: row.price_list_id,
  productCode: row.product_code,
  productName: row.product_name,
  originalUnitCost: row.original_unit_cost ? Big(row.original_unit_cost).toNumber() : null,
  productUnit: row.product_unit as ProductUnit,
  amount: Number.parseFloat(row.amount),
  price: row.price ? Big(row.price) : null,
  billable: row.billable,
  customerSiteId: row.customer_site_id,
  costPlaceDimension: row.cost_place_dimension,
  businessUnitDimension: row.business_unit_dimension,
  areaDimension: row.area_dimension,
  serviceDimension: row.service_dimension,
  contractNumberDimension: row.contract_number_dimension,
  employeeNumberDimension: row.employee_number_dimension,
  projectDimension: row.project_dimension,
  source: row.source,
  additionalInfo: row.additional_info,
  pricedAt: row.priced_at,
  updatedByUser: row.updated_by_user,
  hasManualItems: row.source === ItemSource.MANUAL,
});
