import React from "react";
import styled from "styled-components";

interface Props extends React.HTMLProps<HTMLInputElement> {
  type?: "checkbox";
}

const StyledCheckboxInput = styled.input`
  width: 15px;
  height: 15px;
`;

const CheckboxInput = (props: Props) => <StyledCheckboxInput type="checkbox" {...(props as any)} />;

export default CheckboxInput;
