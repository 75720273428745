import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Color from "../colors";
import { useBaronaCompany } from "../context";
import BaronaCompanySelect from "./BaronaCompanySelect";
import { companyMap } from "@barona/lapa-common-types";
import { ArrowLeftRight } from "@styled-icons/remix-fill/ArrowLeftRight";

const Container = styled.div`
  width: 1200px;
  min-height: 80px;
  margin: 20px auto 10px auto;
  padding: 10px 25px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: ${Color.WHITE};
  display: flex;
  align-items: center;
`;

const SelectContainer = styled.div`
  width: 250px;
`;

const BaronaCompanyTitle = styled.h2`
  font-weight: normal;
  color: ${Color.BLACK};
  display: inline-block;
  margin: 0;
  padding: 0;
`;

const ButtonContainer = styled.div`
  height: 21px;
`;

const ChangeBaronaCompanyButton = styled.button`
  font-size: 14px;
  background: none;
  border: none;
  color: ${Color.LAPA_BLUE};
  cursor: pointer;
  padding: 0;
  margin: 5px 0 0 24px;
`;

const ArrowLeftRightIcon = styled(ArrowLeftRight)`
  color: ${Color.LAPA_BLUE};
  height: 18px;
  margin-right: 6px;
`;

const BaronaCompanySection = () => {
  const { baronaCompany } = useBaronaCompany();
  const [isSelectingBaronaCompany, setIsSelectingBaronaCompany] = useState<boolean>(false);

  const baronaCompanyName = companyMap.get(baronaCompany);

  useEffect(() => {
    setIsSelectingBaronaCompany(false);
  }, [baronaCompany]);

  return (
    <Container>
      {isSelectingBaronaCompany ? (
        <SelectContainer>
          <BaronaCompanySelect autoFocus width={250} />
        </SelectContainer>
      ) : (
        <>
          <BaronaCompanyTitle>{`${baronaCompany} ${baronaCompanyName}`}</BaronaCompanyTitle>
          <ButtonContainer>
            <ChangeBaronaCompanyButton onClick={() => setIsSelectingBaronaCompany(true)}>
              <ArrowLeftRightIcon />
              Vaihda
            </ChangeBaronaCompanyButton>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default BaronaCompanySection;
