import Big from "big.js";

import { BaronaCompany } from "./baronaCompanyEnums";
import { PersistedEntry } from "./persistedTypes";

export enum PriceType {
  Multiplier = "multiplier",
  Fixed = "fixed",
}

export enum ProductUnit {
  PCS = "pcs",
  H = "h",
}

export enum PriceListType {
  Mepco = "mepco",
  Fidaware = "fidaware",
}

export enum ProductType {
  Mepco = "mepco",
  Fidaware = "fidaware",
  Manual = "manual",
}

export enum PriceSetThrough {
  ProductGroup = "product_group",
  Manually = "manually",
}

export interface Product {
  productCode: string;
  name: string;
  unit: ProductUnit | null;
  type: ProductType;
}

export interface CustomerPriceListSum {
  customerId: string;
  customerName?: string;
  baronaCompanyCode?: BaronaCompany;
  priceListsCount: number;
}

export interface CustomerInfo {
  baronaCompanyCode: BaronaCompany;
  customerId: string;
  customerName: string;
}

export interface PriceList extends PersistedEntry, CustomerInfo {
  priceListId: string;
  description: string | null;
  hidden: boolean;
  employeeProfileId?: string;
  type: PriceListType;
  validFrom: string;
  validTo: string | null;
  usedByOtherBaronaCompanies: BaronaCompany[];
  updatedAt: Date | null;
  createdAt: Date | null;
}

export interface ProductPricing extends Pick<PriceList, "priceListId">, Pick<Product, "productCode" | "name" | "unit"> {
  priceType: PriceType;
  priceValue: Big | null;
  billable: boolean;
  priceSetThrough: PriceSetThrough;
}

export interface ProductGroupProductPricing {
  priceType?: PriceType;
  priceValue: Big | null;
  billable: boolean;
  priceSetThrough?: PriceSetThrough;
  priceListId: string;
  productCode: string;
  name: string;
  unit: ProductUnit | null;
}

export interface ProductGroupPricing extends Omit<ProductPricing, "productCode" | "unit" | "priceSetThrough"> {
  productGroupId: string;
}

export interface PriceListProductHistory extends PersistedEntry {
  priceListId: number;
  productId: number;
  productCode: string;
  operation: string;
  newValue: Big | null;
  oldValue: Big | null;
  name: string;
  createdAt: Date;
}
