import styled from "styled-components";
import Color from "../colors";
import { Button } from "./Button";
import { ErrorWarning } from "@styled-icons/remix-fill";
import { Modal } from "./Modal";
import { RemoveButton } from "./RemoveButton";
import { ContentSeparator } from "../pages/common/ContentSeparator";

const Title = styled.div`
  font-size: 20px;
`;

const ExclamationCircle = styled(ErrorWarning)`
  width: 24px;
  height: 24px;
  background-color: ${Color.WHITE};
  color: ${Color.RED};
  margin-right: 10px;
`;

const ModalContent = styled.section`
  position: fixed;
  background: ${Color.WHITE};
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const Container = styled.div`
  padding: 30px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const InfoText = styled.div`
  padding: 30px;
  color: ${Color.GREY_DARK};
`;

interface Props {
  show: boolean;
  onCancel: () => void;
  testId?: string;
}

const VersionChangedModal = ({ show, onCancel, testId }: Props) => (
  <Modal show={show} testId={testId}>
    <ModalContent>
      <Container>
        <Title>
          <ExclamationCircle />
          LAPA on päivittynyt!
        </Title>
        <InfoText>
          LAPAsta on julkaistu uusi versio. Mahdollisten ongelmien välttämiseksi suosittelemme vahvasti, että päivität
          LAPAn klikkaamalla "Päivitä LAPA" tai lataamalla sivun uudelleen selaimessa.
        </InfoText>
        <ContentSeparator />
        <ButtonsContainer>
          <RemoveButton
            onClick={() => {
              onCancel();
            }}
          >
            Peruuta
          </RemoveButton>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Päivitä LAPA
          </Button>
        </ButtonsContainer>
      </Container>
    </ModalContent>
  </Modal>
);

export default VersionChangedModal;
