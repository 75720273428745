import { frontendApi, ProductItem } from "@barona/lapa-common-frontend";
import { BaronaCompany, PriceSetThrough, ProductType } from "@barona/lapa-common-types";
import sortBy from "lodash/sortBy";
import { useQuery } from "react-query";

export const useProducts = (productTypes: ProductType[]) => {
  const { data, isLoading, refetch } = useQuery(
    ["products", productTypes],
    async () => {
      const products = await frontendApi.fetchProducts({
        productTypes,
      });

      if (!products) {
        return [];
      }

      const productItems: ProductItem[] = products.map((product) => ({
        name: product.name,
        productCode: product.productCode,
        billable: true,
        priceListId: "",
        priceType: "",
        priceValue: "",
        unit: product.unit,
        priceSetThrough: PriceSetThrough.Manually,
        productType: product.type,
      }));
      return sortBy(productItems, (product) => product.name);
    },
    { initialData: [] }
  );

  return { products: data || [], isLoading, forceRefresh: refetch };
};

export const useProductGroups = (baronaCompanyCode?: BaronaCompany) => {
  const { data, isLoading, refetch } = useQuery(
    ["productGroups", baronaCompanyCode],
    async () => {
      if (!baronaCompanyCode) {
        return [];
      }
      const groups = await frontendApi.getProductPriceGroups({ baronaCompanyCode });
      return sortBy(groups, (group) => group.sortNumber);
    },
    { initialData: [] }
  );

  return { productGroups: data || [], isLoading, forceRefresh: refetch };
};
