interface FrontendConfig {
  apiBaseUrl: string;
  adClientId: string;
  useMockAuthentication: boolean;
  S2BaseUrl: string;
}

const configs: { [env: string]: FrontendConfig } = {
  test: {
    apiBaseUrl: "http://frontend-test",
    adClientId: "",
    useMockAuthentication: true,
    S2BaseUrl: "https://s2-staging.baronatest.fi",
  },
  e2etest: {
    apiBaseUrl: "http://localhost:6001",
    adClientId: "",
    useMockAuthentication: true,
    S2BaseUrl: "https://s2-staging.baronatest.fi",
  },
  dev: {
    apiBaseUrl: "http://localhost:6001",
    adClientId: "b255af6c-0d7a-4d62-b861-b39f25395ecc",
    useMockAuthentication: false,
    S2BaseUrl: "https://s2-staging.baronatest.fi",
  },
  stage: {
    apiBaseUrl: "https://api.lapa.baronatest.fi",
    adClientId: "2c29577f-35f2-4e22-871f-9b1e8930a5ae",
    useMockAuthentication: false,
    S2BaseUrl: "https://s2-staging.baronatest.fi",
  },
  prod: {
    apiBaseUrl: "https://api.lapa.barona.fi",
    adClientId: "70a6a775-3b2a-4163-a5fc-b8d61ac3ed98",
    useMockAuthentication: false,
    S2BaseUrl: "https://s2.barona.fi",
  },
};

const getConfig = (env?: string) => {
  if (!env) throw new Error("No env provided");
  const config = configs[env];
  if (!config) throw new Error(`No config found with env=${env}`);
  return config;
};

export default getConfig(process.env.REACT_APP_ENV);
