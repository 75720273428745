import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import InvoiceList from "./InvoiceList";
import {
  useInvoiceCustomers,
  useDraftInvoicePeriods,
  useDraftInvoiceReferences,
  useInvoices,
} from "../../services/invoices";
import { useBaronaCompany, useNotifications, NotificationState, useOpenInvoicesCount } from "../../context";
import InvoicesSummary from "./InvoicesSummary";
import Select from "../../components/form/Select";
import { Button } from "../../components/Button";
import { SecondaryButton } from "../../components/SecondaryButton";
import { ContentSeparator } from "../common/ContentSeparator";
import { dateToLocaleDateString } from "@barona/lapa-common-date-utils";
import { frontendApi } from "../../services/api";
import { InvoiceStatus } from "@barona/lapa-common-types";
import { useInterval } from "../../hooks/useInterval";

const InvoiceFilterContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

const SelectContainer = styled.div`
  width: 250px;
`;

const SelectLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 8px;
  margin-top: 18px;
`;

const draftAndInTransitStatuses = [InvoiceStatus.draft, InvoiceStatus.inTransit];

const DraftInvoices = ({ refresh }: { refresh: boolean }) => {
  const { baronaCompany } = useBaronaCompany();

  const [customer, setCustomer] = useState<{ label: string; value: string } | null>(null);
  const [reference, setReference] = useState<{ label: string; value: string | null } | null>(null);
  const [period, setPeriod] = useState<{ label: string; value: { periodStart: Date; periodEnd: Date } } | null>(null);
  const [isSummaryOpen, setIsSummaryOpen] = useState<boolean>(false);

  const {
    invoices,
    isLoading: isLoadingInvoices,
    forceRefresh: forceRefreshDraftInvoices,
  } = useInvoices(
    {
      baronaCompany,
      customerId: customer?.value,
      customerReference: reference?.value,
      periodStart: period?.value.periodStart,
      periodEnd: period?.value.periodEnd,
      statuses: draftAndInTransitStatuses,
    },
    { limit: 1000, skip: 0 }
  );

  const {
    invoiceCustomers,
    isLoading: isLoadingInvoiceCustomers,
    forceRefresh: forceRefreshCustomers,
  } = useInvoiceCustomers({
    baronaCompany,
    customerReference: reference?.value,
    periodStart: period?.value.periodStart,
    periodEnd: period?.value.periodEnd,
    statuses: draftAndInTransitStatuses,
  });
  const {
    invoiceReferences,
    isLoading: isLoadingInvoiceReferences,
    forceRefresh: forceRefreshReferences,
  } = useDraftInvoiceReferences({
    baronaCompany,
    customerId: customer?.value,
    periodStart: period?.value.periodStart,
    periodEnd: period?.value.periodEnd,
  });
  const {
    invoicePeriods,
    isLoading: isLoadingInvoicePeriods,
    forceRefresh: forceRefreshPeriods,
  } = useDraftInvoicePeriods({
    baronaCompany,
    customerId: customer?.value,
    customerReference: reference?.value,
  });

  useInterval(
    () => forceRefreshDraftInvoices(),
    !isLoadingInvoices && invoices.some(({ status }) => status === InvoiceStatus.inTransit) ? 15 * 1000 : null
  );

  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);
  const { addNotification } = useNotifications();
  const { refresh: refreshOpenInvoicesCount } = useOpenInvoicesCount();

  useEffect(() => {
    setSelectedInvoiceIds([]);
  }, [baronaCompany, customer, reference, period]);

  useEffect(() => {
    if (refresh === true) {
      forceRefreshDraftInvoices();
    }
  }, [refresh, forceRefreshDraftInvoices]);

  const handleOnInvoicesOnSelect = (selectedInvoiceIds: string[]) => {
    setSelectedInvoiceIds(selectedInvoiceIds);
  };

  const handleOnClickSendInvoicesToNav = async () => {
    await frontendApi.sendInvoicesToNavQueue({ invoiceIds: selectedInvoiceIds });
    const notificationText = `${selectedInvoiceIds.length} laskua pyydettiin lähettämään Workdayhin.`;

    addNotification(notificationText, NotificationState.Success);

    setSelectedInvoiceIds([]);

    forceRefreshDraftInvoices();
    forceRefreshCustomers();
    forceRefreshReferences();
    forceRefreshPeriods();
    refreshOpenInvoicesCount();
  };

  const draftInvoiceIds = useMemo(() => invoices.map((invoice) => invoice.invoiceId), [invoices]);
  return (
    <div>
      <Container>
        <InvoiceFilterContainer>
          <SelectContainer>
            <SelectLabel>Asiakas</SelectLabel>
            <Select
              noOptionsMessage={() => "Ei asiakkaita"}
              placeholder="Valitse asiakas"
              isLoading={isLoadingInvoiceCustomers}
              options={invoiceCustomers.map(({ id, name }) => ({ label: name, value: id }))}
              onChange={(value) => setCustomer(value)}
              value={customer}
              isClearable
            />
          </SelectContainer>
          <SelectContainer>
            <SelectLabel>Viite</SelectLabel>
            <Select
              noOptionsMessage={() => "Ei viitteitä"}
              placeholder="Valitse viite"
              isLoading={isLoadingInvoiceReferences}
              options={invoiceReferences.map(({ content }) => ({
                label: content === null ? "Tyhjä" : content,
                value: content,
              }))}
              onChange={(value) => setReference(value)}
              value={reference}
              isClearable
            />
          </SelectContainer>
          <SelectContainer>
            <SelectLabel>Laskutuskausi</SelectLabel>
            <Select
              noOptionsMessage={() => "Ei laskutuskausia"}
              placeholder="Valitse laskutuskausi"
              isLoading={isLoadingInvoicePeriods}
              options={invoicePeriods.map((value) => ({
                label: `${dateToLocaleDateString(value.periodStart)} – ${dateToLocaleDateString(value.periodEnd)}`,
                value,
              }))}
              onChange={(value) => setPeriod(value)}
              value={period}
              isClearable
            />
          </SelectContainer>
        </InvoiceFilterContainer>
        <ButtonsContainer>
          <SecondaryButton
            data-testid="InvoicesSummaryButton"
            onClick={() => {
              setIsSummaryOpen(!isSummaryOpen);
            }}
          >
            Täsmäytystiedot
          </SecondaryButton>
          <Button
            disabled={selectedInvoiceIds.length === 0}
            data-testid="SendInvoicesToNav"
            onClick={handleOnClickSendInvoicesToNav}
          >
            Valitut laskut Workdayhin
          </Button>
        </ButtonsContainer>
      </Container>
      <InvoiceList
        invoices={invoices}
        isLoading={isLoadingInvoices}
        handleOnInvoicesOnSelect={handleOnInvoicesOnSelect}
        selectedInvoiceIds={selectedInvoiceIds}
        type="draft"
      />
      <ContentSeparator />
      <InvoicesSummary
        invoiceIds={selectedInvoiceIds.length ? selectedInvoiceIds : draftInvoiceIds}
        hasSelectedIds={!!selectedInvoiceIds.length}
        isLoadingInvoices={isLoadingInvoices}
        customerId={customer?.value}
        customerReference={reference?.value}
        period={period?.value}
        isOpen={isSummaryOpen}
        setIsOpen={setIsSummaryOpen}
      />
    </div>
  );
};

export default DraftInvoices;
