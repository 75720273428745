import React, { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { Routes, Route, Navigate, BrowserRouter, useMatch } from "react-router-dom";
import ItemsPage from "./pages/ItemsPage/ItemsPage";
import InvoicesPage from "./pages/InvoicesPage/InvoicesPage";
import CustomersPage from "./pages/CustomersPage";
import CustomerPage from "./pages/CustomerPage";
import PricelistsPage from "./pages/PricelistsPage";
import PricelistPage from "./pages/PricelistPage/PricelistPage";
import ReferencesPage from "./pages/ReferencesPage/ReferencesPage";
import InvoicingModelsPage from "./pages/InvoicingModelsPage/InvoicingModelsPage";
import InvoicingModelPage from "./pages/InvoicingModelPage/InvoicingModelPage";
import NotFoundPage from "./pages/NotFoundPage";
import NavigationBar from "./components/NavigationBar";
import AuthProvider from "./components/AuthProvider";
import Color from "./colors";
import InvoicePage from "./pages/InvoicePage/InvoicePage";
import ImportItemsPage from "./pages/ImportItemsPage/ImportItemsPage";
import PricelistRedirector from "./pages/PricelistPage/PricelistRedirector";
import ProductGroupsPage from "./pages/management/ProductGroupsPage/ProductGroupsPage";
import SettingsPage from "./pages/management/SettingsPage/SettingsPage";
import ProductManagementPage from "./pages/management/ProductManagementPage/ProductManagementPage";
import { NotificationsProvider } from "./context/Notifications";
import { OpenInvoicesCountProvider } from "./context/OpenInvoicesCount";
import BaronaCompanySection from "./components/BaronaCompanySection";
import { BaronaCompanyContextProvider } from "./context/BaronaCompany";
import { QueryClient, QueryClientProvider } from "react-query";
import { configureFrontendApi } from "./services/api";
import { LAPA_FRONTEND_API_VERSION, LAPA_FRONTEND_API_VERSION_HEADER } from "@barona/lapa-common-frontend";
import VersionChangedModal from "./components/VersionChangedModal";
import PriceListHistoryPage from "./pages/PricelistPage/PriceListHistoryPage";
import { fiLocale, setDefaultLocale } from "@barona/lapa-common-date-utils";

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${Color.GREY_LIGHTEST};
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

setDefaultLocale(fiLocale);

export const App = () => {
  const isManagementRoute = useMatch("/management/*");
  const [showVersionPopup, setShowVersionPopup] = useState(false);
  const [versionPopupDismissed, setVersionPopupDismissed] = useState(false);

  useEffect(() => {
    const CURRENT_FRONTEND_VERSION = LAPA_FRONTEND_API_VERSION;
    configureFrontendApi((response) => {
      const CURRENT_FRONTEND_API_VERSION = Number(response.headers[LAPA_FRONTEND_API_VERSION_HEADER]);
      if (!CURRENT_FRONTEND_API_VERSION) return;

      if (CURRENT_FRONTEND_VERSION < CURRENT_FRONTEND_API_VERSION) {
        if (!versionPopupDismissed && !showVersionPopup) {
          console.log(
            `Current frontend version is ${CURRENT_FRONTEND_VERSION} but API is version ${CURRENT_FRONTEND_API_VERSION}, ask user to refresh.`
          );
          setShowVersionPopup(true);
        }
      }
    });
  }, [versionPopupDismissed, showVersionPopup]);

  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <NotificationsProvider>
            <BaronaCompanyContextProvider>
              <OpenInvoicesCountProvider>
                <NavigationBar mode={isManagementRoute ? "management" : "default"} />
                <BaronaCompanySection />
                <Routes>
                  <Route path="/" element={<Navigate to="/items" />} />
                  <Route path="/items" element={<ItemsPage />} />
                  <Route path="/invoices">
                    <Route index element={<InvoicesPage />} />
                    <Route path=":invoiceId" element={<InvoicePage />} />
                  </Route>
                  <Route path="/customers">
                    <Route index element={<CustomersPage />} />
                    <Route path=":customerId" element={<CustomerPage />}>
                      <Route index element={<Navigate to="pricelists" />} />
                      <Route path="pricelists">
                        <Route index element={<PricelistsPage />} />
                        <Route path="new" element={<PricelistPage new />} />
                        <Route path=":pricelistId" element={<PricelistPage />} />
                      </Route>
                      <Route path="references" element={<ReferencesPage />} />
                      <Route path="invoicing-models">
                        <Route index element={<InvoicingModelsPage />} />
                        <Route path="new" element={<InvoicingModelPage new />} />
                        <Route path=":invoicingModelId" element={<InvoicingModelPage />} />
                      </Route>
                      <Route path="pricelisthistory">
                        <Route path=":pricelistId" element={<PriceListHistoryPage />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="/import" element={<ImportItemsPage />} />
                  <Route path="/pricelists/:pricelistId" element={<PricelistRedirector />} />
                  <Route path="/management">
                    <Route index element={<Navigate to="settings" />} />
                    <Route path="product-groups" element={<ProductGroupsPage />} />
                    <Route path="settings" element={<SettingsPage />} />
                    <Route path="product-management" element={<ProductManagementPage />} />
                  </Route>
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <VersionChangedModal
                  show={showVersionPopup}
                  onCancel={() => {
                    setVersionPopupDismissed(true);
                    setShowVersionPopup(false);
                  }}
                />
              </OpenInvoicesCountProvider>
            </BaronaCompanyContextProvider>
          </NotificationsProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
};

const withRouter = (Component: React.ElementType) => (props: any) =>
  (
    <BrowserRouter>
      <Component {...props} />
    </BrowserRouter>
  );

export default withRouter(App);
