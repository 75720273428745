import styled from "styled-components";
import {
  Table,
  HeaderRow,
  HeaderCell,
  RigthAlignHeaderCell,
  Row,
  Cell,
  RigthAlignCell,
  TableBody,
} from "../../components/Table";
import { useEffect, useState } from "react";
import Big from "big.js";
import "react-popper-tooltip/dist/styles.css";
import { InvoiceResponse } from "../../services/invoice";
import Loading from "../../components/Loading";
import ExpandableRow from "../../components/Table.ExpandableRow";
import InvoiceRowItems from "./InvoiceRowItems";
import {
  IInvoiceRowComment,
  InvoiceRowType,
  InvoiceSource,
  PersistedInvoiceRowProduct,
  PersistedItem,
  ProductUnit,
  Serialized,
} from "@barona/lapa-common-types";
import Color from "../../colors";
import { QuestionMarkCircle } from "@styled-icons/evaicons-solid";
import { usePopperTooltip } from "react-popper-tooltip";
import InvoiceTooltip from "./InvoiceTooltip";
import { frontendApi } from "@barona/lapa-common-frontend";
import { NotificationState, useBaronaCompany, useNotifications } from "../../context";
import DeleteRowModal from "./DeleteRowModal";
import { Button } from "../../components/Button";
import { formatToEuroCurrencyString } from "../../util/money";
import InvoiceContentSidePanel, {
  InvoiceSidePanelAction,
  InvoiceSidePanelType,
  InvoiceSidePanelContentParams,
} from "./InvoiceContentSidepanel";

const Total = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  margin: 15px 15px 0 0;
  text-align: right;
`;

const TotalSum = styled.span`
  margin-left: 20px;
`;

const BoldCell = styled(Cell)`
  font-weight: bold;
`;

const CommentRowPrimary = styled(Row)`
  background-color: ${Color.GREY_LIGHT} !important;
`;

const CommentRow = styled(Row)`
  background-color: ${Color.GREY_LIGHTER} !important;
`;

const InvoiceRowInfoCircleHeaderCell = styled(HeaderCell)`
  width: 20px;
`;

const EvenExpandableRow = styled(ExpandableRow)`
  &:nth-child(even) {
    background-color: ${Color.GREY_LIGHTEST};
  }
  background-color: ${Color.GREY_LIGHTEST};
`;

const OddExpandableRow = styled(ExpandableRow)`
  &:nth-child(even) {
    background-color: ${Color.WHITE};
  }
  background-color: ${Color.WHITE};
`;

const CellWithEllipsis = styled(Cell)`
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DescriptionIcon = styled(QuestionMarkCircle)`
  height: 16px;
  margin: 0 5px 2px 0;
  color: ${Color.LAPA_BLUE};
`;

const DescriptionToolTip = styled.div`
  width: 200px;
  font-size: 14px;
  font-weight: normal;
  margin: 10px;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const UpdatedBadge = styled.div`
  background-color: ${Color.LIGHT_BLUE};
  color: ${Color.DARK_BLUE};
  padding: 3px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  width: fit-content;
`;

export const ManuallyAddedBadge = styled.div`
  background-color: ${Color.YELLOW};
  color: ${Color.DARK_BLUE};
  padding: 3px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  width: fit-content;
`;

export interface Props {
  invoiceRows: InvoiceResponse["invoiceRows"];
  isLoading?: boolean;
  allowExpandingRows?: boolean;
  invoiceId: string;
  refreshInvoice: Function;
  allowEditingRows: boolean;
  invoiceType: InvoiceSource;
  type: string;
  customerId?: string;
}

export enum InvoiceRowsType {
  billable = "billable",
  nonBillable = "nonBillable",
}
export interface InvoiceTooltipProps {
  invoiceRow: Serialized<PersistedInvoiceRowProduct>;
}

export interface OpenPanelProps {
  invoiceRow?: Serialized<PersistedInvoiceRowProduct>;
  item?: Serialized<PersistedItem>;
  panelType: InvoiceSidePanelType;
  invoiceId: string;
  action: InvoiceSidePanelAction;
}

export interface DeleteRowProps {
  panelType: InvoiceSidePanelType;
  invoiceRowId?: string;
  itemId?: string;
}

const isCommentRowPrimary = (ir: IInvoiceRowComment): boolean =>
  ir.productName === ir.additionalGroupingProperties?.customerReference;

const InvoiceRowsTable = (props: Props) => {
  const {
    invoiceRows,
    isLoading = false,
    allowExpandingRows = true,
    invoiceId,
    refreshInvoice,
    allowEditingRows,
    type,
    invoiceType,
  } = props;
  const totalSum = invoiceRows.reduce((acc, invoiceRow) => {
    if (
      !invoiceRow.billable ||
      invoiceRow.rowType === InvoiceRowType.comment ||
      !invoiceRow.unitPrice ||
      !invoiceRow.amount
    )
      return acc;
    return acc.plus(Big(invoiceRow.unitPrice).round(2).mul(invoiceRow.amount).round(2));
  }, new Big(0));
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();
  const [isUpdateRequired, setIsUpdateRequired] = useState(false);
  const [showDeleteRowModal, setShowDeleteRowModal] = useState(false);
  const [showDeleteItemRowModal, setShowDeleteItemRowModal] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<string>("");
  const { addNotification } = useNotifications();
  const { baronaCompany } = useBaronaCompany();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [sidePanelContent, setSidePanelContent] = useState<InvoiceSidePanelContentParams | undefined>();

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
  };

  const sortedInvoiceRows = [
    invoiceRows.filter(
      (ir) =>
        (ir.rowType !== InvoiceRowType.comment || ir.productName !== "") &&
        (ir.rowType !== InvoiceRowType.product || ir.additionalGroupingProperties?.employeeName !== "")
    ),
    invoiceRows.filter(
      (ir) =>
        (ir.rowType === InvoiceRowType.comment && ir.productName === "") ||
        (ir.rowType === InvoiceRowType.product && ir.additionalGroupingProperties?.employeeName === "")
    ),
  ].flat();

  useEffect(() => {
    if (!isSidePanelOpen && isUpdateRequired) {
      setTimeout(() => {
        refreshInvoice();
        setIsUpdateRequired(false);
      }, 1000);
    }
  }, [isSidePanelOpen, isUpdateRequired, refreshInvoice]);

  const openPanel = ({ invoiceRow, item, panelType, invoiceId, action }: OpenPanelProps) => {
    setIsSidePanelOpen(true);
    setIsUpdateRequired(false);

    switch (panelType) {
      case InvoiceSidePanelType.ItemRow: {
        switch (action) {
          case InvoiceSidePanelAction.view:
            setSidePanelContent({
              sidePanelType: InvoiceSidePanelType.ItemRow,
              action: InvoiceSidePanelAction.view,
              item: item!,
            });
            break;
          case InvoiceSidePanelAction.edit:
            allowEditingRows
              ? setSidePanelContent({
                  sidePanelType: InvoiceSidePanelType.ItemRow,
                  action: InvoiceSidePanelAction.edit,
                  item: item!,
                  invoiceId,
                })
              : setSidePanelContent({
                  sidePanelType: InvoiceSidePanelType.ItemRow,
                  action: InvoiceSidePanelAction.view,
                  item: item!,
                });
            break;
          case InvoiceSidePanelAction.create:
            setSidePanelContent({
              sidePanelType: InvoiceSidePanelType.ItemRow,
              action: InvoiceSidePanelAction.create,
              item: item,
              invoiceId,
              baronaCompanyCode: baronaCompany,
              customerId: props.customerId,
            });
            break;
        }
        break;
      }
      case InvoiceSidePanelType.InvoiceRow: {
        switch (action) {
          case InvoiceSidePanelAction.view: {
            setSidePanelContent({
              sidePanelType: InvoiceSidePanelType.InvoiceRow,
              action: InvoiceSidePanelAction.view,
              invoiceRow: invoiceRow!,
            });
            break;
          }
          case InvoiceSidePanelAction.editDescription: {
            setSidePanelContent({
              sidePanelType: InvoiceSidePanelType.InvoiceRow,
              action: InvoiceSidePanelAction.editDescription,
              invoiceRow: invoiceRow!,
              invoiceId,
            });
            break;
          }
          case InvoiceSidePanelAction.edit: {
            setSidePanelContent({
              sidePanelType: InvoiceSidePanelType.InvoiceRow,
              action: InvoiceSidePanelAction.edit,
              invoiceRow: invoiceRow!,
              invoiceId,
            });
            break;
          }
          case InvoiceSidePanelAction.create: {
            setSidePanelContent({
              sidePanelType: InvoiceSidePanelType.InvoiceRow,
              action: InvoiceSidePanelAction.create,
              invoiceId,
            });
            break;
          }
          case InvoiceSidePanelAction.copy: {
            setSidePanelContent({
              sidePanelType: InvoiceSidePanelType.InvoiceRow,
              action: InvoiceSidePanelAction.copy,
              invoiceRow: invoiceRow!,
              invoiceId,
            });
            break;
          }
        }
      }
    }
  };

  const displayDeleteRowModal = ({ panelType, invoiceRowId, itemId }: DeleteRowProps) => {
    switch (panelType) {
      case InvoiceSidePanelType.ItemRow: {
        try {
          if (!itemId) {
            throw new Error("displayDeleteRowModal called without itemId");
          }
          setDeleteRowId(itemId);
          setShowDeleteItemRowModal(true);
        } catch (error) {
          addNotification("Tapahtumarivin poistaminen epäonnistui", NotificationState.Error);
        }
        break;
      }
      case InvoiceSidePanelType.InvoiceRow: {
        try {
          if (!invoiceRowId) {
            throw new Error("displayDeleteRowModal called without invoiceRowId");
          }
          setDeleteRowId(invoiceRowId);
          setShowDeleteRowModal(true);
        } catch (error) {
          addNotification("Laskurivin poistaminen epäonnistui", NotificationState.Error);
        }
        break;
      }
    }
  };

  const deleteRow = async () => {
    try {
      await frontendApi.deleteInvoiceRow({ invoiceId, invoiceRowId: deleteRowId, invoiceType });
      addNotification("Laskurivin poistaminen onnistui", NotificationState.Success);
      setShowDeleteRowModal(false);
      refreshInvoice();
    } catch (error) {
      addNotification("Laskurivin poistaminen epäonnistui", NotificationState.Error);
    }
  };

  const deleteItemRow = async () => {
    try {
      await frontendApi.deleteInvoiceItemRow({ invoiceId, itemId: deleteRowId });
      addNotification("Tapahtumarivin poistaminen onnistui", NotificationState.Success);
      setShowDeleteRowModal(false);
      refreshInvoice();
    } catch (error) {
      addNotification("Tapahtumarivin poistaminen epäonnistui", NotificationState.Error);
    }
  };

  return (
    <>
      <Table>
        <HeaderRow>
          <HeaderCell style={{ width: "26%" }}>SELITE</HeaderCell>
          <HeaderCell style={{ width: "8%" }}>TUOTE</HeaderCell>
          <HeaderCell style={{ width: "15%" }}>
            KUVAUS{" "}
            {
              <span ref={setTriggerRef} data-testid="DescriptionTooltipIcon">
                <DescriptionIcon />
              </span>
            }
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: "tooltip-container",
                  style: { margin: 0 },
                })}
              >
                <DescriptionToolTip>
                  Koko kuvaustekstin näkee, kun valitsee Näytä rivin tiedot kolmen pisteen takaa.
                </DescriptionToolTip>
                <div {...getArrowProps({ className: "tooltip-arrow" })} />
              </div>
            )}
          </HeaderCell>
          <HeaderCell style={{ width: "6%" }}>PVM</HeaderCell>
          <RigthAlignHeaderCell style={{ width: "8%" }}>MÄÄRÄ</RigthAlignHeaderCell>
          <HeaderCell style={{ width: "6%" }}>YKSIKKÖ</HeaderCell>
          <RigthAlignHeaderCell style={{ width: "8%" }}>A-HINTA</RigthAlignHeaderCell>
          <RigthAlignHeaderCell style={{ width: "8%" }}>YHTEENSÄ</RigthAlignHeaderCell>
          <HeaderCell style={{ width: "10%" }} />
          <InvoiceRowInfoCircleHeaderCell style={{ width: "5%" }} />
        </HeaderRow>
        <TableBody>
          {!isLoading &&
            sortedInvoiceRows.map((ir, index) => {
              const even: boolean = index % 2 === 0;
              const StyledExpandableRow = even ? EvenExpandableRow : OddExpandableRow;
              switch (ir.rowType) {
                case InvoiceRowType.product:
                  return (
                    <StyledExpandableRow
                      key={ir.invoiceRowId}
                      testId="ImportLogRow"
                      renderExpandedContent={
                        <InvoiceRowItems
                          invoiceRowId={ir.invoiceRowId}
                          even={even}
                          openPanel={openPanel}
                          deleteRow={displayDeleteRowModal}
                          invoiceId={invoiceId}
                          invoiceType={invoiceType}
                        />
                      }
                      renderTooltip={
                        <InvoiceTooltip
                          invoiceRow={ir}
                          panelType={InvoiceSidePanelType.InvoiceRow}
                          openPanel={openPanel}
                          openDeleteRowModal={displayDeleteRowModal}
                          invoiceId={invoiceId}
                          invoiceType={invoiceType}
                        />
                      }
                      hideExpandButton={!allowExpandingRows}
                    >
                      <Cell>{ir.productName}</Cell>
                      <Cell>{ir.productCode}</Cell>
                      <CellWithEllipsis>{ir.description}</CellWithEllipsis>
                      <Cell />
                      <RigthAlignCell>
                        {Big(ir.amount as string).toFixed(2) /* amount cant be null for product row */}
                      </RigthAlignCell>
                      <Cell>
                        {ir.productUnit === ProductUnit.PCS ? "kpl" : ir.productUnit === ProductUnit.H ? "tunti" : ""}
                      </Cell>
                      <RigthAlignCell>
                        {ir.billable && ir.unitPrice ? formatToEuroCurrencyString(new Big(ir.unitPrice)) : " - "}
                      </RigthAlignCell>
                      <RigthAlignCell>
                        {ir.billable && ir.unitPrice
                          ? formatToEuroCurrencyString(
                              new Big(ir.unitPrice).round(2).mul(ir.amount as string)
                            ) /* amount cant be null for product row */
                          : " - "}
                      </RigthAlignCell>
                      <Cell>
                        <BadgeContainer>
                          {ir.hasUpdatedItems && <UpdatedBadge>päivitetty</UpdatedBadge>}
                          {ir.hasManualItems && <ManuallyAddedBadge>Lisätty käsin</ManuallyAddedBadge>}
                        </BadgeContainer>
                      </Cell>
                    </StyledExpandableRow>
                  );

                case InvoiceRowType.comment:
                  const CommentRowComponent = isCommentRowPrimary(ir) ? CommentRowPrimary : CommentRow;
                  return (
                    <CommentRowComponent key={ir.invoiceRowId}>
                      <BoldCell>{ir.productName}</BoldCell>
                      <Cell />
                      <Cell />
                      <Cell />
                      <Cell />
                      <Cell />
                      <Cell />
                      <Cell />
                      <Cell />
                      <Cell />
                    </CommentRowComponent>
                  );
                default:
                  throw new Error("Invalid invoice row type");
              }
            })}
        </TableBody>
      </Table>
      {isLoading ? (
        <Loading height={36} count={4} />
      ) : (
        <>
          <Total>
            Yhteensä EUR ilman ALV:tä <TotalSum>{formatToEuroCurrencyString(totalSum)}</TotalSum>
          </Total>
          {[InvoiceSource.manual, InvoiceSource.lapa].includes(invoiceType) && type === InvoiceRowsType.billable && (
            <Button
              onClick={() => {
                invoiceType === InvoiceSource.lapa
                  ? openPanel({
                      panelType: InvoiceSidePanelType.ItemRow,
                      invoiceId,
                      action: InvoiceSidePanelAction.create,
                    })
                  : openPanel({
                      panelType: InvoiceSidePanelType.InvoiceRow,
                      invoiceId,
                      action: InvoiceSidePanelAction.create,
                    });
              }}
            >
              Lisää rivi
            </Button>
          )}
        </>
      )}
      <InvoiceContentSidePanel
        isOpen={isSidePanelOpen}
        content={sidePanelContent}
        closeSidePanel={closeSidePanel}
        setIsUpdateRequired={setIsUpdateRequired}
      />
      <DeleteRowModal
        show={showDeleteItemRowModal}
        onCancel={() => setShowDeleteItemRowModal(false)}
        onConfirm={() => deleteItemRow()}
        title="Tapahtumarivi poistetaan"
        body={
          <div>
            <div>Yksittäinen laskuriviin liittyvä tapahtumatieto poistetaan.</div>
            <br />
            <div>
              Jos tapahtuma pitää kuitenkin laskuttaa, tuo se uudestaan Mepcosta tai muusta lähdejärjestelmästä.
            </div>
            <br />
            <div>
              HOX! Laskuttamattomat tapahtumat päivittyvät avoimille laskuille automaattisesti. Jos et halua niitä tälle
              laskulle, lähetä lasku ennen kuin tuot tapahtumia uudelleen.
            </div>
          </div>
        }
        confirmButtonText="Tiedän mitä teen, poista tapahtuma"
      />
      <DeleteRowModal
        show={showDeleteRowModal}
        onCancel={() => setShowDeleteRowModal(false)}
        onConfirm={() => deleteRow()}
        title={"Laskurivi poistetaan"}
        body={
          <div>
            <div>Kun laskurivi poistetaan, myös siihen liittyvät tapahtumat poistetaan.</div>
            <br />
            <div>
              Jos tapahtumat pitää kuitenkin laskuttaa, tuo ne uudestaan Mepcosta tai muusta lähdejärjestelmästä.
            </div>
            <br />
            <div>
              HOX! Laskuttamattomat tapahtumat päivittyvät avoimille laskuille automaattisesti. Jos et halua niitä tälle
              laskulle, lähetä lasku ennen kuin tuot tapahtumat uudelleen.
            </div>
          </div>
        }
        confirmButtonText={"Tiedän mitä teen, poista rivi"}
      />
    </>
  );
};

export default InvoiceRowsTable;
