export interface IProblemJSON {
  type: string;
  status: number;
  title: string;
  detail: string;
  cid: string | undefined;
}

export const isProblemJSON = (obj: unknown): obj is IProblemJSON =>
  Object.prototype.hasOwnProperty.call(obj, "type") &&
  Object.prototype.hasOwnProperty.call(obj, "status") &&
  Object.prototype.hasOwnProperty.call(obj, "title") &&
  Object.prototype.hasOwnProperty.call(obj, "detail") &&
  Object.prototype.hasOwnProperty.call(obj, "cid");
