export enum InvoiceStatus {
  draft = "draft",
  inTransit = "in_transit",
  sent = "sent",
  discarded = "discarded",
}

export enum InvoiceRowType {
  product = "product",
  comment = "comment",
}

export enum InvoiceGroupBy {
  product = "product",
  person = "person",
  referenceAndPerson = "referenceAndPerson",
}

export enum InvoiceSource {
  lapa = "lapa",
  salesforce = "salesforce",
  manual = "manual",
}

export enum InvoiceVat {
  vat0 = "0",
  vat10 = "10",
  vat14 = "14",
  vat24 = "24",
  vat25_5 = "25.5",
  vat24rak = "RAK24",
}

export const defaultInvoiceVat = InvoiceVat.vat25_5;
