import Big from "big.js";
import { Spinner7 } from "@styled-icons/icomoon/Spinner7";
import styled, { keyframes } from "styled-components";
import { ErrorMessage } from "formik";
import { MepcoItemAdditionalInfo, PersistedItem, ProductUnit, Serialized } from "@barona/lapa-common-types";
import Color from "../../colors";
import Select from "../../components/form/Select";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { Button } from "../../components/Button";
import { SecondaryButton } from "../../components/SecondaryButton";
import TextInput from "../../components/form/TextInput";
import { formatToEuroCurrencyString } from "../../util/money";

export const SidePaneContent = styled.div`
  margin: 20px;
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: center;
  margin-bottom: 20px;
`;

export const CloseIcon = styled(Close)`
  height: 24px;
  color: ${Color.BLACK};
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 24px;
  column-gap: 30px;
  padding-right: 30px;
`;

export const FormItem = styled.div``;

export const TotalAndVatContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const DescriptionItem = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
  color: ${Color.GREY};
`;

export const Data = styled.div``;

export const ButtonsItem = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
`;

export const StyledButton = styled(Button)`
  margin: 8px;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin: 8px;
`;

export const ButtonsContainer = styled.div`
  float: right;
`;

export const getProductUnit = (productUnit: ProductUnit | undefined | null) => {
  switch (productUnit) {
    case ProductUnit.PCS:
      return "kpl";
    case ProductUnit.H:
      return "tunti";
    default:
      return "";
  }
};

export const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

export const getTotal = (
  unitPrice: string | null | undefined,
  amount: string | number | undefined,
  billable: boolean | undefined
) => {
  if (billable && unitPrice && amount) {
    return formatToEuroCurrencyString(new Big(unitPrice).round(2).mul(amount));
  }
  return " - ";
};

export const handleEmployeeName = (item: Serialized<PersistedItem> | undefined) => {
  if (!item) {
    return null;
  }
  const additionalInfo = item.additionalInfo as MepcoItemAdditionalInfo;
  if (additionalInfo.employeeName) {
    return additionalInfo.employeeName;
  }
  return null;
};

export const getPrice = (unitPrice: string | null | undefined, billable: boolean | undefined) => {
  if (billable && unitPrice) {
    return formatToEuroCurrencyString(new Big(unitPrice));
  }
  return " - ";
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SubmitIcon = styled(Spinner7)`
  height: 22px;
  animation: 2s linear ${spin} infinite;
`;

export interface ProductOption {
  value: string;
  label: string;
  productName: string;
  productUnit: ProductUnit | null;
}

export interface EmployeeOption {
  value: string;
  label: string;
  employeeName: string;
}

export interface SelectOption {
  value: string;
  label: string;
}

export const StyledSelect = styled(Select)``;

export const StyledErrorMessage = styled(ErrorMessage)`
  padding-top: 8px;
  color: ${Color.RED};
`;
