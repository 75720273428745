import { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import Color from "../colors";

const TableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const Table = ({ children }: PropsWithChildren<{}>) => <TableContainer>{children}</TableContainer>;

export const Row = styled.tr`
  min-height: 50px;
  font-size: 14px;
  box-shadow: inset 0 -1px 0 0 ${Color.BORDER_SHADOW};

  &:nth-child(odd) {
    background-color: ${Color.GREY_LIGHTEST};
  }
`;

const HeaderRowTableRow = styled(Row)`
  background-color: ${Color.WHITE} !important;
`;

const TableBodyContainer = styled.tbody``;

export const TableBody = ({ children }: PropsWithChildren<{}>) => <TableBodyContainer>{children}</TableBodyContainer>;

const HeaderRowContainer = styled.thead``;

export const HeaderRow = ({ children }: PropsWithChildren<{}>) => (
  <HeaderRowContainer>
    <HeaderRowTableRow>{children}</HeaderRowTableRow>
  </HeaderRowContainer>
);

export const HeaderCell = styled.th`
  color: ${Color.GREY};
  text-align: left;
  word-wrap: break-word;
  height: 50px;
  vertical-align: middle;
  padding: 7px;

  &:first-child {
    padding-left: 15px;
  }
  &:last-child {
    padding-right: 15px;
  }
`;

export const RigthAlignHeaderCell = styled(HeaderCell)`
  text-align: right;
`;

export const Cell = styled.td<{ disabled?: boolean }>`
  word-wrap: break-word;
  color: ${Color.BLACK};
  height: 50px;
  vertical-align: middle;
  padding: 7px;

  &:first-child {
    padding-left: 15px;
  }
  &:last-child {
    padding-right: 15px;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${Color.GREY};
    `}
`;

export const RigthAlignCell = styled(Cell)`
  text-align: right;
`;
