import { frontendApi } from "@barona/lapa-common-frontend";
import { useQuery } from "react-query";

export const useImportLogErrors = (importLogId: string) => {
  const { data, isLoading, refetch } = useQuery(
    ["importLogErrors", importLogId],
    async () => await frontendApi.getImportLogErrors({ importLogId })
  );

  return { importLogErrors: data || [], isLoading, forceRefresh: refetch };
};
