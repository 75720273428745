import Loading from "../../components/Loading";
import { useInvoiceRowItems } from "../../services/invoice";
import { InvoiceSource, ItemSource, ProductUnit } from "@barona/lapa-common-types";
import { Row, Cell, RigthAlignCell } from "../../components/Table";
import styled from "styled-components";
import Color from "../../colors";
import InvoiceRowTooltip from "./InvoiceRowTooltip";
import InvoiceTooltip from "./InvoiceTooltip";
import { OpenPanelProps, DeleteRowProps, UpdatedBadge, ManuallyAddedBadge } from "./InvoiceRowsTable";
import { dateISOStringToLocaleDateString } from "@barona/lapa-common-date-utils";
import { InvoiceSidePanelType } from "./InvoiceContentSidepanel";

interface Props {
  invoiceRowId: string;
  even: boolean;
  openPanel: (props: OpenPanelProps) => void;
  deleteRow: (props: DeleteRowProps) => void;
  invoiceId: string;
  invoiceType: InvoiceSource;
}

const InvoiceRowItemsContainer = styled.tr`
  padding: 0;
  padding-right: 0;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

const SpanningColumn = styled.td``;

const EvenRow = styled(Row)`
  && {
    background-color: ${Color.GREY_LIGHTEST};
  }
`;

const OddRow = styled(Row)`
  && {
    background-color: ${Color.WHITE};
  }
`;

const InvoiceRowItems = (props: Props) => {
  const { invoiceRowId, even, invoiceId, invoiceType } = props;
  const { items, isLoading } = useInvoiceRowItems(invoiceRowId);
  const StyledRow = even ? EvenRow : OddRow;

  return isLoading ? (
    <InvoiceRowItemsContainer>
      <SpanningColumn colSpan={10}>
        <Loading height={36} count={2} />
      </SpanningColumn>
    </InvoiceRowItemsContainer>
  ) : (
    <>
      {!isLoading &&
        items.map((item) => (
          <StyledRow key={item.itemId}>
            <Cell>{item.itemId}</Cell>
            <Cell>{item.productCode}</Cell>
            <Cell></Cell>
            <Cell>{dateISOStringToLocaleDateString(item.itemDate)}</Cell>
            <RigthAlignCell>{item.amount}</RigthAlignCell>
            <Cell>
              {item.productUnit === ProductUnit.PCS ? "kpl" : item.productUnit === ProductUnit.H ? "tunti" : ""}
            </Cell>
            <Cell />
            <Cell />
            <Cell>
              {item.source === ItemSource.MEPCO && item.updatedByUser && <UpdatedBadge>päivitetty</UpdatedBadge>}
              {item.source === ItemSource.MANUAL && invoiceType === InvoiceSource.lapa && (
                <ManuallyAddedBadge>Lisätty käsin</ManuallyAddedBadge>
              )}
            </Cell>
            <Cell>
              <InvoiceRowTooltip
                tooltip={
                  <InvoiceTooltip
                    item={item}
                    panelType={InvoiceSidePanelType.ItemRow}
                    openPanel={props.openPanel}
                    openDeleteRowModal={props.deleteRow}
                    invoiceId={invoiceId}
                    invoiceType={invoiceType}
                  />
                }
              />
            </Cell>
          </StyledRow>
        ))}
    </>
  );
};

export default InvoiceRowItems;
