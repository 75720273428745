import { InvoiceSource, PersistedInvoiceRowProduct, PersistedItem, Serialized } from "@barona/lapa-common-types";
import styled from "styled-components";
import Color from "../../colors";
import { InvoiceSidePanelAction, InvoiceSidePanelType } from "./InvoiceContentSidepanel";
import { OpenPanelProps, DeleteRowProps } from "./InvoiceRowsTable";

export interface InvoiceTooltipProps {
  invoiceRow?: Serialized<PersistedInvoiceRowProduct>;
  item?: Serialized<PersistedItem>;
  panelType: InvoiceSidePanelType;
  openPanel: (props: OpenPanelProps) => void;
  openDeleteRowModal: (props: DeleteRowProps) => void;
  invoiceId: string;
  invoiceType: InvoiceSource;
}

const TooltipButton = styled.button`
  display: block;
  all: unset;
  cursor: pointer;
  color: ${Color.LAPA_BLUE};
  font-size: 14px;
  margin: 8px;
`;

const InvoiceTooltip = ({
  invoiceRow,
  item,
  panelType,
  openPanel,
  openDeleteRowModal,
  invoiceId,
  invoiceType,
}: InvoiceTooltipProps) => {
  // ItemRow
  if (panelType === InvoiceSidePanelType.ItemRow) {
    return (
      <>
        <TooltipButton
          data-testid="OpenSidePanelForViewing"
          onClick={() => openPanel({ invoiceRow, item, panelType, invoiceId, action: InvoiceSidePanelAction.view })}
        >
          Näytä rivin tiedot
        </TooltipButton>
        <TooltipButton
          data-testid="OpenSidePanelForEditing"
          onClick={() => openPanel({ invoiceRow, item, panelType, invoiceId, action: InvoiceSidePanelAction.edit })}
        >
          Muokkaa
        </TooltipButton>
        <TooltipButton data-testid="DeleteRow" onClick={() => openDeleteRowModal({ panelType, itemId: item!.itemId })}>
          Poista rivi
        </TooltipButton>
      </>
    );
  }
  // InvoiceRow
  else {
    if (invoiceType === InvoiceSource.manual) {
      return (
        <>
          <TooltipButton
            data-testid="OpenSidePanel"
            onClick={() =>
              openPanel({
                invoiceRow,
                item,
                panelType,
                invoiceId,
                action: InvoiceSidePanelAction.edit,
              })
            }
          >
            Muokkaa
          </TooltipButton>
          <TooltipButton
            data-testid="openCopyRowModal"
            onClick={() => openPanel({ invoiceRow, item, panelType, invoiceId, action: InvoiceSidePanelAction.copy })}
          >
            Käytä uuden rivin pohjana
          </TooltipButton>
          <TooltipButton
            data-testid="openDeleteRowModal"
            onClick={() => openDeleteRowModal({ panelType, invoiceRowId: invoiceRow!.invoiceRowId })}
          >
            Poista rivi
          </TooltipButton>
        </>
      );
    } else if (invoiceType === InvoiceSource.lapa) {
      return (
        <>
          <TooltipButton
            data-testid="OpenSidePanel"
            onClick={() =>
              openPanel({
                invoiceRow,
                item,
                panelType,
                invoiceId,
                action: InvoiceSidePanelAction.editDescription,
              })
            }
          >
            Näytä rivin tiedot
          </TooltipButton>
          <TooltipButton
            data-testid="openCopyRowModal"
            onClick={() => openPanel({ invoiceRow, item, panelType, invoiceId, action: InvoiceSidePanelAction.copy })}
          >
            Käytä uuden rivin pohjana
          </TooltipButton>
          <TooltipButton
            data-testid="openDeleteRowModal"
            onClick={() => openDeleteRowModal({ panelType, invoiceRowId: invoiceRow!.invoiceRowId })}
          >
            Poista rivi
          </TooltipButton>
        </>
      );
    } else {
      return (
        <>
          <TooltipButton
            data-testid="OpenSidePanel"
            onClick={() =>
              openPanel({
                invoiceRow,
                item,
                panelType,
                invoiceId,
                action: InvoiceSidePanelAction.view,
              })
            }
          >
            Näytä rivin tiedot
          </TooltipButton>
          <TooltipButton
            data-testid="openDeleteRowModal"
            onClick={() => openDeleteRowModal({ panelType, invoiceRowId: invoiceRow!.invoiceRowId })}
          >
            Poista rivi
          </TooltipButton>
        </>
      );
    }
  }
};

export default InvoiceTooltip;
