import { SidePane } from "react-side-pane";

interface Props {
  isOpen: boolean;
  closeSidePanel(): void;
}

const SidePanel = ({ isOpen, closeSidePanel, children }: React.PropsWithChildren<Props>) => (
  <SidePane open={isOpen} width={40} onClose={closeSidePanel} disableBackdropClick={true} style={{ overflow: "auto" }}>
    <div>{children}</div>
  </SidePane>
);

export default SidePanel;
