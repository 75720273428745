import { createContext, useCallback, useContext, useState, PropsWithChildren } from "react";
import { frontendApi } from "@barona/lapa-common-frontend";
import { useBaronaCompany } from "../context";
import { useInterval } from "../hooks/useInterval";
import { useIsTabFocused } from "../hooks/useIsTabFocused";

const OpenInvoicesCountContext = createContext<{
  openInvoicesCount: number;
  setOpenInvoicesCount: (count: number) => void;
  setRefreshInterval: (delayMs: number) => void;
  setRefreshIntervalTemporarily: (delayMs: number, restoreDefaultAfterMs: number) => void;
  refresh: () => void;
}>({
  openInvoicesCount: 0,
  setOpenInvoicesCount: () => {},
  setRefreshInterval: () => {},
  setRefreshIntervalTemporarily: () => {},
  refresh: () => {},
});

export const useOpenInvoicesCount = () => useContext(OpenInvoicesCountContext);
export const DEFAULT_REFRESH_INTERVAL = 60000;

type Props = PropsWithChildren<{}>;
export const OpenInvoicesCountProvider = ({ children }: Props) => {
  const { baronaCompany } = useBaronaCompany();
  const [openInvoicesCount, setOpenInvoicesCount] = useState(0);
  const [refreshInterval, setRefreshInterval] = useState(DEFAULT_REFRESH_INTERVAL);
  const isTabFocused = useIsTabFocused();

  const refeshOpenInvoicesCount = useCallback(async () => {
    try {
      const invoicesCountResult = await frontendApi.fetchDraftInvoicesCount({ baronaCompany });
      setOpenInvoicesCount(invoicesCountResult.invoicesCount ?? 0);
    } catch (error) {
      console.error("Fetching open invoices count failed");
    }
  }, [baronaCompany]);

  useInterval(() => {
    if (isTabFocused) {
      refeshOpenInvoicesCount();
    }
  }, refreshInterval);

  const setRefreshIntervalTemporarily = useCallback((delayMs: number, restoreDefaultAfterMs: number) => {
    setRefreshInterval(delayMs);
    setTimeout(() => {
      setRefreshInterval(DEFAULT_REFRESH_INTERVAL);
    }, restoreDefaultAfterMs);
  }, []);

  return (
    <OpenInvoicesCountContext.Provider
      value={{
        openInvoicesCount,
        setOpenInvoicesCount,
        setRefreshInterval,
        setRefreshIntervalTemporarily,
        refresh: refeshOpenInvoicesCount,
      }}
    >
      {children}
    </OpenInvoicesCountContext.Provider>
  );
};
