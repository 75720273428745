import { PriceListProductHistory, Serialized } from "@barona/lapa-common-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { LapaNavigationLink } from "../../components/Link";
import Loading from "../../components/Loading";
import { Cell, HeaderCell, HeaderRow, Row, Table, TableBody } from "../../components/Table";
import { useCustomerName } from "../../services/customer";
import { usePriceList, usePriceListHistory } from "../../services/priceLists";
import PageContainer from "../common/PageContainer";
import { Title } from "../common/Title";
import { orderBy, filter } from "lodash";
import { ChevronDown } from "@styled-icons/boxicons-regular/ChevronDown";
import { useDebounce } from "../../hooks/useDebounce";
import SearchInput from "../../components/form/SearchInput";
import { dateToFinnishDateString } from "@barona/lapa-common-date-utils";

type PriceListHistoryUrlParams = {
  pricelistId: string;
  customerId: string;
};

const PriceListNameContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const DateSorter = styled.span`
  cursor: pointer;
`;
enum SortDirection {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

enum HistoryOperation {
  INSERT = "INSERT",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

const getOperation = (operation: string) => {
  switch (operation) {
    case HistoryOperation.INSERT:
      return "Lisätty tuote";
    case HistoryOperation.DELETE:
      return "Poistettu tuote";
    case HistoryOperation.UPDATE:
      return "Hinnan muutos";
    default:
      return "Tuntematon toiminto";
  }
};

const PriceListHistoryRow = ({ historyItem }: { historyItem: Serialized<PriceListProductHistory> }) => (
  <Row>
    <Cell>{historyItem.name}</Cell>
    <Cell>{historyItem.productCode}</Cell>
    <Cell>{getOperation(historyItem.operation)}</Cell>
    <Cell>{historyItem.oldValue}</Cell>
    <Cell>{historyItem.newValue}</Cell>
    <Cell>{dateToFinnishDateString(new Date(historyItem.createdAt))}</Cell>
  </Row>
);

const SortSelector = styled(ChevronDown)<{ direction: SortDirection }>`
  width: 20px;
  transform: rotate(${(props) => (props.direction === SortDirection.DESCENDING ? "0deg" : "180deg")});
  transition: transform 0.2s ease-out;
`;

const PriceListHistoryPage = () => {
  const { pricelistId, customerId } = useParams<PriceListHistoryUrlParams>();
  const { customerName } = useCustomerName(customerId ?? "");

  const { pricelist } = usePriceList(pricelistId ?? "");
  const { pricelistHistory, isLoading: isPriceListHistoryLoading } = usePriceListHistory(pricelistId ?? "");

  const [sortedPricelistHistory, setSortedPricelistHistory] = useState<Serialized<PriceListProductHistory>[]>([]);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESCENDING);
  const [searchFilter, setSearchFilter] = useState("");
  const debouncedSearchFilter = useDebounce(searchFilter);

  useEffect(() => {
    if (pricelistHistory && pricelistHistory.length > 0) {
      if (debouncedSearchFilter) {
        const filteredHistory = filter(pricelistHistory, (item) =>
          item.name.toLowerCase().includes(debouncedSearchFilter.toLowerCase())
        );
        setSortedPricelistHistory(orderBy(filteredHistory, ["createdAt"], [sortDirection]));
      } else {
        setSortedPricelistHistory(orderBy(pricelistHistory, ["createdAt"], [sortDirection]));
      }
    }
  }, [pricelistHistory, sortDirection, debouncedSearchFilter]);

  const switchSorting = () => {
    if (sortDirection === SortDirection.ASCENDING) {
      setSortDirection(SortDirection.DESCENDING);
    } else {
      setSortDirection(SortDirection.ASCENDING);
    }
  };

  return (
    <PageContainer>
      <LapaNavigationLink to={`../../pricelists/${pricelistId}`}>{"<"} Takaisin hinnastoon</LapaNavigationLink>
      <PriceListNameContainer>
        <Title data-testid={"PriceListHistoryPageTitle"}>{`Muutoshistoria - ${
          pricelist?.description ?? ""
        } / ${customerName}`}</Title>
      </PriceListNameContainer>
      <SearchInput
        data-testid="FilterProducts"
        value={searchFilter ?? ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchFilter(event.target.value)}
        placeholder="Tuote"
      />
      {isPriceListHistoryLoading ? (
        <Loading />
      ) : (
        <Table>
          <HeaderRow>
            <HeaderCell style={{ width: "30%" }}>TUOTE</HeaderCell>
            <HeaderCell style={{ width: "10%" }}>NAV-RESURSSI</HeaderCell>
            <HeaderCell style={{ width: "15%" }}>TOIMINTO</HeaderCell>
            <HeaderCell style={{ width: "10%" }}>VANHA ARVO</HeaderCell>
            <HeaderCell style={{ width: "10%" }}>UUSI ARVO</HeaderCell>
            <HeaderCell>
              <DateSorter onClick={switchSorting}>
                MUUTOSPÄIVÄ <SortSelector direction={sortDirection} />
              </DateSorter>
            </HeaderCell>
          </HeaderRow>
          <TableBody>
            {sortedPricelistHistory?.map((item) => (
              <PriceListHistoryRow historyItem={item} key={item.id} />
            ))}
          </TableBody>
        </Table>
      )}
    </PageContainer>
  );
};

export default PriceListHistoryPage;
