import { BaronaCompany, InvoiceGroupBy, InvoicingModel, ItemSource } from "@barona/lapa-common-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Color from "../../colors";
import Select from "../../components/form/Select";
import TextInput from "../../components/form/TextInput";
import { LapaLink, LapaNavigationLink } from "../../components/Link";
import Loading from "../../components/Loading";
import { useBaronaCompany } from "../../context/BaronaCompany";
import { NotificationState, useNotifications } from "../../context/Notifications";
import { useFrontendApi } from "../../hooks/useFrontendApi";
import PageContainer from "../common/PageContainer";
import { Title } from "../common/Title";
import { ExternalLink } from "@styled-icons/evaicons-solid/ExternalLink";
import CreateableSelect from "../../components/form/CreatableSelects";
import { Button } from "../../components/Button";
import { frontendApi } from "@barona/lapa-common-frontend";
import Notifications from "../common/Notifications";
import { ContentSeparator } from "../common/ContentSeparator";
import InstructionsColumn from "./InstructionsColumn";
import { SubTitle } from "./SubTitle";
import CheckboxInput from "../../components/form/CheckboxInput";
import { useCustomerName } from "../../services/customer";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 16px;
`;

const LeftContainer = styled.div``;
const RightContainer = styled.div`
  border-left: 1px solid #dfdfdf;
  padding-left: 16px;
`;

const TitleContainer = styled.div`
  margin-top: 16px;
`;

const InputContainer = styled.div`
  width: 45%;
  margin: 8px 0;
  input {
    width: 100%;
  }
`;

const InlineInputContainer = styled.div`
  width: 45%;
  input {
    width: 100%;
  }
  display: inline-block;
`;

const FullWidthInputContainer = styled.div`
  width: 100%;
  ${InlineInputContainer}:not(:first-child) {
    margin-left: 16px;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  margin-top: 12px;
  color: ${Color.GREY_DARK};
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  margin-top: 12px;
  color: ${Color.GREY_DARK};
  input {
    position: relative;
    top: 3px;
  }
`;

const HorizontalSeparator = styled.hr`
  border-bottom: none;
  border-top: 1px solid ${Color.GREY_LIGHT};
  margin: 24px 0;
`;

const ExternalLinkIcon = styled(ExternalLink)`
  width: 14px;
  height: 14px;
  margin-right: 2px;
`;

const NotificationContainer = styled.div``;

const ButtonContainer = styled.div`
  text-align: right;
`;

const groupByOptions = [
  { value: InvoiceGroupBy.product, label: "Tuotteittain" },
  { value: InvoiceGroupBy.person, label: "Työntekijöittäin" },
  {
    value: InvoiceGroupBy.referenceAndPerson,
    label: "Viitteittäin ja työntekijöittäin",
  },
];
const defaultGroupByOption = groupByOptions[1];

const invoiceByPaymentPeriodOptions = [
  { value: true, label: "Kyllä" },
  { value: false, label: "Ei" },
];
const defaultInvoiceByPaymentPeriodOption = invoiceByPaymentPeriodOptions[0];

const referenceHandlingOptions: {
  value: "invoiceByReference" | "invoiceBySelectedReference" | "everyReferenceToOneInvoice";
  label: string;
}[] = [
  { value: "invoiceByReference", label: "Joka viitteestä oma lasku" },
  { value: "invoiceBySelectedReference", label: "Valituista viitteistä yksi lasku" },
  { value: "everyReferenceToOneInvoice", label: "Kaikki viitteet samalle laskulle" },
];
const defaultReferenceHandlingOption = referenceHandlingOptions[0];

const customerReferenceFromOptions: { value: InvoicingModel["customerReferenceFrom"]; label: string }[] = [
  { value: "customerReferenceFilter", label: "Viitekentän tieto" },
  { value: "manual", label: "Syötetään käsin" },
];
const defaultCustomerReferenceFromOption = customerReferenceFromOptions[0];

const ourReferenceFromOptions: { value: InvoicingModel["ourReferenceFrom"]; label: string }[] = [
  { value: "manual", label: "Syötetään käsin" },
  { value: "contactPerson", label: "Asiakkaan yhteyshenkilö" },
];
const defaultOurReferenceFromOption = ourReferenceFromOptions[0];

export interface CrossPageNotificationState {
  notificationMessage?: string;
}

interface Props {
  new?: boolean;
}

const InvoicingModelPage = (props: Props) => {
  const { invoicingModelId, customerId } = useParams() as { customerId: string; invoicingModelId?: string };
  const { baronaCompany } = useBaronaCompany();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const { result: invoicingModel, isLoading: isLoadingInvoicingModel } = useFrontendApi(
    "getInvoicingModel",
    !props.new && invoicingModelId
      ? {
          invoicingModelId,
        }
      : null
  );

  const { customerName } = useCustomerName(customerId);

  const { result: availableCustomerReferences, isLoading: isLoadingAvailableCustomerReferences } = useFrontendApi(
    "getReferences",
    { baronaCompanyCode: baronaCompany, customerId, hidden: false }
  );

  const availableCustomerReferenceOptions: { label: string; value: string | null }[] = useMemo(() => {
    const alwaysAvailableCustomerReferenceOptions = [{ label: "Tyhjä", value: null }];
    if (!availableCustomerReferences) return alwaysAvailableCustomerReferenceOptions;
    return [
      ...availableCustomerReferences.map((reference) => ({
        label: reference.name ?? reference.value,
        value: reference.value,
      })),
      ...alwaysAvailableCustomerReferenceOptions,
    ];
  }, [availableCustomerReferences]);

  const [enabled, setEnabled] = useState<boolean>(true);
  const [name, setName] = useState<string | undefined>();
  const [groupBy, setGroupBy] = useState<typeof groupByOptions[number]>(defaultGroupByOption);
  const [invoiceByPaymentPeriod, setInvoiceByPaymentPeriod] = useState<typeof invoiceByPaymentPeriodOptions[number]>(
    defaultInvoiceByPaymentPeriodOption
  );
  const [referenceHandling, setReferenceHandling] =
    useState<typeof referenceHandlingOptions[number]>(defaultReferenceHandlingOption);
  const [customerReferenceFilter, setCustomerReferenceFilter] = useState<{ label: string; value: string | null }[]>([]);
  const [invoiceInfoId, setInvoiceInfoId] = useState<string | null>(null);
  const [customerReferenceFrom, setCustomerReferenceFrom] = useState<typeof customerReferenceFromOptions[number]>(
    defaultCustomerReferenceFromOption
  );
  const [customerReference, setCustomerReference] = useState<string>();
  const [ourReferenceFrom, setOurReferenceFrom] =
    useState<typeof ourReferenceFromOptions[number]>(defaultOurReferenceFromOption);
  const [ourReference, setOurReference] = useState<string>();

  const { result: invoiceInfos, isLoading: isInvoiceInfosLoading } = useFrontendApi("fetchCustomerInvoiceInfos", {
    baronaCompanyCode: baronaCompany,
    customerId,
  });

  const invoiceInfoOptions = useMemo(
    () =>
      (invoiceInfos ?? []).map((invoiceInfo) => ({
        label: invoiceInfo.salesforceId,
        value: invoiceInfo.invoiceInfoA2Id,
      })),
    [invoiceInfos]
  );

  const selectedInvoiceInfo =
    invoiceInfoOptions.find(({ value }) => value === invoiceInfoId) ??
    (invoiceInfoId
      ? {
          label: invoiceInfoId,
          value: invoiceInfoId,
        }
      : null);

  useEffect(() => {
    if (!invoicingModel) return;

    setEnabled(invoicingModel.enabled);
    setName(invoicingModel.name);
    setGroupBy(
      groupByOptions.find((option) => option.value === invoicingModel.invoiceRowGrouping) ?? defaultGroupByOption
    );
    setInvoiceByPaymentPeriod(
      invoiceByPaymentPeriodOptions.find((option) => option.value === invoicingModel.invoiceByPaymentPeriod) ??
        defaultInvoiceByPaymentPeriodOption
    );
    setReferenceHandling(
      invoicingModel.invoiceByReference
        ? referenceHandlingOptions.find((option) => option.value === "invoiceByReference")!
        : invoicingModel.customerReferenceFilter.length === 0
        ? referenceHandlingOptions.find((option) => option.value === "everyReferenceToOneInvoice")!
        : referenceHandlingOptions.find((option) => option.value === "invoiceBySelectedReference")!
    );

    setCustomerReferenceFilter(
      invoicingModel.customerReferenceFilter.map(
        (ref) =>
          availableCustomerReferenceOptions.find((availableRef) => availableRef.value === ref) ?? {
            label: ref ?? "Unknown",
            value: ref,
          }
      )
    );
    setInvoiceInfoId(invoicingModel.invoiceInfoId);
    setCustomerReferenceFrom(
      customerReferenceFromOptions.find((option) => option.value === invoicingModel.customerReferenceFrom) ??
        defaultCustomerReferenceFromOption
    );
    setCustomerReference(invoicingModel.customerReference ?? undefined);
    setOurReferenceFrom(
      ourReferenceFromOptions.find((option) => option.value === invoicingModel.ourReferenceFrom) ??
        defaultOurReferenceFromOption
    );
    setOurReference(invoicingModel.ourReference ?? undefined);
  }, [invoicingModel, availableCustomerReferenceOptions]);

  const onSaveClick = useCallback(async () => {
    if (!name) {
      addNotification("Nimi on pakollinen tieto", NotificationState.Error);
      return;
    }

    if (referenceHandling.value === "invoiceBySelectedReference" && customerReferenceFilter.length === 0) {
      addNotification(
        'Filtteriksi on valittava ainakin yksi viite, jos on valittu "Valituista viitteistä yksi lasku"',
        NotificationState.Error
      );
      return;
    }

    const commonRequestBody = {
      enabled,
      name,
      invoiceRowGrouping: groupBy.value,
      invoiceByPaymentPeriod: invoiceByPaymentPeriod.value,
      invoiceByReference: referenceHandling.value === "invoiceByReference",
      customerReferenceFilter:
        referenceHandling.value === "everyReferenceToOneInvoice"
          ? []
          : customerReferenceFilter.map((option) => option.value),
      customerReferenceFrom: customerReferenceFrom.value,
      customerReference: customerReference ?? null,
      ourReferenceFrom: ourReferenceFrom.value,
      ourReference: ourReference ?? null,
      invoiceInfoId,
      invoicePeriod: null,
      itemSource: ItemSource.MEPCO,
    };

    if (props.new) {
      try {
        await frontendApi.createInvoicingModel({
          ...commonRequestBody,
          baronaCompanyCode: baronaCompany as BaronaCompany,
          customerId,
        });
        navigate("..", {
          state: { notificationMessage: "Laskutusmallin tallennus onnistui" } as CrossPageNotificationState,
        });
      } catch (err) {
        console.error("Creating invoicing model failed", err);
        addNotification("Laskutusmallin tallennus epäonnistui", NotificationState.Error);
      }
    } else {
      if (!invoicingModelId) {
        console.error("Can't update invoicing model without id");
        return;
      }

      try {
        await frontendApi.updateInvoicingModel({
          ...commonRequestBody,
          invoicingModelId: invoicingModelId,
        });
        navigate("..", {
          state: { notificationMessage: "Laskutusmallin päivitys onnistui" } as CrossPageNotificationState,
        });
      } catch (err) {
        console.error("Updating invoicing model failed", err);
        addNotification("Laskutusmallin tallennus epäonnistui", NotificationState.Error);
      }
    }
  }, [
    addNotification,
    baronaCompany,
    customerId,
    customerReference,
    customerReferenceFilter,
    customerReferenceFrom.value,
    enabled,
    groupBy.value,
    invoiceByPaymentPeriod.value,
    invoiceInfoId,
    invoicingModelId,
    name,
    navigate,
    ourReference,
    ourReferenceFrom.value,
    props.new,
    referenceHandling.value,
  ]);

  return (
    <PageContainer>
      <LapaNavigationLink to={".."}>{"<"} Takaisin asiakkaan tietoihin</LapaNavigationLink>

      <Grid>
        <LeftContainer>
          <TitleContainer>
            <Title>{`${name ?? invoicingModel?.name ?? ""} / ${customerName || customerId}`}</Title>
          </TitleContainer>

          <NotificationContainer>
            <Notifications />
          </NotificationContainer>

          {isLoadingInvoicingModel && <Loading height={36} count={4} />}
          {((!isLoadingInvoicingModel && invoicingModel) || props.new) && (
            <>
              <InputContainer>
                <Label htmlFor="name">Nimi</Label>
                <TextInput
                  id="name"
                  value={name ?? ""}
                  placeholder="Anna nimi, josta muutkin tietävät, mistä on kyse"
                  onChange={(e) => setName(e.currentTarget.value || undefined)}
                />
              </InputContainer>

              {!props.new && (
                <CheckboxLabel>
                  <CheckboxInput checked={enabled} onChange={(e) => setEnabled(e.currentTarget.checked)} />
                  Käytössä
                </CheckboxLabel>
              )}

              <HorizontalSeparator />

              <SubTitle>Määrittele laskutustapa</SubTitle>

              <InputContainer>
                <Label htmlFor="groupBy">Laskurivien ryhmittely *</Label>
                <Select
                  inputId="groupBy"
                  options={groupByOptions}
                  value={groupBy}
                  onChange={(option) => setGroupBy(option ?? defaultGroupByOption)}
                  isOptionDisabled={(option) =>
                    option.value === InvoiceGroupBy.referenceAndPerson &&
                    referenceHandling.value === "invoiceByReference"
                  }
                />
              </InputContainer>
              <InputContainer>
                <Label htmlFor="invoiceByPaymentPeriod">Laskuta palkkakausittain *</Label>
                <Select
                  inputId="invoiceByPaymentPeriod"
                  options={invoiceByPaymentPeriodOptions}
                  value={invoiceByPaymentPeriod}
                  onChange={(option) => setInvoiceByPaymentPeriod(option ?? defaultInvoiceByPaymentPeriodOption)}
                />
              </InputContainer>

              <FullWidthInputContainer>
                <InlineInputContainer>
                  <Label htmlFor="referenceHandling">Viitteiden käsittely *</Label>
                  <Select
                    inputId="referenceHandling"
                    options={referenceHandlingOptions}
                    value={referenceHandling}
                    onChange={(option) => {
                      setReferenceHandling(option ?? defaultReferenceHandlingOption);
                      if (option?.value === "invoiceByReference") {
                        setCustomerReferenceFilter([]);
                      }
                      if (option?.value === "invoiceBySelectedReference" && customerReferenceFilter.length > 1) {
                        setCustomerReferenceFrom(customerReferenceFromOptions[1]);
                      }
                      if (option?.value === "everyReferenceToOneInvoice") {
                        setCustomerReferenceFilter([]);
                        setCustomerReferenceFrom(customerReferenceFromOptions[1]);
                      }
                    }}
                  />
                </InlineInputContainer>

                {referenceHandling.value === "invoiceBySelectedReference" && (
                  <InlineInputContainer>
                    <Label htmlFor="customerReferenceFilter">Valitse viite *</Label>
                    <CreateableSelect
                      inputId="customerReferenceFilter"
                      isMulti
                      onCreateOption={(option) => {
                        setCustomerReferenceFilter([...customerReferenceFilter, { label: option, value: option }]);
                        const newCustomerRefernceFilterCount = customerReferenceFilter.length + 1;
                        if (newCustomerRefernceFilterCount > 1) {
                          setCustomerReferenceFrom(customerReferenceFromOptions[1]);
                        }
                      }}
                      formatCreateLabel={(inputValue) => `Käytä viitteenä: ${inputValue}`}
                      noOptionsMessage={() => "Ei viitteitä"}
                      placeholder="Asiakkaan viite"
                      isLoading={isLoadingAvailableCustomerReferences}
                      options={availableCustomerReferenceOptions}
                      onChange={(option) => {
                        const optionArray = ((option as { label: string; value: string | null }[]) || null) ?? [];
                        setCustomerReferenceFilter(optionArray);
                        if (optionArray.length > 1) {
                          setCustomerReferenceFrom(customerReferenceFromOptions[1]);
                        }
                      }}
                      value={customerReferenceFilter}
                      isClearable
                    />
                  </InlineInputContainer>
                )}
              </FullWidthInputContainer>

              <HorizontalSeparator />

              <SubTitle>Määrittele laskun tiedot</SubTitle>

              <InputContainer>
                <Label>Asiakkaan invoice info</Label>
                <Select
                  placeholder="Invoice info"
                  noOptionsMessage={() => "Ei invoice infoja"}
                  isLoading={isInvoiceInfosLoading}
                  options={invoiceInfoOptions}
                  value={selectedInvoiceInfo}
                  onChange={(option) => setInvoiceInfoId(option?.value ?? null)}
                />
                {selectedInvoiceInfo?.label && (
                  <LapaLink
                    target="_blank"
                    href={`https://bravedo.lightning.force.com/lightning/r/Invoice_Information__c/${selectedInvoiceInfo.label}/view`}
                  >
                    <ExternalLinkIcon />
                    Näytä Salesforcessa
                  </LapaLink>
                )}
              </InputContainer>

              <FullWidthInputContainer>
                <InlineInputContainer>
                  <Label htmlFor="customerReferenceFrom">Asiakkaan viite</Label>
                  <Select
                    inputId="customerReferenceFrom"
                    options={customerReferenceFromOptions}
                    value={customerReferenceFrom}
                    onChange={(option) => {
                      setCustomerReferenceFrom(option ?? defaultCustomerReferenceFromOption);
                      if (option?.value === "customerReferenceFilter") {
                        setCustomerReference(undefined);
                      }
                    }}
                    isOptionDisabled={(option) =>
                      option.value === "customerReferenceFilter" &&
                      ((referenceHandling.value === "invoiceBySelectedReference" &&
                        customerReferenceFilter.length > 1) ||
                        referenceHandling.value === "everyReferenceToOneInvoice")
                    }
                  />
                </InlineInputContainer>

                {customerReferenceFrom.value === "manual" && (
                  <InlineInputContainer>
                    <Label htmlFor="customerReference">Syötä asiakkaan viite</Label>
                    <TextInput
                      id="customerReference"
                      value={customerReference ?? ""}
                      onChange={(e) => setCustomerReference(e.currentTarget.value || undefined)}
                    />
                  </InlineInputContainer>
                )}
              </FullWidthInputContainer>

              <FullWidthInputContainer>
                <InlineInputContainer>
                  <Label htmlFor="ourReferenceFrom">Baronan viite</Label>
                  <Select
                    inputId="ourReferenceFrom"
                    options={ourReferenceFromOptions}
                    value={ourReferenceFrom}
                    onChange={(option) => {
                      setOurReferenceFrom(option ?? defaultOurReferenceFromOption);
                      if (option?.value === "contactPerson") {
                        setOurReference(undefined);
                      }
                    }}
                  />
                </InlineInputContainer>

                {ourReferenceFrom.value === "manual" && (
                  <InlineInputContainer>
                    <Label htmlFor="ourReference">Syötä Baronan viite</Label>
                    <TextInput
                      id="ourReference"
                      value={ourReference ?? ""}
                      onChange={(e) => setOurReference(e.currentTarget.value || undefined)}
                    />
                  </InlineInputContainer>
                )}
              </FullWidthInputContainer>
            </>
          )}
        </LeftContainer>

        <RightContainer>
          <InstructionsColumn />
        </RightContainer>
      </Grid>

      <ContentSeparator />
      <ButtonContainer>
        <Button onClick={onSaveClick}>Tallenna</Button>
      </ButtonContainer>
    </PageContainer>
  );
};

export default InvoicingModelPage;
