import { frontendApi } from "@barona/lapa-common-frontend";
import { BaronaCompany, DimensionType } from "@barona/lapa-common-types";
import { useQuery } from "react-query";

type DimensionData = typeof frontendApi.getFrendsDimension.ResponseType;

export const useFrendsDimension = (
  dimensionCode: DimensionType,
  companyCode: BaronaCompany
): { dimensionValues: DimensionData["values"]; isLoading: boolean } => {
  const { data, isLoading } = useQuery(
    ["frendsDimension", companyCode, dimensionCode],
    async () => await frontendApi.getFrendsDimension({ dimensionCode, companyCode }),
    { staleTime: 5 * 60 * 1000 }
  );

  return { dimensionValues: data?.values || [], isLoading };
};
