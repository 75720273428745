import ReactSelect, { GroupBase, Props, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import Color from "../../colors";

const Select = <Option, IsMulti extends boolean = false>(
  props: Props<Option, IsMulti> & {
    width?: number;
    allowCreateOptions?: boolean;
    onCreateOption?: (option: string) => void;
  }
) => {
  const styleConfiguration: StylesConfig<Option, IsMulti, GroupBase<Option>> = {
    indicatorSeparator: () => ({ display: "none" }),
    container: (styles) => ({
      ...styles,
      minWidth: "auto",
    }),
    menu: (styles) => ({
      ...styles,
      fontSize: "14px",
      width: props.width ? `${props.width}px` : styles.width,
      color: Color.BLACK,
    }),
    control: (styles, state) => ({
      ...styles,
      fontSize: "14px",
      backgroundColor: `${state.isDisabled ? Color.DISABLED_INPUT : Color.WHITE}`,
      display: "flex",
      alignItems: "center",
      border: `solid 1px ${Color.GREY_LIGHT}`,
      color: Color.BLACK,
      borderRadius: "3px",
      transition: "box-shadow 0.1s linear",
      boxShadow: state.isFocused ? `0px 0px 0px 2px ${Color.LAPA_BLUE}` : "none",
      outline: "none",
      ":hover": {
        borderColor: Color.GREY_LIGHT,
      },
    }),
  };

  if (props.allowCreateOptions) {
    return (
      <CreatableSelect
        styles={styleConfiguration}
        {...props}
        allowCreateWhileLoading={false}
        formatCreateLabel={(inputValue) => `Lisää uusi: ${inputValue}`}
        loadingMessage={() => "Ladataan..."}
      />
    );
  }
  return <ReactSelect styles={styleConfiguration} {...props} loadingMessage={() => "Ladataan..."} />;
};

export default Select;
