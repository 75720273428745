import { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import mapValues from "lodash/mapValues";
import Color from "../../colors";
import Loading from "../Loading";

const StyledLoading = styled(Loading)``;

interface ItemTypeBase {
  label: string;
  value: any;
}

export interface Props<ItemType extends ItemTypeBase> {
  items: ReadonlyArray<ItemType>;
  onChange?: (value: ItemType[]) => any;
  className?: string;
  emptyMessage?: string;
  multiple?: boolean;
  value?: ItemType[];
  renderLoading?: boolean;
  disabled?: boolean;
}

const List = styled.ul<{ renderLoading?: boolean; disabled?: boolean }>`
  list-style: none;
  margin: 0;
  padding: 5px 0;
  border: solid 1px ${Color.GREY_LIGHT};
  border-radius: 3px;
  overflow-y: auto;
  background-color: ${Color.WHITE};
  ${StyledLoading} {
    margin: 0 5px;
  }
  ${(props) =>
    props.disabled &&
    `
     cursor: not-allowed;
     pointer-events: none;
     opacity: 0.6;
  `}
`;

const ListItem = styled.li<{ isSelected: boolean }>`
  padding: 8px;
  background-color: ${(props) => (props.isSelected ? Color.LIGHT_BLUE : Color.WHITE)};
  &:hover {
    background-color: ${(props) => (props.isSelected ? "#c1dffa" : "#f1f8ff")};
    cursor: pointer;
  }
`;

const EmptyMessage = styled.span`
  margin: 8px;
  display: block;
  color: ${Color.GREY};
`;

type SelectedItemsState<T> = { [key: string]: { item: T; selected: boolean } };

const getSelectedItems = <T,>(selectedItems: SelectedItemsState<T>): T[] =>
  Object.entries(selectedItems)
    .filter(([_key, value]) => value.selected)
    .map(([_key, value]) => value.item);

const ListSelect = <ItemType extends ItemTypeBase>(props: Props<ItemType>) => {
  const { items, onChange, multiple = false, renderLoading = false } = props;
  const uniqueItems = useMemo(() => uniqBy(items, "value"), [items]);
  const [selectedItemsState, setSelectedItemsState] = useState<SelectedItemsState<ItemType>>({});

  // Trigger onChange if the items change in a way that affects which items should still be selected
  useEffect(() => {
    if (props.value) {
      const newValues = props.value.filter((selectedItem) => uniqueItems.some((item) => isEqual(selectedItem, item)));
      if (newValues.length !== props.value.length) {
        onChange?.(newValues);
      }
    } else {
      const newSelectedItemsState = uniqueItems.reduce<SelectedItemsState<ItemType>>(
        (acc, item) => ({
          ...acc,
          [item.label]: { item, selected: selectedItemsState[item.label]?.selected ?? false },
        }),
        {}
      );
      setSelectedItemsState(newSelectedItemsState);

      const oldSelectedValues = getSelectedItems(selectedItemsState);
      const newSelectedValues = getSelectedItems(newSelectedItemsState);
      if (!isEqual(oldSelectedValues, newSelectedValues)) {
        onChange?.(newSelectedValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueItems]);

  const handleOnClick = (selectedItem: ItemType) => {
    if (props.value) {
      const isAlreadySelected = props.value.some((item) => isEqual(item.value, selectedItem.value));
      if (isAlreadySelected) {
        onChange?.(props.value.filter((item) => !isEqual(item.value, selectedItem.value)));
      } else {
        onChange?.(multiple ? [...props.value, selectedItem] : [selectedItem]);
      }
    } else {
      const newSelectedItemsState = {
        ...(multiple
          ? selectedItemsState
          : mapValues(selectedItemsState, (itemState) => ({ ...itemState, selected: false }))),
        [selectedItem.label]: { item: selectedItem, selected: !selectedItemsState[selectedItem.label].selected },
      };
      setSelectedItemsState(newSelectedItemsState);
      onChange?.(getSelectedItems(newSelectedItemsState));
    }
  };

  return (
    <List className={props.className} disabled={props.disabled}>
      {renderLoading ? (
        <StyledLoading height={35} count={5} />
      ) : (
        <>
          {uniqueItems.length === 0 && props.emptyMessage && <EmptyMessage>{props.emptyMessage}</EmptyMessage>}
          {uniqueItems.map((item) => (
            <ListItem
              data-testid="ListSelect-ListItem"
              key={item.label}
              onClick={() => handleOnClick(item)}
              isSelected={
                props.value
                  ? props.value.some(({ value }) => isEqual(value, item.value))
                  : selectedItemsState[item.label]?.selected ?? false
              }
            >
              {item.label}
            </ListItem>
          ))}
        </>
      )}
    </List>
  );
};

export default ListSelect;
