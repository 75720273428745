import { useEffect, useState, useMemo, useCallback } from "react";
import { frontendApi } from "@barona/lapa-common-frontend";

export const useFrontendApi = <apiName extends keyof typeof frontendApi>(
  apiFn: apiName,
  ...options: keyof typeof frontendApi[apiName]["CallArgsType"] extends never
    ? [params?: undefined, dependencyArray?: any[]]
    : [params: typeof frontendApi[apiName]["CallArgsType"] | null, dependencyArray?: any[]]
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = useMemo(() => options[0], options[1] ?? [...Object.values(options[0] ?? {})]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [result, setResult] = useState<typeof frontendApi[apiName]["ResponseType"]>();

  const [forceRefreshValue, setForceRefresh] = useState<{}>({});
  const forceRefresh = useCallback(() => setForceRefresh({}), []);

  useEffect(() => {
    let shouldCancel = false;
    (async () => {
      try {
        if (params === null) {
          setIsLoading(false);
          setError(undefined);
          setResult(undefined);
          return;
        }

        setIsLoading(true);
        setError(undefined);
        //@ts-ignore Expression produces a union type that is too complex to represent
        const result = await frontendApi[apiFn](params);

        if (!shouldCancel) {
          setResult(result);
          setIsLoading(false);
        }
      } catch (e) {
        if (!shouldCancel) {
          setError(e as any);
          setResult(undefined);
          setIsLoading(false);
        }
      }
    })();

    return () => {
      shouldCancel = true;
    };
  }, [apiFn, params, forceRefreshValue]);

  return { result, error, isLoading, forceRefresh };
};
