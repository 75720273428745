import { APIDeclaration } from "papupata";
import { APIGatewayEvent } from "aws-lambda";
import {
  BaronaCompany,
  CustomerInfo,
  PersistedItem,
  ProductPricing,
  Serialized,
  InvoiceSummaryItem,
  PersistedProductPriceGroup,
  InvoiceGroupBy,
  IInvoice,
} from "@barona/lapa-common-types";
import {
  CreateInvoiceRequestBody,
  CreateInvoiceResponse,
  GetPriceListResponse,
  UpdatePriceListRequest,
  UpdatePriceListResponse,
  UpdateProductPrcingsRequest,
  CreatePriceListWithProductPricingsRequest,
  GetProductsResponse,
  CreatePriceListWithProductPricingsResponse,
  GetInvoiceResponse,
  dimensionsApi,
  GetImportLogsResponse,
  invoicingApi,
  priceListsApi,
  InvoiceInfo,
  CompanySettingResponse,
} from "@barona/lapa-common-api-definitions";

export const frontendApiBase = new APIDeclaration<APIGatewayEvent>();

export interface Content {
  content: string;
}

export interface CustomerReference {
  content: string | null;
}
export interface PaymentGroup extends Content {}
export interface PaymentPeriod extends Content {}

export interface Customer {
  id: string;
  name: string;
}

export interface CustomerSite extends Customer {}

export type CustomerIdWithName = Omit<CustomerInfo, "baronaCompanyCode">;

export interface FilterParameters {
  baronaCompany?: BaronaCompany;
  customerId?: string;
  customerReferences?: (string | null)[];
  paymentGroup?: string;
  paymentPeriod?: string;
  startDate?: string;
  endDate?: string;
  groupBy?: InvoiceGroupBy;
}

export interface FetchReportItemsParams {
  invoiceIds: string[];
}

interface UninvoicedItemsResponse {
  items: UninvoicedItemsResponseItem[];
  hasAnyItemsWithoutPrice: boolean;
}

export interface UninvoicedItemsResponseItem extends Omit<PersistedItem, "id" | "itemDate" | "price"> {
  itemDate: string;
  price: number | null;
  priceListName: string;
}

type InvoicingApiInvoiceResponseElement = typeof invoicingApi.getInvoices.ResponseType["invoices"][number];
interface GetInvoicesResponseElement extends InvoicingApiInvoiceResponseElement {
  customerName?: string;
}

type GetInvoicesResponse = {
  invoices: GetInvoicesResponseElement[];
  paging: typeof invoicingApi.getInvoices.ResponseType["paging"];
};

export interface ImportMepcoCsvRequestBody {
  fileName: string;
  content: string;
}

export const frontendApi = {
  ping: frontendApiBase.declareGetAPI("/frontend/ping").response<"pong">(),
  fetchCustomers: frontendApiBase
    .declarePostAPI("/frontend/customers")
    .body<FilterParameters>()
    .response<CustomerIdWithName[]>(),
  fetchCustomerName: frontendApiBase
    .declareGetAPI("/frontend/customers/name")
    .query({ customerId: String })
    .response<string | undefined>(),
  fetchCustomerBusinessId: frontendApiBase
    .declareGetAPI("/frontend/customers/businessid")
    .query({ customerId: String })
    .response<string | undefined>(),
  fetchContactPersonDetails: frontendApiBase
    .declareGetAPI("/frontend/contact-persons")
    .query({ contactPersonId: [String] })
    .response<{ contactPersonId: string; name: string | null }[]>(),
  fetchCustomerInvoiceInfos: frontendApiBase
    .declareGetAPI("/frontend/customers/invoice-infos")
    .query({ customerId: String, baronaCompanyCode: String })
    .response<InvoiceInfo[]>(),
  fetchCustomerReferences: frontendApiBase
    .declarePostAPI("/frontend/references")
    .body<FilterParameters>()
    .response<CustomerReference[]>(),
  fetchPaymentGroups: frontendApiBase
    .declarePostAPI("/frontend/payment-groups")
    .body<FilterParameters>()
    .response<PaymentGroup[]>(),
  fetchPaymentPeriods: frontendApiBase
    .declarePostAPI("/frontend/payment-periods")
    .body<FilterParameters>()
    .response<PaymentPeriod[]>(),
  fetchUninvoicedItems: frontendApiBase
    .declarePostAPI("/frontend/items")
    .body<FilterParameters>()
    .response<UninvoicedItemsResponse>(),
  fetchDraftInvoicesCount: frontendApiBase
    .declareGetAPI("/frontend/invoice/count")
    .query({ baronaCompany: String })
    .response<typeof invoicingApi.fetchDraftInvoicesCount.ResponseType>(),
  fetchReportItems: frontendApiBase
    .declarePostAPI("/frontend/invoice/report")
    .body<FetchReportItemsParams>()
    .response<string>(),
  createInvoiceByFilters: frontendApiBase
    .declarePostAPI("/frontend/create-invoice-by-filters")
    .body<CreateInvoiceRequestBody>()
    .response<Serialized<CreateInvoiceResponse>>(),
  createManualInvoice: frontendApiBase
    .declarePostAPI("/frontend/create-invoice")
    .body<IInvoice>()
    .response<{ invoiceId: string }>(),
  sendInvoicesToNavQueue: frontendApiBase
    .declarePostAPI("/frontend/invoice/send")
    .body<{ invoiceIds: string[] }>()
    .response<void>(),
  reviseInvoice: frontendApiBase
    .declarePostAPI("/frontend/invoice/:invoiceId/revise")
    .params({ invoiceId: String })
    .response<typeof invoicingApi.reviseInvoice.ResponseType>(),
  getInvoices: frontendApiBase
    .declareGetAPI("/frontend/invoices")
    .optionalQuery({
      statuses: [String],
      baronaCompany: String,
      customer: String,
      customerReference: String,
      periodStart: String,
      periodEnd: String,
      invoiceDateStart: String,
      invoiceDateEnd: String,
      limit: Number,
      skip: Number,
    })
    .response<GetInvoicesResponse>(),
  getInvoice: frontendApiBase
    .declareGetAPI("/frontend/invoice/:invoiceId")
    .params({ invoiceId: String })
    .response<GetInvoiceResponse>(),
  updateInvoiceParameters: frontendApiBase
    .declarePatchAPI("/frontend/invoice/:invoiceId")
    .params({ invoiceId: String })
    .body<typeof invoicingApi.updateInvoice.BodyType>()
    .response<typeof invoicingApi.updateInvoice.ResponseType>(),
  updateInvoiceItems: frontendApiBase
    .declarePostAPI("/frontend/invoice/updateitems")
    .body<typeof invoicingApi.updateInvoiceItems.BodyType>()
    .response<typeof invoicingApi.updateInvoiceItems.ResponseType>(),
  updateInvoiceRow: frontendApiBase
    .declarePostAPI("/frontend/invoice/updaterow")
    .body<typeof invoicingApi.updateInvoiceRow.BodyType>()
    .response<typeof invoicingApi.updateInvoiceRow.ResponseType>(),
  priceUnpricedItems: frontendApiBase
    .declarePostAPI("/frontend/price-unpriced-items/:priceListId")
    .params({ priceListId: String })
    .response<typeof invoicingApi.priceUnpricedItems.ResponseType>(),
  deleteInvoice: frontendApiBase
    .declareDeleteAPI("/frontend/invoice/:invoiceId")
    .params({ invoiceId: String })
    .response<void>(),
  updateInvoiceStatus: frontendApiBase
    .declarePostAPI("/frontend/invoice/:invoiceId/status/:newStatus")
    .params({ invoiceId: String, newStatus: String })
    .response<void>(),
  getInvoiceRowItems: frontendApiBase
    .declareGetAPI("/frontend/invoice-row/:invoiceRowId/items")
    .params(["invoiceRowId"] as const)
    .response<Serialized<PersistedItem>[]>(),
  getInvoicesSummary: frontendApiBase
    .declareGetAPI("/frontend/invoices-summary")
    .query({
      invoiceIds: [String],
    })
    .response<InvoiceSummaryItem[]>(),
  getInvoiceCustomers: frontendApiBase
    .declareGetAPI("/frontend/invoices-customers")
    .optionalQuery({
      statuses: [String],
      baronaCompany: String,
      customerReference: String,
      periodStart: String,
      periodEnd: String,
      invoiceDateStart: String,
      invoiceDateEnd: String,
    })
    .response<Customer[]>(),
  getInvoiceReferences: frontendApiBase
    .declareGetAPI("/frontend/invoices-references")
    .optionalQuery({
      draft: String,
      baronaCompany: String,
      customer: String,
      periodStart: String,
      periodEnd: String,
    })
    .response<CustomerReference[]>(),
  getInvoicePeriods: frontendApiBase
    .declareGetAPI("/frontend/invoices-periods")
    .optionalQuery({
      draft: String,
      baronaCompany: String,
      customer: String,
      customerReference: String,
    })
    .response<{ periodStart: string; periodEnd: string }[], { periodStart: Date; periodEnd: Date }[]>(),
  searchPriceLists: frontendApiBase
    .declareGetAPI("/frontend/pricelists/search")
    .query({ customerId: String })
    .optionalQuery({ baronaCompany: String, searchFilter: String })
    .response<GetPriceListResponse>(),
  fetchProducts: frontendApiBase
    .declareGetAPI("/frontend/pricelists/products")
    .optionalQuery({ productTypes: [String] })
    .response<GetProductsResponse>(),
  createProduct: frontendApiBase
    .declarePostAPI("/frontend/pricelists/products/new")
    .body<
      Serialized<{
        name: string;
        productCode: string;
        type: string;
      }>
    >()
    .response<void>(),
  createPriceList: frontendApiBase
    .declarePostAPI("/frontend/pricelists/new")
    .body<CreatePriceListWithProductPricingsRequest["body"]>()
    .response<CreatePriceListWithProductPricingsResponse>(),
  cancelGroupOverridePrice: frontendApiBase
    .declarePatchAPI("/frontend/pricelists/cancel-price-override")
    .query({ priceListId: String, productCode: String, productGroupId: String })
    .response<void>(),
  createCompanySetting: frontendApiBase
    .declarePostAPI("/frontend/company-settings/new")
    .body<CompanySettingResponse>()
    .response<typeof priceListsApi.createCompanySetting.ResponseType>(),
  getCompanySetting: frontendApiBase
    .declareGetAPI("/frontend/company-settings/:baronaCompanyCode")
    .params({ baronaCompanyCode: String })
    .response<typeof priceListsApi.getCompanySetting.ResponseType>(),
  updateCompanySetting: frontendApiBase
    .declarePutAPI("/frontend/company-settings/:baronaCompanyCode")
    .params({ baronaCompanyCode: String })
    .body<typeof priceListsApi.updateCompanySetting.BodyType>()
    .response<typeof priceListsApi.updateCompanySetting.ResponseType>(),
  removeCompanySetting: frontendApiBase
    .declareDeleteAPI("/frontend/company-settings/:baronaCompanyCode")
    .params({ baronaCompanyCode: String })
    .response<typeof priceListsApi.removeCompanySetting.ResponseType>(),
  updateProductGroupPrices: frontendApiBase
    .declarePatchAPI("/frontend/pricelists/product-groups")
    .body<typeof priceListsApi.updateProductGroupPrices.BodyType>()
    .response<typeof priceListsApi.updateProductGroupPrices.ResponseType>(),
  updatePriceList: frontendApiBase
    .declarePatchAPI("/frontend/pricelists/:priceListId")
    .params({ priceListId: String })
    .body<UpdatePriceListRequest["body"]>()
    .response<UpdatePriceListResponse>(),
  getProductPriceGroups: frontendApiBase
    .declareGetAPI("/frontend/pricelists/product-groups/")
    .query({ baronaCompanyCode: String })
    .response<typeof priceListsApi.getProductPriceGroups.ResponseType>(),
  createProductGroup: frontendApiBase
    .declarePostAPI("/frontend/pricelists/product-groups")
    .body<{ groupName: string; baronaCompanyCode: string }>()
    .response<Serialized<PersistedProductPriceGroup>>(),
  moveProductsToProductGroup: frontendApiBase
    .declarePostAPI("/frontend/pricelists/product-groups/:productGroupId")
    .params({ productGroupId: String })
    .body<{ productCodes: string[] }>()
    .response<void>(),
  removeProductFromAGroup: frontendApiBase
    .declareDeleteAPI("/frontend/pricelists/product-groups/:productGroupId/products/:productCode")
    .params({ productGroupId: String, productCode: String })
    .response<void>(),
  removeProductGroup: frontendApiBase
    .declareDeleteAPI("/frontend/pricelists/product-groups/:productGroupId")
    .params({ productGroupId: String })
    .response<void>(),
  updateProductGroupPositions: frontendApiBase
    .declarePatchAPI("/frontend/pricelists/product-groups/sort")
    .body<typeof priceListsApi.updateProductGroupPositions.BodyType>()
    .response<void>(),
  fetchPriceList: frontendApiBase
    .declareGetAPI("/frontend/pricelists/:priceListId")
    .params({ priceListId: String })
    .response<typeof priceListsApi.getPriceListById.ResponseType>(),
  fetchPriceListHistory: frontendApiBase
    .declareGetAPI("/frontend/pricelisthistory/:priceListId")
    .params({ priceListId: String })
    .response<typeof priceListsApi.getPriceListProductHistory.ResponseType>(),
  fetchProductPricingsWithoutGroup: frontendApiBase
    .declareGetAPI("/frontend/pricelists/:priceListId/product-pricings-without-group")
    .params({ priceListId: String })
    .optionalQuery({ searchFilter: String })
    .response<Serialized<ProductPricing>[]>(),
  fetchProductPricingsGroups: frontendApiBase
    .declareGetAPI("/frontend/pricelists/product-group/prices")
    .optionalQuery({ priceListId: String, baronaCompanyCode: String })
    .response<typeof priceListsApi.getProductPricingsGroups.ResponseType>(),
  fetchProductPricingForProductGroup: frontendApiBase
    .declareGetAPI("/frontend/pricelists/product-pricings-for-group/pricings")
    .optionalQuery({ priceListId: String, productGroupId: String })
    .response<typeof priceListsApi.getProductPricingForProductGroup.ResponseType>(),
  updateProductPricings: frontendApiBase
    .declarePutAPI("/frontend/pricelists/:priceListId/products")
    .params({ priceListId: String })
    .body<UpdateProductPrcingsRequest["body"]>()
    .response<Serialized<ProductPricing>[]>(),
  removeProductFromPricelist: frontendApiBase
    .declareDeleteAPI("/frontend/pricelists/:priceListId/products/:productCode")
    .params({ priceListId: String, productCode: String })
    .response<void>(),
  searchCustomers: frontendApiBase
    .declareGetAPI("/frontend/customers/search")
    .query({ searchFilter: String })
    .optionalQuery({ baronaCompany: String, withPricelists: String })
    .response<
      {
        customerId: string;
        businessId?: string;
        customerName: string;
        priceListsCount: number;
        referenceCount: number;
        invoiceInfoCount: number;
        invoicingModelCount: number;
      }[]
    >(),
  importMepcoCsv: frontendApiBase.declarePostAPI("/frontend/import").body<ImportMepcoCsvRequestBody>().response<void>(),
  getImportLogs: frontendApiBase
    .declareGetAPI("/frontend/import-log")
    .optionalQuery({
      importedBy: String,
      limit: Number,
      skip: Number,
    })
    .response<GetImportLogsResponse>(),
  removeImportedItems: frontendApiBase
    .declareDeleteAPI("/frontend/items/imported")
    .query({ batchKey: String })
    .response<void>(),
  removeItems: frontendApiBase
    .declareDeleteAPI("/frontend/items")
    .body<typeof invoicingApi.removeItems.BodyType>()
    .response<typeof invoicingApi.removeItems.ResponseType>(),
  getImportLogErrors: frontendApiBase
    .declareGetAPI("/frontend/import-log/:importLogId/errors")
    .params({ importLogId: String })
    .response<typeof invoicingApi.getImportLogErrors.ResponseType>(),
  getReferences: frontendApiBase
    .declareGetAPI("/frontend/dimensions/references")
    .optionalQuery({ baronaCompanyCode: String, customerId: String, hidden: Boolean })
    .response<typeof dimensionsApi.getReferences.ResponseType>(),
  addReference: frontendApiBase
    .declarePostAPI("/frontend/dimensions/references")
    .body<typeof dimensionsApi.addReference.BodyType>()
    .response<typeof dimensionsApi.addReference.ResponseType>(),
  updateReference: frontendApiBase
    .declarePatchAPI("/frontend/dimensions/references/:referenceId")
    .params({ referenceId: String })
    .body<typeof dimensionsApi.updateReference.BodyType>()
    .response<typeof dimensionsApi.updateReference.ResponseType>(),
  getInvoicingModels: frontendApiBase
    .declareGetAPI("/frontend/invoicing-models")
    .query({
      baronaCompanyCode: String,
      customerId: String,
    })
    .response<typeof invoicingApi.getInvoicingModels.ResponseType>(),
  updateInvoicingModelRunOrders: frontendApiBase
    .declarePostAPI("/frontend/invoicing-models/update-run-orders")
    .body<typeof invoicingApi.updateInvoicingModelRunOrders.BodyType>()
    .response<typeof invoicingApi.updateInvoicingModelRunOrders.ResponseType>(),
  getInvoicingModel: frontendApiBase
    .declareGetAPI("/frontend/invoicing-models/:invoicingModelId")
    .params(["invoicingModelId"] as const)
    .response<typeof invoicingApi.getInvoicingModel.ResponseType>(),
  updateInvoicingModel: frontendApiBase
    .declarePatchAPI("/frontend/invoicing-models/:invoicingModelId")
    .params(["invoicingModelId"] as const)
    .body<typeof invoicingApi.updateInvoicingModel.BodyType>()
    .response<typeof invoicingApi.updateInvoicingModel.ResponseType>(),
  createInvoicingModel: frontendApiBase
    .declarePostAPI("/frontend/invoicing-models")
    .body<typeof invoicingApi.createInvoicingModel.BodyType>()
    .response<typeof invoicingApi.createInvoicingModel.ResponseType>(),
  getFrendsDimension: frontendApiBase
    .declareGetAPI("/frontend/frends/dimensions/:dimensionCode") // cant remove the frends reference because of the references endpoints
    .params(["dimensionCode"] as const)
    .query({ companyCode: String })
    .response<typeof invoicingApi.getFrendsDimension.ResponseType>(),
  deleteInvoiceRow: frontendApiBase
    .declareDeleteAPI("/frontend/invoice/:invoiceId/deleteInvoiceRow/:invoiceRowId")
    .params({ invoiceId: String, invoiceRowId: String })
    .query({ invoiceType: String })
    .response<void>(),
  deleteInvoiceItemRow: frontendApiBase
    .declareDeleteAPI("/frontend/invoice/:invoiceId/deleteInvoiceItemRow/:itemId")
    .params({ invoiceId: String, itemId: String })
    .response<void>(),
  createInvoiceRow: frontendApiBase
    .declarePostAPI("/frontend/invoice/createInvoiceRow")
    .body<typeof invoicingApi.createInvoiceRow.BodyType>()
    .response<typeof invoicingApi.createInvoiceRow.ResponseType>(),
  createInvoiceItem: frontendApiBase
    .declarePostAPI("/frontend/invoice/createInvoiceItem")
    .body<typeof invoicingApi.createInvoiceItem.BodyType>()
    .response<typeof invoicingApi.createInvoiceItem.ResponseType>(),
};

export type FetchCustomersRequest = typeof frontendApi.fetchCustomers.RequestType;
export type FetchCustomerReferencesRequest = typeof frontendApi.fetchCustomerReferences.RequestType;
export type FetchPaymentGroupsRequest = typeof frontendApi.fetchPaymentGroups.RequestType;
export type FetchPaymentPeriodsRequest = typeof frontendApi.fetchPaymentPeriods.RequestType;

export type FetchCustomerResponse = typeof frontendApi.fetchCustomers.ResponseType;
export type FetchCustomerReferencesResponse = typeof frontendApi.fetchCustomerReferences.ResponseType;
export type FetchPaymentGroupsResponse = typeof frontendApi.fetchPaymentGroups.ResponseType;
export type FetchPaymentPeriodsResponse = typeof frontendApi.fetchPaymentPeriods.ResponseType;

export type FetchUninvoicedItemsResponse = typeof frontendApi.fetchUninvoicedItems.ResponseType;

export type FetchReportItemsRequest = typeof frontendApi.fetchReportItems.RequestType;
export type FetchReportItemsResponse = typeof frontendApi.fetchReportItems.ResponseType;

export type SearchCustomerRequest = typeof frontendApi.searchCustomers.RequestType;
export type FetchCustomerNameRequest = typeof frontendApi.fetchCustomerName.RequestType;
export type FetchCustomerBusinessIdRequest = typeof frontendApi.fetchCustomerBusinessId.RequestType;
export type FetchContactPersonDetailsRequest = typeof frontendApi.fetchContactPersonDetails.RequestType;
export type FetchCustomerInvoiceInfos = typeof frontendApi.fetchCustomerInvoiceInfos.RequestType;
export type ImportMepcoCsvRequest = typeof frontendApi.importMepcoCsv.RequestType;
