import styled from "styled-components";
import Loading from "../../components/Loading";
import { useImportLogErrors } from "../../services/importLogErrors";
import uniqWith from "lodash/uniqWith";
import { SidePaneContent, HeaderContainer, CloseIcon } from "../InvoicePage/CommonPanelContent";
import ImportLogErrorTable, { ImportLogErrorTableProps } from "./ImportLogErrorTable";
import { ImportLogError, Serialized } from "@barona/lapa-common-types";
import Color from "../../colors";
import { sortErrors } from "./ImportLogErrorUtils";

export interface importLogErrorSidePanelContentProps {
  importLogId: string;
  setIsOpen: (isOpen: boolean) => void;
}

const getHeaderFromError = (error: Serialized<ImportLogError>) => {
  if (error.name === "ParsingError") {
    return error.metadata.header;
  }
  return "no-header";
};

const HelpTextContainer = styled.div`
  margin-bottom: 18px;
`;

const BoldValue = styled.span`
  font-weight: bold;
`;

const Link = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${Color.LAPA_BLUE};
  margin: 0;
`;

const getErrorHelpText = (error: Serialized<ImportLogError>) => {
  switch (error.name) {
    case "ParsingError": {
      switch (error.metadata.header) {
        case "As. nro":
          return (
            <>
              <BoldValue>Asiakas-ID:n arvoa ei saatu luettua:</BoldValue> Korjaa asiakas-ID UUID-muotoon
              xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx ja lataa tiedosto uudelleen
            </>
          );
        case "Ryhmälyhenne":
          return (
            <>
              <BoldValue>Ryhmälyhennettä ei saatu luettua:</BoldValue> Ryhmälyhenteen tulee olla neljä merkkiä pitkä.
              Korjaa ryhmälyhenne oikeaan muotoon ja lataa tiedosto uudelleen
            </>
          );
        case "Tapahtumapäivä":
          return (
            <>
              <BoldValue>Tapahtumapäivää ei saatu luettua:</BoldValue> Korjaa tapahtumapäivät muotoon p-k.vvvv t.mm.ss
              ja lataa tiedosto uudelleen
            </>
          );
        case "Toimipiste":
          return (
            <>
              <BoldValue>Toimipiste-ID:n arvoa ei saatu luettua:</BoldValue> Korjaa Toimipiste-ID UUID-muotoon
              xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx ja lataa tiedosto uudelleen
            </>
          );
        default:
          return "";
      }
    }
    case "DuplicateItemError":
      return (
        <>
          <BoldValue>Rivi on jo tuotu Lapaan:</BoldValue> Jos aineisto on jo tuotu, mutta uusi aineisto sisältää
          päivitettyä tietoa, poista aikaisempi versio ja yritä uudestaan. Mikäli vain osa riveistä on tuotu, poista ne
          uudesta aineistosta ja yritä uudestaan
        </>
      );
    case "MissingPriceListFromStaffingContractError":
      return (
        <>
          <BoldValue>Tilaussopimukselta puuttuu hinnasto:</BoldValue> Lisää hinnasto tilaussopimukselle S2:ssa, ja lataa
          aineisto uudelleen
        </>
      );
    case "MissingInformationError":
      return (
        <>
          <BoldValue>Aineiston riviltä puuttuu arvo: </BoldValue> Lisää aineistoriveille puuttuva tieto ja lataa
          aineisto uudelleen
        </>
      );
    case "MissingProductError":
      return (
        <>
          <BoldValue>Tuote puuttuu LAPA:sta:</BoldValue> Pyydä tuotteen lisäämistä{" "}
          <Link
            target="_blank"
            href={`https://teams.microsoft.com/l/channel/19%3a7ce8065e425f42fc888bc807d4582eeb%40thread.tacv2/General?groupId=75043c0b-812b-4154-839e-adc2c48f5e6e&tenantId=10351e6d-1517-4d1d-b6bb-4fdfe0e2eec6`}
          >
            täältä.
          </Link>{" "}
          Tuotteen lisäämisen jälkeen lataa aineisto uudelleen
        </>
      );
    case "NoItemsFoundInMepcoFileError":
      return <>Tiedostosta ei löytynyt tapahtumia. Tarkasta tiedoston tapahtumat ja yritä uudelleen.</>;
    case "MalformedMepcoFileError":
      const standardText = "Tiedoston muoto on virheellinen. Varmista tiedoston rakenne ja yritä uudestaan.";
      const missingLineText = error.metadata.lineNumber ? ` Virhe rivillä ${error.metadata.lineNumber}.` : "";
      return <>{standardText + missingLineText}</>;
    case "MissingStaffingContractError":
      return (
        <>
          <BoldValue>Tilaussopimusta ei löytynyt:</BoldValue> tarkista tilaussopimuksen voimassaoloaika ja tila S2:sta
        </>
      );
    case "UnableToUpdateError":
      return (
        <>
          <BoldValue>Rivi on jo laskutettu ja viety NAViin:</BoldValue> NAViin vietyä aineistoa ei voi päivittää.
        </>
      );
    case "MepcoLineMissingColumnError":
      return (
        <>
          Rivillä <BoldValue>{error.metadata.lineNumber}</BoldValue> on väärä määrä sarakkeita.
        </>
      );
    case "ForwardInvoicingError":
      return (
        <>
          <BoldValue>Laskuttava yhtiö ja tilaussopimukselle merkitty yhtiö eivät täsmää:</BoldValue> Korjaa
          tilaussopimukselle merkitty yhtiö siten, että se vastaa laskuttavaa yhtiötä.
        </>
      );
  }
};

const createHelpTextKey = (error: Serialized<ImportLogError>) => {
  if (error.name === "ParsingError") {
    return `${error.name}.${error.metadata.header}`;
  }
  return error.name;
};

const ErrorHelpTexts = ({ errors, isLoading }: ImportLogErrorTableProps) => {
  const sortedErrors = sortErrors(
    uniqWith(
      errors,
      (error1, error2) => error1.name === error2.name && getHeaderFromError(error1) === getHeaderFromError(error2)
    )
  );
  return (
    <>
      {!isLoading &&
        sortedErrors.map((error) => (
          <HelpTextContainer key={createHelpTextKey(error)}>{getErrorHelpText(error)}</HelpTextContainer>
        ))}
      {isLoading && <Loading />}
    </>
  );
};

const ImportLogErrorSidePanelContent = ({ importLogId, setIsOpen }: importLogErrorSidePanelContentProps) => {
  const { importLogErrors, isLoading } = useImportLogErrors(importLogId);

  return (
    <SidePaneContent>
      <HeaderContainer>
        <h2>{`Virhe tiedoston lataamisessa`}</h2>
        <div>
          <CloseIcon data-testid="closeIcon" onClick={() => setIsOpen(false)} />
        </div>
      </HeaderContainer>
      <div>
        <h3>Aineistosta löytyi seuraavanlaisia virheitä:</h3>
        <ErrorHelpTexts errors={importLogErrors} isLoading={isLoading} />
        <ImportLogErrorTable errors={importLogErrors} isLoading={isLoading} />
      </div>
    </SidePaneContent>
  );
};

export default ImportLogErrorSidePanelContent;
