import { useState, useEffect } from "react";

export const useDebounce = <T>(initialState: T, delayInMs: number = 400) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(initialState);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(initialState);
    }, delayInMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [initialState, delayInMs]);

  return debouncedValue;
};
