import { PersistedEntry } from "./persistedTypes";

export enum ImportLogStatus {
  Uploaded = "uploaded",
  Processing = "processing",
  Failed = "failed",
  Succeeded = "succeeded",
  Deleted = "deleted",
}

export interface ImportLog {
  importLogId: string;
  fileName: string;
  status: ImportLogStatus;
  importedBy: string;
}

export interface PersistedImportLog extends ImportLog, PersistedEntry {
  createdAt: Date;
  updatedAt: Date | null;
  canRemoveImportedItems: boolean;
  errorCount: number;
}

export enum ImportLogErrorSource {
  STAFFING_CONTRACT = "STAFFING_CONTRACT",
  PRICELIST = "PRICELIST",
  ITEM = "ITEM",
}

export interface ImportLogErrorMetaSource {
  source: ImportLogErrorSource;
}

export interface ImportLogErrorMetaPricelist {
  pricelistId: string;
}

export interface ImportLogErrorMetaItem {
  itemId: string;
}

export interface ImportLogErrorMetaCustomer {
  customerId: string;
}

export interface ImportLogErrorFileName {
  fileName: string;
}

export interface ImportLogErrorDuplicateItemMeta extends ImportLogErrorMetaItem, ImportLogErrorMetaSource {}

export interface ImportLogErrorMalformedMepcoFileMeta extends ImportLogErrorFileName {
  lineNumber?: number;
}

export interface ImportLogErrorNoItemsMeta extends ImportLogErrorFileName {}

export interface ImportLogErrorUnableToUpdateItemMeta extends ImportLogErrorMetaItem, ImportLogErrorMetaSource {}

export interface ImportLogErrorForwardInvoicingMeta extends ImportLogErrorMetaItem, ImportLogErrorMetaSource {}

export interface ImportLogErrorMissingInformationMeta extends ImportLogErrorMetaItem, ImportLogErrorMetaSource {
  missingValue: string;
}

export interface ImportLogErrorMissingStaffingContractMeta extends ImportLogErrorMetaItem, ImportLogErrorMetaSource {
  contractNumber: string;
  date: string;
  employeeNumberDimension: string;
}

export interface ImportLogErrorMissingPriceListFromStaffingContractMeta extends ImportLogErrorMetaSource {
  contractNumber: string;
  staffingContractId: string;
}

export interface ImportLogErrorNoProductMeta extends ImportLogErrorMetaSource {
  productCode: string;
}

export interface ImportLogErrorFailedProductPricingMeta extends ImportLogErrorMetaCustomer, ImportLogErrorMetaSource {
  productCode: string;
  baronaCompanyCode: string;
}

export interface ImportLogErrorParsingMeta extends ImportLogErrorMetaItem, ImportLogErrorMetaSource {
  header: string;
  value: string;
}

export interface ImportLogErrorMepcoLineMissingColumnMeta {
  lineNumber: number;
}

export interface ImportLogErrorBase {
  name: string; // have to match error name, i.e. "MissingStaffingContractError"
  rawError: string | null;
  metadata:
    | ImportLogErrorDuplicateItemMeta
    | ImportLogErrorMalformedMepcoFileMeta
    | ImportLogErrorNoProductMeta
    | ImportLogErrorMissingInformationMeta
    | ImportLogErrorMissingStaffingContractMeta
    | ImportLogErrorMissingPriceListFromStaffingContractMeta
    | ImportLogErrorFailedProductPricingMeta
    | ImportLogErrorParsingMeta
    | ImportLogErrorUnableToUpdateItemMeta
    | ImportLogErrorMepcoLineMissingColumnMeta
    | ImportLogErrorForwardInvoicingMeta;
  createdAt?: Date;
  importLogId?: string;
}

export interface DuplicateItemImportLogError extends ImportLogErrorBase {
  name: "DuplicateItemError";
  metadata: ImportLogErrorDuplicateItemMeta;
}

export interface MalformedMepcoFileImportLogError extends ImportLogErrorBase {
  name: "MalformedMepcoFileError";
  metadata: ImportLogErrorMalformedMepcoFileMeta;
}

export interface NoItemsImportLogError extends ImportLogErrorBase {
  name: "NoItemsFoundInMepcoFileError";
  metadata: ImportLogErrorNoItemsMeta;
}

export interface NoProductFoundImportLogError extends ImportLogErrorBase {
  name: "MissingProductError";
  metadata: ImportLogErrorNoProductMeta;
}

export interface MissingInformationImportLogError extends ImportLogErrorBase {
  name: "MissingInformationError";
  metadata: ImportLogErrorMissingInformationMeta;
}

export interface MissingStaffingContractImportLogError extends ImportLogErrorBase {
  name: "MissingStaffingContractError";
  metadata: ImportLogErrorMissingStaffingContractMeta;
}

export interface MissingPriceListFromStaffingContractImportLogError extends ImportLogErrorBase {
  name: "MissingPriceListFromStaffingContractError";
  metadata: ImportLogErrorMissingPriceListFromStaffingContractMeta;
}

export interface FailedProductPricingImportLogError extends ImportLogErrorBase {
  name: "FailedProductPricingImportLogError";
  metadata: ImportLogErrorFailedProductPricingMeta;
}

export interface ParsingImportLogError extends ImportLogErrorBase {
  name: "ParsingError";
  metadata: ImportLogErrorParsingMeta;
}

export interface UnableToUpdateImportLogError extends ImportLogErrorBase {
  name: "UnableToUpdateError";
  metadata: ImportLogErrorUnableToUpdateItemMeta;
}
export interface MepcoLineMissingColumnImportLogError extends ImportLogErrorBase {
  name: "MepcoLineMissingColumnError";
  metadata: ImportLogErrorMepcoLineMissingColumnMeta;
}

export interface ForwardInvoicingImportLogError extends ImportLogErrorBase {
  name: "ForwardInvoicingError";
  metadata: ImportLogErrorForwardInvoicingMeta;
}

export type ImportLogError =
  | DuplicateItemImportLogError
  | MalformedMepcoFileImportLogError
  | NoItemsImportLogError
  | NoProductFoundImportLogError
  | MissingInformationImportLogError
  | MissingStaffingContractImportLogError
  | MissingPriceListFromStaffingContractImportLogError
  | FailedProductPricingImportLogError
  | ParsingImportLogError
  | UnableToUpdateImportLogError
  | MepcoLineMissingColumnImportLogError
  | ForwardInvoicingImportLogError;
