import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import Color from "../../colors";

const Container = styled.div`
  width: 1200px;
  margin: 10px auto;
  padding: 25px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: ${Color.WHITE};
  &:only-of-type {
    margin: 20px auto;
  }
`;

const PageContainer: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <Container data-testid="PageContainer">{children}</Container>
);

export default PageContainer;
