import styled, { css } from "styled-components";
import PageContainer from "../common/PageContainer";
import { Title } from "../common/Title";
import Notifications from "../common/Notifications";
import { NavLink, NavLinkProps, useNavigate } from "react-router-dom";
import Color from "../../colors";
import DraftInvoices from "./DraftInvoices";
import ArchivedInvoices from "./ArchivedInvoices";
import { useSelectedInvoiceTab } from "./hooks/useSelectedInvoiceTab";
import { SecondaryButton } from "../../components/SecondaryButton";
import NewInvoiceModal from "./NewManualInvoiceModal";
import { useBaronaCompany } from "../../context";
import { useState } from "react";

const SubNavigationBar = styled.nav`
  display: flex;
  align-items: center;
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
  margin: 0 -27px 16px;
  padding: 0 27px;
`;

const ExplicitlyActiveLink = (props: NavLinkProps & { active: boolean }) => {
  const { active, ...rest } = props;
  return <NavLink {...rest} />;
};

const SubNavigationBarLink = styled(ExplicitlyActiveLink)`
  font-size: 14px;
  text-decoration: none;
  color: ${Color.GREY};
  margin-right: 40px;
  padding: 20px 0;
  ${({ active }) =>
    active &&
    css`
      border-bottom: solid 4px ${Color.LAPA_BLUE};
      margin-top: 4px;
      color: ${Color.BLACK};
    `}
`;

const SubPageContainer = styled.div`
  padding-top: 16px;
`;

const InvoicesPage = () => {
  const { baronaCompany } = useBaronaCompany();
  const selectedInvoiceType = useSelectedInvoiceTab();
  const [isNewInvoiceOpen, setIsNewInvoiceOpen] = useState<boolean>(false);
  const [refreshDraftInvoices, setRefreshDraftInvoices] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <PageContainer>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>Laskut</Title>
        <SecondaryButton
          data-testid="NewInvoiceButton"
          onClick={() => {
            setIsNewInvoiceOpen(!isNewInvoiceOpen);
            setRefreshDraftInvoices(false);
          }}
        >
          Uusi lasku
        </SecondaryButton>
      </div>
      <Notifications />
      <SubNavigationBar>
        <SubNavigationBarLink to=".?type=draft" active={selectedInvoiceType === "draft"}>
          Avoimet laskut
        </SubNavigationBarLink>
        <SubNavigationBarLink to=".?type=archive" active={selectedInvoiceType === "archive"}>
          Arkisto
        </SubNavigationBarLink>
      </SubNavigationBar>

      <SubPageContainer>
        {selectedInvoiceType === "draft" ? <DraftInvoices refresh={refreshDraftInvoices} /> : <ArchivedInvoices />}
      </SubPageContainer>
      {isNewInvoiceOpen && (
        <NewInvoiceModal
          show={isNewInvoiceOpen}
          onCancel={() => setIsNewInvoiceOpen(!isNewInvoiceOpen)}
          onSubmit={(invoiceId: string) => {
            setIsNewInvoiceOpen(!isNewInvoiceOpen);
            navigate(`/invoices/${invoiceId}?type=draft`);
          }}
          baronaCompany={baronaCompany}
        />
      )}
    </PageContainer>
  );
};

export default InvoicesPage;
