import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckboxInput from "../../components/form/CheckboxInput";
import Loading from "../../components/Loading";
import {
  Cell,
  HeaderCell,
  HeaderRow,
  RigthAlignCell,
  RigthAlignHeaderCell,
  Row,
  Table,
  TableBody,
} from "../../components/Table";
import TablePagination from "../../components/Table.Pagination";
import { Invoice } from "../../services/invoices";
import { LapaNavigationLink } from "../../components/Link";
import { InvoiceParameters, InvoiceParametersProps } from "./components/InvoiceParameters";
import { dateISOStringToLocaleDateString, dateToLocaleDateString } from "@barona/lapa-common-date-utils";
import { useLocation } from "react-router-dom";
import { DraftInvoiceActionsMessage } from "../InvoicePage/DraftInvoiceActions";
import { NotificationState, useNotifications } from "../../context";
import { InvoiceSource, InvoiceStatus } from "@barona/lapa-common-types";
import { InvoiceTabType } from "./hooks/useSelectedInvoiceTab";
import { partition } from "lodash";
import SidePanel from "../../components/SidePanel";
import InvoiceListErrorContent from "./InvoiceListErrorContent";
import Big from "big.js";
import { formatToEuroCurrencyString } from "../../util/money";

const LinkHeaderCell = styled(HeaderCell)`
  width: 40px;
`;

const Container = styled.div`
  padding-bottom: 16px;
`;

const BoldRigthAlignCell = styled(RigthAlignCell)`
  font-weight: bold;
`;

const invoiceStateOptions = new Map<InvoiceStatus, string>([
  [InvoiceStatus.discarded, "Hylätty"],
  [InvoiceStatus.draft, "Avoin"],
  [InvoiceStatus.inTransit, "Lähetetään"],
  [InvoiceStatus.sent, "Lähetetty"],
]);

const getInvoiceNotifications = (invoice: Invoice) => {
  const parameters: InvoiceParametersProps["filters"] = [];
  if (!invoice.navError) {
    parameters.push({ type: "info", text: invoiceStateOptions.get(invoice.status) || "" });
  }
  if (invoice.source === InvoiceSource.salesforce) {
    parameters.push({
      type: "notice",
      text: "Salesforce-lasku",
    });
  } else if (invoice.source === InvoiceSource.manual) {
    parameters.push({
      type: "notice",
      text: "Lisätty käsin",
    });
  }

  if (invoice.hasUpdatedItems) {
    parameters.push({
      type: "info",
      text: "Tapahtumia päivitetty",
    });
  }
  return parameters;
};

const mapInvoiceParameters = (invoice: Invoice): InvoiceParametersProps["filters"] => {
  const { customerReferences, rangeStart, rangeEnd, paymentGroup, paymentPeriod } = invoice.filters;
  const parameters: InvoiceParametersProps["filters"] = [];

  if (invoice.source === InvoiceSource.lapa) {
    customerReferences?.forEach((reference) => {
      parameters.push({ type: "info", text: reference === null ? "Tyhjä" : reference });
    });
    paymentGroup && parameters.push({ type: "info", text: paymentGroup });

    if (rangeStart && !rangeEnd) {
      parameters.push({ type: "info", text: `alkaen ${dateISOStringToLocaleDateString(rangeStart)}` });
    } else if (!rangeStart && rangeEnd) {
      parameters.push({ type: "info", text: `${dateISOStringToLocaleDateString(rangeEnd)} asti` });
    } else if (rangeStart && rangeEnd) {
      parameters.push({
        type: "info",
        text: `${dateISOStringToLocaleDateString(rangeStart)} - ${dateISOStringToLocaleDateString(rangeEnd)}`,
      });
    }
    paymentPeriod &&
      parameters.push({
        type: "info",
        text: `${dateISOStringToLocaleDateString(paymentPeriod.start)} - ${dateISOStringToLocaleDateString(
          paymentPeriod.end
        )}`,
      });
  }
  return parameters;
};

const invoiceRequiresUserInput = (invoice: Invoice): boolean => {
  if (!invoice.invoiceInfoId) return true;
  if (invoice.ourReferenceFrom === "contactPerson" && !invoice.contactPerson) return true;
  if (invoice.navError) return true;
  return false;
};

const isInvoiceSelectable = (invoice: Invoice): boolean => {
  if (invoice.status === InvoiceStatus.inTransit) return false;
  return !invoiceRequiresUserInput(invoice);
};

interface Props {
  invoices: Invoice[];
  handleOnInvoicesOnSelect?: (selectedInvoiceIds: string[]) => void;
  selectedInvoiceIds?: string[];
  isLoading?: boolean;
  type?: InvoiceTabType;
  currentPage?: number;
  onSetCurrentPage?: (page: number) => void;
  pageCount?: number;
  pageSize?: number;
  onSetPageSize?: (pageSize: number) => void;
}

const InvoiceList = (props: Props) => {
  const {
    invoices,
    handleOnInvoicesOnSelect,
    isLoading,
    selectedInvoiceIds = [],
    onSetCurrentPage,
    onSetPageSize,
    currentPage = 1,
    pageCount = 1,
    pageSize = 10,
  } = props;

  const [invoicesWithErrors, invoicesWithoutErrors] = partition(invoices, (invoice) =>
    invoiceRequiresUserInput(invoice)
  );

  const allSelectableInvoiceIds = invoices.filter(isInvoiceSelectable).map((invoice) => invoice.invoiceId);
  const isAllChecked =
    allSelectableInvoiceIds.every((id) => selectedInvoiceIds.includes(id)) && !!allSelectableInvoiceIds.length;
  const { addNotification } = useNotifications();
  const location = useLocation();
  const locationState = location.state as DraftInvoiceActionsMessage;

  const isPagingEnabled = !!onSetCurrentPage && !!onSetPageSize;
  const isInvoiceSelectingEnabled = !!handleOnInvoicesOnSelect;

  if (isPagingEnabled && isInvoiceSelectingEnabled) {
    throw new Error("Simultaneous invoice selection and paging not implemented");
  }
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const [sidePanelContent, setsidePanelContent] = useState<React.ReactNode>();

  const closeSidePanel = () => {
    setSidePanelOpen(false);
  };

  const openSidePanel = (invoiceId: string) => {
    const selectedInvoice = invoices.find((invoice) => invoice.invoiceId === invoiceId);
    if (selectedInvoice) {
      setsidePanelContent(<InvoiceListErrorContent closePanel={closeSidePanel} invoice={selectedInvoice} />);
      setSidePanelOpen(true);
    }
  };

  const invoiceHasErrors = (invoice: Invoice) => {
    if (invoice.navError && invoice.status !== InvoiceStatus.inTransit && invoice.status !== InvoiceStatus.sent) {
      return true;
    }
    if (!invoice.invoiceInfoId) {
      return true;
    }

    if (invoice.ourReferenceFrom === "contactPerson" && !invoice.contactPerson) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (locationState?.invoiceId) {
      const { state } = locationState;
      switch (state) {
        case "DeletedInvoice":
          addNotification("Lasku poistettiin", NotificationState.Success);
          break;
        case "DiscardInvoice":
          addNotification("Lasku hylättiin", NotificationState.Success);
          break;
        case "SentToFrends":
          addNotification("Laskun lähetystä Workdayhin pyydettiin onnistuneesti", NotificationState.Success);
          break;
      }
      window.history.replaceState({}, document.title);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Table>
        <HeaderRow>
          {isInvoiceSelectingEnabled && (
            <HeaderCell>
              <CheckboxInput
                data-testid="CheckAllInvoices"
                checked={isAllChecked}
                onChange={() => {
                  if (isAllChecked) {
                    handleOnInvoicesOnSelect?.([]);
                  } else {
                    handleOnInvoicesOnSelect?.(allSelectableInvoiceIds);
                  }
                }}
              />
            </HeaderCell>
          )}

          <HeaderCell style={{ width: "10%" }}>KIRJAUSPÄIVÄ</HeaderCell>
          <HeaderCell style={{ width: "20%" }}>ASIAKAS</HeaderCell>
          <HeaderCell style={{ width: "25%" }}>HUOMIOT</HeaderCell>
          <HeaderCell style={{ width: "20%" }}>MUODOSTUKSEN PERUSTEET</HeaderCell>
          <HeaderCell style={{ width: "15%" }}>VIITE</HeaderCell>
          <HeaderCell style={{ width: "10%" }}>LASKUTUS-KAUSI</HeaderCell>
          <RigthAlignHeaderCell style={{ width: "15%" }}>LOPPUSUMMA</RigthAlignHeaderCell>
          <LinkHeaderCell />
        </HeaderRow>
        <TableBody>
          {!isLoading &&
            [...invoicesWithErrors, ...invoicesWithoutErrors].map((invoice) => {
              const disabled = invoice.status === InvoiceStatus.inTransit;

              return (
                <Row key={invoice.invoiceId} data-testid="InvoiceRow">
                  {isInvoiceSelectingEnabled && (
                    <Cell>
                      <CheckboxInput
                        disabled={!isInvoiceSelectable(invoice)}
                        data-testid="InvoiceCheckbox"
                        checked={selectedInvoiceIds.includes(invoice.invoiceId)}
                        onChange={() => {
                          if (selectedInvoiceIds.includes(invoice.invoiceId)) {
                            handleOnInvoicesOnSelect(
                              selectedInvoiceIds.filter((invoiceId) => invoiceId !== invoice.invoiceId)
                            );
                          } else {
                            handleOnInvoicesOnSelect([...selectedInvoiceIds, invoice.invoiceId]);
                          }
                        }}
                      />
                    </Cell>
                  )}
                  <Cell disabled={disabled}>{dateToLocaleDateString(invoice.invoiceDate)}</Cell>
                  <Cell disabled={disabled}>{invoice.customerName ?? invoice.customerId}</Cell>
                  <Cell disabled={disabled}>
                    <InvoiceParameters
                      filters={getInvoiceNotifications(invoice)}
                      invoiceId={invoice.invoiceId}
                      openSidePanel={openSidePanel}
                      hasErrors={invoiceHasErrors(invoice)}
                    />
                  </Cell>
                  <Cell disabled={disabled}>
                    <InvoiceParameters
                      filters={mapInvoiceParameters(invoice)}
                      invoiceId={invoice.invoiceId}
                      openSidePanel={openSidePanel}
                      hasErrors={false}
                    />
                  </Cell>
                  <Cell disabled={disabled}>
                    {invoice.customerReference === null ? "Tyhjä" : invoice.customerReference}
                  </Cell>
                  <Cell disabled={disabled}>
                    {invoice.periodStart && dateToLocaleDateString(invoice.periodStart)}
                    {" – "}
                    {invoice.periodEnd && dateToLocaleDateString(invoice.periodEnd)}
                  </Cell>
                  <BoldRigthAlignCell disabled={disabled}>
                    {formatToEuroCurrencyString(new Big(invoice.total))}
                  </BoldRigthAlignCell>
                  <RigthAlignCell>
                    <LapaNavigationLink to={`${invoice.invoiceId}${props.type ? `?type=${props.type}` : ""}`}>
                      Avaa
                    </LapaNavigationLink>
                  </RigthAlignCell>
                </Row>
              );
            })}
        </TableBody>
      </Table>
      {isLoading && <Loading height={36} count={4} />}
      {isPagingEnabled && (
        <TablePagination
          currentPage={currentPage}
          onSetCurrentPage={onSetCurrentPage}
          pageSize={pageSize}
          onSetPageSize={onSetPageSize}
          pageCount={pageCount}
        />
      )}
      <SidePanel isOpen={isSidePanelOpen} closeSidePanel={closeSidePanel}>
        {sidePanelContent}
      </SidePanel>
    </Container>
  );
};

export default InvoiceList;
