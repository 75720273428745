import React from "react";
import styled, { keyframes } from "styled-components";
import Loading from "../../components/Loading";
import { Table, HeaderRow, HeaderCell, TableBody } from "../../components/Table";
import { ReferencesResponse } from "../../services/references";
import ReferenceRow from "./ReferenceRow";

const SlideIn = keyframes`
  from {
    transform: translateY(-30px);
    opacity: 0.5;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const NewReferenceRow = styled(ReferenceRow)`
  animation: ${SlideIn} 0.25s ease-out forwards;
`;

interface Props {
  references: ReferencesResponse;
  isAddingNewReference?: boolean;
  onCancelNewReference: () => void;
  onHideReference?: (reference: ReferencesResponse[number]) => void;
  onReferenceSave?: (
    referenceValue: string,
    isSelectableInHourReporting: boolean,
    editing: boolean,
    referenceName: string | null,
    referenceId?: string
  ) => void;
  refreshReferences: () => void;
  isLoading?: boolean;
}

const ReferenceTable = ({
  isAddingNewReference,
  onHideReference,
  onCancelNewReference,
  onReferenceSave,
  refreshReferences,
  references,
  isLoading,
}: Props) => (
  <>
    <Table>
      <HeaderRow>
        <HeaderCell style={{ width: "30%" }}>VIITE</HeaderCell>
        <HeaderCell style={{ width: "20%" }}>NIMI</HeaderCell>
        <HeaderCell style={{ width: "10%" }}>VALITTAVISSA TUNTIKIRJAUKSELLE</HeaderCell>
      </HeaderRow>
      <TableBody>
        {isAddingNewReference && (
          <NewReferenceRow
            onCancelAddingNewReference={onCancelNewReference}
            isAddingReference={true}
            data-testid="NewReferenceRow"
            refreshReferences={refreshReferences}
            onReferenceSave={onReferenceSave}
          />
        )}
        {!isLoading &&
          references.map((reference) => (
            <ReferenceRow
              key={reference.referenceId}
              reference={reference}
              refreshReferences={refreshReferences}
              onHide={onHideReference}
              onReferenceSave={onReferenceSave}
            />
          ))}
      </TableBody>
    </Table>
    {isLoading && <Loading height={36} count={4} />}
  </>
);

export default ReferenceTable;
