import Big from "big.js";
import { Formik, FormikHelpers, Form, Field, FieldProps, FormikHandlers, FormikProps } from "formik";
import {
  DimensionType,
  PersistedItem,
  ProductType,
  ProductUnit,
  Serialized,
  UpdatedPricedItem,
} from "@barona/lapa-common-types";
import { useMemo } from "react";
import { useProducts } from "../../services/products";
import { NotificationState, useBaronaCompany, useNotifications } from "../../context";
import * as Yup from "yup";
import { frontendApi } from "@barona/lapa-common-frontend";
import {
  ButtonsContainer,
  ButtonsItem,
  CloseIcon,
  ContentContainer,
  Data,
  FormItem,
  handleEmployeeName,
  HeaderContainer,
  Label,
  getProductUnit,
  SidePaneContent,
  StyledButton,
  StyledSecondaryButton,
  StyledTextInput,
  SubmitIcon,
  ProductOption,
  SelectOption,
  StyledSelect,
  StyledErrorMessage,
  EmployeeOption,
} from "./CommonPanelContent";
import { formatToEuroCurrencyString } from "../../util/money";
import { useFrendsDimension } from "../../services/useFrendsDimension";

interface FormValues {
  productName: string;
  productCode: SelectOption;
  amount: string | null;
  productUnit: ProductUnit | null;
  unitPrice: string | null;
  areaDimension: SelectOption;
  businessUnitDimension: SelectOption;
  costPlaceDimension: SelectOption;
  serviceDimension: SelectOption;
  contractNumberDimension: string;
  projectDimension: SelectOption;
  employeeNumberDimension: SelectOption;
  employeeName: string;
}

const rowSchema = Yup.object().shape({
  unitPrice: Yup.number().typeError("Syötä hinta käyttäen numeroita").required("Yksikköhinta on pakollinen tieto"),
  amount: Yup.number().typeError("Syötä määrä käyttäen numeroita").required("Määrä on pakollinen tieto"),
  contractNumberDimension: Yup.string().matches(/^[0-9]+$/, {
    message: "Syötä sopimusnumero käyttäen numeroita",
    excludeEmptyString: true,
  }),
  productName: Yup.string().required("Tuotenimi on pakollinen tieto"),
  productCode: Yup.object().shape({
    value: Yup.string().required("Tuotekoodi on pakollinen tieto"),
  }),
  businessUnitDimension: Yup.object().shape({
    value: Yup.string().required("Toimiala on pakollinen tieto"),
  }),
  costPlaceDimension: Yup.object().shape({
    value: Yup.string().required("Kustannuspaikka on pakollinen tieto"),
  }),
  areaDimension: Yup.object().shape({
    value: Yup.string().required("Alue on pakollinen tieto"),
  }),
  serviceDimension: Yup.object().shape({
    value: Yup.string().required("Palvelu on pakollinen tieto"),
  }),
});

const calculateTotal = (unitPrice: FormValues["unitPrice"], amount: FormValues["amount"]) => {
  if (unitPrice && amount) {
    try {
      return formatToEuroCurrencyString(new Big(unitPrice).round(2).mul(amount));
    } catch (error) {
      return " - ";
    }
  }
  return " - ";
};

const handleSelectOptionInitialValue = (option: string | undefined | null) => ({
  value: option || "",
  label: option || "",
});

const handleProductCodeChange = (option: ProductOption, setFieldValue: FormikHelpers<FormValues>["setFieldValue"]) => {
  setFieldValue("productCode", option);
  setFieldValue("productName", option.productName);
  setFieldValue("productUnit", option.productUnit);
};

const handleEmployeeChange = (option: EmployeeOption, setFieldValue: FormikHelpers<FormValues>["setFieldValue"]) => {
  setFieldValue("employeeNumberDimension", option);
  setFieldValue("employeeName", option.employeeName);
};

const handleNumberValueChange = (
  fieldName: string,
  event: React.FormEvent<HTMLInputElement>,
  setFieldValue: FormikHelpers<FormValues>["setFieldValue"],
  handleBlur: FormikHandlers["handleBlur"]
) => {
  try {
    const newValue = Big(event.currentTarget.value).toFixed(2);
    setFieldValue(fieldName, newValue);
  } catch (error) {
  } finally {
    handleBlur(event);
  }
};

export enum InvoiceItemPanelAction {
  create = "create",
  edit = "edit",
}

export interface EditableItemPanelContentProps {
  item?: Serialized<PersistedItem>;
  closeSidePanel(): void;
  invoiceId: string;
  setIsUpdateRequired: Function;
  action: InvoiceItemPanelAction;
  baronaCompanyCode?: string;
  customerId?: string;
}

const EditableItemPanelContent = ({
  item,
  closeSidePanel,
  invoiceId,
  setIsUpdateRequired,
  action,
  baronaCompanyCode,
  customerId,
}: EditableItemPanelContentProps) => {
  const { products, isLoading: isProductsLoading } = useProducts([ProductType.Mepco, ProductType.Manual]);
  const { baronaCompany } = useBaronaCompany();
  const { dimensionValues: areas, isLoading: isAreasLoading } = useFrendsDimension(DimensionType.area, baronaCompany);
  const { dimensionValues: businessUnits, isLoading: isBusinessUnitsLoading } = useFrendsDimension(
    DimensionType.businessUnitD,
    baronaCompany
  );
  const { dimensionValues: costPlaces, isLoading: isCostPlacesLoading } = useFrendsDimension(
    DimensionType.costPlace,
    baronaCompany
  );
  const { dimensionValues: services, isLoading: isServicesLoading } = useFrendsDimension(
    DimensionType.service,
    baronaCompany
  );
  const { dimensionValues: projects, isLoading: isProjectLoading } = useFrendsDimension(
    DimensionType.project,
    baronaCompany
  );
  const { dimensionValues: employees, isLoading: isEmployeeLoading } = useFrendsDimension(
    DimensionType.employee,
    baronaCompany
  );

  const productOptions: ProductOption[] = useMemo(
    () =>
      products
        .map((product) => ({
          value: product.productCode,
          label: product.productCode,
          productName: product.name,
          productUnit: product.unit,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [products]
  );

  const areaOptions: SelectOption[] = useMemo(
    () => areas.map((area) => ({ value: area.code, label: `${area.code} - ${area.name}` })),
    [areas]
  );

  const businessUnitOptions: SelectOption[] = useMemo(
    () =>
      businessUnits.map((businessUnit) => ({
        value: businessUnit.code,
        label: `${businessUnit.code} - ${businessUnit.name}`,
      })),
    [businessUnits]
  );

  const costPlaceOptions: SelectOption[] = useMemo(
    () => costPlaces.map((costPlace) => ({ value: costPlace.code, label: `${costPlace.code} - ${costPlace.name}` })),
    [costPlaces]
  );

  const servicesOptions: SelectOption[] = useMemo(
    () => services.map((service) => ({ value: service.code, label: `${service.code} - ${service.name}` })),
    [services]
  );

  const projectOptions: SelectOption[] = useMemo(
    () => projects.map((project) => ({ value: project.code, label: `${project.code} - ${project.name}` })),
    [projects]
  );

  const employeeOptions: EmployeeOption[] = useMemo(
    () => employees.map((employee) => ({ value: employee.code, label: employee.code, employeeName: employee.name })),
    [employees]
  );

  const { addNotification } = useNotifications();

  const handleSubmit = async (values: FormValues) => {
    if (action === InvoiceItemPanelAction.create) {
      const newItem = {
        invoiceId,
        baronaCompanyCode: baronaCompanyCode ?? "",
        customerId: customerId ?? "",
        amount: values.amount,
        price: new Big(values.unitPrice!),
        costPlaceDimension: values.costPlaceDimension.value,
        businessUnitDimension: values.businessUnitDimension.value,
        areaDimension: values.areaDimension.value,
        serviceDimension: values.serviceDimension.value,
        contractNumberDimension: values.contractNumberDimension,
        projectDimension: values.projectDimension.value,
        productCode: values.productCode.value,
        productName: values.productName,
        productUnit: values.productUnit,
        employeeNumberDimension: values.employeeNumberDimension.value,
        employeeName: values.employeeName,
      };
      try {
        await frontendApi.createInvoiceItem(newItem);
        setIsUpdateRequired(true);
        addNotification("Laskutapahtuman lisäys onnistui", NotificationState.Success);
        closeSidePanel();
      } catch (error) {
        addNotification("Laskutapahtuman lisäys epäonnistui", NotificationState.Error);
      }
    } else {
      const updatedItem: UpdatedPricedItem = {
        itemId: item!.itemId,
        amount: values.amount,
        price: new Big(values.unitPrice!),
        costPlaceDimension: values.costPlaceDimension.value,
        businessUnitDimension: values.businessUnitDimension.value,
        areaDimension: values.areaDimension.value,
        serviceDimension: values.serviceDimension.value,
        contractNumberDimension: values.contractNumberDimension,
        projectDimension: values.projectDimension.value,
        productCode: values.productCode.value,
        productName: values.productName,
        productUnit: values.productUnit,
        employeeNumberDimension: values.employeeNumberDimension.value,
        employeeName: values.employeeName,
        source: item!.source,
      };
      try {
        await frontendApi.updateInvoiceItems({ item: updatedItem, invoiceId });
        setIsUpdateRequired(true);
        addNotification("Laskutapahtuman muokkaaminen onnistui", NotificationState.Success);
        closeSidePanel();
      } catch (error) {
        addNotification("Laskutapahtuman muokkaaminen epäonnistui", NotificationState.Error);
      }
    }
  };

  const initialValues: FormValues = {
    productName: item?.productName || "",
    productCode: handleSelectOptionInitialValue(item?.productCode),
    amount: item?.amount ? item.amount.toString() : null,
    productUnit: item?.productUnit || null,
    unitPrice: item?.billable && item?.price ? Big(item.price).toFixed(2) : null,
    areaDimension: handleSelectOptionInitialValue(item?.areaDimension),
    businessUnitDimension: handleSelectOptionInitialValue(item?.businessUnitDimension),
    costPlaceDimension: handleSelectOptionInitialValue(item?.costPlaceDimension),
    serviceDimension: handleSelectOptionInitialValue(item?.serviceDimension),
    contractNumberDimension: item?.contractNumberDimension || "",
    projectDimension: handleSelectOptionInitialValue(item?.projectDimension),
    employeeNumberDimension: handleSelectOptionInitialValue(item?.employeeNumberDimension),
    employeeName: handleEmployeeName(item) || "",
  };

  return (
    <SidePaneContent>
      <HeaderContainer>
        <h2>
          {InvoiceItemPanelAction.create ? "Uusi tapahtuma" : `Rivin tiedot: ${item?.productName} ${item?.productCode}`}
        </h2>
        <div>
          <CloseIcon data-testid="closeIcon" onClick={closeSidePanel} />
        </div>
      </HeaderContainer>

      <Formik
        initialValues={initialValues}
        validationSchema={rowSchema}
        onSubmit={(values: FormValues) => handleSubmit(values)}
      >
        {({ setFieldValue, values, handleBlur, isSubmitting }: FormikProps<FormValues>) => (
          <Form>
            <ContentContainer>
              <FormItem>
                <Label>SELITE</Label>
                <Data>
                  <Field name="productName">
                    {({ field }: FieldProps) => <StyledTextInput {...field} maxLength={50} />}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>TUOTE</Label>
                <Data>
                  <Field name="productCode">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        options={productOptions}
                        onChange={(option) => handleProductCodeChange(option as ProductOption, setFieldValue)}
                        isLoading={isProductsLoading}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>MÄÄRÄ *</Label>
                <Data>
                  <Field name="amount">
                    {({ field }: FieldProps) => (
                      <StyledTextInput
                        {...field}
                        onBlur={(value) => handleNumberValueChange("amount", value, setFieldValue, handleBlur)}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="amount" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label>YKSIKKÖ</Label>
                <Data>{getProductUnit(values.productUnit)}</Data>
              </FormItem>
              <FormItem>
                <Label>A-HINTA *</Label>
                <Data>
                  <Field name="unitPrice">
                    {({ field }: FieldProps) => (
                      <StyledTextInput
                        {...field}
                        onBlur={(value) => handleNumberValueChange("unitPrice", value, setFieldValue, handleBlur)}
                      />
                    )}
                  </Field>
                  <StyledErrorMessage name="unitPrice" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label>YHTEENSÄ</Label>
                <Data>{calculateTotal(values.unitPrice, values.amount)}</Data>
              </FormItem>
              <FormItem>
                <Label>TOIMIALA *</Label>
                <Data>
                  <Field name="businessUnitDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        options={businessUnitOptions}
                        onChange={(option) => setFieldValue("businessUnitDimension", option)}
                        isLoading={isBusinessUnitsLoading}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>ALUE *</Label>
                <Data>
                  <Field name="areaDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        options={areaOptions}
                        onChange={(option) => setFieldValue("areaDimension", option)}
                        isLoading={isAreasLoading}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>PALVELU *</Label>
                <Data>
                  <Field name="serviceDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        options={servicesOptions}
                        onChange={(option) => setFieldValue("serviceDimension", option)}
                        isLoading={isServicesLoading}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>KUSTANNUSPAIKKA *</Label>
                <Data>
                  <Field name="costPlaceDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        options={costPlaceOptions}
                        onChange={(option) => setFieldValue("costPlaceDimension", option)}
                        isLoading={isCostPlacesLoading}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>SOPIMUSNUMERO</Label>
                <Data>
                  <Field name="contractNumberDimension">
                    {({ field }: FieldProps) => <StyledTextInput {...field} maxLength={12} />}
                  </Field>
                  <StyledErrorMessage name="contractNumberDimension" component="div" />
                </Data>
              </FormItem>
              <FormItem>
                <Label>PROJEKTI</Label>
                <Data>
                  <Field name="projectDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        options={projectOptions}
                        onChange={(option) => setFieldValue("projectDimension", option)}
                        isLoading={isProjectLoading}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>TYÖNTEKIJÄ</Label>
                <Data>
                  <Field name="employeeName">
                    {({ field }: FieldProps) => (
                      <StyledTextInput
                        {...field}
                        maxLength={50}
                        disabled={values.employeeNumberDimension.value.length > 0}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <FormItem>
                <Label>TYÖNTEKIJÄNUMERO</Label>
                <Data>
                  <Field name="employeeNumberDimension">
                    {({ field }: FieldProps) => (
                      <StyledSelect
                        {...field}
                        options={employeeOptions}
                        onChange={(option) => handleEmployeeChange(option as EmployeeOption, setFieldValue)}
                        isLoading={isEmployeeLoading}
                        openMenuOnClick={false}
                        isSearchable={true}
                      />
                    )}
                  </Field>
                </Data>
              </FormItem>
              <ButtonsItem>
                <ButtonsContainer>
                  <StyledSecondaryButton type="button" onClick={closeSidePanel}>
                    Peruuta
                  </StyledSecondaryButton>
                  <StyledButton type="submit" disabled={isSubmitting}>
                    Tallenna rivi {isSubmitting && <SubmitIcon />}
                  </StyledButton>
                </ButtonsContainer>
              </ButtonsItem>
            </ContentContainer>
          </Form>
        )}
      </Formik>
    </SidePaneContent>
  );
};

export default EditableItemPanelContent;
