import {
  DateTime,
  DateTimeJSOptions,
  DateTimeOptions,
  FixedOffsetZone,
  LocaleOptions,
  Settings,
  IANAZone,
} from "luxon";

export { DateTime, FixedOffsetZone, IANAZone };
export const utcZone = FixedOffsetZone.utcInstance;
export const fiLocale = "fi-FI";

/**
 * Set luxon default locale. Useful in tests where you don't want snapshot generation to differ from machine to machine
 * @param locale
 */
export const setDefaultLocale = (locale: string = fiLocale) => {
  Settings.defaultLocale = locale;
};

/**
 * Set luxon default zone. Useful in tests where you don't want snapshot generation to differ from machine to machine
 * @param zone
 */
export const setDefaultZone = (zone: IANAZone = utcZone) => {
  Settings.defaultZone = zone;
};

/**
 * Convert javascript Date object to an ISO date string, eg. "2020-01-01"
 * @param date Javascript Date object
 * @param options Can be used to modify timezone and locale options. By default UTC timezone is used.
 * @returns Date as an ISO date string, eg. "2020-01-01"
 */
export const dateToISODate = (date: Date, options?: DateTimeJSOptions) =>
  DateTime.fromJSDate(date, { zone: utcZone, ...options }).toISODate();

/**
 * Convert ISO date string to a human readable date format
 * @param dateISOString
 * @param options Can be used to modify timezone and locale options. By default UTC timezone is used.
 * @returns Date in human readable format, eg. "25.8.2021"
 */
export const dateISOStringToLocaleDateString = (
  dateISOString: string,
  options: { dateOptions?: DateTimeOptions; localeOptions?: LocaleOptions } = {}
) =>
  DateTime.fromISO(dateISOString, { zone: utcZone, ...options.dateOptions }).toLocaleString(
    undefined,
    options.localeOptions
  );

/**
 * Convert ISO date string to a human readable date and time format
 * @param dateISOString
 * @param options Can be used to modify timezone and locale options. By default UTC timezone is used.
 * @returns Date in human readable format, eg. "1.1.2021 klo 0.00.00"
 */
export const dateISOStringToLocaleDateTimeString = (
  dateISOString: string,
  options: { dateOptions?: DateTimeOptions } = {}
) =>
  DateTime.fromISO(dateISOString, { zone: utcZone, ...options.dateOptions })
    .setLocale(fiLocale)
    .toFormat("d.M.yyyy' klo 'H.mm.ss");

/**
 * Convert ISO date string without timestamp to a javascript date with UTC timestamp
 * @param dateISOString
 * @param options Can be used to modify timezone and locale options. By default UTC timezone is used.
 * @returns Date in human readable format, eg. "1.1.2021 klo 0.00.00"
 */
export const dateISOStringWithoutTimeToDate = (
  dateISOStringWithoutTimestamp: string,
  options: { dateOptions?: DateTimeOptions; localeOptions?: LocaleOptions } = {}
) => DateTime.fromISO(dateISOStringWithoutTimestamp, { zone: utcZone, ...options.dateOptions }).toJSDate();

/**
 * Convert javascript date object to a human readable date format
 * @param date
 * @param options Can be used to modify timezone and locale options. By default UTC timezone is used.
 * @returns Date in human readable format, eg. "25.8.2021"
 */
export const dateToLocaleDateString = (
  date: Date,
  options: { dateOptions?: DateTimeOptions; localeOptions?: LocaleOptions } = {}
) =>
  DateTime.fromJSDate(date, { zone: utcZone, ...options.dateOptions }).toLocaleString(undefined, options.localeOptions);

/**
 * Determines the invoice date based on the end date of the invoice period
 * @param invoicePeriodEndDate
 * @returns Date
 */
export const determineInvoiceDate = (invoicePeriodEndDate: Date): Date => {
  const date = DateTime.fromJSDate(invoicePeriodEndDate, { zone: utcZone });

  // If invoice period end date is the last day of the year, use the same date
  if (date.ordinal === date.daysInYear) {
    return date.toJSDate();
  }

  // If invoice period end date is the first day of month, second day is used instead
  if (date.day === 1) {
    return date.plus({ days: 1 }).toJSDate();
  }

  // If invoice period end date is the last day of the month, use the first day of next month
  if (date.day === date.daysInMonth) {
    return date.plus({ days: 1 }).toJSDate();
  }

  // Otherwise use invoice period end date as is
  return date.toJSDate();
};

/**
 * Convert a finnish date string eg. "01.01.2010" to a Date.
 * Throws if date could not be parsed from input string.
 * @param finnishDateString
 * @returns Date
 */
export const finnishDateStringToDate = (finnishDateString: string): Date => {
  const date = DateTime.fromFormat(finnishDateString, "dd.MM.yyyy", { zone: utcZone });
  if (!date.isValid) throw new Error(`Could not parse valid date from input string "${finnishDateString}"`);
  return date.toJSDate();
};

/**
 * Convert a date to Finnish date string eg. a Date to "01.01.2010".
 * @param date
 * @returns date string in 'dd.MM.yyyy' format
 */
export const dateToFinnishDateString = (date: Date): string =>
  DateTime.fromJSDate(date).setZone(utcZone).toFormat("dd.MM.yyyy");
