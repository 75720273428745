import styled from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import Color from "../colors";

const ToggleStyle = styled.div`
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${Color.LIGHT_BLUE};
  }
  .react-toggle-track {
    background-color: ${Color.LIGHT_BLUE};
  }

  .react-toggle-thumb {
    border-color: ${Color.LIGHT_BLUE};
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${Color.LAPA_BLUE};
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${Color.LAPA_BLUE};
  }

  .react-toggle--checked .react-toggle-thumb {
    border-color: ${Color.LAPA_BLUE};
  }
`;

const BaronaToggle = ({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <ToggleStyle>
    <Toggle checked={checked} icons={false} onChange={onChange} />
  </ToggleStyle>
);

export default BaronaToggle;
