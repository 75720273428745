import CreatableSelect, { CreatableProps } from "react-select/creatable";
import { GroupBase } from "react-select";
import Color from "../../colors";
import styled from "styled-components";

const CreatableSelectContainer = styled.div``;

interface CreateableSelectProps {
  testId?: string;
  width?: number;
}

const CreateableSelect = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: CreatableProps<Option, IsMulti, Group> & CreateableSelectProps
) => (
  <CreatableSelectContainer data-testid={props.testId ? props.testId : "CreateSelect"}>
    <CreatableSelect
      styles={{
        indicatorSeparator: () => ({ display: "none" }),
        container: (styles) => ({
          ...styles,
          minWidth: "auto",
        }),
        menu: (styles) => ({
          ...styles,
          fontSize: "14px",
          width: props.width ? `${props.width}px` : styles.width,
          color: Color.BLACK,
        }),
        control: (styles, state) => ({
          ...styles,
          fontSize: "14px",
          backgroundColor: `${state.isDisabled ? Color.DISABLED_INPUT : Color.WHITE}`,
          display: "flex",
          alignItems: "center",
          border: `solid 1px ${Color.GREY_LIGHT}`,
          color: Color.BLACK,
          borderRadius: "3px",
          transition: "box-shadow 0.1s linear",
          boxShadow: state.isFocused ? `0px 0px 0px 2px ${Color.LAPA_BLUE}` : "none",
          outline: "none",
          ":hover": {
            borderColor: Color.GREY_LIGHT,
          },
        }),
      }}
      {...props}
    />
  </CreatableSelectContainer>
);

export default CreateableSelect;
