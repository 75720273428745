import styled from "styled-components";
import { Title } from "../common/Title";
import { SubTitle } from "./SubTitle";

const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin-bottom: 48px;
  li {
    margin-bottom: 16px;
  }
`;

const InstructionsColumn = () => (
  <>
    <Title>Ohjeet</Title>

    <SubTitle>Laskutusväli</SubTitle>
    <List>
      <li>
        <b>Palkkakausittain:</b> Samalle palkkakaudelle osuvat tapahtumat menevät samalle laskulle.
      </li>
      <li>
        <b>Ei määritelty:</b> Tapahtumat kertyvät laskulle, jonka laskuttaja aika ajoin lähettää asiakkaalle.
      </li>
    </List>

    <SubTitle>Viitteiden käsittely</SubTitle>

    <List>
      <li>
        <b>Joka viittestä oma lasku:</b> Jokaisesta tapahtumasta löytyvästä viitteestä tehdään oma lasku.
      </li>
      <li>
        <b>Valituista viitteistä lasku:</b> Yhdestä tai useammasta valitusta viitteestä tehdään yksi lasku. Käytetään
        yleensä asiakkaan erityistoiveiden hallintaan ylimääräisen pörhistelyn välttämiseksi.
      </li>
      <li>
        <b>Kaikki viitteet samalle laskulle:</b> Kaikki asiakkaan samalle tapahtumavälille osuvat tapahtumat laitetaan
        yhdelle laskulle viitteestä riippumatta.
      </li>
    </List>

    <SubTitle>Laskun tiedot</SubTitle>
    <List>
      <li>
        <b>Asiakkaan viite:</b> Asiakkaan viite voi olla tapahtumien viite tai sen voi rustata käsin. Mikäli viitteitä
        on valittu filtteriksi useita, viite on pakkokin rustata käsin, tai jättää tyhjäksi.
      </li>
      <li>
        <b>Baronan viite:</b> Baronan viitteen voi syöttää käsin.
      </li>
    </List>
  </>
);

export default InstructionsColumn;
