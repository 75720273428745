import React from "react";
import { BaronaCompany, PersistedInvoiceRowProduct, PersistedItem, Serialized } from "@barona/lapa-common-types";
import InvoiceRowPanelContent from "./InvoiceRowPanelContent";
import EditableInvoiceRowPanelContent, { InvoiceRowPanelAction } from "./EditableInvoiceRowPanelContent";
import ItemPanelContent from "./ItemPanelContent";
import EditableItemPanelContent, { InvoiceItemPanelAction } from "./EditableItemPanelContent";
import SidePanel from "../../components/SidePanel";

export enum InvoiceSidePanelAction {
  view = "view",
  create = "create",
  edit = "edit",
  editDescription = "editDescription",
  copy = "copy",
}

export enum InvoiceSidePanelType {
  ItemRow = "ItemRow",
  InvoiceRow = "InvoiceRow",
}

interface InvoiceSidePanelRowBase {
  sidePanelType: InvoiceSidePanelType.InvoiceRow;
  invoiceRow: Serialized<PersistedInvoiceRowProduct>;
  invoiceId: string;
}

interface InvoiceSidePanelViewRowParams extends Omit<InvoiceSidePanelRowBase, "invoiceId"> {
  action: InvoiceSidePanelAction.view;
}

interface InvoiceSidePanelEditDescriptionRowParams extends InvoiceSidePanelRowBase {
  action: InvoiceSidePanelAction.editDescription;
}

interface InvoiceSidePanelEditRowParams extends InvoiceSidePanelRowBase {
  action: InvoiceSidePanelAction.edit;
}

interface InvoiceSidePanelCopyRowParams extends InvoiceSidePanelRowBase {
  action: InvoiceSidePanelAction.copy;
}

interface InvoiceSidePanelCreateRowParams extends Omit<InvoiceSidePanelRowBase, "invoiceRow"> {
  action: InvoiceSidePanelAction.create;
}

type InvoiceSidePanelRowContentParams =
  | InvoiceSidePanelViewRowParams
  | InvoiceSidePanelEditDescriptionRowParams
  | InvoiceSidePanelEditRowParams
  | InvoiceSidePanelCopyRowParams
  | InvoiceSidePanelCreateRowParams;

const getSidePanelContentForRow = ({
  content,
  closeSidePanel,
  setIsUpdateRequired,
}: {
  content?: InvoiceSidePanelRowContentParams;
  closeSidePanel: () => void;
  setIsUpdateRequired: Function;
}): JSX.Element => {
  switch (content?.action) {
    case InvoiceSidePanelAction.view:
      return <InvoiceRowPanelContent invoiceRow={content.invoiceRow} closeSidePanel={closeSidePanel} />;
    case InvoiceSidePanelAction.editDescription:
      return (
        <EditableInvoiceRowPanelContent
          action={InvoiceRowPanelAction.view}
          invoiceRow={content.invoiceRow!}
          invoiceId={content.invoiceId}
          closeSidePanel={closeSidePanel}
          setIsUpdateRequired={setIsUpdateRequired}
        />
      );
    case InvoiceSidePanelAction.edit:
      return (
        <EditableInvoiceRowPanelContent
          action={InvoiceRowPanelAction.edit}
          invoiceRow={content.invoiceRow!}
          invoiceId={content.invoiceId}
          closeSidePanel={closeSidePanel}
          setIsUpdateRequired={setIsUpdateRequired}
        />
      );
    case InvoiceSidePanelAction.copy:
      return (
        <EditableInvoiceRowPanelContent
          action={InvoiceRowPanelAction.copy}
          invoiceRow={content.invoiceRow!}
          invoiceId={content.invoiceId}
          closeSidePanel={closeSidePanel}
          setIsUpdateRequired={setIsUpdateRequired}
        />
      );
    case InvoiceSidePanelAction.create:
      return (
        <EditableInvoiceRowPanelContent
          action={InvoiceRowPanelAction.create}
          invoiceId={content.invoiceId}
          closeSidePanel={closeSidePanel}
          setIsUpdateRequired={setIsUpdateRequired}
        />
      );
    default:
      return <></>;
  }
};

interface InvoiceSidePanelItemBase {
  sidePanelType: InvoiceSidePanelType.ItemRow;
  item: Serialized<PersistedItem>;
}

interface InvoiceSidePanelViewItemParams extends InvoiceSidePanelItemBase {
  action: InvoiceSidePanelAction.view;
}

interface InvoiceSidePanelEditItemParams extends InvoiceSidePanelItemBase {
  action: InvoiceSidePanelAction.edit;
  invoiceId: string;
}

interface InvoiceSidePanelCreateItemParams extends Omit<InvoiceSidePanelItemBase, "item"> {
  action: InvoiceSidePanelAction.create;
  item?: InvoiceSidePanelItemBase["item"];
  invoiceId: string;
  baronaCompanyCode: BaronaCompany;
  customerId?: string;
}

type InvoiceSidePanelItemContentParams =
  | InvoiceSidePanelViewItemParams
  | InvoiceSidePanelEditItemParams
  | InvoiceSidePanelCreateItemParams;

const getSidePanelContentForItem = ({
  content,
  closeSidePanel,
  setIsUpdateRequired,
}: {
  content?: InvoiceSidePanelItemContentParams;
  closeSidePanel: () => void;
  setIsUpdateRequired: Function;
}): JSX.Element => {
  switch (content?.action) {
    case InvoiceSidePanelAction.view:
      return <ItemPanelContent item={content.item} closeSidePanel={closeSidePanel} />;
    case InvoiceSidePanelAction.edit:
      return (
        <EditableItemPanelContent
          action={InvoiceItemPanelAction.edit}
          item={content.item!}
          invoiceId={content.invoiceId}
          closeSidePanel={closeSidePanel}
          setIsUpdateRequired={setIsUpdateRequired}
        />
      );
    case InvoiceSidePanelAction.create:
      return (
        <EditableItemPanelContent
          action={InvoiceItemPanelAction.create}
          item={content.item}
          invoiceId={content.invoiceId}
          baronaCompanyCode={content.baronaCompanyCode}
          customerId={content.customerId}
          closeSidePanel={closeSidePanel}
          setIsUpdateRequired={setIsUpdateRequired}
        />
      );
    default:
      return <></>;
  }
};

export type InvoiceSidePanelContentParams = InvoiceSidePanelRowContentParams | InvoiceSidePanelItemContentParams;

interface Props {
  isOpen: boolean;
  closeSidePanel: () => void;
  setIsUpdateRequired: Function;
  content?: InvoiceSidePanelContentParams;
}

const InvoiceContentSidePanel = ({ isOpen, closeSidePanel, content, setIsUpdateRequired }: Props) => {
  const contentAsJsx: JSX.Element =
    content?.sidePanelType === InvoiceSidePanelType.InvoiceRow
      ? getSidePanelContentForRow({ content, closeSidePanel, setIsUpdateRequired })
      : getSidePanelContentForItem({ content, closeSidePanel, setIsUpdateRequired });

  return (
    <SidePanel isOpen={isOpen} closeSidePanel={closeSidePanel}>
      {contentAsJsx}
    </SidePanel>
  );
};

export default InvoiceContentSidePanel;
