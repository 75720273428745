import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import CheckboxInput from "../../../components/form/CheckboxInput";
import TextInput from "../../../components/form/TextInput";
import Color from "../../../colors";
import DatePicker from "../../../components/DatePicker";
import CreateableSelect from "../../../components/form/CreatableSelects";
import Select from "../../../components/form/Select";
import { useBaronaCompany } from "../../../context";
import { BaronaCompany, InvoiceGroupBy } from "@barona/lapa-common-types";

export const CUSTOMER_CONTACT_PERSON_OPTION_VALUE = "customerContact";

const Title = styled.div`
  margin-bottom: 8px;
`;

const Label = styled.label`
  display: flex;
  padding: 5px 0;
  color: ${Color.GREY_DARK};
`;

const InputGroup = styled.div`
  margin-bottom: 10px;
  display: grid;
  color: ${Color.GREY_DARK};
`;

const CheckboxInputGroup = styled.div`
  margin-bottom: 10px;
  color: ${Color.GREY};
  user-select: none;
`;

export interface InvoiceParamsValue {
  invoiceDate?: Date;
  referenceOnInvoice?: string;
  ourReference?: string;
  useStaffingContractContactPersonAsOurReference: boolean;
  groupBy: InvoiceGroupBy;
  automaticallyCreateInvoicesUsingReferences: boolean;
}

interface Props {
  value: InvoiceParamsValue;
  onChange?: (values: InvoiceParamsValue) => any;
  paymentPeriod?: string;
}

const InvoiceParams = (props: Props) => {
  const { baronaCompany } = useBaronaCompany();
  const isOnlyGroupByProductEnabled = baronaCompany === BaronaCompany.Varastopalvelut;

  const [groupByOptions, setGroupByOptions] = useState([
    { value: InvoiceGroupBy.product, label: "Tuotteittain", disabled: false },
    { value: InvoiceGroupBy.person, label: "Työntekijöittäin", disabled: isOnlyGroupByProductEnabled },
    {
      value: InvoiceGroupBy.referenceAndPerson,
      label: "Viitteittäin ja työntekijöittäin",
      disabled: isOnlyGroupByProductEnabled,
    },
  ]);

  const setGroupByOptionDisabled = useCallback(
    (value: InvoiceGroupBy[], disabled: boolean) => {
      setGroupByOptions(
        [...groupByOptions].map((option) => (value.includes(option.value) ? { ...option, disabled: disabled } : option))
      );
    },
    [groupByOptions, setGroupByOptions]
  );

  const handleInvoiceDateChange = (date: Date) => {
    props.onChange?.({
      ...props.value,
      invoiceDate: date || undefined,
    });
  };

  const handleReferenceOnInvoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.({
      ...props.value,
      referenceOnInvoice: event.target.value || undefined,
    });
  };

  const handleGroupByChange = useCallback(
    (groupBy: InvoiceGroupBy) => {
      props.onChange?.({
        ...props.value,
        groupBy,
      });
    },
    [props]
  );

  useEffect(() => {
    if (isOnlyGroupByProductEnabled && props.value.groupBy !== InvoiceGroupBy.product) {
      handleGroupByChange(groupByOptions[0].value);
    }
  }, [props.value.groupBy, isOnlyGroupByProductEnabled, groupByOptions, handleGroupByChange]);

  useEffect(() => {
    setGroupByOptionDisabled([InvoiceGroupBy.person, InvoiceGroupBy.referenceAndPerson], isOnlyGroupByProductEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyGroupByProductEnabled]);

  const handleAutomaticallyCreateInvoicesUsingReferencesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.({
      ...props.value,
      referenceOnInvoice: undefined,
      automaticallyCreateInvoicesUsingReferences: event.target.checked,
    });
    setGroupByOptionDisabled([InvoiceGroupBy.referenceAndPerson], event.target.checked);
    if (event.target.checked && props.value?.groupBy === InvoiceGroupBy.referenceAndPerson) {
      handleGroupByChange(groupByOptions[0].value);
    }
  };

  const handleOurReferenceChange = (value: { label: string; value: string } | null) => {
    props.onChange?.({
      ...props.value,
      ourReference: value?.value === CUSTOMER_CONTACT_PERSON_OPTION_VALUE ? undefined : value?.value,
      useStaffingContractContactPersonAsOurReference: value?.value === CUSTOMER_CONTACT_PERSON_OPTION_VALUE,
    });
  };

  const handleOnCreate = (ourReference: string) => {
    props.onChange?.({
      ...props.value,
      ourReference,
      useStaffingContractContactPersonAsOurReference: false,
    });
  };

  const CustomerContactOption = { label: "Asiakkaan yhteyshenkilö", value: CUSTOMER_CONTACT_PERSON_OPTION_VALUE };

  return (
    <>
      <InputGroup>
        <Title>Kirjauspäivä</Title>
        <DatePicker
          placeholderText="Anna päivämäärä"
          testId="InvoiceDate"
          onChange={handleInvoiceDateChange}
          selected={props.value?.invoiceDate}
        />
      </InputGroup>
      <InputGroup>
        <Title>Viite laskulla</Title>
        <TextInput
          data-testid="InvoiceReference"
          value={props.value?.referenceOnInvoice ?? ""}
          onChange={handleReferenceOnInvoiceChange}
          disabled={props.value?.automaticallyCreateInvoicesUsingReferences}
          placeholder="Viite laskulla"
        />
      </InputGroup>
      <InputGroup>
        <Title>Baronan viite</Title>
        <CreateableSelect
          onCreateOption={handleOnCreate}
          formatCreateLabel={(inputValue) => `Käytä viitteenä: ${inputValue}`}
          noOptionsMessage={() => "Ei viitteitä"}
          placeholder="Baronan viite"
          isLoading={false}
          options={[CustomerContactOption]}
          onChange={(value) => handleOurReferenceChange(value as { label: string; value: string })}
          value={
            props.value.useStaffingContractContactPersonAsOurReference
              ? CustomerContactOption
              : props.value.ourReference
              ? { value: props.value?.ourReference, label: props.value?.ourReference }
              : null
          }
          isClearable
        />
      </InputGroup>
      <InputGroup>
        <Title>Ryhmittely</Title>
        <div data-testid="GroupBy">
          <Select
            options={groupByOptions}
            isOptionDisabled={(option) => option.disabled}
            onChange={(value) => handleGroupByChange(value!.value)}
            value={groupByOptions.find((option) => option.value === props.value?.groupBy)}
          />
        </div>
      </InputGroup>
      <InputGroup>
        <Title>Laskutuskausi</Title>
        <span data-testid="PaymentPeriod">{props.paymentPeriod ?? "—"}</span>
      </InputGroup>
      <CheckboxInputGroup>
        <Label>
          <CheckboxInput
            data-testid="AutomaticallyCreateInvoicesUsingReferences"
            checked={props.value.automaticallyCreateInvoicesUsingReferences}
            onChange={handleAutomaticallyCreateInvoicesUsingReferencesChange}
            disabled={isOnlyGroupByProductEnabled || props.value.groupBy === InvoiceGroupBy.referenceAndPerson}
          />
          Joka viitteestä oma lasku
        </Label>
      </CheckboxInputGroup>
    </>
  );
};

export default InvoiceParams;
